"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) {
    var _a, _b, _c, _d;
    return ({
        errorWrap: (_a = {
                background: theme.palette.background.paper,
                boxShadow: theme.shadows[2],
                borderRadius: "50%",
                width: 700,
                height: 700
            },
            _a[theme.breakpoints.down("lg")] = {
                width: 400,
                height: 400,
            },
            _a.display = "flex",
            _a.alignItems = "center",
            _a.justifyContent = "center",
            _a.flexDirection = "column",
            _a.position = "relative",
            _a.margin = "".concat(theme.spacing(3), " auto"),
            _a["& h5"] = (_b = {},
                _b[theme.breakpoints.down("lg")] = {
                    fontSize: "1.2rem",
                },
                _b),
            _a),
        title: (_c = {
                color: theme.palette.primary.main,
                textShadow: "10px 6px 50px ".concat(theme.palette.primary.main)
            },
            _c[theme.breakpoints.down("lg")] = {
                fontSize: "4rem",
                marginBottom: theme.spacing(2),
            },
            _c),
        deco: {
            boxShadow: theme.shadows[2],
            position: "absolute",
            borderRadius: 2,
        },
        button: {
            marginTop: 24,
        },
        lottie: (_d = {
                borderRadius: "50%",
                width: 700 / 1.5,
                height: 700 / 1.5
            },
            _d[theme.breakpoints.down("lg")] = {
                width: 400 / 1.5,
                height: 400 / 1.5,
            },
            _d),
    });
});
exports.default = useStyles;
