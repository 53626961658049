"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.taskPanelViews = void 0;
var taskPanelViews = function (t) {
    return [
        { value: 0, label: t("flow.comment_panel.updates") },
        { value: 1, label: t("flow.comment_panel.updates_internal") },
        { value: 2, label: t("flow.comment_panel.activity_log") },
    ];
};
exports.taskPanelViews = taskPanelViews;
