"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
function replaceOrAppendItems(arr, val) {
    var res = __spreadArray([], arr, true);
    var i = arr.findIndex(function (v) { return v.id === val.id && v.type === val.type; });
    if (i === -1)
        res.push(val);
    else
        res.splice(i, 1, val);
    return res;
}
var collator = new Intl.Collator("en", { numeric: true, sensitivity: "base" });
function sortByPath(array) {
    return array.sort(function (a, b) { return collator.compare(a.path, b.path); });
}
var initialState = {
    loadings: __assign(__assign({}, constants_1.initialLoadings), { show: false }),
    permissionSets: [],
    permittedItems: [],
    permittedClients: [],
    sharedUsers: [],
    isAdding: false,
    isEditingId: null,
    clientPermissions: { editAll: false, viewAll: false, taskGroups: {}, tasks: {} },
};
function reducer(state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case "BPS_INDEX_LOADING":
        case "BPS_STORE_LOADING":
        case "BPS_SHOW_LOADING":
        case "BPS_DESTROY_LOADING":
        case "BPS_UPDATE_TITLE_LOADING":
        case "BPS_DETACH_LOADING":
        case "BPS_ATTACH_PERMITTED_CLIENT_LOADING":
        case "BPS_DETACH_PERMITTED_CLIENT_LOADING":
        case "BPS_TOGGLE_PERMISSION_LOADING":
        case "BPS_UPDATE_PERMITTED_ITEM_LOADING":
        case "BPS_DETACH_PERMITTED_ITEM_LOADING":
        case "BPS_ATTACH_PERMITTED_ITEM_LOADING":
        case "BPS_GET_SHARED_USERS_LOADING":
        case "BPS_REMOVE_SHARED_USER_LOADING":
        case "BPS_GET_CLIENT_PERMISSIONS_LOADING":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case "BPS_INDEX_FAILURE":
        case "BPS_STORE_FAILURE":
        case "BPS_SHOW_FAILURE":
        case "BPS_DESTROY_FAILURE":
        case "BPS_UPDATE_TITLE_FAILURE":
        case "BPS_DETACH_FAILURE":
        case "BPS_ATTACH_PERMITTED_CLIENT_FAILURE":
        case "BPS_DETACH_PERMITTED_CLIENT_FAILURE":
        case "BPS_TOGGLE_PERMISSION_FAILURE":
        case "BPS_UPDATE_PERMITTED_ITEM_FAILURE":
        case "BPS_DETACH_PERMITTED_ITEM_FAILURE":
        case "BPS_ATTACH_PERMITTED_ITEM_FAILURE":
        case "BPS_GET_SHARED_USERS_FAILURE":
        case "BPS_REMOVE_SHARED_USER_FAILURE":
        case "BPS_GET_CLIENT_PERMISSIONS_FAILURE":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadingType] = false, _b)) });
        case "BPS_INDEX_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_c = {}, _c[action.loadingType] = false, _c)), permissionSets: action.data.permissionSets });
        case "BPS_SHOW_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadingType] = false, _d)), permittedItems: action.data.permittedItems, permittedClients: action.data.permittedClients, permissionSets: state.permissionSets.map(function (permissionSet) {
                    if (permissionSet.id === action.data.setId) {
                        return __assign(__assign({}, permissionSet), { itemCount: action.data.permittedItems.length, clientCount: action.data.permittedClients.length });
                    }
                    return permissionSet;
                }) });
        case "BPS_CLEAR_CONTENT":
            return __assign(__assign({}, state), { permittedItems: [], permittedClients: [] });
        case "BPS_UPDATE_TITLE_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_e = {}, _e[action.loadingType] = false, _e)), permissionSets: state.permissionSets.map(function (permissionSet) {
                    if (permissionSet.id === action.data.id) {
                        return __assign(__assign({}, permissionSet), { title: action.data.title });
                    }
                    return permissionSet;
                }) });
        case "BPS_TOGGLE_PERMISSION_SUCCESS": {
            var _s = action.data, id_1 = _s.id, permittedItems = _s.permittedItems, permittedItemCount_1 = _s.permittedItemCount, perms_1 = __rest(_s, ["id", "permittedItems", "permittedItemCount"]);
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_f = {}, _f[action.loadingType] = false, _f)), permissionSets: state.permissionSets.map(function (permissionSet) {
                    if (permissionSet.id === id_1) {
                        return __assign(__assign(__assign({}, permissionSet), perms_1), { itemCount: permittedItemCount_1 });
                    }
                    return permissionSet;
                }), permittedItems: permittedItems });
        }
        case "BPS_ON_ADD":
            return __assign(__assign({}, state), { isAdding: !state.isAdding, isEditingId: null });
        case "BPS_ON_EDIT":
            return __assign(__assign({}, state), { isEditingId: action.id === state.isEditingId ? null : action.id });
        case "BPS_ATTACH_PERMITTED_ITEM_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_g = {}, _g[action.loadingType] = false, _g)), permittedItems: sortByPath(replaceOrAppendItems(state.permittedItems, action.data.permittedItem)), permissionSets: state.permissionSets.map(function (permissionSet) {
                    if (permissionSet.id === action.data.setId) {
                        return __assign(__assign({}, permissionSet), { itemCount: permissionSet.itemCount + 1 });
                    }
                    return permissionSet;
                }) });
        case "BPS_UPDATE_PERMITTED_ITEM_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_h = {}, _h[action.loadingType] = false, _h)), permittedItems: state.permittedItems.map(function (item) {
                    if (item.id === action.data.itemId && item.type === action.data.itemType) {
                        return __assign(__assign({}, item), { canEdit: action.data.itemCanEdit });
                    }
                    return item;
                }) });
        case "BPS_DETACH_PERMITTED_ITEM_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_j = {}, _j[action.loadingType] = false, _j)), permittedItems: state.permittedItems.filter(function (item) {
                    return item.type === action.data.itemType ? item.id !== action.data.itemId : true;
                }), permissionSets: state.permissionSets.map(function (permissionSet) {
                    if (permissionSet.id === action.data.setId) {
                        return __assign(__assign({}, permissionSet), { itemCount: permissionSet.itemCount - 1 > 0 ? permissionSet.itemCount - 1 : 0 });
                    }
                    return permissionSet;
                }) });
        case "BPS_STORE_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_k = {}, _k[action.loadingType] = false, _k)), permissionSets: __spreadArray(__spreadArray([], state.permissionSets, true), [action.data.set], false).sort(function (a, b) {
                    return a.title.localeCompare(b.title);
                }), isAdding: false, isEditingId: action.isCreator ? action.data.set.id : null });
        case "BPS_ATTACH_PERMITTED_CLIENT_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_l = {}, _l[action.loadingType] = false, _l)), permittedClients: state.isEditingId === action.data.setId
                    ? __spreadArray(__spreadArray([], state.permittedClients, true), action.data.permittedClients, true).sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    })
                    : state.permittedClients, permissionSets: state.permissionSets.map(function (permissionSet) {
                    if (permissionSet.id === action.data.setId) {
                        return __assign(__assign({}, permissionSet), { clientCount: permissionSet.clientCount + action.data.permittedClients.length });
                    }
                    return permissionSet;
                }) });
        case "BPS_DETACH_PERMITTED_CLIENT_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_m = {}, _m[action.loadingType] = false, _m)), permittedClients: state.isEditingId === action.data.setId
                    ? state.permittedClients.filter(function (client) { return client.id !== action.data.permittedClient.id; })
                    : state.permittedClients, permissionSets: state.permissionSets.map(function (permissionSet) {
                    if (permissionSet.id === action.data.setId) {
                        return __assign(__assign({}, permissionSet), { clientCount: permissionSet.clientCount - 1 > 0 ? permissionSet.clientCount - 1 : 0 });
                    }
                    return permissionSet;
                }) });
        case "BPS_DESTROY_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_o = {}, _o[action.loadingType] = false, _o)), permissionSets: state.permissionSets.filter(function (permissionSet) { return permissionSet.id !== action.data.setId; }), isEditingId: null, permittedClients: [], permittedItems: [] });
        case "BPS_GET_SHARED_USERS_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_p = {}, _p[action.loadingType] = false, _p)), sharedUsers: action.sharedUsers });
        case "BPS_REMOVE_SHARED_USER_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_q = {}, _q[action.loadingType] = false, _q)), sharedUsers: state.sharedUsers.filter(function (user) { return user.id !== action.payload.id; }) });
        case "BPS_GET_CLIENT_PERMISSIONS_SUCCESS":
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_r = {}, _r[action.loadingType] = false, _r)), clientPermissions: action.payload });
        case "BPS_CLEAR_CLIENT_PERMISSIONS":
            return __assign(__assign({}, state), { clientPermissions: initialState.clientPermissions });
        default:
            return state;
    }
}
exports.default = reducer;
