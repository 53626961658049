"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHANGE_COMMENT = exports.WS_REMOVE_COMMENT_SUCCESS = exports.REMOVE_COMMENT_SUCCESS = exports.REMOVE_COMMENT_FAILED = exports.REMOVE_COMMENT_LOADING = exports.WS_STORE_COMMENT_SUCCESS = exports.STORE_COMMENT_SUCCESS = exports.STORE_COMMENT_FAILED = exports.STORE_COMMENT_LOADING = exports.CLEAR_COMMENT_LOG = exports.GET_MENTIONABLE_LOG_SUCCESS = exports.GET_COMMENT_LOG_FAILED = exports.GET_COMMENT_LOG_SUCCESS = exports.GET_COMMENT_LOG_LOADING = void 0;
exports.GET_COMMENT_LOG_LOADING = "GET_COMMENT_LOG_LOADING";
exports.GET_COMMENT_LOG_SUCCESS = "GET_COMMENT_LOG_SUCCESS";
exports.GET_COMMENT_LOG_FAILED = "GET_COMMENT_LOG_FAILED";
exports.GET_MENTIONABLE_LOG_SUCCESS = "GET_MENTIONABLE_LOG_SUCCESS";
exports.CLEAR_COMMENT_LOG = "CLEAR_COMMENT_LOG";
exports.STORE_COMMENT_LOADING = "STORE_COMMENT_LOADING";
exports.STORE_COMMENT_FAILED = "STORE_COMMENT_FAILED";
exports.STORE_COMMENT_SUCCESS = "STORE_COMMENT_SUCCESS";
exports.WS_STORE_COMMENT_SUCCESS = "WS_STORE_COMMENT_SUCCESS";
exports.REMOVE_COMMENT_LOADING = "REMOVE_COMMENT_LOADING";
exports.REMOVE_COMMENT_FAILED = "REMOVE_COMMENT_FAILED";
exports.REMOVE_COMMENT_SUCCESS = "REMOVE_COMMENT_SUCCESS";
exports.WS_REMOVE_COMMENT_SUCCESS = "WS_REMOVE_COMMENT_SUCCESS";
exports.CHANGE_COMMENT = "CHANGE_COMMENT";
