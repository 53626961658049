"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var redux_1 = require("@hooks/redux");
var auth0_react_1 = require("@auth0/auth0-react");
var react_router_dom_1 = require("react-router-dom");
var styles_1 = require("@mui/material/styles");
var constants_1 = require("@api/constants");
var constants_2 = require("@pages/Nodes/constants");
var converters_1 = require("@helpers/colors/converters");
var nodeActions_1 = require("@pages/Nodes/reducers/nodeActions");
var color_1 = require("@helpers/flow/color");
var useStandardElement = function (dispatch) {
    var user = (0, auth0_react_1.useAuth0)().user;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var location = (0, react_router_dom_1.useLocation)();
    var theme = (0, styles_1.useTheme)();
    var id = (0, constants_1.getId)(location);
    var title = (0, redux_1.useAppSelector)(function (state) { return state.node.title; });
    var description = (0, redux_1.useAppSelector)(function (state) { return state.node.description; });
    var deletedAttributes = (0, redux_1.useAppSelector)(function (state) { return state.node.deletedAttributes; });
    var height = (0, redux_1.useAppSelector)(function (state) { return state.node.height; });
    var width = (0, redux_1.useAppSelector)(function (state) { return state.node.width; });
    var positionY = (0, redux_1.useAppSelector)(function (state) { return state.node.positionY; });
    var positionX = (0, redux_1.useAppSelector)(function (state) { return state.node.positionX; });
    var selectedAttributes = (0, redux_1.useAppSelector)(function (state) { return state.node.selectedAttributes; });
    var backgroundColor = (0, redux_1.useAppSelector)(function (state) { return state.node.backgroundColor; });
    var borderColor = (0, redux_1.useAppSelector)(function (state) { return state.node.borderColor; });
    var textColor = (0, redux_1.useAppSelector)(function (state) { return state.node.textColor; });
    var shape = (0, redux_1.useAppSelector)(function (state) { return state.node.shape; });
    var fitSizeToLabel = (0, redux_1.useAppSelector)(function (state) { return state.node.fitSizeToLabel; });
    var notes = (0, redux_1.useAppSelector)(function (state) { return state.node.notes; });
    var documents = (0, redux_1.useAppSelector)(function (state) { return state.node.documents; });
    var fontSize = (0, redux_1.useAppSelector)(function (state) { return state.node.fontSize; });
    var attributeFontSize = (0, redux_1.useAppSelector)(function (state) { return state.node.attributeFontSize; });
    var fontFamily = (0, redux_1.useAppSelector)(function (state) { return state.node.fontFamily; });
    var flag = (0, redux_1.useAppSelector)(function (state) { return state.node.flag; });
    var icon = (0, redux_1.useAppSelector)(function (state) { return state.node.icon; });
    var borderStyle = (0, redux_1.useAppSelector)(function (state) { return state.node.borderStyle; });
    var onSave = (0, react_1.useCallback)(function () {
        var newWidth = width === null ? 0 : width;
        var newHeight = height === null ? 0 : height;
        user &&
            id &&
            dispatch((0, nodeActions_1.putNode)(user, id, title, description, notes, documents, Array.from(selectedAttributes.values()), deletedAttributes, newWidth, newHeight, (0, converters_1.rgbaToHex)(backgroundColor), (0, converters_1.rgbaToHex)(borderColor), (0, converters_1.rgbaToHex)(textColor), shape, fitSizeToLabel, fontSize, attributeFontSize, fontFamily, flag, icon, navigate, location, borderStyle));
    }, [
        width,
        height,
        backgroundColor,
        borderColor,
        theme,
        user,
        id,
        title,
        description,
        notes,
        selectedAttributes,
        deletedAttributes,
        location,
        shape,
        textColor,
        fitSizeToLabel,
        documents,
        fontSize,
        attributeFontSize,
        fontFamily,
        flag,
        icon,
        borderStyle,
    ]);
    var nodeElement = (0, react_1.useMemo)(function () {
        var newWidth = width === null ? 0 : width * constants_2.cmToPxRatio;
        var newHeight = height === null ? 0 : height * constants_2.cmToPxRatio;
        var data = {
            label: title,
            backgroundColor: (0, color_1.toRgbString)(backgroundColor),
            borderColor: (0, color_1.toRgbString)(borderColor),
            textColor: (0, color_1.toRgbString)(textColor),
            figure: shape,
            width: newWidth,
            height: newHeight,
            fontSize: fontSize,
            flag: flag,
            attributeFontSize: attributeFontSize,
            fontFamily: fontFamily,
            description: description,
            attributes: selectedAttributes.size > 0 ? Array.from(selectedAttributes.values()) : [],
            fitSizeToLabel: fitSizeToLabel,
            icon: icon,
            borderStyle: borderStyle,
        };
        return [
            {
                id: "1",
                position: { x: positionX, y: positionY },
                data: data,
                type: "template",
                width: newWidth,
                height: newHeight,
            },
        ];
    }, [
        positionX,
        positionY,
        title,
        backgroundColor,
        borderColor,
        textColor,
        shape,
        width,
        height,
        selectedAttributes,
        fontSize,
        attributeFontSize,
        fontFamily,
        flag,
        icon,
        borderStyle,
    ]);
    return { onSave: onSave, nodeElement: nodeElement };
};
exports.default = useStandardElement;
