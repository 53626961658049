"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var styles_1 = require("@customTypes/styles");
var global_1 = require("@styles/global");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    avatar: {
        width: 20,
        height: 20,
        fontSize: 8,
    },
    avatarGroup: {
        "& .MuiAvatar-root": {
            width: 20,
            height: 20,
            fontSize: 8,
        },
    },
    createElementContainer: {
        width: "100%",
        overflowY: "auto",
    },
    dropzone2: {
        display: "flex",
        width: "100%",
        backgroundColor: "#F7F8FA",
        borderRadius: theme.rounded.small,
        border: "1px solid #F1F1F1",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: 1000,
    },
    inputBox: {
        display: "flex",
        gap: 1,
        flexGrow: 1,
        justifyContent: "flex-end",
    },
    addCircle: {
        width: "30%",
        height: "30%",
    },
    chatText: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    listItemText: {
        marginTop: 0,
        marginBottom: 0,
    },
    listItemIcon: {
        fontSize: 20,
    },
    listItemButton: {
        borderRadius: theme.rounded.small,
    },
    search: {
        input: {
            paddingLeft: theme.spacing(2),
        },
    },
    searchContainer: {
        width: "100%",
        padding: theme.spacing(1, 2),
    },
    historySkeleton: {
        borderRadius: theme.rounded.small,
        margin: theme.spacing(1, 0),
    },
    newChatButton: {
        position: "sticky",
        bottom: 0,
        width: "100%",
        "& .MuiSvgIcon-root": {
            marginRight: theme.spacing(1),
            color: "white",
        },
    },
    fullCenter: {
        height: "100%",
        display: "flex",
        alignItems: "center",
    },
    chatHistoryPaper: __assign({ height: "100%", padding: theme.spacing(1), display: "flex", flexDirection: "column", justifyContent: "space-between", overflowY: "auto" }, (0, styles_1.JuristicScroll)(theme)),
    list: {
        paddingTop: 0,
    },
    peoplePaper: __assign({ height: "30%", overflowY: "auto" }, (0, styles_1.JuristicScroll)(theme)),
    connectionsPaper: __assign({ height: "calc(70% - 8px)", overflowY: "auto" }, (0, styles_1.JuristicScroll)(theme)),
    stickyBox: {
        position: "sticky",
        top: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 1,
    },
    paperHeader: {
        fontSize: 20,
        fontWeight: "bold",
        letterSpacing: 0,
    },
    promptBar: {
        borderRadius: theme.rounded.small,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        display: "flex",
        justifyContent: "space-between",
    },
    paperRow: __assign(__assign({ display: "flex", gap: 8 }, global_1.horizontalScrollbarWithDesign), { paddingTop: 8, paddingBottom: 8, scrollbarWidth: "none" }),
    horizontalPaper: {
        padding: 16,
        display: "flex",
        gap: 8,
        position: "relative",
        alignItems: "center",
    },
    sourceAndConnectionText: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        textAlign: "left",
    },
}); });
exports.default = useStyles;
