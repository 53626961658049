"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var redux_1 = require("@hooks/redux");
var auth0_react_1 = require("@auth0/auth0-react");
var constants_1 = require("@api/constants");
var lawsuitActions_1 = require("@pages/Lawsuits/reducers/lawsuitActions");
var uiActions_1 = require("@redux/actions/uiActions");
function useClientTheme(isClient) {
    var user = (0, auth0_react_1.useAuth0)().user;
    var dispatch = (0, redux_1.useAppDispatch)();
    var location = (0, react_router_dom_1.useLocation)();
    var lawsuitId = (0, constants_1.getGroupOrLawsuitQueryParams)(location, true).typeId;
    var cvr = (0, redux_1.useAppSelector)(function (state) { return state.lawsuits.cvr; });
    var _a = (0, react_1.useState)(undefined), customer = _a[0], setCustomer = _a[1];
    (0, react_1.useEffect)(function () {
        if (isClient) {
            dispatch((0, lawsuitActions_1.getClientLawsuitCVR)(user, lawsuitId));
        }
    }, []);
    (0, react_1.useEffect)(function () {
        if (isClient && cvr) {
            setCustomer((0, constants_1.getCustomerFromCVR)(cvr));
        }
    }, [cvr]);
    (0, react_1.useEffect)(function () {
        if (isClient) {
            var theme = (0, uiActions_1.getCustomerTheme)(customer);
            dispatch((0, uiActions_1.setTheme)(theme));
        }
    }, [customer]);
    return { customer: customer };
}
exports.default = useClientTheme;
