// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/**\n * Retrieves a color value from the $material-colors Sass map\n * Inspired by https://github.com/nilskaspersson/Google-Material-UI-Color-Palette/\n *\n * @function\n * @param {string} color-name             - quoted, lower-case, dasherized color\n *                                          name (e.g. 'pink', 'amber')\n * @param {string} [color-variant='500']  - quoted, lowercase color variant (e.g.\n *                                          '200', 'a100')\n */\n.qTFj2hOP7b65_j8QVMRJ {\n  position: relative;\n  background: #FFF;\n  padding: 16px;\n  max-height: 400px;\n  overflow: auto;\n}\n@media (max-width: 600px) {\n  .qTFj2hOP7b65_j8QVMRJ {\n    padding: 15px 10px;\n  }\n}\n\n.Su8JeaWL9lEJe_kcVVC4 {\n  background: #f5f5f5;\n  position: relative;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  text-align: right;\n  padding: 8px 24px;\n}\n.Su8JeaWL9lEJe_kcVVC4 button {\n  margin-right: 5px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyForm": "qTFj2hOP7b65_j8QVMRJ",
	"buttonArea": "Su8JeaWL9lEJe_kcVVC4"
};
module.exports = ___CSS_LOADER_EXPORT___;
