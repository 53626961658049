"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var gantt_1 = require("@bryntum/gantt");
var redux_1 = require("@hooks/redux");
var _i18n_1 = require("@i18n");
var react_1 = require("react");
function GanttLanguageTransformer(language) {
    switch (language) {
        case _i18n_1.Language.PT:
            return "Pt";
        case _i18n_1.Language.SV:
            return "SvSE";
        case _i18n_1.Language.ES:
            return "Es";
        case _i18n_1.Language.NO:
            return "No";
        case _i18n_1.Language.DE:
            return "De";
        case _i18n_1.Language.DA:
            return "Da";
        case _i18n_1.Language.NL:
            return "Nl";
        case _i18n_1.Language.FR:
            return "Fr";
        default:
            return "EnGb";
    }
}
function useGanttLanguage() {
    var language = (0, redux_1.useAppSelector)(function (state) { return state.ui.language; });
    (0, react_1.useEffect)(function () {
        gantt_1.LocaleManager.applyLocale(GanttLanguageTransformer(language));
    }, [language]);
}
exports.default = useGanttLanguage;
