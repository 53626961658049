"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var globalSearch_1 = require("@customTypes/reducers/globalSearch");
function FilterResults(results, type) {
    switch (type) {
        case globalSearch_1.ESearchTabs.CaseOrGroup:
            return results.filter(function (result) {
                return ["lawsuits", "groups"].includes(result.item.filterType);
            });
        case globalSearch_1.ESearchTabs.Board:
            return results.filter(function (result) {
                return ["boards", "tasks"].includes(result.item.filterType);
            });
        case globalSearch_1.ESearchTabs.Workspace:
            return results.filter(function (result) {
                return ["workspaces", "workspace_template"].includes(result.item.filterType);
            });
        case globalSearch_1.ESearchTabs.Timeline:
            return results.filter(function (result) {
                return ["timelines", "timeline_template"].includes(result.item.filterType);
            });
        case globalSearch_1.ESearchTabs.Element:
            return results.filter(function (result) {
                return [
                    "alerts",
                    "attributs",
                    "conditions",
                    "documents",
                    "nodes",
                    "outputs",
                    "relationships",
                    "people",
                ].includes(result.item.filterType);
            });
        case globalSearch_1.ESearchTabs.All:
            return results;
        default:
            return results;
    }
}
exports.default = FilterResults;
