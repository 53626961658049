"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var removeNode = function (ele) {
    var parent = ele.parentNode;
    if (parent) {
        parent.removeChild(ele);
    }
};
var replaceNode = function (replacementNode, node) {
    removeNode(replacementNode);
    var parent = node.parentNode;
    if (parent) {
        parent.insertBefore(replacementNode, node);
    }
    removeNode(node);
};
var unwrap = function (ele) {
    var parent = ele.parentNode;
    if (!parent) {
        return;
    }
    var range = document.createRange();
    range.selectNodeContents(ele);
    replaceNode(range.extractContents(), ele);
    // Merge the text nodes
    parent.normalize();
};
exports.default = unwrap;
