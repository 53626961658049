"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    menuContainer: {
        width: 250,
        position: "absolute",
        zIndex: 9999,
    },
    editName: {
        maxWidth: 90,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: theme.palette.primary.main,
    },
    loadingConatiner: {
        width: "100%",
        padding: 10,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
    },
}); });
exports.default = useStyles;
