"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function aggregatedStrings(t, array) {
    if (!array) {
        return array;
    }
    if (array.length === 1) {
        return "1x " + t("workspaces.node_types.".concat(array[0]));
    }
    var aggregated = {};
    array.forEach(function (item) {
        aggregated[item] = (aggregated[item] || 0) + 1;
    });
    var beforeLast = Object.keys(aggregated).slice(0, -1);
    var last = Object.keys(aggregated).slice(-1)[0];
    var beforeLastString = beforeLast
        .map(function (item) { return "".concat(aggregated[item], "x ").concat(t("workspaces.node_types.".concat(item))); })
        .join(", ");
    var lastString = "".concat(aggregated[last], "x ").concat(t("workspaces.node_types.".concat(last)));
    if (beforeLastString.length === 0)
        return lastString;
    return "".concat(beforeLastString, " ").concat(t("generic.and"), " ").concat(lastString);
}
exports.default = aggregatedStrings;
