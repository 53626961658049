"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RGB = /** @class */ (function () {
    function RGB() {
    }
    /**
     * @param luminance 0 < x < 1 - the lower the luminance the darker the color
     */
    RGB.prototype.isDarkerThan = function (rgba, luminance) {
        var match = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/i);
        if (!match)
            return false;
        var r = Number(match[1]);
        var g = Number(match[2]);
        var b = Number(match[3]);
        // Calculate luminance (brightness)
        var calculatedLuminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        // Check if luminance is less than the threshold x
        return calculatedLuminance < luminance;
    };
    return RGB;
}());
exports.default = RGB;
