"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
function timeValidator(obj) {
    return (0, utils_1.valuesAreValid)(__assign(__assign({}, obj), { scheduledOccurrence: obj.scheduledOccurrence || 1 }));
}
var triggerValidatorMap = {
    column_change: utils_1.valuesAreValid,
    date_arrives: timeValidator,
    date_passed: utils_1.valuesAreValid,
    person_assigned: utils_1.valuesAreValid,
    status_change: utils_1.valuesAreValid,
    status_change_from_something: utils_1.valuesAreValid,
    subtask_created: utils_1.valuesAreValid,
    task_created: utils_1.valuesAreValid,
    task_moved_to_group: utils_1.valuesAreValid,
    time_period: timeValidator,
};
function triggerValidator(trigger) {
    return triggerValidatorMap[trigger.type](trigger);
}
exports.default = triggerValidator;
