"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constant_1 = require("@components/Boards/constant");
var mapTaskToGanttTask = function (task, index, options) {
    var _a;
    var dateRangeColumnId = options.dateRangeColumnId, _b = options.isManualIndex, isManualIndex = _b === void 0 ? false : _b, _c = options.isSubTask, isSubTask = _c === void 0 ? false : _c;
    var newIndex = isManualIndex ? task.manuelIndex || "" : "".concat(index, ".").concat(task.index + 1);
    var children = task.tasks
        .filter(function (subTask) { return subTask.id !== constant_1.ADD_ITEM && subTask.id !== constant_1.AUTO_GENERATED_GROUP_FOOTER; })
        .map(function (subTask) {
        return mapTaskToGanttTask(subTask, newIndex, {
            isManualIndex: isManualIndex,
            isSubTask: true,
            dateRangeColumnId: dateRangeColumnId,
        });
    });
    var startDate;
    var duration = 0;
    var dateRange = dateRangeColumnId &&
        ((_a = task["dateRange-".concat(dateRangeColumnId)]) === null || _a === void 0 ? void 0 : _a.cellValue);
    if (dateRange) {
        startDate = dateRange[0];
        var endDate = dateRange[1];
        duration = Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) /
            (1000 * 60 * 60 * 24));
    }
    return {
        id: "task-".concat(task.id),
        type: "task",
        name: task.title,
        indentation: { canIndent: !isSubTask && !children.length, canOutdent: isSubTask },
        index: newIndex,
        manuallyScheduled: task.tasks.length < 2,
        startDate: startDate,
        duration: duration,
        expanded: true,
        rollup: true,
        children: children,
    };
};
var mapTaskGroupToGanttTask = function (taskGroup, options) {
    var newIndex = "".concat(taskGroup.index + 1);
    var children = taskGroup.tasks
        .filter(function (task) { return task.id !== constant_1.ADD_ITEM && task.id !== constant_1.AUTO_GENERATED_GROUP_FOOTER; })
        .map(function (task) { return mapTaskToGanttTask(task, newIndex, options); });
    return {
        id: "taskGroup-".concat(taskGroup.id),
        type: "taskGroup",
        indentation: { canIndent: false, canOutdent: false },
        name: taskGroup.title,
        index: newIndex,
        manuallyScheduled: false,
        expanded: true,
        rollup: true,
        children: children,
    };
};
var GanttFormatter = function (taskGroups, options) { return taskGroups.map(function (taskGroup) { return mapTaskGroupToGanttTask(taskGroup, options); }); };
exports.default = GanttFormatter;
