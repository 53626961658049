"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.POST_DOCUMENT_LOADING = exports.NODE_DUPLICATE_FAILED = exports.NODE_DUPLICATE_SUCCESS = exports.NODE_DUPLICATE_LOADING = exports.CHANGE_ICON_NODE = exports.CHANGE_FLAG_NODE = exports.TOGGLE_DOCUMENT_MODAL = exports.CHANGE_FONT_FAMILY = exports.CHANGE_ATTRIBUTE_FONT_SIZE = exports.CHANGE_FONT_SIZE = exports.CHANGE_FIT_SIZE_TO_LABEL = exports.CHANGE_SHAPE = exports.CHANGE_TEXT_COLOR = exports.CHANGE_WIDTH = exports.CHANGE_HEIGHT = exports.CHANGE_BORDER_COLOR = exports.CHANGE_BACKGROUND_COLOR = exports.CHANGE_SIZE = exports.GET_NODE_DESCENDANTS_FAILED = exports.GET_NODE_DESCENDANTS_SUCCESS = exports.PUT_SELECTED_ATTRIBUTE_OPTION_FAILED = exports.PUT_SELECTED_ATTRIBUTE_OPTION_SUCCESS = exports.POST_DROPDOWN_VALUE_FAILED = exports.POST_DROPDOWN_VALUE_SUCCESS = exports.GET_SELECTED_ATTRIBUTES_FAILED = exports.GET_SELECTED_ATTRIBUTES_SUCCESS = exports.REMOVE_ATTRIBUTE = exports.UPDATE_ATTRIBUTE = exports.ADD_ATTRIBUTE = exports.DOCUMENTS_CHANGE = exports.NOTES_CHANGE = exports.DESCRIPTION_CHANGE = exports.TITLE_CHANGE = exports.NODE_GET_ATTRIBUTE_DROPDOWN_FAILED = exports.NODE_GET_ATTRIBUTE_DROPDOWN_SUCCESS = exports.CLEAR_NODE_DESCENDANTS = exports.FORCE_DELETE_NODE_FAILED = exports.FORCE_DELETE_NODE_SUCCESS = exports.DELETE_NODE_FAILED = exports.DELETE_NODE_SUCCESS = exports.PUT_NODE_FAILED = exports.PUT_NODE_SUCCESS = exports.SHOW_NODE_FAILED = exports.SHOW_NODE_LITE_SUCCESS = exports.SHOW_NODE_SUCCESS = exports.POST_NODE_FAILED = exports.POST_NODE_SUCCESS = exports.GET_NODES_FAILED = exports.GET_NODES_SUCCESS = exports.GET_NODES_LOADING = void 0;
exports.CHANGE_BORDER_STYLE = exports.GET_NODE_ICON_FAILED = exports.GET_NODE_ICON_SUCCESS = exports.POST_DOCUMENT_FAILED = exports.POST_DOCUMENT_SUCCESS = void 0;
// API
exports.GET_NODES_LOADING = "GET_NODES_LOADING";
exports.GET_NODES_SUCCESS = "GET_NODES_SUCCESS";
exports.GET_NODES_FAILED = "GET_NODES_FAILED";
exports.POST_NODE_SUCCESS = "POST_NODE_SUCCESS";
exports.POST_NODE_FAILED = "POST_NODE_FAILED";
exports.SHOW_NODE_SUCCESS = "SHOW_NODE_SUCCESS";
exports.SHOW_NODE_LITE_SUCCESS = "SHOW_NODE_LITE_SUCCESS";
exports.SHOW_NODE_FAILED = "SHOW_NODE_FAILED";
exports.PUT_NODE_SUCCESS = "PUT_NODE_SUCCESS";
exports.PUT_NODE_FAILED = "PUT_NODE_FAILED";
exports.DELETE_NODE_SUCCESS = "DELETE_NODE_SUCCESS";
exports.DELETE_NODE_FAILED = "DELETE_NODE_FAILED";
exports.FORCE_DELETE_NODE_SUCCESS = "FORCE_DELETE_NODE_SUCCESS";
exports.FORCE_DELETE_NODE_FAILED = "FORCE_DELETE_NODE_FAILED";
exports.CLEAR_NODE_DESCENDANTS = "CLEAR_NODE_DESCENDANTS";
exports.NODE_GET_ATTRIBUTE_DROPDOWN_SUCCESS = "NODE_GET_ATTRIBUTE_DROPDOWN_SUCCESS";
exports.NODE_GET_ATTRIBUTE_DROPDOWN_FAILED = "NODE_GET_ATTRIBUTE_DROPDOWN_FAILED";
exports.TITLE_CHANGE = "NODE_TITLE_CHANGE";
exports.DESCRIPTION_CHANGE = "NODE_DESCRIPTION_CHANGE";
exports.NOTES_CHANGE = "NODE_NOTES_CHANGE";
exports.DOCUMENTS_CHANGE = "NODE_DOCUMENTS_CHANGE";
exports.ADD_ATTRIBUTE = "NODE_ADD_ATTRIBUTE";
exports.UPDATE_ATTRIBUTE = "NODE_UPDATE_ATTRIBUTE";
exports.REMOVE_ATTRIBUTE = "NODE_REMOVE_ATTRIBUT";
exports.GET_SELECTED_ATTRIBUTES_SUCCESS = "NODE_GET_SELECTED_ATTRIBUTES_SUCCESS";
exports.GET_SELECTED_ATTRIBUTES_FAILED = "NODE_GET_SELECTED_ATTRIBUTES_FAILED";
exports.POST_DROPDOWN_VALUE_SUCCESS = "NODE_POST_DROPDOWN_VALUE_SUCCESS";
exports.POST_DROPDOWN_VALUE_FAILED = "NODE_POST_DROPDOWN_VALUE_FAILED";
exports.PUT_SELECTED_ATTRIBUTE_OPTION_SUCCESS = "NODE_PUT_SELECTED_ATTRIBUTE_OPTION_SUCCESS";
exports.PUT_SELECTED_ATTRIBUTE_OPTION_FAILED = "NODE_PUT_SELECTED_ATTRIBUTE_OPTION_FAILED";
exports.GET_NODE_DESCENDANTS_SUCCESS = "GET_NODE_DESCENDANTS_SUCCESS";
exports.GET_NODE_DESCENDANTS_FAILED = "GET_NODE_DESCENDANTS_FAILED";
// STYLE
exports.CHANGE_SIZE = "CHANGE_SIZE";
exports.CHANGE_BACKGROUND_COLOR = "CHANGE_BACKGROUND_COLOR";
exports.CHANGE_BORDER_COLOR = "CHANGE_BORDER_COLOR";
exports.CHANGE_HEIGHT = "CHANGE_HEIGHT";
exports.CHANGE_WIDTH = "CHANGE_WIDTH";
exports.CHANGE_TEXT_COLOR = "CHANGE_TEXT_COLOR";
exports.CHANGE_SHAPE = "CHANGE_SHAPE";
exports.CHANGE_FIT_SIZE_TO_LABEL = "CHANGE_FIT_SIZE_TO_LABEL";
exports.CHANGE_FONT_SIZE = "CHANGE_FONT_SIZE";
exports.CHANGE_ATTRIBUTE_FONT_SIZE = "CHANGE_ATTRIBUTE_FONT_SIZE";
exports.CHANGE_FONT_FAMILY = "CHANGE_FONT_FAMILY";
exports.TOGGLE_DOCUMENT_MODAL = "NODE_TOGGLE_DOCUMENT_MODAL";
exports.CHANGE_FLAG_NODE = "CHANGE_FLAG_NODE";
exports.CHANGE_ICON_NODE = "CHANGE_ICON_NODE";
exports.NODE_DUPLICATE_LOADING = "NODE_DUPLICATE_LOADING";
exports.NODE_DUPLICATE_SUCCESS = "NODE_DUPLICATE_SUCCESS";
exports.NODE_DUPLICATE_FAILED = "NODE_DUPLICATE_FAILED";
exports.POST_DOCUMENT_LOADING = "NODE_POST_DOCUMENT_LOADING";
exports.POST_DOCUMENT_SUCCESS = "NODE_POST_DOCUMENT_SUCCESS";
exports.POST_DOCUMENT_FAILED = "NODE_POST_DOCUMENT_FAILED";
exports.GET_NODE_ICON_SUCCESS = "NODE_GET_NODE_ICON_SUCCESS";
exports.GET_NODE_ICON_FAILED = "NODE_GET_NODE_ICON_FAILED";
exports.CHANGE_BORDER_STYLE = "CHANGE_BORDER_STYLE";
