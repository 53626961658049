"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moveElementToDestroyedParentSuccess = exports.moveElementToDestroyedParentFailed = exports.moveElementToDestroyedParentLoading = exports.duplicateFolderSuccess = exports.duplicateFolderFailed = exports.duplicateFolderLoading = exports.deleteFolderSuccess = exports.deleteFolderFailed = exports.deleteFolderLoading = exports.putFolderElementLocationSuccess = exports.putFolderElementLocationFailed = exports.putFolderElementLocationLoading = exports.putFolderLocationSuccess = exports.putFolderLocationFailed = exports.putFolderLocationLoading = exports.putFolderTitleSuccess = exports.putFolderTitleFailed = exports.putFolderTitleLoading = exports.postFolderSuccess = exports.postFolderFailed = exports.postFolderLoading = exports.getFolderChildrenAndElementsSuccess = exports.getFolderChildrenAndElementsFailed = exports.getFolderChildrenAndElementsLoading = exports.CLEAR_FOLDERS = exports.GET_FOLDER_TREE_SUCCESS = exports.GET_FOLDER_TREE_FAILED = exports.GET_FOLDER_TREE_LOADING = exports.LEAVE_ALL_FOLDERS = exports.LEAVE_FOLDERS = exports.GO_BACK_TO_FOLDER = exports.ENTER_FOLDER = void 0;
/**
 *
 * Folder Constants
 *
 */
exports.ENTER_FOLDER = "ENTER_FOLDER";
exports.GO_BACK_TO_FOLDER = "GO_BACK_TO_FOLDER";
exports.LEAVE_FOLDERS = "LEAVE_FOLDERS";
exports.LEAVE_ALL_FOLDERS = "LEAVE_ALL_FOLDERS";
exports.GET_FOLDER_TREE_LOADING = "GET_FOLDER_TREE_LOADING";
exports.GET_FOLDER_TREE_FAILED = "GET_FOLDER_TREE_FAILED";
exports.GET_FOLDER_TREE_SUCCESS = "GET_FOLDER_TREE_SUCCESS";
exports.CLEAR_FOLDERS = "CLEAR_FOLDERS";
var getFolderChildrenAndElementsLoading = function (folderChild) {
    return "GET_".concat(folderChild, "_CHILDREN_AND_ELEMENTS_LOADING");
};
exports.getFolderChildrenAndElementsLoading = getFolderChildrenAndElementsLoading;
var getFolderChildrenAndElementsFailed = function (folderChild) {
    return "GET_".concat(folderChild, "_CHILDREN_AND_ELEMENTS_FAILED");
};
exports.getFolderChildrenAndElementsFailed = getFolderChildrenAndElementsFailed;
var getFolderChildrenAndElementsSuccess = function (folderChild) {
    return "GET_".concat(folderChild, "_CHILDREN_AND_ELEMENTS_SUCCESS");
};
exports.getFolderChildrenAndElementsSuccess = getFolderChildrenAndElementsSuccess;
var postFolderLoading = function (folderChild) {
    return "POST_".concat(folderChild, "_FOLDER_LOADING");
};
exports.postFolderLoading = postFolderLoading;
var postFolderFailed = function (folderChild) {
    return "POST_".concat(folderChild, "_FOLDER_FAILED");
};
exports.postFolderFailed = postFolderFailed;
var postFolderSuccess = function (folderChild) {
    return "POST_".concat(folderChild, "_FOLDER_SUCCESS");
};
exports.postFolderSuccess = postFolderSuccess;
var putFolderTitleLoading = function (folderChild) {
    return "PUT_".concat(folderChild, "_FOLDER_TITLE_LOADING");
};
exports.putFolderTitleLoading = putFolderTitleLoading;
var putFolderTitleFailed = function (folderChild) {
    return "PUT_".concat(folderChild, "_FOLDER_TITLE_FAILED");
};
exports.putFolderTitleFailed = putFolderTitleFailed;
var putFolderTitleSuccess = function (folderChild) {
    return "PUT_".concat(folderChild, "_FOLDER_TITLE_SUCCESS");
};
exports.putFolderTitleSuccess = putFolderTitleSuccess;
var putFolderLocationLoading = function (folderChild) {
    return "PUT_".concat(folderChild, "_FOLDER_LOCATION_LOADING");
};
exports.putFolderLocationLoading = putFolderLocationLoading;
var putFolderLocationFailed = function (folderChild) {
    return "PUT_".concat(folderChild, "_FOLDER_LOCATION_FAILED");
};
exports.putFolderLocationFailed = putFolderLocationFailed;
var putFolderLocationSuccess = function (folderChild) {
    return "PUT_".concat(folderChild, "_FOLDER_LOCATION_SUCCESS");
};
exports.putFolderLocationSuccess = putFolderLocationSuccess;
var putFolderElementLocationLoading = function (folderChild) {
    return "PUT_".concat(folderChild, "_FOLDER_ELEMENT_LOCATION_LOADING");
};
exports.putFolderElementLocationLoading = putFolderElementLocationLoading;
var putFolderElementLocationFailed = function (folderChild) {
    return "PUT_".concat(folderChild, "_FOLDER_ELEMENT_LOCATION_FAILED");
};
exports.putFolderElementLocationFailed = putFolderElementLocationFailed;
var putFolderElementLocationSuccess = function (folderChild) {
    return "PUT_".concat(folderChild, "_FOLDER_ELEMENT_LOCATION_SUCCESS");
};
exports.putFolderElementLocationSuccess = putFolderElementLocationSuccess;
var deleteFolderLoading = function (folderChild) {
    return "DELETE_".concat(folderChild, "_FOLDER_LOADING");
};
exports.deleteFolderLoading = deleteFolderLoading;
var deleteFolderFailed = function (folderChild) {
    return "DELETE_".concat(folderChild, "_FOLDER_FAILED");
};
exports.deleteFolderFailed = deleteFolderFailed;
var deleteFolderSuccess = function (folderChild) {
    return "DELETE_".concat(folderChild, "_FOLDER_SUCCESS");
};
exports.deleteFolderSuccess = deleteFolderSuccess;
var duplicateFolderLoading = function (folderChild) {
    return "DUPLICATE_".concat(folderChild, "_FOLDER_LOADING");
};
exports.duplicateFolderLoading = duplicateFolderLoading;
var duplicateFolderFailed = function (folderChild) {
    return "DUPLICATE_".concat(folderChild, "_FOLDER_FAILED");
};
exports.duplicateFolderFailed = duplicateFolderFailed;
var duplicateFolderSuccess = function (folderChild) {
    return "DUPLICATE_".concat(folderChild, "_FOLDER_SUCCESS");
};
exports.duplicateFolderSuccess = duplicateFolderSuccess;
var moveElementToDestroyedParentLoading = function (folderChild) {
    return "MOVE_".concat(folderChild, "_TO_DESTROYED_PARENT_LOADING");
};
exports.moveElementToDestroyedParentLoading = moveElementToDestroyedParentLoading;
var moveElementToDestroyedParentFailed = function (folderChild) {
    return "MOVE_".concat(folderChild, "_TO_DESTROYED_PARENT_FAILED");
};
exports.moveElementToDestroyedParentFailed = moveElementToDestroyedParentFailed;
var moveElementToDestroyedParentSuccess = function (folderChild) {
    return "MOVE_".concat(folderChild, "_TO_DESTROYED_PARENT_SUCCESS");
};
exports.moveElementToDestroyedParentSuccess = moveElementToDestroyedParentSuccess;
