"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var teamConstants_1 = require("./teamConstants");
var initialTeam = {
    id: 0,
    name: "",
    department: "",
    title: "",
    email: "",
    phone: "",
    mobile: "",
    lawsuitId: 0,
    createdAt: "",
    updatedAt: "",
};
var initialState = {
    teamIndex: [],
    options: [],
    choosenTeam: [],
    team: initialTeam,
    loadings: constants_1.initialLoadings,
};
function reducer(state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case teamConstants_1.TEAM_INDEX_LOADING:
        case teamConstants_1.TEAM_STORE_LOADING:
        case teamConstants_1.TEAM_UPDATE_LOADING:
        case teamConstants_1.POST_CHOOSEN_TEAM_LOADING:
        case teamConstants_1.TEAM_DELETE_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case teamConstants_1.TEAM_INDEX_FAILED:
        case teamConstants_1.TEAM_STORE_FAILED:
        case teamConstants_1.TEAM_UPDATE_FAILED:
        case teamConstants_1.POST_CHOOSEN_TEAM_FAILED:
        case teamConstants_1.TEAM_DELETE_FAILED:
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case teamConstants_1.TEAM_INDEX_SUCCESS:
            return __assign(__assign({}, state), { teamIndex: action.teamIndex, options: action.options, choosenTeam: action.teamIndex.map(function (x) { return ({ value: x.id, label: x.name }); }), loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case teamConstants_1.TEAM_STORE_SUCCESS:
            return __assign(__assign({}, state), { teamIndex: __spreadArray(__spreadArray([], state.teamIndex, true), [action.team], false), team: initialTeam, loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case teamConstants_1.TEAM_UPDATE_SUCCESS:
            return __assign(__assign({}, state), { teamIndex: state.teamIndex.map(function (team) {
                    return team.id === action.team.id ? action.team : team;
                }), team: initialTeam, loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case teamConstants_1.TEAM_DELETE_SUCCESS:
            return __assign(__assign({}, state), { teamIndex: state.teamIndex.filter(function (team) { return team.id !== action.id; }), choosenTeam: state.choosenTeam.filter(function (team) { return team.value !== action.id; }), loadings: __assign(__assign({}, state.loadings), { mouse: false }) });
        case teamConstants_1.SET_TEAM:
            return __assign(__assign({}, state), { team: action.team });
        case teamConstants_1.RESET_TEAM:
            return __assign(__assign({}, state), { team: initialTeam });
        case teamConstants_1.CHANGE_TEAM:
            return __assign(__assign({}, state), { team: __assign(__assign({}, state.team), (_b = {}, _b[action.key] = action.value, _b)) });
        case teamConstants_1.CHANGE_CHOOSEN_TEAM:
            return __assign(__assign({}, state), { choosenTeam: action.choosenTeam });
        case teamConstants_1.POST_CHOOSEN_TEAM_SUCCESS:
            return __assign(__assign({}, state), { teamIndex: action.teamIndex, loadings: __assign(__assign({}, state.loadings), { post: false }) });
        default:
            return state;
    }
}
exports.default = reducer;
