"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GLOBAL_SEARCH_DOCUMENTS_FAILED = exports.GLOBAL_SEARCH_DOCUMENTS_SUCCESS = exports.GLOBAL_SEARCH_DOCUMENTS_LOADING = exports.CLEAR_GLOBAL_SEARCH_RESULTS = exports.GLOBAL_SEARCH_QUERY_CHANGE = exports.GLOBAL_SEARCH_GO_TO_ELEMENT_FAILED = exports.GLOBAL_SEARCH_GO_TO_ELEMENT_SUCCESS = exports.GLOBAL_SEARCH_GO_TO_ELEMENT_LOADING = exports.GLOBAL_SEARCH_FAILED = exports.GLOBAL_SEARCH_SUCCESS = exports.GLOBAL_SEARCH_LOADING = exports.GLOBAL_SEARCH_CLOSE = exports.GLOBAL_SEARCH_OPEN = void 0;
exports.GLOBAL_SEARCH_OPEN = "GLOBAL_SEARCH_OPEN";
exports.GLOBAL_SEARCH_CLOSE = "GLOBAL_SEARCH_CLOSE";
exports.GLOBAL_SEARCH_LOADING = "GLOBAL_SEARCH_LOADING";
exports.GLOBAL_SEARCH_SUCCESS = "GLOBAL_SEARCH_SUCCESS";
exports.GLOBAL_SEARCH_FAILED = "GLOBAL_SEARCH_FAILED";
exports.GLOBAL_SEARCH_GO_TO_ELEMENT_LOADING = "GLOBAL_SEARCH_GO_TO_ELEMENT_LOADING";
exports.GLOBAL_SEARCH_GO_TO_ELEMENT_SUCCESS = "GLOBAL_SEARCH_GO_TO_ELEMENT_SUCCESS";
exports.GLOBAL_SEARCH_GO_TO_ELEMENT_FAILED = "GLOBAL_SEARCH_GO_TO_ELEMENT_FAILED";
exports.GLOBAL_SEARCH_QUERY_CHANGE = "GLOBAL_SEARCH_QUERY_CHANGE";
exports.CLEAR_GLOBAL_SEARCH_RESULTS = "CLEAR_GLOBAL_SEARCH_RESULTS";
exports.GLOBAL_SEARCH_DOCUMENTS_LOADING = "GLOBAL_SEARCH_DOCUMENTS_LOADING";
exports.GLOBAL_SEARCH_DOCUMENTS_SUCCESS = "GLOBAL_SEARCH_DOCUMENTS_SUCCESS";
exports.GLOBAL_SEARCH_DOCUMENTS_FAILED = "GLOBAL_SEARCH_DOCUMENTS_FAILED";
