"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    shareDialogContainer: {
        display: "flex",
        flexDirection: "row",
        gap: 5,
        padding: "16px 24px",
    },
    shareDialogButton: {
        "&&&": {
            padding: 4,
        },
        "&:hover": {
            background: theme.palette.action.hover,
        },
        "& > MuiTouchRipple-root": {
            borderRadius: 6,
        },
        boxShadow: theme.shadows[1],
        outline: "none",
        display: "flex",
        flexDirection: "column",
        border: "1px solid ".concat(theme.palette.primary.main),
        borderRadius: theme.rounded.small,
        transition: "0.2s ease-in-out",
        flex: 1,
    },
    shareDialogHeader: {
        "& > h6": {
            color: theme.palette.primary.main,
        },
        "& > p": {
            fontSize: 12,
        },
    },
    shareDialogCaption: {
        padding: 4,
    },
    shareDialogTitle: {
        "&:after": {
            content: "''",
            position: "absolute",
            width: 60,
            height: 6,
            background: theme.palette.primary.main,
            bottom: 8,
            left: 24,
        },
    },
}); });
exports.default = useStyles;
