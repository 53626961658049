"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoardMemberRoleCodes = exports.ManagementRoleCodes = void 0;
exports.ManagementRoleCodes = new Set([
    "VD",
    "SVD",
    "VVD",
    "EVD",
    "EVVD",
    "FÖ",
    "GRUND",
]);
exports.BoardMemberRoleCodes = new Set([
    "LE",
    "OF",
    "SU",
    "VLE",
    "VOF",
    "BO",
]);
