"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeApiRef = exports.addApiRef = void 0;
var reducer_1 = require("./reducer");
var addApiRef = function (id, apiRef) {
    return ({
        type: reducer_1.ADD_API_REF,
        payload: { id: id, apiRef: apiRef },
    });
};
exports.addApiRef = addApiRef;
var removeApiRef = function (id) {
    return ({
        type: reducer_1.REMOVE_API_REF,
        payload: { id: id },
    });
};
exports.removeApiRef = removeApiRef;
