"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var tss_react_1 = require("tss-react");
var mui_1 = require("tss-react/mui");
var shake = (0, tss_react_1.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% { transform: translateX(0rem); }\n  25% { transform: translateX(0.3rem); }\n  75% { transform: translateX(-0.3rem); }\n  100% { transform: translateX(0rem); }\n"], ["\n  0% { transform: translateX(0rem); }\n  25% { transform: translateX(0.3rem); }\n  75% { transform: translateX(-0.3rem); }\n  100% { transform: translateX(0rem); }\n"])));
var useStyles = (0, mui_1.makeStyles)()(function (theme, _params, classes) {
    var _a;
    return ({
        buttonContainer: {
            display: "flex",
            gap: 6,
        },
        arrow: {
            marginTop: "24px",
            marginBottom: "24px",
            display: "flex",
            height: 32,
            width: "auto",
            color: theme.palette.success.light,
            "&--ready": {
                color: theme.palette.success.main,
            },
        },
        button: {
            all: "unset",
            zIndex: 1,
            display: "block",
            position: "relative",
            transition: "0.3s",
            "&:disabled": {
                cursor: "default",
            },
            "&:hover": {
                cursor: "pointer",
                "&:disabled": {
                    cursor: "default",
                    background: "inherit",
                },
            },
            "&:before": {
                content: "''",
                borderBottom: "2px solid ".concat(theme.palette.text.primary),
                padding: "0 10px",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                margin: "0 auto",
                transition: "0.3s",
                "&:disabled": {
                    borderBottom: "2px solid ".concat(theme.palette.text.disabled),
                },
            },
        },
        buttonTypography: {
            color: theme.palette.text.disabled,
            maxWidth: 300,
            textOverflow: "ellipsis",
            overflow: "hidden",
            textWrap: "nowrap",
            "&--value-selected": {
                "&&&": {
                    color: theme.palette.text.primary,
                },
            },
        },
        factoryContainer: {
            maxWidth: 1200,
            margin: "0 auto",
            padding: "60px 20px",
            display: "flex",
            flexDirection: "column",
            gap: 40,
        },
        iconButton: {
            "&:hover": {
                "& > svg": {
                    color: theme.palette.text.primary,
                },
            },
            "& > svg": {
                color: theme.palette.action.disabled,
            },
        },
        deleteIcon: {
            "&:hover": (_a = {},
                _a["&.".concat(classes.factoryContainer)] = {
                    textDecoration: "line-through",
                },
                _a),
        },
        listContainer: {
            padding: 8,
        },
        listTextfieldContainer: {},
        listTextfield: {
            paddingTop: 0,
            paddingBottom: 0,
        },
        list: {},
        listItem: {
            padding: 0,
        },
        listItemButton: {
            fontSize: 12,
        },
        addColumnButton: {
            color: theme.palette.primary.main,
            paddingRight: 40,
            paddingLeft: 40,
        },
        datePassedContainer: {
            padding: 16,
        },
        datePassedRadio: {
            display: "flex",
            alignItems: "center",
            padding: 8,
            gap: 8,
        },
        radio: {
            "& .MuiSvgIcon-root": {
                color: theme.palette.primary.main,
            },
        },
        timePeriodOccurenceContainer: {
            display: "flex",
            alignItems: "center",
            gap: 6,
            alignSelf: "start",
        },
        timePeriodContainer: {
            padding: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 4,
            width: 370,
        },
        timePeriodFooter: {
            display: "flex",
            width: "100%",
            gap: 4,
        },
        capitalizeFirstLetter: {
            "&:first-letter": {
                textTransform: "capitalize",
            },
        },
        labelList: {
            display: "flex",
            flexDirection: "column",
            gap: 8,
            padding: 8,
        },
        labelListButton: {
            display: "block",
            padding: 8,
            borderRadius: 4,
            minHeight: 37,
            width: 200,
        },
        labelSubheader: {
            lineHeight: "25px",
        },
        shake: {
            animation: "".concat(shake, " 0.2s ease-in-out 0s 1.5"),
        },
        filterButton: { minWidth: 0, color: "#666", display: "flex", gap: 5 },
        flexboxButton: {
            display: "flex",
            gap: 4,
        },
        startIconButton: {
            "& > .MuiButton-startIcon > .MuiSvgIcon-root": {
                color: theme.palette.primary.main,
            },
        },
        dialogContent: {
            width: "90vw",
            height: "90vh",
            overflowY: "hidden",
            display: "flex",
            gap: 1,
            background: theme.palette.background.default,
        },
        dialogTitle: {
            borderBottom: "1px solid ".concat(theme.palette.grey[200]),
            padding: 24,
        },
        noResultsItem: {
            paddingTop: "8px",
            paddingBottom: "8px",
            paddingLeft: "16px",
            paddingRight: "16px",
        },
        automationInfoBox: {
            width: 0,
            display: "flex",
            gap: 4,
            justifyContent: "flex-end",
            flexWrap: "wrap",
            transition: theme.transitions.create("width", { duration: 200, delay: 50 }),
            overflow: "hidden",
        },
        infoIcon: {
            fontSize: "1rem",
            color: theme.palette.grey[400],
            "&:hover": {
                color: theme.palette.primary.main,
            },
        },
    });
});
exports.default = useStyles;
var templateObject_1;
