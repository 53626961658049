"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PROMPT_EVENTS = {
    JOIN_ROOM: "JOIN_ROOM",
    LEAVE_ROOM: "LEAVE_ROOM",
    USER_DISCONNECTS: "USER_DISCONNECTS",
    USER_RECONNECT: "USER_RECONNECT",
    USER_TOGGLE_ACTIVE: "USER_TOGGLE_ACTIVE",
    //----------------------------------------------------------------
    // PROMPT EVENTS
    //----------------------------------------------------------------
    PROMPT_TITLE_GENERATED: "PROMPT_TITLE_GENERATED",
};
exports.default = PROMPT_EVENTS;
