"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
function splitEmailBody(emailBody) {
    if (!emailBody) {
        return [];
    }
    // Old if we need to revert
    var gmailAttrReg = /class="gmail_attr"/gm;
    var hasGmailAttr = gmailAttrReg.test(emailBody);
    var hasGmailAttrReg = [
        /(?=<div\b[^>]*class="gmail_attr">)/m, // gmail text seperator top
    ];
    // gmail text seperator if gmail classes exits
    var notHasGmailAttrReg = [
        // common gmail seperator phrases (For some reason these cannot be combined into one regex)
        /\s-{1,10}\sVideresendt meddelelse\s-{1,10}\s/m,
        /-{1,10}\sVidarebefordrat meddelande\s-{1,10}/m,
        // e.g 'Den man. 10. okt. 2022 kl. 09.52 skrev'
        /(?=<p.*(?:On|Op|Em|W|Den|Am) \w{3}[.,] \d{1,2}[.,] \w{3}[.,] \d{4} [\s\S]*? \d{2}[.:]\d{2}\s(?:skrev))/m,
        // e.g 'Den 2018-08-24 kl. 12:44 skrev'
        /(?=<p.*(?:On|Op|Em|W|Den|Am) \d{4}-\d{2}-\d{2}[\s\S]*? \d{2}[.:]\d{2}\s(?:skrev))/m,
        // e.g 'Den 10. maj 2022 kl. 14.13 skrev'
        /(?=<p.*(?:On|Op|Em|W|Den|Am) \d{1,2}. [a-zA-Z]+ \d{2}[\s\S]*? \d{2}[.:]\d{2}\s(?:skrev))/m,
        // e.g 'Den tirsdag den 28. august 2018 21.17.45 CEST skrev'
        /(?=<p.*(?:On|Op|Em|W|Den|Am) (?:mandag|tirsdag|onsdag|torsdag|fredag|lørdag|søndag).*\d{2}.*(?:januar|februar|marts|april|maj|juni|juli|august|september|oktober|november|december).*\d{4}.*\d{2}[.:]\d{2}[.:]\d{2}.*(?:skrev))/m,
    ];
    var seperators = __spreadArray(__spreadArray([
        /(?=<div\sstyle.*border:none.*border-top:solid.*>)/m,
        /(?=<div.*border-top:\ssolid\s#E1E1E1\s?.*>)/m
    ], (hasGmailAttr ? hasGmailAttrReg : notHasGmailAttrReg), true), [
        /(?=<div\b[^>]*class="moz-cite-prefix">)/m,
        /(?=<div.*border-right:none.*border-top:1pt solid.*>)/m,
        /-{1,10}\sOriginal meddelelse\s-{1,10}/m,
        /(?=<div>\s<div.*border-top:\ssolid\s#B5C4DF\s?.*>)/m,
        /(?=<div\s*(?:id="?divRplyFwdMsg"?).*?>[\s\S]*?<\/div>)/m,
        // Basically the same as the one above, but the regex engine does not understand an optional substring of 'x_'
        /(?=<div\s*(?:id="?x_divRplyFwdMsg"?).*?>[\s\S]*?<\/div>)/m,
        // Finds a div with 'align="center"' and 'style="text-align:center' that contains an email
        // (might be a bit excessive with the email part, but it is safer i guess)
        /(?=<div.*align="center".*style="text-align:center;[\s\S]*?(?:[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)[\s\S]*?<\/div>)/m,
        // 25 or more occurences of '—' in a row
        /—{25,}/m,
        // e.g '-----Oprindelig meddelelse-----'
        /-{4,}Oprindelig meddelelse-{4,}/, // seen in Kemp & Lauritzen mails
    ], false).map(function (regex) { return new RegExp(regex).source; })
        .join("|");
    var regExForBody = new RegExp(seperators, "gm");
    return emailBody.split(regExForBody);
}
exports.default = splitEmailBody;
