"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var columnActions_websocket_1 = require("@pages/TaskBoard/reducers/WebSocket/columnActions.websocket");
var taskActions_websocket_1 = require("@pages/TaskBoard/reducers/WebSocket/taskActions.websocket");
var constant_1 = require("../../../constant");
var boardActions_websocket_1 = require("@pages/TaskBoard/reducers/WebSocket/boardActions.websocket");
var react_1 = require("react");
function useDataGrid(taskGroup, editItemRef, boardId) {
    var dispatch = (0, redux_1.useAppDispatch)();
    var onColumnOrderChange = (0, react_1.useCallback)(function (params) {
        // @ts-ignore it exists
        var columnId = params.column.id;
        // +1 as index starts at 1
        var deductionConstant = constant_1.pinnedColumns.left.length + 1;
        dispatch((0, columnActions_websocket_1.updateBoardColumnIndex)(columnId, params.targetIndex - deductionConstant, params.oldIndex - deductionConstant));
    }, [constant_1.pinnedColumns]);
    var handleRowOrderChange = (0, react_1.useCallback)(function (moved) {
        var oldIndex = moved.oldIndex, targetIndex = moved.targetIndex, row = moved.row;
        dispatch((0, taskActions_websocket_1.updateTaskIndex)(targetIndex, oldIndex, row.id, taskGroup.id));
    }, [taskGroup]);
    var handleColumnWidthChange = (0, react_1.useCallback)(function (params) {
        var colDef = params.colDef, width = params.width;
        // @ts-ignore it exists
        var id = colDef.id;
        if (colDef.field === constant_1.TITLE) {
            dispatch((0, boardActions_websocket_1.changeItemWidth)(boardId, width));
        }
        else {
            dispatch((0, columnActions_websocket_1.updateBoardColumnWidth)(id, width));
        }
    }, [boardId]);
    var handleCellClick = (0, react_1.useCallback)(function (params) {
        var _a;
        if (params.id === constant_1.ADD_ITEM) {
            (_a = editItemRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [editItemRef]);
    return {
        onColumnOrderChange: onColumnOrderChange,
        handleRowOrderChange: handleRowOrderChange,
        handleColumnWidthChange: handleColumnWidthChange,
        handleCellClick: handleCellClick,
    };
}
exports.default = useDataGrid;
