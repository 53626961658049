"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (theme) { return ({
    swimlane: {
        pointerEvents: "none",
        position: "absolute",
        left: 0,
        width: "100%",
        borderRight: "none",
        borderLeft: "none",
        zIndex: 1000,
    },
    swimlaneInner: {
        pointerEvents: "auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: theme.spacing(0.5),
        opacity: 0.5,
        position: "absolute",
        left: theme.spacing(8),
    },
    swimlaneButton: {
        padding: 0,
        "&& svg": { fontSize: 12 },
    },
    swimlaneButtonHidden: {
        "&&&&": { opacity: 0 },
    },
}); });
