"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onDeleteView = exports.onSetAsDefault = exports.onRenameView = exports.onPostView = void 0;
var types = __importStar(require("../../viewConstants"));
function onPostView() {
    return function (dispatch) { return function (view) {
        dispatch({
            type: types.POST_BOARD_VIEW_SUCCESS,
            view: view,
        });
    }; };
}
exports.onPostView = onPostView;
function onRenameView() {
    return function (dispatch) {
        return function (_a) {
            var boardViewId = _a.boardViewId, title = _a.title;
            dispatch({
                type: types.RENMAE_BOARD_VIEW_SUCCESS,
                title: title,
                viewId: boardViewId,
            });
        };
    };
}
exports.onRenameView = onRenameView;
function onSetAsDefault() {
    return function (dispatch) { return function (viewId) {
        dispatch({
            type: types.SET_AS_DEFAULT_BOARD_VIEW_SUCCESS,
            viewId: viewId,
        });
    }; };
}
exports.onSetAsDefault = onSetAsDefault;
function onDeleteView() {
    return function (dispatch) { return function (viewId) {
        dispatch({
            type: types.DELETE_BOARD_VIEW_SUCCESS,
            viewId: viewId,
        });
    }; };
}
exports.onDeleteView = onDeleteView;
