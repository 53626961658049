"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vanillaEdgeData = exports.vanillaStrokeColor = exports.vanillaLabelBgStyle = void 0;
var converters_1 = require("@helpers/colors/converters");
exports.vanillaLabelBgStyle = {
    r: 243,
    g: 245,
    b: 248,
    a: 1,
};
exports.vanillaStrokeColor = {
    r: 0,
    g: 0,
    b: 0,
    a: 1,
};
exports.vanillaEdgeData = {
    selected: false,
    labelBgStyle: { background: (0, converters_1.rgbaToString)(exports.vanillaLabelBgStyle) },
    style: {
        stroke: (0, converters_1.rgbaToString)(exports.vanillaStrokeColor),
    },
    label: "",
    data: {
        id: -1,
        createdAt: "",
        relationshipId: null,
        label: "",
        value: "",
        color: exports.vanillaStrokeColor,
        notes: "",
        stroke: "false",
        documents: [],
        showArrow: false,
        animated: false,
        hideValue: false,
        hideLabel: false,
        lineThrough: false,
        curveScalar: 0.5,
        offsetBearing: null,
        pathKnots: [],
        baseReferenceId: "-1",
        stepReferenceId: "-1",
        workspaceTabId: -1,
        workspaceStepId: -1,
        editing: null,
    },
};
