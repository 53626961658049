const data = {
  "nm": "Main Scene",
  "ddd": 0,
  "h": 500,
  "w": 500,
  "meta": {
    "g": "@lottiefiles/creator 1.18.0"
  },
  "layers": [
    {
      "ty": 0,
      "nm": " loading_ring_medium",
      "sr": 1,
      "st": 0,
      "op": 50.0000019958109,
      "ip": 1,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 0,
          "k": [
            100,
            100
          ]
        },
        "s": {
          "a": 0,
          "k": [
            401,
            401
          ]
        },
        "sk": {
          "a": 0,
          "k": 0
        },
        "p": {
          "a": 0,
          "k": [
            253,
            248
          ]
        },
        "r": {
          "a": 0,
          "k": 0
        },
        "sa": {
          "a": 0,
          "k": 0
        },
        "o": {
          "a": 0,
          "k": 100
        }
      },
      "ef": [],
      "w": 500,
      "h": 500,
      "refId": "precomp_loading_ring_medium_83ba4c18-9df6-4b44-9b3b-8746d652e01f",
      "ind": 1
    }
  ],
  "v": "5.7.0",
  "fr": 29.9700012207031,
  "op": 50,
  "ip": 0,
  "assets": [
    {
      "nm": "loading_ring_medium",
      "id": "precomp_loading_ring_medium_83ba4c18-9df6-4b44-9b3b-8746d652e01f",
      "fr": 29.9700012207031,
      "layers": [
        {
          "ty": 4,
          "nm": "green ring 1",
          "sr": 1,
          "st": -1.00000004073083,
          "op": 51.0000020365418,
          "ip": 1,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                200,
                200,
                100
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 0,
              "k": [
                100,
                100,
                0
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 0.167,
                    "y": 0.167
                  },
                  "i": {
                    "x": 0.833,
                    "y": 0.833
                  },
                  "s": [
                    0
                  ],
                  "t": 1
                },
                {
                  "s": [
                    360
                  ],
                  "t": 50.0000019958109
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 0,
              "k": 100
            }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Ellipse 1",
              "np": 3,
              "it": [
                {
                  "ty": "el",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Ellipse",
                  "nm": "Ellipse Path 1",
                  "d": 1,
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ]
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      54,
                      54
                    ]
                  }
                },
                {
                  "ty": "st",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Stroke",
                  "nm": "Stroke 1",
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "o": {
                    "a": 0,
                    "k": 100
                  },
                  "w": {
                    "a": 0,
                    "k": 6
                  },
                  "c": {
                    "a": 0,
                    "k": [
                      0.4824,
                      0.7176,
                      1
                    ]
                  }
                },
                {
                  "ty": "tr",
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  }
                }
              ]
            },
            {
              "ty": "tm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Trim",
              "nm": "Trim Paths 1",
              "ix": 2,
              "e": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "s": [
                      0
                    ],
                    "t": 0
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 38.0000015070409
                  }
                ],
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "s": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0.644,
                      "y": 0
                    },
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "s": [
                      0
                    ],
                    "t": 11
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 51.0000020365418
                  }
                ],
                "ix": 1
              },
              "m": 1
            }
          ],
          "ind": 1
        },
        {
          "ty": 4,
          "nm": "flamingo ring 3",
          "sr": 1,
          "st": -1.00000004073083,
          "op": 45.0000017921567,
          "ip": 18.0000006924242,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 1,
                    "y": 0
                  },
                  "i": {
                    "x": 0.785,
                    "y": 1
                  },
                  "s": [
                    14.2
                  ],
                  "t": 18
                },
                {
                  "s": [
                    360
                  ],
                  "t": 51.0000020365418
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 0,
              "k": 100
            }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Ellipse 1",
              "np": 3,
              "it": [
                {
                  "ty": "el",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Ellipse",
                  "nm": "Ellipse Path 1",
                  "d": 1,
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ]
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      54,
                      54
                    ]
                  }
                },
                {
                  "ty": "st",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Stroke",
                  "nm": "Stroke 1",
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "o": {
                    "a": 0,
                    "k": 100
                  },
                  "w": {
                    "a": 0,
                    "k": 6
                  },
                  "c": {
                    "a": 0,
                    "k": [
                      0.7098,
                      0.9176,
                      1
                    ]
                  }
                },
                {
                  "ty": "tr",
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  }
                }
              ]
            },
            {
              "ty": "tm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Trim",
              "nm": "Trim Paths 1",
              "ix": 2,
              "e": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "i": {
                      "x": 0.833,
                      "y": 1
                    },
                    "s": [
                      0
                    ],
                    "t": 21
                  },
                  {
                    "s": [
                      1
                    ],
                    "t": 45.0000017921567
                  }
                ],
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "s": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "s": [
                      0
                    ],
                    "t": 43
                  },
                  {
                    "s": [
                      1
                    ],
                    "t": 45.0000017921567
                  }
                ],
                "ix": 1
              },
              "m": 1
            }
          ],
          "ind": 2,
          "parent": 1
        },
        {
          "ty": 4,
          "nm": "flamingo ring 2",
          "sr": 1,
          "st": -1.00000004073083,
          "op": 45.0000017921567,
          "ip": 18.0000006924242,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ]
            },
            "r": {
              "a": 1,
              "k": [
                {
                  "o": {
                    "x": 1,
                    "y": 0
                  },
                  "i": {
                    "x": 0.612,
                    "y": 1
                  },
                  "s": [
                    14.2
                  ],
                  "t": 18
                },
                {
                  "s": [
                    360
                  ],
                  "t": 51.0000020365418
                }
              ]
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 0,
              "k": 100
            }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Ellipse 1",
              "np": 3,
              "it": [
                {
                  "ty": "el",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Ellipse",
                  "nm": "Ellipse Path 1",
                  "d": 1,
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ]
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      54,
                      54
                    ]
                  }
                },
                {
                  "ty": "st",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Stroke",
                  "nm": "Stroke 1",
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "o": {
                    "a": 0,
                    "k": 100
                  },
                  "w": {
                    "a": 0,
                    "k": 6
                  },
                  "c": {
                    "a": 0,
                    "k": [
                      0.7098,
                      0.9176,
                      1
                    ]
                  }
                },
                {
                  "ty": "tr",
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  }
                }
              ]
            },
            {
              "ty": "tm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Trim",
              "nm": "Trim Paths 1",
              "ix": 2,
              "e": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "i": {
                      "x": 0.833,
                      "y": 1
                    },
                    "s": [
                      0
                    ],
                    "t": 21
                  },
                  {
                    "s": [
                      13.7
                    ],
                    "t": 45.0000017921567
                  }
                ],
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "s": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "s": [
                      0
                    ],
                    "t": 43
                  },
                  {
                    "s": [
                      13.7
                    ],
                    "t": 45.0000017921567
                  }
                ],
                "ix": 1
              },
              "m": 1
            }
          ],
          "ind": 3,
          "parent": 1
        },
        {
          "ty": 4,
          "nm": "flaming ring 1",
          "sr": 1,
          "st": -1.00000004073083,
          "op": 45.0000017921567,
          "ip": 16.000000610962502,
          "hd": false,
          "ddd": 0,
          "bm": 0,
          "hasMask": false,
          "ao": 0,
          "ks": {
            "a": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ]
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ]
            },
            "sk": {
              "a": 0,
              "k": 0
            },
            "p": {
              "a": 0,
              "k": [
                0,
                0,
                0
              ]
            },
            "r": {
              "a": 0,
              "k": 0
            },
            "sa": {
              "a": 0,
              "k": 0
            },
            "o": {
              "a": 0,
              "k": 100
            }
          },
          "ef": [],
          "shapes": [
            {
              "ty": "gr",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Group",
              "nm": "Ellipse 1",
              "np": 3,
              "it": [
                {
                  "ty": "el",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Shape - Ellipse",
                  "nm": "Ellipse Path 1",
                  "d": 1,
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ]
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      54,
                      54
                    ]
                  }
                },
                {
                  "ty": "st",
                  "bm": 0,
                  "hd": false,
                  "mn": "ADBE Vector Graphic - Stroke",
                  "nm": "Stroke 1",
                  "lc": 2,
                  "lj": 1,
                  "ml": 4,
                  "o": {
                    "a": 0,
                    "k": 100
                  },
                  "w": {
                    "a": 0,
                    "k": 6
                  },
                  "c": {
                    "a": 0,
                    "k": [
                      0.7098,
                      0.9176,
                      1
                    ]
                  }
                },
                {
                  "ty": "tr",
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  }
                }
              ]
            },
            {
              "ty": "tm",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Filter - Trim",
              "nm": "Trim Paths 1",
              "ix": 2,
              "e": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 1,
                      "y": 0
                    },
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "s": [
                      0
                    ],
                    "t": 0
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 38.0000015070409
                  }
                ],
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "s": {
                "a": 1,
                "k": [
                  {
                    "o": {
                      "x": 1,
                      "y": 0
                    },
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "s": [
                      0
                    ],
                    "t": 9
                  },
                  {
                    "s": [
                      100
                    ],
                    "t": 49.0000019550801
                  }
                ],
                "ix": 1
              },
              "m": 1
            }
          ],
          "ind": 4,
          "parent": 1
        }
      ]
    }
  ]
}
module.exports = data;