"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasUserImanageAccess = void 0;
var auth0_react_1 = require("@auth0/auth0-react");
function hasUserImanageAccess(user) {
    var meta = user && user["https://juristic.io/meta"];
    return !!(meta === null || meta === void 0 ? void 0 : meta.dbUser.accesses.includes("imanage"));
}
exports.hasUserImanageAccess = hasUserImanageAccess;
function useImanageAccess() {
    var user = (0, auth0_react_1.useAuth0)().user;
    return hasUserImanageAccess(user);
}
exports.default = useImanageAccess;
