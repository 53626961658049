"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var notification_1 = require("@customTypes/reducers/notification");
var initialState = {
    severity: "info",
    message: "",
    helperText: undefined,
    code: undefined,
    duration: undefined,
};
function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case "SHOW_NOTIFICATION":
            return __assign(__assign({}, state), { severity: action.severity, message: action.message || notification_1.defaultSeverityMessage[action.severity], helperText: action.helperText, code: action.code, duration: action.duration });
        case "CLOSE_NOTIFICATION":
            return __assign(__assign({}, state), { severity: "info", message: "", helperText: "", code: undefined });
        default:
            return state;
    }
}
exports.default = reducer;
