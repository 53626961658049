"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterQP = exports.SearchQP = void 0;
var SearchQP;
(function (SearchQP) {
    SearchQP["q"] = "q";
    SearchQP["columns"] = "columns";
})(SearchQP = exports.SearchQP || (exports.SearchQP = {}));
var FilterQP;
(function (FilterQP) {
    FilterQP["filter"] = "filter";
})(FilterQP = exports.FilterQP || (exports.FilterQP = {}));
