"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useMove = function () {
    var _a = (0, react_1.useState)(1), currentZoom = _a[0], setCurrentZoom = _a[1];
    var onMove = (0, react_1.useCallback)(function (e, viewport) {
        if (viewport) {
            setCurrentZoom(viewport.zoom);
        }
    }, []);
    return { currentZoom: currentZoom, onMove: onMove };
};
exports.default = useMove;
