"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable import/prefer-default-export */
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (theme) {
    var _a;
    return ({
        search: {
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            width: "100%",
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        container: {
            backgroundColor: "#fcfcfc",
            borderRadius: "8px",
            display: "flex",
        },
        searchBox: {
            width: 300,
        },
        inputRoot: {
            color: "inherit",
        },
        inputInput: (_a = {
                padding: theme.spacing(1, 1, 1, 0),
                // vertical padding + font size from searchIcon
                paddingLeft: "calc(1em + ".concat(theme.spacing(4), ")"),
                transition: theme.transitions.create("width"),
                width: "100%"
            },
            _a[theme.breakpoints.up("md")] = {
                width: "20ch",
            },
            _a),
        divider: {
            marginTop: 5,
            marginBottom: 5,
        },
        menuItem: {
            "&:hover": {
                backgroundColor: "transparent",
            },
            "&:hover > div": {
                color: theme.palette.primary.main,
            },
        },
        button: {
            "&:hover": {
                "& > svg": {
                    color: theme.palette.primary.main,
                },
            },
        },
        buttonActive: {
            "& > svg": {
                color: theme.palette.primary.main,
            },
        },
        list: {
            width: "30ch",
            maxHeight: 300,
            overflow: "auto",
        },
        listItem: {},
        listSubHeader: {
            lineHeight: "32px",
            "&&&": { backgroundColor: "#fcfcfc" },
        },
        popperPaper: {},
    });
});
