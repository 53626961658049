"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OPEN_TRIGGERED_ALERT = exports.UNHIGHLIGHT_ALERT = exports.HIGHLIGHT_ALERT = exports.HIDE_ANALYSIS_ALERT = exports.CLEAR_ANALYSIS_ALERTS = exports.ANALYSE_ALERTS_FAILED = exports.ANALYSE_ALERTS_SUCCESS = exports.ANALYSE_ALERTS_LOADING = exports.ALERT_DUPLICATE_FAILED = exports.ALERT_DUPLICATE_SUCCESS = exports.ALERT_DUPLICATE_LOADING = exports.ALERT_DELETE_CONDITION = exports.ALERT_CHANGE_CONDITION = exports.ALERT_ADD_CONDITION = exports.CHANGE_TAGS = exports.DESCRIPTION_CHANGE = exports.TITLE_CHANGE = exports.GET_CONDITION_DROPDOWN_FAILED = exports.GET_CONDITION_DROPDOWN_SUCCESS = exports.DELETE_ALERT_FAILED = exports.DELETE_ALERT_SUCCESS = exports.PUT_ALERT_FAILED = exports.PUT_ALERT_SUCCESS = exports.SHOW_ALERT_FAILED = exports.SHOW_ALERT_SUCCESS = exports.POST_ALERT_FAILED = exports.POST_ALERT_SUCCESS = exports.GET_ALERTS_FAILED = exports.GET_ALERTS_SUCCESS = void 0;
// API
exports.GET_ALERTS_SUCCESS = "GET_ALERTS_SUCCESS";
exports.GET_ALERTS_FAILED = "GET_ALERTS_FAILED";
exports.POST_ALERT_SUCCESS = "POST_ALERT_SUCCESS";
exports.POST_ALERT_FAILED = "POST_ALERT_FAILED";
exports.SHOW_ALERT_SUCCESS = "SHOW_ALERT_SUCCESS";
exports.SHOW_ALERT_FAILED = "SHOW_ALERT_FAILED";
exports.PUT_ALERT_SUCCESS = "PUT_ALERT_SUCCESS";
exports.PUT_ALERT_FAILED = "PUT_ALERT_FAILED";
exports.DELETE_ALERT_SUCCESS = "DELETE_ALERT_SUCCESS";
exports.DELETE_ALERT_FAILED = "DELETE_ALERT_FAILED";
exports.GET_CONDITION_DROPDOWN_SUCCESS = "GET_CONDITION_DROPDOWN_SUCCESS";
exports.GET_CONDITION_DROPDOWN_FAILED = "GET_CONDITION_DROPDOWN_FAILED";
exports.TITLE_CHANGE = "ALERT_TITLE_CHANGE";
exports.DESCRIPTION_CHANGE = "ALERT_DESCRIPTION_CHANGE";
exports.CHANGE_TAGS = "CHANGE_TAGS";
exports.ALERT_ADD_CONDITION = "ADD_CONDITION";
exports.ALERT_CHANGE_CONDITION = "CHANGE_CONDITION";
exports.ALERT_DELETE_CONDITION = "DELETE_CONDITION";
exports.ALERT_DUPLICATE_LOADING = "ALERT_DUPLICATE_LOADING";
exports.ALERT_DUPLICATE_SUCCESS = "ALERT_DUPLICATE_SUCCESS";
exports.ALERT_DUPLICATE_FAILED = "ALERT_DUPLICATE_FAILED";
exports.ANALYSE_ALERTS_LOADING = "ALERT_ALERTS_LOADING";
exports.ANALYSE_ALERTS_SUCCESS = "ALERT_ALERTS_SUCCESS";
exports.ANALYSE_ALERTS_FAILED = "ALERT_ALERTS_FAILED";
exports.CLEAR_ANALYSIS_ALERTS = "CLEAR_ANALYSIS_ALERTS";
exports.HIDE_ANALYSIS_ALERT = "HIDE_ANALYSIS_ALERT";
exports.HIGHLIGHT_ALERT = "HIGHLIGHT_ALERT";
exports.UNHIGHLIGHT_ALERT = "UNHIGHLIGHT_ALERT";
exports.OPEN_TRIGGERED_ALERT = "OPEN_TRIGGERED_ALERT";
