"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var styles_1 = require("@customTypes/styles");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    collapseBodyCoontainer: __assign({ display: "flex", gap: 24, maxHeight: 400, overflowY: "auto" }, (0, styles_1.JuristicScroll)(theme)),
    collapseHeader: {
        fontWeight: "bold",
        fontSize: 14,
        margin: theme.spacing(2),
    },
    collapseColumnLeft: {
        width: "100%",
    },
}); });
exports.default = useStyles;
