"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var uiActions_1 = require("@redux/actions/uiActions");
var useRelationshipGenerics = function (dispatch, user) {
    (0, react_1.useEffect)(function () {
        /*     if (reactFlowContainer) {
          setReactFlowDimensions({
            height: (reactFlowContainer.current as HTMLDivElement).clientHeight,
            width: (reactFlowContainer.current as HTMLDivElement).clientWidth,
          });
        } */
    }, []);
    (0, react_1.useEffect)(function () {
        dispatch(uiActions_1.closeMenuAction);
        return function () {
            dispatch(uiActions_1.openMenuAction);
        };
    }, [user]);
    var toggleSubMenu = (0, react_1.useCallback)(function () { return dispatch(uiActions_1.toggleAction); }, []);
    return { toggleSubMenu: toggleSubMenu };
};
exports.default = useRelationshipGenerics;
