"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function mod(a, b) {
    return ((a % b) + b) % b;
}
var offsetRadiusConfig = 2;
function useCarousel(numberOfSlides) {
    var _a = (0, react_1.useState)(0), index = _a[0], setIndex = _a[1];
    var modBySlidesLength = function (idx) { return mod(idx, numberOfSlides); };
    var moveSlide = function (direction) {
        setIndex(modBySlidesLength(index + direction));
    };
    var clampOffsetRadius = function (offsetRadius) {
        var upperBound = Math.floor((numberOfSlides - 1) / 2);
        if (offsetRadius < 0) {
            return 0;
        }
        if (offsetRadius > upperBound) {
            return upperBound;
        }
        return offsetRadius;
    };
    return {
        index: index,
        modBySlidesLength: modBySlidesLength,
        moveSlide: moveSlide,
        clampOffsetRadius: clampOffsetRadius,
        offsetRadiusConfig: offsetRadiusConfig,
    };
}
exports.default = useCarousel;
