"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.htmlDecode = exports.removeHtmlTags = void 0;
function removeHtmlTags(input) {
    var regex = /<[^>]+>/g; // Matches any HTML tag
    return input.replace(regex, "");
}
exports.removeHtmlTags = removeHtmlTags;
/**
 * Returns the text content of the HTML string.
 * THIS IS SUPERIOR TO THE removeHtmlTags FUNCTION. Removes all HTML tags and decodes HTML entities.
 * @param input The HTML string to decode.
 * @returns The decoded HTML string.
 * @example htmlDecode("<p>Test af &oslash;</p>") => "Test af ø"
 */
function htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent || removeHtmlTags(input);
}
exports.htmlDecode = htmlDecode;
