"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var alertConstants_1 = require("./alertConstants");
var folderConstants_1 = require("@redux/constants/folderConstants");
var constants_1 = require("@api/constants");
var initialState = {
    alerts: [],
    title: "",
    description: "",
    groupOrLawsuit: "",
    alertConditions: [],
    conditionsDropDownOptions: [],
    alertTags: [],
    loadings: constants_1.initialLoadings,
    triggeredAlerts: [],
    openTriggeredAlert: null,
};
function reducer(state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case alertConstants_1.ANALYSE_ALERTS_LOADING:
        case alertConstants_1.ALERT_DUPLICATE_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case alertConstants_1.GET_ALERTS_SUCCESS:
            return __assign(__assign({}, state), { alerts: action.alerts });
        case alertConstants_1.POST_ALERT_SUCCESS:
            return __assign(__assign({}, state), { title: "", description: "", groupOrLawsuit: "", alertConditions: [] });
        case alertConstants_1.SHOW_ALERT_SUCCESS:
            return __assign(__assign({}, state), { title: action.title, description: action.description, groupOrLawsuit: action.groupOrLawsuit, alertConditions: action.conditions, alertTags: action.tags });
        case alertConstants_1.GET_CONDITION_DROPDOWN_SUCCESS:
            return __assign(__assign({}, state), { conditionsDropDownOptions: action.conditions });
        case alertConstants_1.TITLE_CHANGE:
            return __assign(__assign({}, state), { title: action.title });
        case alertConstants_1.DESCRIPTION_CHANGE:
            return __assign(__assign({}, state), { description: action.description });
        case alertConstants_1.ALERT_ADD_CONDITION:
            return __assign(__assign({}, state), { alertConditions: __spreadArray(__spreadArray([], state.alertConditions, true), [action.condition], false) });
        case alertConstants_1.ALERT_CHANGE_CONDITION:
            return __assign(__assign({}, state), { alertConditions: state.alertConditions.map(function (condition, index) {
                    if (index === action.index) {
                        condition = action.condition;
                    }
                    return condition;
                }) });
        case alertConstants_1.ALERT_DELETE_CONDITION:
            return __assign(__assign({}, state), { alertConditions: state.alertConditions.filter(function (condition, index) { return index !== action.index; }) });
        case alertConstants_1.CHANGE_TAGS:
            return __assign(__assign({}, state), { alertTags: action.tags });
        case alertConstants_1.PUT_ALERT_SUCCESS:
            return state; // do something in the future
        case alertConstants_1.CLEAR_ANALYSIS_ALERTS:
            return __assign(__assign({}, state), { triggeredAlerts: [] });
        case alertConstants_1.ANALYSE_ALERTS_SUCCESS: {
            var mappedAlerts_1 = {};
            state.triggeredAlerts.forEach(function (alert) {
                mappedAlerts_1[alert.uniqueIndex] = {
                    id: alert.id,
                    visible: alert.visible,
                    new: alert.new,
                };
            });
            var filteredAlerts = action.alerts.map(function (alert) {
                var _a, _b, _c;
                if (((_a = mappedAlerts_1[alert.uniqueIndex]) === null || _a === void 0 ? void 0 : _a.id) === alert.id) {
                    if (((_b = mappedAlerts_1[alert.uniqueIndex]) === null || _b === void 0 ? void 0 : _b.visible) &&
                        ((_c = mappedAlerts_1[alert.uniqueIndex]) === null || _c === void 0 ? void 0 : _c.new)) {
                        return __assign(__assign({}, alert), { visible: true, new: true });
                    }
                    return __assign(__assign({}, alert), { visible: false, new: false });
                }
                return __assign(__assign({}, alert), { visible: !action.initial, new: true });
            });
            return __assign(__assign({}, state), { triggeredAlerts: filteredAlerts, loadings: constants_1.initialLoadings });
        }
        case alertConstants_1.HIGHLIGHT_ALERT:
            return __assign(__assign({}, state), { triggeredAlerts: state.triggeredAlerts.map(function (alert) {
                    if (alert.uniqueIndex === action.uniqueIndex) {
                        alert.visible = true;
                        alert.new = false;
                    }
                    return alert;
                }) });
        case alertConstants_1.UNHIGHLIGHT_ALERT:
            return __assign(__assign({}, state), { triggeredAlerts: state.triggeredAlerts.map(function (alert) {
                    if (alert.uniqueIndex === action.uniqueIndex) {
                        alert.visible = false;
                        alert.new = false;
                    }
                    return alert;
                }) });
        case alertConstants_1.HIDE_ANALYSIS_ALERT:
            return __assign(__assign({}, state), { triggeredAlerts: state.triggeredAlerts.map(function (alert) {
                    if (alert.uniqueIndex === action.uniqueIndex) {
                        alert.visible = false;
                        alert.new = false;
                    }
                    return alert;
                }) });
        case alertConstants_1.OPEN_TRIGGERED_ALERT:
            return __assign(__assign({}, state), { openTriggeredAlert: action.alert });
        case alertConstants_1.ANALYSE_ALERTS_FAILED:
        case alertConstants_1.POST_ALERT_FAILED:
        case alertConstants_1.SHOW_ALERT_FAILED:
        case alertConstants_1.PUT_ALERT_FAILED:
        case alertConstants_1.DELETE_ALERT_SUCCESS:
        case alertConstants_1.DELETE_ALERT_FAILED:
        case alertConstants_1.GET_CONDITION_DROPDOWN_FAILED:
        case alertConstants_1.GET_ALERTS_FAILED:
        case alertConstants_1.ALERT_DUPLICATE_FAILED:
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case alertConstants_1.ALERT_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { alerts: action.alert ? __spreadArray(__spreadArray([], state.alerts, true), [action.alert], false) : state.alerts, loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.postFolderFailed)("alerts"):
        case (0, folderConstants_1.putFolderTitleFailed)("alerts"):
        case (0, folderConstants_1.putFolderLocationFailed)("alerts"):
        case (0, folderConstants_1.putFolderElementLocationFailed)("alerts"):
        case (0, folderConstants_1.deleteFolderFailed)("alerts"):
        case (0, folderConstants_1.duplicateFolderFailed)("alerts"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.deleteFolderLoading)("alerts"):
        case (0, folderConstants_1.putFolderLocationLoading)("alerts"):
        case (0, folderConstants_1.putFolderElementLocationLoading)("alerts"):
        case (0, folderConstants_1.putFolderTitleLoading)("alerts"):
        case (0, folderConstants_1.postFolderLoading)("alerts"):
        case (0, folderConstants_1.duplicateFolderLoading)("alerts"):
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadings] = true, _b)) });
        case (0, folderConstants_1.postFolderSuccess)("alerts"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, alerts: __spreadArray([action.folder], state.alerts, true) });
        case (0, folderConstants_1.putFolderTitleSuccess)("alerts"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, alerts: state.alerts.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderLocationSuccess)("alerts"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, alerts: state.alerts.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderElementLocationSuccess)("alerts"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, alerts: state.alerts.map(function (c) {
                    if (!c.isFolder && c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.deleteFolderSuccess)("alerts"): {
            if (action.deleteChildren) {
                return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, alerts: state.alerts
                        .filter(function (c) { return c.id !== action.folder.id; })
                        .filter(function (c) { return c.folderId !== action.folder.id; }) });
            }
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, alerts: state.alerts
                    .filter(function (c) { return c.id !== action.folder.id; })
                    .map(function (c) {
                    return c.folderId === action.folder.id
                        ? __assign(__assign({}, c), { folderId: action.folder.folderId }) : c;
                }) });
        }
        case (0, folderConstants_1.duplicateFolderSuccess)("alerts"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, alerts: __spreadArray(__spreadArray([], action.folder, true), state.alerts, true) });
        default:
            return state;
    }
}
exports.default = reducer;
