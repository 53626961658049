"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleKeyDown = void 0;
function handleKeyDown(ev) {
    var _a, _b;
    ev.stopPropagation();
    if (ev.key === "Enter") {
        (_b = (_a = ev.target) === null || _a === void 0 ? void 0 : _a.blur) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
}
exports.handleKeyDown = handleKeyDown;
