"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var HEX = /** @class */ (function () {
    function HEX() {
    }
    /**
     * @param luminance 0 < x < 1 - the lower the luminance the darker the color
     */
    HEX.prototype.isDarkerThan = function (hexcode, luminance) {
        // Remove the '#' character if present
        var cleanHexCode = hexcode.replace("#", "");
        var rSubString = cleanHexCode.substring(0, 2);
        var gSubString = cleanHexCode.substring(2, 4);
        var bSubString = cleanHexCode.substring(4, 6);
        // Parse the hex code to RGB components
        var r = Number.parseInt(rSubString, 16);
        var g = Number.parseInt(gSubString, 16);
        var b = Number.parseInt(bSubString, 16);
        // Calculate luminance (brightness)
        var calculatedLuminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        // Check if luminance is less than the threshold x
        return calculatedLuminance < luminance;
    };
    return HEX;
}());
exports.default = HEX;
