"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useDimFlowItems(flowId) {
    var elementBelongsToFlow = (0, react_1.useCallback)(function (element) {
        if (!flowId)
            return true;
        return !!element.closest(".react-flow[id=\"".concat(flowId, "\"]"));
    }, [flowId]);
    var getEdgeLabel = (0, react_1.useCallback)(function (edgeId) {
        var selector = ".react-flow__edgelabel-renderer > div > div[data-id=\"".concat(edgeId, "\"]");
        var element = null;
        document.querySelectorAll(selector).forEach(function (label) {
            if (elementBelongsToFlow(label)) {
                element = label;
            }
        });
        return element;
    }, [elementBelongsToFlow]);
    var dimEdgeLabel = (0, react_1.useCallback)(function (edgeId) {
        if (!edgeId)
            return;
        var edgeLabel = getEdgeLabel(edgeId);
        if (edgeLabel) {
            edgeLabel.classList.add("dim");
        }
    }, [getEdgeLabel]);
    var removeEdgeLabelDim = (0, react_1.useCallback)(function (edgeId) {
        if (!edgeId)
            return;
        var edgeLabel = getEdgeLabel(edgeId);
        if (edgeLabel) {
            edgeLabel.classList.remove("dim");
        }
    }, [getEdgeLabel]);
    var dimFlowItem = (0, react_1.useCallback)(function (isNode, _id) {
        document.querySelectorAll(".react-flow__node").forEach(function (node) {
            if (!elementBelongsToFlow(node))
                return;
            // Using the child because react flow overrides the classes on the node on click
            var nodeChild = node.children[0];
            if (_id === null) {
                nodeChild.classList.remove("dim");
            }
            else if (isNode === false) {
                nodeChild.classList.add("dim");
            }
            else if (isNode === true) {
                var nodeId = node.getAttribute("data-id");
                if (nodeId !== _id) {
                    nodeChild.classList.add("dim");
                }
                else {
                    nodeChild.classList.remove("dim");
                }
            }
        });
        document.querySelectorAll(".react-flow__edge").forEach(function (edge) {
            var _a;
            if (!elementBelongsToFlow(edge))
                return;
            var edgeId = (_a = edge.getAttribute("data-testid")) === null || _a === void 0 ? void 0 : _a.replace("rf__edge-", "");
            if (_id === null) {
                edge.classList.remove("dim");
                removeEdgeLabelDim(edgeId);
            }
            else if (isNode === true) {
                edge.classList.add("dim");
                dimEdgeLabel(edgeId);
            }
            else if (isNode === false) {
                if (edgeId !== _id) {
                    edge.classList.add("dim");
                    dimEdgeLabel(edgeId);
                }
                else {
                    edge.classList.remove("dim");
                    removeEdgeLabelDim(edgeId);
                }
            }
        });
    }, [elementBelongsToFlow, dimEdgeLabel, removeEdgeLabelDim]);
    var dimNodes = (0, react_1.useCallback)(function (nodeIds) {
        document.querySelectorAll(".react-flow__node").forEach(function (node) {
            if (!elementBelongsToFlow(node))
                return;
            var nodeId = node.getAttribute("data-id");
            if (nodeIds === undefined) {
                node.classList.remove("dim");
            }
            else if (nodeId && nodeIds.includes(nodeId)) {
                node.classList.remove("dim");
            }
            else {
                node.classList.add("dim");
            }
        });
    }, [elementBelongsToFlow]);
    var dimEdges = (0, react_1.useCallback)(function (edgeIds) {
        document.querySelectorAll(".react-flow__edge").forEach(function (edge) {
            var _a;
            if (!elementBelongsToFlow(edge))
                return;
            var edgeId = (_a = edge.getAttribute("data-testid")) === null || _a === void 0 ? void 0 : _a.replace("rf__edge-", "");
            if (edgeIds === undefined) {
                edge.classList.remove("dim");
                removeEdgeLabelDim(edgeId);
            }
            else if (edgeId && edgeIds.includes(edgeId)) {
                edge.classList.remove("dim");
                removeEdgeLabelDim(edgeId);
            }
            else {
                edge.classList.add("dim");
                dimEdgeLabel(edgeId);
            }
        });
    }, [dimEdgeLabel, elementBelongsToFlow, removeEdgeLabelDim]);
    var dimFlowItems = (0, react_1.useCallback)(function (nodeIds, edgeIds) {
        dimNodes(nodeIds);
        dimEdges(edgeIds);
    }, [dimEdges, dimNodes]);
    return { dimFlowItem: dimFlowItem, dimFlowItems: dimFlowItems, dimNodes: dimNodes, dimEdges: dimEdges };
}
exports.default = useDimFlowItems;
