const data = {
  "nm": "icn-success",
  "ddd": 0,
  "h": 200,
  "w": 200,
  "meta": {
    "g": "@lottiefiles/toolkit-js 0.33.2"
  },
  "layers": [
    {
      "ty": 3,
      "nm": "tick-parent",
      "sr": 1,
      "st": 0,
      "op": 1800,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0.333,
                "y": 0
              },
              "i": {
                "x": 0.576,
                "y": 1
              },
              "s": [
                222.22,
                222.22,
                100
              ],
              "t": 87.5
            },
            {
              "o": {
                "x": 0.423,
                "y": 0
              },
              "i": {
                "x": 0.667,
                "y": 1
              },
              "s": [
                266.67,
                266.67,
                100
              ],
              "t": 130
            },
            {
              "s": [
                246.91,
                246.91,
                100
              ],
              "t": 147.5
            }
          ],
          "ix": 6
        },
        "sk": {
          "a": 0,
          "k": 0
        },
        "p": {
          "a": 0,
          "k": [
            100,
            100,
            0
          ],
          "ix": 2
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "sa": {
          "a": 0,
          "k": 0
        },
        "o": {
          "a": 0,
          "k": 0,
          "ix": 11
        }
      },
      "ef": [],
      "ind": 1
    },
    {
      "ty": 4,
      "nm": "dash-1",
      "sr": 1,
      "st": 0,
      "op": 1800,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 0,
          "k": [
            4.302,
            4.302,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        },
        "sk": {
          "a": 0,
          "k": 0
        },
        "p": {
          "a": 0,
          "k": [
            -11.053,
            15.359,
            0
          ],
          "ix": 2
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "sa": {
          "a": 0,
          "k": 0
        },
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      3.303,
                      3.302
                    ],
                    [
                      -3.303,
                      -3.302
                    ]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "c": {
                "a": 0,
                "k": [
                  0.8,
                  0.8,
                  0.8
                ],
                "ix": 3
              }
            },
            {
              "ty": "tr",
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "p": {
                "a": 0,
                "k": [
                  4.302,
                  4.302
                ],
                "ix": 2
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              }
            }
          ]
        },
        {
          "ty": "tm",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Filter - Trim",
          "nm": "Trim Paths 1",
          "ix": 2,
          "e": {
            "a": 1,
            "k": [
              {
                "o": {
                  "x": 0.333,
                  "y": 0
                },
                "i": {
                  "x": 0.667,
                  "y": 1
                },
                "s": [
                  100
                ],
                "t": 87.5
              },
              {
                "s": [
                  0
                ],
                "t": 115
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "s": {
            "a": 1,
            "k": [
              {
                "o": {
                  "x": 0.333,
                  "y": 0
                },
                "i": {
                  "x": 0.302,
                  "y": 1
                },
                "s": [
                  100
                ],
                "t": 87.5
              },
              {
                "s": [
                  0
                ],
                "t": 115
              }
            ],
            "ix": 1
          },
          "m": 1
        }
      ],
      "ind": 2,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "dash-2",
      "sr": 1,
      "st": 0,
      "op": 1800,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 0,
          "k": [
            11.358,
            11.257,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        },
        "sk": {
          "a": 0,
          "k": 0
        },
        "p": {
          "a": 0,
          "k": [
            11.07,
            3.502,
            0
          ],
          "ix": 2
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "sa": {
          "a": 0,
          "k": 0
        },
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      10.357,
                      -10.258
                    ],
                    [
                      -10.357,
                      10.258
                    ]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "c": {
                "a": 0,
                "k": [
                  0.8,
                  0.8,
                  0.8
                ],
                "ix": 3
              }
            },
            {
              "ty": "tr",
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "p": {
                "a": 0,
                "k": [
                  11.358,
                  11.257
                ],
                "ix": 2
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              }
            }
          ]
        },
        {
          "ty": "tm",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Filter - Trim",
          "nm": "Trim Paths 1",
          "ix": 2,
          "e": {
            "a": 1,
            "k": [
              {
                "o": {
                  "x": 0.333,
                  "y": 0
                },
                "i": {
                  "x": 0.667,
                  "y": 1
                },
                "s": [
                  100
                ],
                "t": 102.5
              },
              {
                "s": [
                  0
                ],
                "t": 125
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "s": {
            "a": 1,
            "k": [
              {
                "o": {
                  "x": 0.333,
                  "y": 0
                },
                "i": {
                  "x": 0.302,
                  "y": 1
                },
                "s": [
                  100
                ],
                "t": 102.5
              },
              {
                "s": [
                  0
                ],
                "t": 125
              }
            ],
            "ix": 1
          },
          "m": 1
        }
      ],
      "ind": 3,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "tick",
      "sr": 1,
      "st": 0,
      "op": 1800,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 0,
          "k": [
            17.739,
            13.258,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        },
        "sk": {
          "a": 0,
          "k": 0
        },
        "p": {
          "a": 0,
          "k": [
            -0.333,
            1.799,
            0
          ],
          "ix": 2
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "sa": {
          "a": 0,
          "k": 0
        },
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": false,
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      14.739,
                      -10.258
                    ],
                    [
                      -5.976,
                      10.258
                    ],
                    [
                      -14.739,
                      1.494
                    ]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 8,
                "ix": 5
              },
              "c": {
                "a": 0,
                "k": [
                  0.451,
                  0.6941,
                  1
                ],
                "ix": 3
              }
            },
            {
              "ty": "tr",
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "p": {
                "a": 0,
                "k": [
                  17.739,
                  13.258
                ],
                "ix": 2
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              }
            }
          ]
        },
        {
          "ty": "tm",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Filter - Trim",
          "nm": "Trim Paths 1",
          "ix": 2,
          "e": {
            "a": 0,
            "k": 100,
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "s": {
            "a": 1,
            "k": [
              {
                "o": {
                  "x": 0.324,
                  "y": 0
                },
                "i": {
                  "x": 0.185,
                  "y": 1
                },
                "s": [
                  100
                ],
                "t": 87.5
              },
              {
                "s": [
                  0
                ],
                "t": 147.5
              }
            ],
            "ix": 1
          },
          "m": 1
        }
      ],
      "ind": 4,
      "parent": 1
    },
    {
      "ty": 4,
      "nm": "circle-blue",
      "sr": 1,
      "st": 0,
      "op": 1800,
      "ip": 0,
      "hd": false,
      "ddd": 0,
      "bm": 0,
      "hasMask": false,
      "ao": 0,
      "ks": {
        "a": {
          "a": 0,
          "k": [
            37.5,
            37.5,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            246.91,
            246.91,
            100
          ],
          "ix": 6
        },
        "sk": {
          "a": 0,
          "k": 0
        },
        "p": {
          "a": 0,
          "k": [
            100,
            100,
            0
          ],
          "ix": 2
        },
        "r": {
          "a": 1,
          "k": [
            {
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "s": [
                0
              ],
              "t": 0
            },
            {
              "s": [
                360
              ],
              "t": 120
            }
          ],
          "ix": 10
        },
        "sa": {
          "a": 0,
          "k": 0
        },
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        }
      },
      "ef": [],
      "shapes": [
        {
          "ty": "gr",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Group",
          "nm": "Group 1",
          "ix": 1,
          "cix": 2,
          "np": 2,
          "it": [
            {
              "ty": "sh",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Shape - Group",
              "nm": "Path 1",
              "ix": 1,
              "d": 1,
              "ks": {
                "a": 0,
                "k": {
                  "c": true,
                  "i": [
                    [
                      -19.054,
                      0
                    ],
                    [
                      0,
                      -19.054
                    ],
                    [
                      19.054,
                      0
                    ],
                    [
                      0,
                      19.054
                    ]
                  ],
                  "o": [
                    [
                      19.054,
                      0
                    ],
                    [
                      0,
                      19.054
                    ],
                    [
                      -19.054,
                      0
                    ],
                    [
                      0,
                      -19.054
                    ]
                  ],
                  "v": [
                    [
                      0,
                      -34.5
                    ],
                    [
                      34.5,
                      0
                    ],
                    [
                      0,
                      34.5
                    ],
                    [
                      -34.5,
                      0
                    ]
                  ]
                },
                "ix": 2
              }
            },
            {
              "ty": "st",
              "bm": 0,
              "hd": false,
              "mn": "ADBE Vector Graphic - Stroke",
              "nm": "Stroke 1",
              "lc": 2,
              "lj": 2,
              "ml": 1,
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 8,
                "ix": 5
              },
              "c": {
                "a": 0,
                "k": [
                  0.451,
                  0.6941,
                  1
                ],
                "ix": 3
              }
            },
            {
              "ty": "tr",
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "p": {
                "a": 0,
                "k": [
                  37.5,
                  37.5
                ],
                "ix": 2
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              }
            }
          ]
        },
        {
          "ty": "tm",
          "bm": 0,
          "hd": false,
          "mn": "ADBE Vector Filter - Trim",
          "nm": "Trim Paths 1",
          "ix": 2,
          "e": {
            "a": 0,
            "k": 0,
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "s": {
            "a": 1,
            "k": [
              {
                "o": {
                  "x": 0.333,
                  "y": 0
                },
                "i": {
                  "x": 0.123,
                  "y": 1
                },
                "s": [
                  0
                ],
                "t": 0
              },
              {
                "s": [
                  100
                ],
                "t": 120
              }
            ],
            "ix": 1
          },
          "m": 1
        }
      ],
      "ind": 5
    }
  ],
  "v": "5.5.1",
  "fr": 60,
  "op": 150,
  "ip": 0,
  "assets": []
}
module.exports = data;