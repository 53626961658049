"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var useReports = function (rf) {
    var store = (0, reactflow_1.useStoreApi)();
    var reportPanelOpen = (0, redux_1.useAppSelector)(function (state) { return state.workspace.reportPanelOpen; });
    var reportPanelViewingFor = (0, redux_1.useAppSelector)(function (state) { return state.workspace.reportPanelViewingFor; });
    var resetPickedElements = (0, react_1.useCallback)(function () {
        var state = store.getState();
        var nodeElements = state.getNodes();
        var edgeElements = state.edges;
        nodeElements.forEach(function (element) {
            var htmlNode = document.querySelector("[data-id=\"".concat(element.id, "\"]"));
            if (htmlNode === null || htmlNode === void 0 ? void 0 : htmlNode.style) {
                htmlNode.style.opacity = "1";
            }
        });
        edgeElements.forEach(function (element) {
            var htmlEdge = document.querySelector(".id_".concat(element.id));
            if (htmlEdge === null || htmlEdge === void 0 ? void 0 : htmlEdge.style) {
                htmlEdge.style.opacity = "1";
            }
        });
    }, []);
    var setPickedElements = (0, react_1.useCallback)(function (activeReport) {
        var state = store.getState();
        var nodeElements = state.getNodes();
        var edgeElements = state.edges;
        nodeElements
            .filter(function (node) { return !(activeReport === null || activeReport === void 0 ? void 0 : activeReport.nodeIds.includes(node.id)); })
            .forEach(function (element) {
            var htmlNode = document.querySelector("[data-id=\"".concat(element.id, "\"]"));
            if (htmlNode === null || htmlNode === void 0 ? void 0 : htmlNode.style) {
                htmlNode.style.opacity = "0.2";
            }
        });
        edgeElements
            .filter(function (edge) { return !(activeReport === null || activeReport === void 0 ? void 0 : activeReport.edgeIds.includes(edge.id)); })
            .forEach(function (element) {
            var htmlEdge = document.querySelector(".id_".concat(element.id));
            if (htmlEdge === null || htmlEdge === void 0 ? void 0 : htmlEdge.style) {
                htmlEdge.style.opacity = "0.2";
            }
        });
    }, []);
    var _a = (0, react_1.useState)(0), current = _a[0], setCurrent = _a[1];
    (0, react_1.useEffect)(function () {
        if (reportPanelOpen && reportPanelViewingFor) {
            resetPickedElements();
            setPickedElements(reportPanelViewingFor);
            if (current !== reportPanelViewingFor.id) {
                var state = store.getState();
                var nodeElements = state.getNodes();
                var nodes = nodeElements.filter(function (node) {
                    return reportPanelViewingFor.nodeIds.includes(node.id);
                });
                if (nodes.length > 0) {
                    var bounds = (0, reactflow_1.getNodesBounds)(nodes);
                    bounds.x -= 75;
                    bounds && (rf === null || rf === void 0 ? void 0 : rf.fitBounds(bounds, { duration: 500, padding: 0.5 }));
                }
                setCurrent(reportPanelViewingFor.id);
            }
        }
        else {
            setCurrent(0);
            resetPickedElements();
            rf === null || rf === void 0 ? void 0 : rf.fitView({ duration: 500, padding: 1 });
        }
    }, [reportPanelViewingFor]);
};
exports.default = useReports;
