"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNodePositionInsideParent = exports.sortNodes = void 0;
// we have to make sure that parent nodes are rendered before their children
var sortNodes = function (a, b) {
    if (a.type === b.type) {
        return 0;
    }
    return a.type === "customgroup" && b.type !== "customgroup" ? -1 : 1;
};
exports.sortNodes = sortNodes;
var getNodePositionInsideParent = function (node, groupNode) {
    var _a, _b, _c, _d;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    var position = node.position;
    var nodeWidth = (_a = node.width) !== null && _a !== void 0 ? _a : 0;
    var nodeHeight = (_b = node.height) !== null && _b !== void 0 ? _b : 0;
    var groupWidth = (_c = groupNode.width) !== null && _c !== void 0 ? _c : 0;
    var groupHeight = (_d = groupNode.height) !== null && _d !== void 0 ? _d : 0;
    if (position.x < groupNode.position.x) {
        position.x = 0;
    }
    else if (position.x + nodeWidth > groupNode.position.x + groupWidth) {
        position.x = groupWidth - nodeWidth;
    }
    else {
        position.x -= groupNode.position.x;
    }
    if (position.y < groupNode.position.y) {
        position.y = 0;
    }
    else if (position.y + nodeHeight > groupNode.position.y + groupHeight) {
        position.y = groupHeight - nodeHeight;
    }
    else {
        position.y -= groupNode.position.y;
    }
    return position;
};
exports.getNodePositionInsideParent = getNodePositionInsideParent;
