"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customGetStraightPath = exports.calcCustomStraightPath = exports.getStraightPathInitiateDragHandles = exports.getStraightPathDragHandles = exports.getStraightPathKnotPositions = void 0;
var pathFunctions_1 = require("./pathFunctions");
var getStraightPathKnotPositions = function (path) {
    var subPaths = path.split(/[ML]/);
    var knots = [];
    for (var i = 1; i < subPaths.length; i++) {
        var _a = subPaths[i].split(","), _x = _a[0], _y = _a[1];
        knots.push({ x: Number(_x), y: Number(_y) });
    }
    return knots;
};
exports.getStraightPathKnotPositions = getStraightPathKnotPositions;
var getStraightPathDragHandles = function (path) {
    var knots = (0, exports.getStraightPathKnotPositions)(path);
    var newPathDragHandles = [];
    for (var i = 1; i < knots.length - 1; i++) {
        newPathDragHandles.push(knots[i]);
    }
    return newPathDragHandles;
};
exports.getStraightPathDragHandles = getStraightPathDragHandles;
var getStraightPathInitiateDragHandles = function (path) {
    var straightPathKnots = (0, exports.getStraightPathKnotPositions)(path);
    var newPathInitiateDragHandles = [];
    for (var i = 0; i < straightPathKnots.length - 1; i++) {
        var knot1 = straightPathKnots[i];
        var knot2 = straightPathKnots[i + 1];
        var midPoint = (0, pathFunctions_1.calcMidPoint)(knot1, knot2);
        newPathInitiateDragHandles.push(midPoint);
    }
    return newPathInitiateDragHandles;
};
exports.getStraightPathInitiateDragHandles = getStraightPathInitiateDragHandles;
var calcCustomStraightPath = function (knots) {
    var path = ["M".concat(knots[0].x, ",").concat(knots[0].y)];
    for (var i = 1; i < knots.length; i++) {
        path.push("L".concat(knots[i].x, ",").concat(knots[i].y));
    }
    return path.join(" ");
};
exports.calcCustomStraightPath = calcCustomStraightPath;
var customGetStraightPath = function (_a) {
    var sourceX = _a.sourceX, sourceY = _a.sourceY, targetX = _a.targetX, targetY = _a.targetY, pathKnots = _a.pathKnots, curveScalar = _a.curveScalar, bearing = _a.bearing;
    var pathKnotPositions = (0, pathFunctions_1.getAbsoluteKnots)(pathKnots || [], { x: sourceX, y: sourceY }, { x: targetX, y: targetY });
    var path = (0, exports.calcCustomStraightPath)(pathKnotPositions);
    var labelPoint = (0, pathFunctions_1.calcPointFromStringPath)(path, curveScalar || 0.5);
    var toolbarPosition = (0, pathFunctions_1.getToolbarPosition)(pathKnotPositions, labelPoint, bearing !== undefined ? bearing : null);
    var labelX = labelPoint.x, labelY = labelPoint.y;
    return [path, labelX, labelY, toolbarPosition];
};
exports.customGetStraightPath = customGetStraightPath;
