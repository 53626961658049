"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REMOVE_AUTOMATION_FAILED = exports.REMOVE_AUTOMATION_SUCCESS = exports.REMOVE_AUTOMATION_OPTIMISTIC = exports.DEACTIVATE_AUTOMATION = exports.TOGGLE_AUTOMATION_ACTIVE_FAILED = exports.TOGGLE_AUTOMATION_ACTIVE_SUCCESS = exports.TOGGLE_AUTOMATION_ACTIVE_OPTIMISTIC = exports.STORE_AUTOMATION_FAILED = exports.STORE_AUTOMATION_SUCCESS = exports.STORE_AUTOMATION_LOADING = exports.INDEX_AUTOMATION_FAILED = exports.INDEX_AUTOMATION_SUCCESS = exports.INDEX_AUTOMATION_LOADING = void 0;
exports.INDEX_AUTOMATION_LOADING = "INDEX_AUTOMATION_LOADING";
exports.INDEX_AUTOMATION_SUCCESS = "INDEX_AUTOMATION_SUCCESS";
exports.INDEX_AUTOMATION_FAILED = "INDEX_AUTOMATION_FAILED";
exports.STORE_AUTOMATION_LOADING = "STORE_AUTOMATION_LOADING";
exports.STORE_AUTOMATION_SUCCESS = "STORE_AUTOMATION_SUCCESS";
exports.STORE_AUTOMATION_FAILED = "STORE_AUTOMATION_FAILED";
exports.TOGGLE_AUTOMATION_ACTIVE_OPTIMISTIC = "TOGGLE_AUTOMATION_ACTIVE_OPTIMISTIC";
exports.TOGGLE_AUTOMATION_ACTIVE_SUCCESS = "TOGGLE_AUTOMATION_ACTIVE_SUCCESS";
exports.TOGGLE_AUTOMATION_ACTIVE_FAILED = "TOGGLE_AUTOMATION_ACTIVE_FAILED";
exports.DEACTIVATE_AUTOMATION = "DEACTIVATE_AUTOMATION";
exports.REMOVE_AUTOMATION_OPTIMISTIC = "REMOVE_AUTOMATION_OPTIMISTIC";
exports.REMOVE_AUTOMATION_SUCCESS = "REMOVE_AUTOMATION_SUCCESS";
exports.REMOVE_AUTOMATION_FAILED = "REMOVE_AUTOMATION_FAILED";
