"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPath = exports.immutablyChangeObjectByKeys = exports.getObjectByKeys = void 0;
function getObjectByKeys(obj, keys) {
    return keys.reduce(function (currentObj, key) {
        if (currentObj && currentObj.children) {
            return currentObj.children[key];
        }
        return undefined;
    }, obj);
}
exports.getObjectByKeys = getObjectByKeys;
function immutablyChangeObjectByKeys(obj, keys) {
    return function (cb) {
        var shallowCopy = __assign({}, obj);
        var finalObj = keys.reduce(function (currentObj, key) {
            if (!currentObj)
                return undefined;
            if (currentObj.children) {
                currentObj.children[key] = __assign({}, currentObj.children[key]);
                return currentObj.children[key];
            }
            return currentObj[key];
        }, shallowCopy);
        if (finalObj) {
            cb(finalObj);
        }
        return shallowCopy;
    };
}
exports.immutablyChangeObjectByKeys = immutablyChangeObjectByKeys;
function getPath(obj) {
    var parent = obj.parent;
    var path = [obj.id];
    while (parent) {
        path.unshift(parent.id);
        parent = parent.parent;
    }
    return path;
}
exports.getPath = getPath;
