"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    paper: {
        padding: theme.spacing(2),
        width: "100%",
    },
    flexCenter: {
        display: "flex",
        alignItems: "center",
    },
    linkInheritColor: {
        a: {
            color: "inherit",
        },
    },
    svgActive: {
        svg: {
            color: theme.palette.primary.main + "!important",
        },
    },
}); });
exports.default = useStyles;
