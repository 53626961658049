"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var react_1 = __importStar(require("react"));
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function () { return ({
    pxInput: {
        width: "98px",
        input: {
            textAlign: "right",
            paddingRight: "31px",
        },
    },
    pxInputAdornment: {
        textAlign: "left",
        position: "absolute",
        left: "36px",
        pointerEvents: "none",
    },
}); });
function SliderInput(props) {
    var value = props.value, maxValue = props.maxValue, minVal = props.minVal, onSliderChange = props.onSliderChange, onInputChange = props.onInputChange, onInputBlur = props.onInputBlur, endAdornment = props.endAdornment, step = props.step;
    var classes = useStyles().classes;
    var _a = react_1.default.useState(""), inputValue = _a[0], setInputValue = _a[1];
    (0, react_1.useEffect)(function () {
        if (parseFloat(inputValue) !== value &&
            inputValue.charAt(inputValue.length - 1) !== ".") {
            setInputValue(value ? value.toString() : "");
        }
    }, [value]);
    var handleInputChange = (0, react_1.useCallback)(function (_, newValue) {
        // regex that removes all non numeric characters (. is allowed)
        var decimalString = newValue.replace(/[^0-9.]/g, "");
        var endsOnDotOrDotZero = decimalString.match(/\..*0$/g);
        setInputValue(decimalString);
        if (!endsOnDotOrDotZero) {
            onInputChange(parseFloat(decimalString));
        }
    }, [onInputChange]);
    return (react_1.default.createElement("div", { style: {
            display: "flex",
            gap: "20px",
            flexGrow: 1,
            alignItems: "center",
        } },
        react_1.default.createElement(material_1.Slider, { value: parseFloat(inputValue), onChange: onSliderChange, color: "primary", max: maxValue, min: minVal, size: "small", step: step, "aria-labelledby": "input-slider", style: { minWidth: "150px" } }),
        react_1.default.createElement(material_1.Input, { value: inputValue !== null && inputValue !== void 0 ? inputValue : "", margin: "dense", className: classes.pxInput, endAdornment: react_1.default.createElement(material_1.InputAdornment, { className: classes.pxInputAdornment, position: "end" }, endAdornment || ""), onChange: function (event) { return handleInputChange(event, event.target.value); }, onBlur: onInputBlur, inputProps: {
                inputMode: "numeric",
                "aria-labelledby": "input-slider",
                sx: { padding: "5px" },
            } })));
}
exports.default = SliderInput;
