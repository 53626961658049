"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@components/Tags/constants");
var data_1 = require("@customTypes/data");
var tableOptions_1 = require("@helpers/tableOptions");
exports.default = (function (t, lawsuitId, onEnterFolder) {
    if (onEnterFolder === void 0) { onEnterFolder = function () { }; }
    return __spreadArray([
        tableOptions_1.folderColumn,
        (0, tableOptions_1.titleFolderColumn)(0),
        {
            label: t("workspaces.table_desc"),
            name: "description",
            options: {
                filter: true,
            },
        },
        {
            label: "",
            name: "tags",
            options: constants_1.tableOptions,
        },
        {
            label: "",
            name: "id",
            options: {
                filter: false,
                sort: false,
                customHeadRender: function () { return ""; },
                customBodyRender: function (value, tableMeta) {
                    return (0, tableOptions_1.openElementOrFolder)({
                        folderColumnIndex: 0,
                        tableMeta: tableMeta,
                        value: value,
                        groupOrLawsuitId: lawsuitId,
                        location: "cases",
                        onEnterFolder: onEnterFolder,
                        where: data_1.WhereInApp.boards,
                        disabled: !value,
                        mainPath: "client",
                    });
                },
            },
        }
    ], tableOptions_1.timestampColumns, true);
});
