"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    container: {
        width: "100%",
        height: "100%",
    },
    searchContainer: {
        flexGrow: 1,
        margin: theme.spacing(2, 0, 4),
        borderRadius: 40,
        overflow: "hidden",
        boxShadow: theme.shadows[5],
    },
    flex: {
        flex: 1,
    },
    wrapper: {
        fontFamily: theme.typography.fontFamily,
        position: "relative",
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
        borderRadius: 2,
        display: "block",
        color: theme.palette.text.secondary,
        "& svg": {
            fill: theme.palette.text.secondary,
        },
    },
    search: {
        width: "auto",
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    input: {
        font: "inherit",
        padding: "".concat(theme.spacing(1), " ").concat(theme.spacing(1), " ").concat(theme.spacing(1), " ").concat(theme.spacing(4)),
        border: 0,
        display: "block",
        verticalAlign: "middle",
        whiteSpace: "normal",
        background: "none",
        margin: 0,
        color: "inherit",
        width: "100%",
        "&:focus": {
            outline: 0,
        },
    },
    toggleContainer: {
        height: 56,
        padding: "".concat(theme.spacing(1), " ").concat(theme.spacing(2)),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        margin: "".concat(theme.spacing(1), " 0"),
    },
    itemIconContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}); });
exports.default = useStyles;
