"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRelativeNodesBounds = exports.getBoundsOfBoxes = exports.getNodePositionInsideParent = exports.getId = exports.sortNodes = void 0;
var reactflow_1 = require("reactflow");
// we have to make sure that parent nodes are rendered before their children
var sortNodes = function (a, b) {
    if (a.type === b.type) {
        return 0;
    }
    return a.type === "group" && b.type !== "group" ? -1 : 1;
};
exports.sortNodes = sortNodes;
var getId = function (prefix) {
    if (prefix === void 0) { prefix = "node"; }
    return "".concat(prefix, "_").concat(Math.random() * 10000);
};
exports.getId = getId;
var getNodePositionInsideParent = function (node, groupNode) {
    var _a, _b, _c, _d, _e;
    var position = (_a = node.position) !== null && _a !== void 0 ? _a : { x: 0, y: 0 };
    var nodeWidth = (_b = node.width) !== null && _b !== void 0 ? _b : 0;
    var nodeHeight = (_c = node.height) !== null && _c !== void 0 ? _c : 0;
    var groupWidth = (_d = groupNode.width) !== null && _d !== void 0 ? _d : 0;
    var groupHeight = (_e = groupNode.height) !== null && _e !== void 0 ? _e : 0;
    if (position.x < groupNode.position.x) {
        position.x = 0;
    }
    else if (position.x + nodeWidth > groupNode.position.x + groupWidth) {
        position.x = groupWidth - nodeWidth;
    }
    else {
        position.x -= groupNode.position.x;
    }
    if (position.y < groupNode.position.y) {
        position.y = 0;
    }
    else if (position.y + nodeHeight > groupNode.position.y + groupHeight) {
        position.y = groupHeight - nodeHeight;
    }
    else {
        position.y -= groupNode.position.y;
    }
    return position;
};
exports.getNodePositionInsideParent = getNodePositionInsideParent;
var getBoundsOfBoxes = function (box1, box2) { return ({
    x: Math.min(box1.x, box2.x),
    y: Math.min(box1.y, box2.y),
    x2: Math.max(box1.x2, box2.x2),
    y2: Math.max(box1.y2, box2.y2),
}); };
exports.getBoundsOfBoxes = getBoundsOfBoxes;
var getRelativeNodesBounds = function (nodes, nodeOrigin) {
    if (nodeOrigin === void 0) { nodeOrigin = [0, 0]; }
    if (nodes.length === 0) {
        return { x: 0, y: 0, width: 0, height: 0 };
    }
    var box = nodes.reduce(function (currBox, node) {
        var _a = (0, reactflow_1.getNodePositionWithOrigin)(node, nodeOrigin), x = _a.x, y = _a.y;
        return (0, exports.getBoundsOfBoxes)(currBox, (0, reactflow_1.rectToBox)({
            x: x,
            y: y,
            width: node.width || 0,
            height: node.height || 0,
        }));
    }, { x: Infinity, y: Infinity, x2: -Infinity, y2: -Infinity });
    return (0, reactflow_1.boxToRect)(box);
};
exports.getRelativeNodesBounds = getRelativeNodesBounds;
