"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListIcon = exports.DefaultIcon = void 0;
/* eslint-disable react/no-danger */
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var sizes = {
    s: 20,
    m: 30,
    l: 40,
};
function DefaultIcon(_a) {
    var vector = _a.vector, size = _a.size, top = _a.top, right = _a.right;
    var ref = (0, react_1.useRef)(null);
    var sx = {
        top: top,
        right: right,
        transform: "translate(50%, -50%)",
    };
    var width = size;
    var height = size;
    (0, react_1.useEffect)(function () {
        if (!ref.current) {
            return;
        }
        var svg = ref.current.getElementsByTagName("svg")[0];
        if (!svg) {
            return;
        }
        svg.setAttribute("width", "".concat(width, "px"));
        svg.setAttribute("height", "".concat(height, "px"));
        ref.current.style.width = "".concat(width, "px");
        ref.current.style.height = "".concat(height, "px");
    }, [ref, height, width]);
    return (react_1.default.createElement(material_1.Box, { position: "absolute", display: "flex", sx: sx },
        react_1.default.createElement("div", { ref: ref, 
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML: { __html: vector }, style: {
                width: width,
                height: height,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
            } })));
}
exports.DefaultIcon = DefaultIcon;
function ListIcon(_a) {
    var icon = _a.icon;
    var size = sizes.m;
    var top = icon.verticalOffset > 0 ? 0 : "".concat(-icon.verticalOffset, "%");
    var right = icon.horizontalOffset > 0 ? 0 : "".concat(-icon.horizontalOffset, "%");
    return icon.vector ? (react_1.default.createElement(DefaultIcon, { right: right, top: top, size: size, vector: icon.vector })) : null;
}
exports.ListIcon = ListIcon;
function Icon(_a) {
    var icon = _a.icon;
    var size = sizes[icon.size];
    var top = icon.verticalOffset > 0 ? -icon.verticalOffset : "".concat(-icon.verticalOffset, "%");
    var right = icon.horizontalOffset > 0 ? -icon.horizontalOffset : "".concat(-icon.horizontalOffset, "%");
    return icon.vector ? (react_1.default.createElement(DefaultIcon, { right: right, top: top, size: size, vector: icon.vector })) : null;
}
exports.default = Icon;
