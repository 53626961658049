"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BOARD_PERMISSION_SET_EVENTS = {
    BPS_STORE: "BPS_STORE",
    BPS_DESTROY: "BPS_DESTROY",
    BPS_UPDATE_TITLE: "BPS_UPDATE_TITLE",
    BPS_TOGGLE_PERMISSION: "BPS_TOGGLE_PERMISSION",
    BPS_ATTACH_PERMITTED_ITEM: "BPS_ATTACH_PERMITTED_ITEM",
    BPS_UPDATE_PERMITTED_ITEM: "BPS_UPDATE_PERMITTED_ITEM",
    BPS_DETACH_PERMITTED_ITEM: "BPS_DETACH_PERMITTED_ITEM",
    BPS_ATTACH_PERMITTED_CLIENTS: "BPS_ATTACH_PERMITTED_CLIENTS",
    BPS_DETACH_PERMITTED_CLIENT: "BPS_DETACH_PERMITTED_CLIENT",
    BPS_REMOVE_SHARED_USER: "BPS_REMOVE_SHARED_USER",
};
exports.default = BOARD_PERMISSION_SET_EVENTS;
