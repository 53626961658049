"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.enterAnimation = void 0;
var tss_react_1 = require("tss-react");
var mui_1 = require("tss-react/mui");
exports.enterAnimation = (0, tss_react_1.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n0% { \n  opacity: 0;  \n}\n100% { \n  opacity: 1;\n}"], ["\n0% { \n  opacity: 0;  \n}\n100% { \n  opacity: 1;\n}"])));
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    nodeAvatar: {
        position: "absolute",
        top: "calc(100% + 3px)",
        left: "calc(100% - 10px)",
        borderRadius: "50%",
        "& > p": {
            display: "table-cell",
            verticalAlign: "middle",
            textAlign: "center",
            color: "#fff",
        },
        pointerEvents: "none",
        userSelect: "none",
        backgroundColor: theme.palette.primary.dark,
    },
    cursor: {
        zIndex: 500,
        cursor: "default",
        position: "absolute",
        transition: "top 0.3s ease, left 0.3s ease",
        pointerEvents: "none",
        animation: "".concat(exports.enterAnimation, " .3s ease-in"),
        color: theme.palette.primary.dark,
    },
    cursorAwayText: {
        position: "absolute",
        fontSize: 12,
        width: 18,
        height: 18,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
    },
    groupAvatar: {
        all: "unset",
        display: "flex",
        alignItems: "center",
        ":hover": {
            cursor: "pointer",
        },
    },
    groupAvatarPopper: {
        "&&&": {
            zIndex: 1000,
        },
    },
    groupAvatarPopperList: {
        width: "100%",
        maxWidth: 360,
        borderRadius: 5,
        marginTop: ".5rem",
        backgroundColor: theme.palette.background.paper,
    },
}); });
exports.default = useStyles;
var templateObject_1;
