"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var initalState = {
    connection: {
        status: "connected",
    },
};
function reducer(state, action) {
    if (state === void 0) { state = initalState; }
    switch (action.type) {
        case "SOCKET_CONNECTED":
            return __assign(__assign({}, state), { connection: __assign(__assign({}, state.connection), { status: "connected" }) });
        case "SOCKET_DISCONNECTED":
            return __assign(__assign({}, state), { connection: __assign(__assign({}, state.connection), { status: "disconnected" }) });
        case "SOCKET_RECONNECTING":
            return __assign(__assign({}, state), { connection: __assign(__assign({}, state.connection), { status: "reconnecting" }) });
        default:
            return state;
    }
}
exports.default = reducer;
