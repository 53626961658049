"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useContextMenuOnItem = function (container) {
    var _a = (0, react_1.useState)(false), show = _a[0], setShow = _a[1];
    var _b = (0, react_1.useState)({
        x: 0,
        y: 0,
    }), points = _b[0], setPoints = _b[1];
    (0, react_1.useEffect)(function () {
        var handleClick = function () { return setShow(false); };
        (container || document).addEventListener("click", handleClick);
        return function () {
            (container || document).removeEventListener("click", handleClick);
        };
    }, []);
    return {
        show: show,
        setShow: setShow,
        points: points,
        setPoints: setPoints,
    };
};
exports.default = useContextMenuOnItem;
