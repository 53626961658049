"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.forceUpdateStepElements = exports.applyMirrorModeChanges = exports.updateStepTitle = exports.editStep = exports.handleLoading = void 0;
function handleLoading(currLoadings, bool, loadingType) {
    var loadings = currLoadings;
    if (loadingType) {
        loadings[loadingType] = bool;
    }
    return {
        loadings: loadings,
    };
}
exports.handleLoading = handleLoading;
function editStep(state, id) {
    var s = state.steps.find(function (_s) { return _s.id === id; }) || null;
    var selection = new Set(state.deselectedSteps);
    s && selection.delete(s.id);
    return {
        currentStep: s && { id: s.id, title: s.title, index: s.index },
        deselectedSteps: selection,
    };
}
exports.editStep = editStep;
function updateStepTitle(state, title, stepId) {
    var _a;
    var steps = structuredClone(state.steps);
    var i = steps.findIndex(function (step) { return step.id === stepId; });
    if (i !== -1)
        steps[i].title = title;
    return {
        steps: steps,
        currentStep: ((_a = state.currentStep) === null || _a === void 0 ? void 0 : _a.id) === stepId
            ? __assign(__assign({}, state.currentStep), { title: title }) : state.currentStep,
    };
}
exports.updateStepTitle = updateStepTitle;
function applyMirrorModeChanges(state, changes) {
    var steps = structuredClone(state.steps);
    changes.forEach(function (change) {
        var i = steps.findIndex(function (s) { return s.id === change.stepId; });
        if (i === -1)
            return;
        var flowNodes = change.flowNodes, flowEdges = change.flowEdges, removedNodes = change.removedNodes, removedEdges = change.removedEdges;
        if (flowNodes) {
            var added = flowNodes.filter(function (e) { return !steps[i].nodes.some(function (se) { return se.id === e.id; }); });
            var updated = steps[i].nodes.map(function (e) { return flowNodes.find(function (pe) { return pe.id === e.id; }) || e; });
            steps[i].nodes = __spreadArray(__spreadArray([], updated, true), added, true);
        }
        if (flowEdges) {
            var added = flowEdges.filter(function (e) { return !steps[i].edges.some(function (se) { return se.id === e.id; }); });
            var updated = steps[i].edges.map(function (e) { return flowEdges.find(function (pe) { return pe.id === e.id; }) || e; });
            steps[i].edges = __spreadArray(__spreadArray([], updated, true), added, true);
        }
        if (removedNodes) {
            steps[i].nodes = steps[i].nodes.filter(function (n) { return !removedNodes.includes(n.id); });
        }
        if (removedEdges) {
            steps[i].edges = steps[i].edges.filter(function (e) { return !removedEdges.includes(e.id); });
        }
    });
    return {
        steps: steps,
    };
}
exports.applyMirrorModeChanges = applyMirrorModeChanges;
function forceUpdateStepElements(state, payload) {
    var stepId = payload.stepId, nodes = payload.nodes, edges = payload.edges;
    var stateSteps = state.steps;
    var i = stateSteps.findIndex(function (s) { return s.id === stepId; });
    if (i === -1)
        return {};
    var steps = structuredClone(stateSteps);
    steps[i].nodes = nodes;
    steps[i].edges = edges;
    return { steps: steps };
}
exports.forceUpdateStepElements = forceUpdateStepElements;
