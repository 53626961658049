"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (theme) { return ({
    paper: {
        width: "100%",
        // paddingLeft: theme.spacing(3),
        // paddingRight: theme.spacing(3),
        // paddingTop: theme.spacing(2),
        // paddingBottom: theme.spacing(2),
        padding: theme.spacing(3),
    },
    column: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: theme.spacing(2),
    },
    scrollX: {
        overflowX: "auto",
        whiteSpace: "nowrap",
        scrollBehavior: "smooth",
        "&::-webkit-scrollbar": {
            height: "0",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
    },
    textEllipsis: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
    },
    chip: {
        borderWidth: 0.2,
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
    },
}); });
