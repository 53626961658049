"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialPerson = void 0;
var constants_1 = require("@api/constants");
var personConstants_1 = require("./personConstants");
var folderConstants_1 = require("@redux/constants/folderConstants");
exports.initialPerson = {
    id: "",
    name: null,
    company: null,
    affiliation: null,
    color: "rgba(25, 55, 101, 1)",
    email: null,
    phone: null,
    description: null,
    address: null,
    organization_id: 0,
    group_id: null,
    lawsuit_id: null,
    created_at: "",
    updated_at: "",
    groupOrLawsuit: "",
    tags: [],
};
var initialState = {
    persons: [],
    loadings: constants_1.initialLoadings,
    person: exports.initialPerson,
    personOptions: [],
    peopleDescendants: [],
    deletingFolder: 0,
};
function reducer(state, action) {
    var _a, _b, _c, _d;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case personConstants_1.GET_PERSONS_SUCCESS:
            return __assign(__assign({}, state), { persons: action.persons, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case personConstants_1.POST_PERSON_SUCCESS:
        case personConstants_1.PUT_PERSON_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case personConstants_1.SHOW_PERSON_SUCCESS:
            return __assign(__assign({}, state), { person: action.person, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case personConstants_1.DELETE_PERSON_SUCCESS:
            return __assign(__assign({}, state), { peopleDescendants: action.peopleDescendants });
        case personConstants_1.GET_PERSON_DESCENDANTS_SUCCESS:
            return __assign(__assign({}, state), { peopleDescendants: action.peopleDescendants });
        case personConstants_1.FORCE_DELETE_PERSON_SUCCESS:
            return __assign(__assign({}, state), { persons: state.persons.filter(function (person) { return person.id !== action.id; }) });
        case personConstants_1.GET_PERSONS_LOADING:
        case personConstants_1.POST_PERSON_LOADING:
        case personConstants_1.PUT_PERSON_LOADING:
        case personConstants_1.SHOW_PERSON_LOADING:
        case personConstants_1.PERSON_DUPLICATE_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case personConstants_1.GET_PERSONS_FAILED:
        case personConstants_1.POST_PERSON_FAILED:
        case personConstants_1.PERSON_DUPLICATE_FAILED:
        case personConstants_1.PUT_PERSON_FAILED:
        case personConstants_1.SHOW_PERSON_FAILED:
        case personConstants_1.GET_PERSON_DROPDOWN_FAILED:
        case personConstants_1.DELETE_PERSON_FAILED:
        case personConstants_1.FORCE_DELETE_PERSON_FAILED:
        case personConstants_1.GET_PERSON_DESCENDANTS_FAILED:
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case personConstants_1.CHANGE_PERSON:
            return __assign(__assign({}, state), { person: action.attr === "initial"
                    ? exports.initialPerson
                    : __assign(__assign({}, state.person), (_b = {}, _b[action.attr] = action.val, _b)) });
        case personConstants_1.GET_PERSON_DROPDOWN_SUCCESS:
        case personConstants_1.CHANGE_PERSON_OPTIONS:
            return __assign(__assign({}, state), { personOptions: action.persons });
        case personConstants_1.CLEAR_PEOPLE_DESCENDANTS:
            return __assign(__assign({}, state), { peopleDescendants: initialState.peopleDescendants });
        case personConstants_1.PERSON_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { persons: action.person ? __spreadArray(__spreadArray([], state.persons, true), [action.person], false) : state.persons, loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.moveElementToDestroyedParentFailed)("timeline_persons"):
        case (0, folderConstants_1.postFolderFailed)("timeline_persons"):
        case (0, folderConstants_1.putFolderTitleFailed)("timeline_persons"):
        case (0, folderConstants_1.putFolderLocationFailed)("timeline_persons"):
        case (0, folderConstants_1.putFolderElementLocationFailed)("timeline_persons"):
        case (0, folderConstants_1.duplicateFolderFailed)("timeline_persons"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.deleteFolderFailed)("timeline_persons"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.deleteFolderLoading)("timeline_persons"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: __assign(__assign({}, state.loadings), (_c = {}, _c[action.loadings] = true, _c)) });
        case (0, folderConstants_1.moveElementToDestroyedParentLoading)("timeline_persons"):
        case (0, folderConstants_1.putFolderLocationLoading)("timeline_persons"):
        case (0, folderConstants_1.putFolderElementLocationLoading)("timeline_persons"):
        case (0, folderConstants_1.putFolderTitleLoading)("timeline_persons"):
        case (0, folderConstants_1.postFolderLoading)("timeline_persons"):
        case (0, folderConstants_1.duplicateFolderLoading)("timeline_persons"):
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadings] = true, _d)) });
        case (0, folderConstants_1.postFolderSuccess)("timeline_persons"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, persons: __spreadArray([action.folder], state.persons, true) });
        case (0, folderConstants_1.putFolderTitleSuccess)("timeline_persons"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, persons: state.persons.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderLocationSuccess)("timeline_persons"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, persons: state.persons.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderElementLocationSuccess)("timeline_persons"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, persons: state.persons.map(function (c) {
                    if (!c.isFolder && c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.deleteFolderSuccess)("timeline_persons"): {
            if (action.descendants && action.descendants.length > 0) {
                return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, deletingFolder: action.folder.id, peopleDescendants: action.descendants });
            }
            if (action.deleteChildren) {
                return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, deletingFolder: 0, persons: state.persons
                        .filter(function (c) { return c.id !== action.folder.id; })
                        .filter(function (c) { return c.folderId !== action.folder.id; }) });
            }
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, deletingFolder: 0, persons: state.persons
                    .filter(function (c) { return c.id !== action.folder.id; })
                    .map(function (c) {
                    return c.folderId === action.folder.id
                        ? __assign(__assign({}, c), { folderId: action.folder.folderId }) : c;
                }) });
        }
        case (0, folderConstants_1.moveElementToDestroyedParentSuccess)("timeline_persons"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, persons: state.persons.map(function (c) {
                    if (c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.duplicateFolderSuccess)("timeline_persons"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, persons: __spreadArray(__spreadArray([], action.folder, true), state.persons, true) });
        case personConstants_1.SET_PERSON:
            return __assign(__assign({}, state), { person: action.payload !== "clear" ? action.payload : exports.initialPerson });
        default:
            return state;
    }
}
exports.default = reducer;
