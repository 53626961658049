"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GROUP_DUPLICATE_FAILED = exports.GROUP_DUPLICATE_SUCCESS = exports.GROUP_DUPLICATE_LOADING = exports.CLEAR_GROUP_DROPDOWN = exports.GET_GROUP_DROPDOWN_FAILED = exports.GET_GROUP_DROPDOWN_SUCCESS = exports.GIVE_ACCESS_TO_ALL_CHANGE = exports.DESCRIPTION_CHANGE = exports.TITLE_CHANGE = exports.SHOW_DETAIL_PRODUCT = exports.GET_GROUP_USERS_FAILED = exports.GET_GROUP_USERS_SUCCESS = exports.ADD_GROUP_USERS = exports.SHOW_GROUP_LITE_CHANGE = exports.SHOW_GROUP_LITE_FAILED = exports.SHOW_GROUP_LITE_SUCCESS = exports.DELETE_GROUP_FAILED = exports.DELETE_GROUP_SUCCESS = exports.PUT_GROUP_FAILED = exports.PUT_GROUP_SUCCESS = exports.SHOW_GROUP_FAILED = exports.SHOW_GROUP_SUCCESS = exports.SHOW_GROUP_LOADING = exports.POST_GROUP_FAILED = exports.POST_GROUP_SUCCESS = exports.POST_GROUP_LOADING = exports.CREATE_NEW_GROUP = exports.CLEAR_GROUPS = exports.GET_GROUPS_FAILED = exports.GET_GROUPS_SUCCESS = exports.GET_GROUPS_LOADING = void 0;
// API
exports.GET_GROUPS_LOADING = "GET_GROUPS_LOADING";
exports.GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
exports.GET_GROUPS_FAILED = "GET_GROUPS_FAILED";
exports.CLEAR_GROUPS = "CLEAR_GROUPS";
exports.CREATE_NEW_GROUP = "CREATE_NEW_GROUP";
exports.POST_GROUP_LOADING = "POST_GROUP_LOADING";
exports.POST_GROUP_SUCCESS = "POST_GROUP_SUCCESS";
exports.POST_GROUP_FAILED = "POST_GROUP_FAILED";
exports.SHOW_GROUP_LOADING = "SHOW_GROUP_LOADING";
exports.SHOW_GROUP_SUCCESS = "SHOW_GROUP_SUCCESS";
exports.SHOW_GROUP_FAILED = "SHOW_GROUP_FAILED";
exports.PUT_GROUP_SUCCESS = "PUT_GROUP_SUCCESS";
exports.PUT_GROUP_FAILED = "PUT_GROUP_FAILED";
exports.DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
exports.DELETE_GROUP_FAILED = "DELETE_GROUP_FAILED";
exports.SHOW_GROUP_LITE_SUCCESS = "SHOW_GROUP_LITE_SUCCESS";
exports.SHOW_GROUP_LITE_FAILED = "SHOW_GROUP_LITE_FAILED";
exports.SHOW_GROUP_LITE_CHANGE = "SHOW_GROUP_LITE_CHANGE";
exports.ADD_GROUP_USERS = "ADD_GROUP_USERS";
exports.GET_GROUP_USERS_SUCCESS = "GET_GROUP_USERS_SUCCESS";
exports.GET_GROUP_USERS_FAILED = "GET_GROUP_USERS_FAILED";
exports.SHOW_DETAIL_PRODUCT = "SHOW_DETAIL_PRODUCT";
exports.TITLE_CHANGE = "GROUP_TITLE_CHANGE";
exports.DESCRIPTION_CHANGE = "GROUP_DESCRIPTION_CHANGE";
exports.GIVE_ACCESS_TO_ALL_CHANGE = "GIVE_ACCESS_TO_ALL_CHANGE";
exports.GET_GROUP_DROPDOWN_SUCCESS = "GET_GROUP_DROPDOWN_SUCCESS";
exports.GET_GROUP_DROPDOWN_FAILED = "GET_GROUP_DROPDOWN_FAILED";
exports.CLEAR_GROUP_DROPDOWN = "CLEAR_GROUP_DROPDOWN";
exports.GROUP_DUPLICATE_LOADING = "GROUP_DUPLICATE_LOADING";
exports.GROUP_DUPLICATE_SUCCESS = "GROUP_DUPLICATE_SUCCESS";
exports.GROUP_DUPLICATE_FAILED = "GROUP_DUPLICATE_FAILED";
