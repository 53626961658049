"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (theme) { return ({
    wrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        width: "100%",
    },
    innerWrapper: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        width: "100%",
    },
    textField: {
        margin: theme.spacing(0.5),
        "& .MuiInputBase-root": {
            borderRadius: "4px",
        },
        "& .MuiInputBase-input": {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderStyle: "none",
        },
        "&.isEditable:hover .MuiOutlinedInput-notchedOutline": {
            borderStyle: "solid",
        },
    },
    endAdornmentBox: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(0.5),
        borderRadius: theme.shape.borderRadius,
        display: "flex",
    },
    endAdornmentTypography: {
        color: "#00000088",
        lineHeight: 1,
    },
}); });
