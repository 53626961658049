"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHANGE_LINK = exports.RESET_LINK = exports.SET_LINK = exports.LINK_DELETE_FAILED = exports.LINK_DELETE_SUCCESS = exports.LINK_DELETE_LOADING = exports.LINK_UPDATE_FAILED = exports.LINK_UPDATE_SUCCESS = exports.LINK_UPDATE_LOADING = exports.LINK_STORE_FAILED = exports.LINK_STORE_SUCCESS = exports.LINK_STORE_LOADING = exports.LINK_INDEX_FAILED = exports.LINK_INDEX_SUCCESS = exports.LINK_INDEX_LOADING = void 0;
exports.LINK_INDEX_LOADING = "LINK_INDEX_LOADING";
exports.LINK_INDEX_SUCCESS = "LINK_INDEX_SUCCESS";
exports.LINK_INDEX_FAILED = "LINK_INDEX_FAILED";
exports.LINK_STORE_LOADING = "LINK_STORE_LOADING";
exports.LINK_STORE_SUCCESS = "LINK_STORE_SUCCESS";
exports.LINK_STORE_FAILED = "LINK_STORE_FAILED";
exports.LINK_UPDATE_LOADING = "LINK_UPDATE_LOADING";
exports.LINK_UPDATE_SUCCESS = "LINK_UPDATE_SUCCESS";
exports.LINK_UPDATE_FAILED = "LINK_UPDATE_FAILED";
exports.LINK_DELETE_LOADING = "LINK_DELETE_LOADING";
exports.LINK_DELETE_SUCCESS = "LINK_DELETE_SUCCESS";
exports.LINK_DELETE_FAILED = "LINK_DELETE_FAILED";
exports.SET_LINK = "SET_LINK";
exports.RESET_LINK = "RESET_LINK";
exports.CHANGE_LINK = "CHANGE_LINK";
