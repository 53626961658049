"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var calculateOffset = function (children, parent) {
    var top = children.offsetTop;
    var left = children.offsetLeft;
    var p = children.parentElement;
    while (p && p !== parent) {
        top += p.offsetTop;
        left += p.offsetLeft;
        p = p.parentElement;
    }
    return {
        left: left,
        top: top,
    };
};
exports.default = calculateOffset;
