"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIND_ELEMENT_URL = exports.GET_SPECIFIC_LOG_EVENTS_FAILED = exports.GET_SPECIFIC_LOG_EVENTS_SUCCESS = exports.GET_SPECIFIC_LOG_EVENTS_LOADING = exports.TOGGLE_ACTIVITY_LOG_PANEL = exports.CLEAR_ACTIVITY_LOG_SPECIFIC_EVENTS = exports.CLEAR_ACTIVITY_LOG_PARAMS = exports.CLEAR_ACTIVITY_LOG_EVENTS = exports.CLEAR_ACTIVITY_LOG = exports.GET_ACTIVITY_LOG_FAILED = exports.GET_ACTIVITY_LOG_SUCCESS = exports.GET_ACTIVITY_LOG_LOADING = void 0;
exports.GET_ACTIVITY_LOG_LOADING = "GET_ACTIVITY_LOG_LOADING";
exports.GET_ACTIVITY_LOG_SUCCESS = "GET_ACTIVITY_LOG_SUCCESS";
exports.GET_ACTIVITY_LOG_FAILED = "GET_ACTIVITY_LOG_FAILED";
exports.CLEAR_ACTIVITY_LOG = "CLEAR_ACTIVITY_LOG";
exports.CLEAR_ACTIVITY_LOG_EVENTS = "CLEAR_ACTIVITY_LOG_EVENTS";
exports.CLEAR_ACTIVITY_LOG_PARAMS = "CLEAR_ACTIVITY_LOG_PARAMS";
exports.CLEAR_ACTIVITY_LOG_SPECIFIC_EVENTS = "CLEAR_ACTIVITY_LOG_SPECIFIC_EVENTS";
exports.TOGGLE_ACTIVITY_LOG_PANEL = "TOGGLE_ACTIVITY_PANEL";
exports.GET_SPECIFIC_LOG_EVENTS_LOADING = "GET_SPECIFIC_LOG_EVENTS_LOADING";
exports.GET_SPECIFIC_LOG_EVENTS_SUCCESS = "GET_SPECIFIC_LOG_EVENTS_SUCCESS";
exports.GET_SPECIFIC_LOG_EVENTS_FAILED = "GET_SPECIFIC_LOG_EVENTS_FAILED";
exports.FIND_ELEMENT_URL = "FIND_ELEMENT_URL";
