"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeDropdownConditionLabel = exports.addDropdownConditionLabel = exports.updateCondition = exports.removeCondition = exports.addCondition = exports.removeTrigger = exports.updateTrigger = exports.addTrigger = exports.updateHandler = exports.removeHandler = exports.addHandler = void 0;
var constants_1 = require("./constants");
var mappings_1 = require("./mappings");
function addHandler(type) {
    return { type: constants_1.ADD_HANDLER, payload: (0, mappings_1.getInitialHandler)(type) };
}
exports.addHandler = addHandler;
function removeHandler(index) {
    return { type: constants_1.REMOVE_HANDLER, payload: index };
}
exports.removeHandler = removeHandler;
function updateHandler(handler, index) {
    return { type: constants_1.UPDATE_HANDLER, payload: { handler: handler, index: index } };
}
exports.updateHandler = updateHandler;
function addTrigger(trigger) {
    return { type: constants_1.ADD_TRIGGER, payload: (0, mappings_1.getInitialTrigger)(trigger) };
}
exports.addTrigger = addTrigger;
function updateTrigger(trigger) {
    return { type: constants_1.UPDATE_TRIGGER, payload: trigger };
}
exports.updateTrigger = updateTrigger;
function removeTrigger() {
    return { type: constants_1.REMOVE_TRIGGER };
}
exports.removeTrigger = removeTrigger;
function addCondition(type) {
    return { type: constants_1.ADD_CONDITION, payload: (0, mappings_1.getInitialCondition)(type) };
}
exports.addCondition = addCondition;
function removeCondition(index) {
    return { type: constants_1.REMOVE_CONDITION, payload: index };
}
exports.removeCondition = removeCondition;
function updateCondition(condition, index) {
    return { type: constants_1.UPDATE_CONDITION, payload: { condition: condition, index: index } };
}
exports.updateCondition = updateCondition;
function addDropdownConditionLabel(index, value) {
    return { type: constants_1.ADD_DROPDOWN_CONDITION_LABEL, payload: { index: index, option: value } };
}
exports.addDropdownConditionLabel = addDropdownConditionLabel;
function removeDropdownConditionLabel(index, value) {
    return { type: constants_1.REMOVE_DROPDOWN_CONDITION_LABEL, payload: { index: index, option: value } };
}
exports.removeDropdownConditionLabel = removeDropdownConditionLabel;
