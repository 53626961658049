"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Time;
(function (Time) {
    /**
     * @name Nanosecond
     * @is 1 nanosecond
     */
    Time[Time["NANOSECOND"] = 0.000001] = "NANOSECOND";
    /**
     * @name Microsecond
     * @is 1000 nanoseconds
     */
    Time[Time["MICROSECOND"] = 0.001] = "MICROSECOND";
    /**
     * @name Millisecond
     * @is 1000 microseconds
     */
    Time[Time["MILLISECOND"] = 1] = "MILLISECOND";
    /**
     * @name Second
     * @is 1000 milliseconds
     */
    Time[Time["SECOND"] = 1000] = "SECOND";
    /**
     * @name Minute
     * @is 60 seconds
     */
    Time[Time["MINUTE"] = 60000] = "MINUTE";
    /**
     * @name Quarter-hour
     * @is  15 minutes
     */
    Time[Time["QUARTER"] = 900000] = "QUARTER";
    /**
     * @name Half-hour
     * @is 30 minutes
     */
    Time[Time["HALF"] = 1800000] = "HALF";
    /**
     * @name Hour
     * @is 60 minutes
     */
    Time[Time["HOUR"] = 3600000] = "HOUR";
    /**
     * @name Day
     * @is 24 hours
     */
    Time[Time["DAY"] = 86400000] = "DAY";
    /**
     * @name Week
     * @is 7 days
     */
    Time[Time["WEEK"] = 604800000] = "WEEK";
})(Time || (Time = {}));
exports.default = Time;
