"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@customTypes/styles");
var react_1 = require("react");
var useCursor = function (handleCursor, mouseLoading, cursor) {
    (0, react_1.useEffect)(function () {
        var pane = document.getElementsByClassName("react-flow__pane");
        if (pane.length > 0) {
            pane[0].style.cursor = cursor;
        }
    }, [cursor]);
    (0, react_1.useEffect)(function () {
        if (mouseLoading) {
            handleCursor(styles_1.CursorType.Progress);
        }
        else {
            handleCursor(styles_1.CursorType.Auto);
        }
    }, [mouseLoading]);
};
exports.default = useCursor;
