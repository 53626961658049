"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@pages/Timelines/constants");
function FormatToCollaborator(collaborator) {
    var user = collaborator.user;
    var data = {
        name: user.name,
        email: user.email,
        isClient: user.isClient,
        color: (0, constants_1.stringToColor)(user.name || user.email),
        avatar: user.initials || (0, constants_1.stringAvatar)(user.name || user.email).children,
    };
    return {
        active: collaborator.active,
        id: collaborator.id,
        data: data,
        room: collaborator.room,
    };
}
exports.default = FormatToCollaborator;
