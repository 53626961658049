"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var styles_1 = require("@customTypes/styles");
exports.default = (0, mui_1.makeStyles)()(function (theme) { return ({
    root: {
        flexGrow: 1,
        minWidth: 500,
        paddingBottom: 50,
    },
    userList: {
        width: "100%",
        overflow: "auto",
        maxHeight: 250,
    },
    shareList: {
        width: "100%",
        overflow: "auto",
        maxHeight: 200,
    },
    scrollWheel: __assign({}, (0, styles_1.JuristicScroll)(theme)),
    divider: {
        marginTop: 20,
        marginBottom: 20,
    },
}); });
