"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.horizontalScrollbarWithDesign = exports.verticalScrollbarWithDesign = exports.verticalScrollbar = void 0;
exports.verticalScrollbar = {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
        width: "0.4em",
    },
};
exports.verticalScrollbarWithDesign = {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
        width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#73B1FF",
        borderRadius: 10,
    },
};
exports.horizontalScrollbarWithDesign = {
    overflowX: "auto",
    "&::-webkit-scrollbar": {
        height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#73B1FF",
        borderRadius: 10,
    },
};
