"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePassword = exports.validateSpecialChars = exports.validateNumbers = exports.validateLowercase = exports.validateUppercase = void 0;
function validateUppercase(password) {
    var regex = /^(?=.*[A-Z])/;
    return regex.test(password);
}
exports.validateUppercase = validateUppercase;
function validateLowercase(password) {
    var regex = /^(?=.*[a-z])/;
    return regex.test(password);
}
exports.validateLowercase = validateLowercase;
function validateNumbers(password) {
    var regex = /^(?=.*[0-9])/;
    return regex.test(password);
}
exports.validateNumbers = validateNumbers;
function validateSpecialChars(password) {
    var regex = /^(?=.*\W)/;
    return regex.test(password);
}
exports.validateSpecialChars = validateSpecialChars;
function validatePassword(password) {
    if (!password)
        return false;
    var conditionsMet = 0;
    if (validateLowercase(password))
        conditionsMet++;
    if (validateUppercase(password))
        conditionsMet++;
    if (validateNumbers(password))
        conditionsMet++;
    if (validateSpecialChars(password))
        conditionsMet++;
    return conditionsMet >= 3;
}
exports.validatePassword = validatePassword;
