"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var uiActions_1 = require("@redux/actions/uiActions");
var useRelationshipGenerics = function (reactFlowContainer, dispatch, user) {
    var _a = (0, react_1.useState)(null), reactFlowDimensions = _a[0], setReactFlowDimensions = _a[1];
    (0, react_1.useEffect)(function () {
        if (reactFlowContainer) {
            setReactFlowDimensions({
                height: reactFlowContainer.current.clientHeight,
                width: reactFlowContainer.current.clientWidth,
            });
        }
    }, []);
    (0, react_1.useEffect)(function () {
        dispatch(uiActions_1.closeMenuAction);
        return function () {
            dispatch(uiActions_1.openMenuAction);
        };
    }, [user]);
    var toggleSubMenu = (0, react_1.useCallback)(function () { return dispatch(uiActions_1.toggleAction); }, []);
    return { reactFlowDimensions: reactFlowDimensions, toggleSubMenu: toggleSubMenu };
};
exports.default = useRelationshipGenerics;
