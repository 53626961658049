"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Colors_1 = require("@enums/Colors");
var mui_1 = require("tss-react/mui");
var styles_1 = require("@customTypes/styles");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    elementDesignPaper: {
        backgroundColor: Colors_1.otherGenericColors.panel,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        overflowY: "auto",
        pointerEvents: "auto",
        position: "relative",
        padding: "10px",
    },
    elementDesignOverflowContainer: __assign({ overflowY: "auto", display: "flex", flexDirection: "column", width: "100%", padding: "0 10px", margin: "10px 0", gap: "15px" }, (0, styles_1.JuristicScroll)(theme)),
    noMargin: {
        margin: 0,
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column",
    },
    attributeRow: {
        display: "flex",
        width: "100%",
    },
    flexBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    horDividerNoMargin: {
        height: 1,
    },
    colorLabelContainer: {
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
    },
    colorPickerPosition: {
        bottom: 10,
    },
}); });
exports.default = useStyles;
