"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function () { return ({
    root: {
        backgroundColor: "white",
        position: "relative",
    },
    standard: {
        width: "33.86cm",
        height: "19.05cm",
    },
    logo: {
        position: "absolute",
        height: "1.42cm",
        width: "4.01cm",
        top: "17cm",
        left: "0.85cm",
        objectFit: "cover",
    },
    title: {
        position: "absolute",
        top: "0.8cm",
        left: "2.9cm",
        width: "28cm",
        height: "2.46cm",
    },
    slideBody: {
        position: "absolute",
        top: "5.4cm",
        left: "2.9cm",
        height: "10cm",
        width: "28cm",
    },
    slideBodyTextLeft: {
        position: "absolute",
        top: "5.4cm",
        left: "2.9cm",
        height: "10cm",
        width: "13.2cm",
    },
    slideBodyTextRight: {
        position: "absolute",
        top: "5.4cm",
        left: "17.7cm",
        height: "10cm",
        width: "13.2cm",
    },
    footer: {
        position: "absolute",
        fontSize: "9pt",
        fontFamily: "Cambria",
    },
    footerDate: {
        height: "0.51cm",
        width: "2.55cm",
        top: "17.91cm",
        left: "5.58cm",
    },
    footerPageNumber: {
        height: "0.51cm",
        width: "1.13cm",
        top: "17.91cm",
        left: "31.74cm",
        textAlign: "right",
    },
}); });
