"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (theme) { return ({
    containerBox: {
        padding: 8,
    },
    header: {
        padding: 12,
        display: "flex",
        justifyContent: "space-between",
    },
    footer: {
        display: "flex",
        justifyContent: "flex-start",
        padding: 12,
        borderTop: "1px ".concat(theme.palette.secondary.dark),
    },
    applyButton: {},
    columnContainerBox: {
        padding: 16,
    },
    filterContainerBox: {
        maxWidth: 700,
        overflow: "auto",
        display: "flex",
        flexDirection: "row",
        gap: "32px",
        padding: 12,
        "&::-webkit-scrollbar": {
            height: "6px",
        },
        "&::-webkit-scrollbar-track": {
            background: "unset",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: 8,
        },
    },
    filterColumn: {
        maxWidth: 150,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: "10px",
    },
    filterList: {
        width: "20ch",
        maxHeight: 200,
        minHeight: 120,
        paddingTop: "unset",
        paddingBottom: "unset",
        overflow: "auto",
        gap: "8px",
        display: "flex",
        flexDirection: "column",
        "&::-webkit-scrollbar": {
            width: "4px",
        },
        "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.secondary.dark,
            borderRadius: 10,
        },
    },
    filterListItem: {},
    filterListButton: {
        "&--active": {
            "&&&": {
                backgroundColor: theme.palette.secondary.dark,
            },
        },
        height: 10,
        borderRadius: 4,
        backgroundColor: theme.palette.grey[100],
        padding: 15,
    },
    filterTextBox: {
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
        flex: 1,
    },
    filterListText: {
        fontSize: 10,
        color: theme.palette.text.secondary,
        flex: 1,
        "& > span": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
        },
    },
    filterAmount: {
        borderRadius: 4,
        WebkitFlex: "unset",
        padding: "0 2px 0 2px",
    },
    columnTitle: {
        fontSize: "12px",
        fontWeight: "500px",
        marginRight: 4,
    },
    columnTitleWrapper: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        "&:hover .MuiSvgIcon-root": {
            display: "block",
        },
    },
    searchIcon: {
        display: "none",
        fontSize: "8px",
    },
    Tooltip: {
        zIndex: 3001,
    },
    decoration: {
        marginRight: 4,
        alignSelf: "center",
    },
    textField: {
        margin: theme.spacing(0.5),
        "& .MuiInputBase-root": {
            borderRadius: "4px",
        },
        "& .MuiInputBase-input": {
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    },
}); });
