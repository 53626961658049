"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearTemplate = exports.startDateChange = exports.templateChange = void 0;
var templateConstants_1 = require("@redux/constants/templateConstants");
var templateChange = function (template) { return function (dispatch) {
    return dispatch({
        type: templateConstants_1.TEMPLATE_CHANGE,
        template: template,
    });
}; };
exports.templateChange = templateChange;
var startDateChange = function (startDate) { return function (dispatch) {
    return dispatch({
        type: templateConstants_1.START_DATE_CHANGE,
        startDate: startDate,
    });
}; };
exports.startDateChange = startDateChange;
var clearTemplate = function () { return function (dispatch) {
    return dispatch({
        type: templateConstants_1.CLEAR_TEMPLATE,
    });
}; };
exports.clearTemplate = clearTemplate;
