"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getHelperLines = void 0;
// this utility function can be called with a position change (inside onNodesChange)
// it checks all other nodes and calculated the helper line positions and the position where the current node should snap to
function getHelperLines(changes, nodes, distance) {
    if (distance === void 0) { distance = 5; }
    var defaultResult = {
        helperLines: {
            horizontal: undefined,
            vertical: undefined,
            snapPosition: { x: undefined, y: undefined },
        },
        updatedChanges: [],
    };
    var reducedChanges = changes.reduce(function (acc, change) {
        if (change.type === "position" && change.dragging && change.position) {
            acc.set(change.id, {
                x: change.position.x,
                y: change.position.y,
            });
        }
        return acc;
    }, new Map());
    if (reducedChanges.size === nodes.length) {
        return defaultResult;
    }
    var _a = nodes.reduce(function (acc, node) {
        var _a, _b;
        var change = reducedChanges.get(node.id);
        if (change && !node.parentNode) {
            acc.nodeABounds.left = Math.min(acc.nodeABounds.left, change.x);
            acc.nodeABounds.right = Math.max(acc.nodeABounds.right, change.x + ((_a = node.width) !== null && _a !== void 0 ? _a : 0));
            acc.nodeABounds.top = Math.min(acc.nodeABounds.top, change.y);
            acc.nodeABounds.bottom = Math.max(acc.nodeABounds.bottom, change.y + ((_b = node.height) !== null && _b !== void 0 ? _b : 0));
        }
        else if (!node.parentNode) {
            acc.remainingNodes.push(node);
        }
        return acc;
    }, {
        nodeABounds: {
            left: Infinity,
            right: -Infinity,
            top: Infinity,
            bottom: -Infinity,
            width: 0,
            height: 0,
            xMid: 0,
            yMid: 0,
        },
        remainingNodes: [],
    }), nodeABounds = _a.nodeABounds, remainingNodes = _a.remainingNodes;
    if (!remainingNodes.length || remainingNodes.length === nodes.length) {
        return defaultResult;
    }
    nodeABounds.width = nodeABounds.right - nodeABounds.left;
    nodeABounds.height = nodeABounds.bottom - nodeABounds.top;
    nodeABounds.xMid = nodeABounds.left + (nodeABounds.right - nodeABounds.left) / 2;
    nodeABounds.yMid = nodeABounds.top + (nodeABounds.bottom - nodeABounds.top) / 2;
    var horizontalDistance = distance;
    var verticalDistance = distance;
    var finalResult = remainingNodes.reduce(function (result, nodeB) {
        var _a, _b, _c, _d, _e, _f;
        var nodeBBounds = {
            left: nodeB.position.x,
            right: nodeB.position.x + ((_a = nodeB.width) !== null && _a !== void 0 ? _a : 0),
            top: nodeB.position.y,
            xMid: nodeB.position.x + ((_b = nodeB.width) !== null && _b !== void 0 ? _b : 0) / 2,
            yMid: nodeB.position.y + ((_c = nodeB.height) !== null && _c !== void 0 ? _c : 0) / 2,
            bottom: nodeB.position.y + ((_d = nodeB.height) !== null && _d !== void 0 ? _d : 0),
            width: (_e = nodeB.width) !== null && _e !== void 0 ? _e : 0,
            height: (_f = nodeB.height) !== null && _f !== void 0 ? _f : 0,
        };
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |
        //  |___________|
        //  |
        //  |
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     B     |
        //  |___________|
        var distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left);
        if (distanceLeftLeft < verticalDistance) {
            result.snapPosition.x = nodeBBounds.left;
            result.vertical = nodeBBounds.left;
            verticalDistance = distanceLeftLeft;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |
        //  |___________|
        //              |
        //              |
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     B     |
        //  |___________|
        var distanceRightRight = Math.abs(nodeABounds.right - nodeBBounds.right);
        if (distanceRightRight < verticalDistance) {
            result.snapPosition.x = nodeBBounds.right - nodeABounds.width;
            result.vertical = nodeBBounds.right;
            verticalDistance = distanceRightRight;
        }
        //              |‾‾‾‾‾‾‾‾‾‾‾|
        //              |     A     |
        //              |___________|
        //              |
        //              |
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     B     |
        //  |___________|
        var distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right);
        if (distanceLeftRight < verticalDistance) {
            result.snapPosition.x = nodeBBounds.right;
            result.vertical = nodeBBounds.right;
            verticalDistance = distanceLeftRight;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |
        //  |___________|
        //              |
        //              |
        //              |‾‾‾‾‾‾‾‾‾‾‾|
        //              |     B     |
        //              |___________|
        var distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left);
        if (distanceRightLeft < verticalDistance) {
            result.snapPosition.x = nodeBBounds.left - nodeABounds.width;
            result.vertical = nodeBBounds.left;
            verticalDistance = distanceRightLeft;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |     |     B     |
        //  |___________|     |___________|
        var distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top);
        if (distanceTopTop < horizontalDistance) {
            result.snapPosition.y = nodeBBounds.top;
            result.horizontal = nodeBBounds.top;
            horizontalDistance = distanceTopTop;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |
        //  |___________|_________________
        //                    |           |
        //                    |     B     |
        //                    |___________|
        var distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top);
        if (distanceBottomTop < horizontalDistance) {
            result.snapPosition.y = nodeBBounds.top - nodeABounds.height;
            result.horizontal = nodeBBounds.top;
            horizontalDistance = distanceBottomTop;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |     |     B     |
        //  |___________|_____|___________|
        var distanceBottomBottom = Math.abs(nodeABounds.bottom - nodeBBounds.bottom);
        if (distanceBottomBottom < horizontalDistance) {
            result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height;
            result.horizontal = nodeBBounds.bottom;
            horizontalDistance = distanceBottomBottom;
        }
        //                    |‾‾‾‾‾‾‾‾‾‾‾|
        //                    |     B     |
        //                    |           |
        //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
        //  |     A     |
        //  |___________|
        var distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom);
        if (distanceTopBottom < horizontalDistance) {
            result.snapPosition.y = nodeBBounds.bottom;
            result.horizontal = nodeBBounds.bottom;
            horizontalDistance = distanceTopBottom;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |_____|     B     |
        //  |___________|     |___________|
        var distanceYMidYMid = Math.abs(nodeABounds.yMid - nodeBBounds.yMid);
        if (distanceYMidYMid < horizontalDistance) {
            result.snapPosition.y = nodeBBounds.yMid - nodeABounds.height / 2;
            result.horizontal = nodeBBounds.yMid;
            horizontalDistance = distanceYMidYMid;
        }
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     A     |
        //  |___________|
        //        |
        //        |
        //  |‾‾‾‾‾‾‾‾‾‾‾|
        //  |     B     |
        //  |___________|
        var distanceXMidXMid = Math.abs(nodeABounds.xMid - nodeBBounds.xMid);
        if (distanceXMidXMid < verticalDistance) {
            result.snapPosition.x = nodeBBounds.xMid - nodeABounds.width / 2;
            result.vertical = nodeBBounds.xMid;
            verticalDistance = distanceXMidXMid;
        }
        return result;
    }, defaultResult.helperLines);
    changes.forEach(function (change) {
        if (change.type === "position" && change.position) {
            var updatedChange = reducedChanges.get(change.id);
            if (updatedChange) {
                change.position.x = finalResult.snapPosition.x
                    ? finalResult.snapPosition.x + (change.position.x - nodeABounds.left)
                    : change.position.x;
                change.position.y = finalResult.snapPosition.y
                    ? finalResult.snapPosition.y + (change.position.y - nodeABounds.top)
                    : change.position.y;
                if (reducedChanges.size > 1) {
                    changes.push({
                        id: change.id,
                        type: "select",
                        selected: true,
                    });
                }
            }
        }
    });
    return { helperLines: finalResult, updatedChanges: changes };
}
exports.getHelperLines = getHelperLines;
