"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var redux_1 = require("@hooks/redux");
function emptySelector(_) {
    return false;
}
function usePagePermissions(editableSelector, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.ignoreSocket, ignoreSocket = _c === void 0 ? false : _c;
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var _d = (0, react_1.useState)(pathname.split("/")[1] === "client"), isPublic = _d[0], setIsPublic = _d[1];
    var isAllowed = (0, redux_1.useAppSelector)(editableSelector || emptySelector);
    var socketConnection = (0, redux_1.useAppSelector)(function (state) { return state.socket.connection.status; });
    var isConnected = ignoreSocket || socketConnection === "connected";
    var isEditable = (!isPublic || isAllowed) && isConnected;
    (0, react_1.useEffect)(function () {
        var mainPath = pathname.split("/")[1];
        if (mainPath === "client") {
            setIsPublic(true);
        }
        else {
            setIsPublic(false);
        }
    }, [pathname]);
    return [isEditable, isPublic];
}
exports.default = usePagePermissions;
