"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var activityLogConstants_1 = require("@redux/constants/activityLogConstants");
var initialState = {
    loadings: constants_1.initialLoadings,
    events: [],
    specificEvents: [],
    params: {},
    panelOpen: false,
};
function reducer(state, action) {
    var _a, _b, _c, _d;
    var _e;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case activityLogConstants_1.GET_SPECIFIC_LOG_EVENTS_LOADING:
        case activityLogConstants_1.GET_ACTIVITY_LOG_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case activityLogConstants_1.GET_SPECIFIC_LOG_EVENTS_FAILED:
        case activityLogConstants_1.GET_ACTIVITY_LOG_FAILED:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadingType] = false, _b)) });
        case activityLogConstants_1.GET_ACTIVITY_LOG_SUCCESS:
            return __assign(__assign({}, state), { events: action.log, loadings: __assign(__assign({}, state.loadings), (_c = {}, _c[action.loadingType] = false, _c)) });
        case activityLogConstants_1.GET_SPECIFIC_LOG_EVENTS_SUCCESS:
            return __assign(__assign({}, state), { specificEvents: action.events, loadings: __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadingType] = false, _d)) });
        case activityLogConstants_1.FIND_ELEMENT_URL:
            return __assign(__assign({}, state), { params: __assign({}, action.data.params) });
        case activityLogConstants_1.CLEAR_ACTIVITY_LOG_EVENTS:
            return __assign(__assign({}, state), { events: [] });
        case activityLogConstants_1.CLEAR_ACTIVITY_LOG_PARAMS:
            return __assign(__assign({}, state), { params: {} });
        case activityLogConstants_1.CLEAR_ACTIVITY_LOG_SPECIFIC_EVENTS:
            return __assign(__assign({}, state), { specificEvents: [] });
        case activityLogConstants_1.TOGGLE_ACTIVITY_LOG_PANEL:
            return __assign(__assign({}, state), { panelOpen: (_e = action.override) !== null && _e !== void 0 ? _e : !state.panelOpen });
        case activityLogConstants_1.CLEAR_ACTIVITY_LOG:
            return __assign(__assign({}, state), { events: [], specificEvents: [] });
        default:
            return state;
    }
}
exports.default = reducer;
