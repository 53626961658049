"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var comparisonMap = {
    "is equal to": "=",
    "is not equal to": "≠",
    "is greater than": ">",
    "is less than": "<",
    exists: "∃",
    "does not exist": "∄",
    contains: "⊆",
    "does not contain": "⊈",
    all: "*",
};
exports.default = comparisonMap;
