"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var timelineActions_http_1 = require("@pages/Timelines/reducers/timelineActions.http");
var redux_1 = require("@hooks/redux");
var auth0_react_1 = require("@auth0/auth0-react");
var react_1 = require("react");
var documentActions_1 = require("@pages/Documents/reducers/documentActions");
function useDocument() {
    var user = (0, auth0_react_1.useAuth0)().user;
    var dispatch = (0, redux_1.useAppDispatch)();
    var _a = (0, react_1.useState)([]), elementDocuments = _a[0], setElementDocuments = _a[1];
    var isDocumentModalOpen = (0, redux_1.useAppSelector)(function (state) { return state.timeline.documentOpen; });
    var openDocument = (0, react_1.useCallback)(function () { return dispatch((0, timelineActions_http_1.timelineElementDocumentChange)(true)); }, []);
    var handleDocumentClose = (0, react_1.useCallback)(function () { return dispatch((0, timelineActions_http_1.timelineElementDocumentChange)(false)); }, []);
    var handleDocumentOpen = (0, react_1.useCallback)(function (id, title) {
        dispatch((0, documentActions_1.changeDocument)("", "initial"));
        if (id) {
            dispatch((0, documentActions_1.getDocument)(user, id, openDocument));
        }
        else {
            openDocument();
            if (title) {
                dispatch((0, documentActions_1.changeDocument)(title, "title"));
            }
        }
    }, [user, openDocument]);
    return {
        isDocumentModalOpen: isDocumentModalOpen,
        handleDocumentClose: handleDocumentClose,
        elementDocuments: elementDocuments,
        setElementDocuments: setElementDocuments,
        handleDocumentOpen: handleDocumentOpen,
    };
}
exports.default = useDocument;
