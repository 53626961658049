"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ElementOverlay = /** @class */ (function () {
    function ElementOverlay(theme) {
        this.overlay = document.createElement("div");
        this.overlay.className = "_ext-element-overlay";
        this.overlay.style.borderTop = "4px dashed ".concat(theme.palette.primary.main);
        this.overlay.style.boxSizing = "border-box";
        this.overlay.style.position = "absolute";
        this.overlay.style.zIndex = "2147483647";
        this.overlay.style.maxHeight = "10px";
        this.shadowContainer = document.createElement("div");
        this.shadowContainer.className = "_ext-element-overlay-container";
        this.shadowContainer.style.position = "absolute";
        this.shadowContainer.style.top = "0px";
        this.shadowContainer.style.left = "0px";
        this.shadowRoot = this.shadowContainer.attachShadow({ mode: "open" });
        // button
        this.button = document.createElement("button");
        this.button.className =
            "MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary";
        this.button.tabIndex = 0;
        this.button.type = "button";
        // label
        this.buttonlabel = document.createElement("span");
        this.buttonlabel.className = "MuiButton-label";
        this.buttonlabel.innerText = "Split";
        // ripple
        this.buttonRipple = document.createElement("span");
        this.buttonRipple.className = "MuiTouchRipple-root";
    }
    ElementOverlay.prototype.addToDOM = function (parent, useShadowDOM) {
        this.usingShadowDOM = useShadowDOM;
        if (useShadowDOM) {
            parent.insertBefore(this.shadowContainer, parent.firstChild);
            this.shadowRoot.appendChild(this.overlay);
        }
        else {
            parent.appendChild(this.overlay);
        }
    };
    ElementOverlay.prototype.removeFromDOM = function () {
        this.setBounds({ x: 0, y: 0, width: 0, height: 0 });
        this.overlay.remove();
        if (this.usingShadowDOM) {
            this.shadowContainer.remove();
        }
    };
    ElementOverlay.prototype.captureCursor = function () {
        this.overlay.style.pointerEvents = "auto";
    };
    ElementOverlay.prototype.ignoreCursor = function () {
        this.overlay.style.pointerEvents = "none";
    };
    ElementOverlay.prototype.setBounds = function (_a) {
        var x = _a.x, y = _a.y, width = _a.width, height = _a.height;
        this.overlay.style.left = x + "px";
        this.overlay.style.top = y + "px";
        this.overlay.style.width = width + "px";
        this.overlay.style.height = height + "px";
    };
    return ElementOverlay;
}());
exports.default = ElementOverlay;
