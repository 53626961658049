"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var fileConstants_1 = require("@redux/constants/fileConstants");
var initialState = {
    files: [],
    file: null,
    loadings: constants_1.initialLoadings,
};
function reducer(state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case fileConstants_1.GET_FILES_LOADING:
        case fileConstants_1.POST_FILES_LOADING:
        case fileConstants_1.PUT_FILES_LOADING:
        case fileConstants_1.DELETE_FILES_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case fileConstants_1.GET_FILES_FAILED:
        case fileConstants_1.POST_FILES_FAILED:
        case fileConstants_1.PUT_FILES_FAILED:
        case fileConstants_1.DELETE_FILES_FAILED:
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case fileConstants_1.GET_FILES_SUCCESS:
            return __assign(__assign({}, state), { files: action.files, loadings: constants_1.initialLoadings });
        case fileConstants_1.POST_FILES_SUCCESS:
            return __assign(__assign({}, state), { files: action.files, loadings: constants_1.initialLoadings });
        case fileConstants_1.PUT_FILES_SUCCESS:
            return __assign(__assign({}, state), { files: state.files.map(function (file) {
                    return file.id === action.file.id ? action.file : file;
                }), loadings: constants_1.initialLoadings });
        case fileConstants_1.DELETE_FILES_SUCCESS:
            return __assign(__assign({}, state), { files: state.files.filter(function (file) { return file.id !== action.file.id; }), loadings: constants_1.initialLoadings });
        case fileConstants_1.OPEN_FILE:
            return __assign(__assign({}, state), { file: action.file });
        case fileConstants_1.CLOSE_FILE:
            return __assign(__assign({}, state), { file: null });
        case fileConstants_1.CHANGE_NAME_FILE:
            return __assign(__assign({}, state), { file: state.file
                    ? __assign(__assign({}, state.file), { name: action.name }) : null });
        case fileConstants_1.TOGGLE_USE_GLOBAL_FILE:
            return __assign(__assign({}, state), { file: state.file
                    ? __assign(__assign({}, state.file), { useGlobal: !state.file.useGlobal }) : null });
        default:
            return state;
    }
}
exports.default = reducer;
