"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var permissionSetActions_websocket_1 = require("@pages/TaskBoard/reducers/WebSocket/permissionSetActions.websocket");
var react_1 = require("react");
var taskPrefix = "task-";
var taskGroupPrefix = "task_group-";
function useBoardPermissionSets() {
    var dispatch = (0, redux_1.useAppDispatch)();
    var permissionSets = (0, redux_1.useAppSelector)(function (state) { return state.boardPermissionSet.permissionSets; });
    var isEditingId = (0, redux_1.useAppSelector)(function (state) { return state.boardPermissionSet.isEditingId; });
    var permittedItems = (0, redux_1.useAppSelector)(function (state) { return state.boardPermissionSet.permittedItems; });
    var _a = (0, react_1.useState)(false), isEditingPermission = _a[0], setIsEditingPermission = _a[1];
    var _b = (0, react_1.useState)(false), canEditAll = _b[0], setEditAll = _b[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (isEditingId) {
            setIsEditingPermission(true);
            var _editAll = (_a = permissionSets.find(function (set) { return set.id === isEditingId; })) === null || _a === void 0 ? void 0 : _a.editAll;
            setEditAll(_editAll || false);
        }
        else {
            setIsEditingPermission(false);
            setEditAll(false);
        }
    }, [isEditingId, permissionSets]);
    var _c = (0, react_1.useState)(new Set([])), permittedItemIds = _c[0], setPermittedItemIds = _c[1];
    (0, react_1.useEffect)(function () {
        if (isEditingPermission) {
            var _tempSet_1 = new Set();
            var _permittedItemIds = permittedItems.forEach(function (item) {
                if (item.type === "task") {
                    _tempSet_1.add("".concat(taskPrefix).concat(item.id));
                }
                if (item.type === "task_group") {
                    _tempSet_1.add("".concat(taskGroupPrefix).concat(item.id));
                }
            });
            setPermittedItemIds(_tempSet_1);
        }
        else {
            setPermittedItemIds(new Set([]));
        }
    }, [isEditingPermission, permittedItems]);
    var handlePermittedItem = (0, react_1.useCallback)(function (id) {
        if (canEditAll)
            return;
        var item = id.startsWith(taskPrefix)
            ? {
                type: "task",
                id: Number(id.split("-")[1]),
            }
            : {
                type: "task_group",
                id: Number(id.split("-")[1]),
            };
        if (permittedItemIds.has(id)) {
            isEditingId && dispatch((0, permissionSetActions_websocket_1.detachPermittedItem)({ id: isEditingId, item: item }));
        }
        else {
            isEditingId && dispatch((0, permissionSetActions_websocket_1.attachPermittedItem)({ id: isEditingId, item: item }));
        }
    }, [isEditingId, permittedItemIds, canEditAll]);
    return {
        isEditingPermission: isEditingPermission,
        permittedItemIds: permittedItemIds,
        handlePermittedItem: handlePermittedItem,
        canEditAll: canEditAll,
    };
}
exports.default = useBoardPermissionSets;
