"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (theme) { return ({
    drawerPaper: {
        width: 570,
        minWidth: 570,
    },
    top: {
        borderBottom: "1px solid #ddd",
    },
    dragger: {
        width: "14px",
        cursor: "ew-resize",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: "100",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "transparant",
        transition: "background-color 0.3s ease",
        "& .MuiSvgIcon-root": {
            opacity: 0,
            visibility: "hidden",
            transition: "opacity 0.3s ease, visibility 0.3s ease",
        },
        "&:hover": {
            backgroundColor: "#f4f7f9",
            "& .MuiSvgIcon-root": {
                opacity: 1,
                visibility: "visible",
            },
        },
    },
}); });
