"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var react_1 = require("react");
function useMirrorGenerator() {
    var ds = (0, redux_1.useAppSelector)(function (state) { return state.workspaceStep.deselectedSteps; });
    var stepId = (0, redux_1.useAppSelector)(function (state) { var _a; return (_a = state.workspaceStep.currentStep) === null || _a === void 0 ? void 0 : _a.id; });
    var shouldMirror = (0, redux_1.useAppSelector)(function (state) { return state.workspaceStep.isMirrorMode; });
    var mirror = (0, react_1.useCallback)(function () { return ({ stepId: stepId, deselectedSteps: Array.from(ds), shouldMirror: shouldMirror }); }, [ds, stepId, shouldMirror]);
    return mirror;
}
exports.default = useMirrorGenerator;
