"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PUT_WORKSPACE_FRAME_SLIDES_SUCCESS = exports.PUT_WORKSPACE_FRAME_SLIDES_LOADING = exports.POST_WORKSPACE_FRAME_SLIDE_SUCCESS = exports.POST_WORKSPACE_FRAME_SLIDE_LOADING = exports.POST_WORKSPACE_FRAME_SLIDE_FAILED = exports.CHANGE_MERGE_CONFLICT = exports.SET_MERGE_CONFLICT = exports.REMOVE_ACTIVE_EDGES_FAILED = exports.REMOVE_ACTIVE_EDGES_SUCCESS = exports.REMOVE_ACTIVE_EDGES_LOADING = exports.SET_ACTIVE_EDGES_FAILED = exports.SET_ACTIVE_EDGES_SUCCESS = exports.SET_ACTIVE_EDGES_LOADING = exports.REMOVE_ACTIVE_NODES_FAILED = exports.REMOVE_ACTIVE_NODES_SUCCESS = exports.REMOVE_ACTIVE_NODES_LOADING = exports.SET_ACTIVE_NODES_FAILED = exports.SET_ACTIVE_NODES_SUCCESS = exports.SET_ACTIVE_NODES_LOADING = exports.SET_PICK_ELEMENTS_FOR_FAILED = exports.SET_PICK_ELEMENTS_FOR_LOADING = exports.SET_PICK_ELEMENTS_FOR = exports.CHANGE_TEXT = exports.DELETE_MULTIPLE_WORKSPACES_FRAMES_FAILED = exports.DELETE_MULTIPLE_WORKSPACES_FRAMES_SUCCESS = exports.DELETE_MULTIPLE_WORKSPACES_FRAMES_LOADING = exports.DELETE_WORKSPACES_FRAMES_FAILED = exports.DELETE_WORKSPACES_FRAMES_SUCCESS = exports.DELETE_WORKSPACES_FRAMES_LOADING = exports.DOWNLOAD_WORKSPACES_FRAMES_FAILED = exports.DOWNLOAD_WORKSPACES_FRAMES_SUCCESS = exports.DOWNLOAD_WORKSPACES_FRAMES_LOADING = exports.MERGE_WORKSPACES_FRAMES_FAILED = exports.MERGE_WORKSPACES_FRAMES_SUCCESS = exports.MERGE_WORKSPACES_FRAMES_LOADING = exports.PUT_WORKSPACES_FRAMES_FAILED = exports.PUT_WORKSPACES_FRAMES_SUCCESS = exports.PUT_WORKSPACES_FRAMES_LOADING = exports.POST_WORKSPACES_FRAMES_FAILED = exports.POST_WORKSPACES_FRAMES_SUCCESS = exports.POST_WORKSPACES_FRAMES_LOADING = exports.ANALYSE_WORKSPACES_FRAMES_FAILED = exports.ANALYSE_WORKSPACES_FRAMES_SUCCESS = exports.ANALYSE_WORKSPACES_FRAMES_LOADING = exports.GET_WORKSPACES_FRAMES_FAILED = exports.GET_WORKSPACES_FRAMES_SUCCESS = exports.GET_WORKSPACES_FRAMES_LOADING = exports.GET_SLIDES_FAILED = exports.GET_SLIDES_SUCCESS = exports.GET_SLIDES_LOADING = void 0;
exports.SET_EDIT_FRAME = exports.TOGGLE_EDITABLE_SLIDE_VIEWER = exports.FRAME_ANALYSIS_UPDATE_EVENT = exports.CLOSE_SLIDE_VIEWER = exports.OPEN_SLIDE_VIEWER = exports.CLEAR_WORKSPACE_FRAME_ELEMENTS = exports.GET_WORKSPACE_FRAME_ELEMENTS_SUCCESS = exports.GET_WORKSPACE_FRAME_ELEMENTS_FAILED = exports.GET_WORKSPACE_FRAME_ELEMENTS_LOADING = exports.OVERRIDE_WORKSPACE_FRAME_ELEMENT = exports.PUT_WORKSPACE_FRAME_SLIDE_CONTENT_FAILED = exports.PUT_WORKSPACE_FRAME_SLIDE_CONTENT_SUCCESS = exports.DELETE_WORKSPACE_FRAME_SLIDES_FAILED = exports.DELETE_WORKSPACE_FRAME_SLIDES_LOADING = exports.DELETE_WORKSPACE_FRAME_SLIDES_SUCCESS = exports.PUT_WORKSPACE_FRAME_SLIDES_FAILED = void 0;
exports.GET_SLIDES_LOADING = "GET_SLIDES_LOADING";
exports.GET_SLIDES_SUCCESS = "GET_SLIDES_SUCCESS";
exports.GET_SLIDES_FAILED = "GET_SLIDES_FAILED";
exports.GET_WORKSPACES_FRAMES_LOADING = "GET_WORKSPACES_FRAMES_LOADING";
exports.GET_WORKSPACES_FRAMES_SUCCESS = "GET_WORKSPACES_FRAMES_SUCCESS";
exports.GET_WORKSPACES_FRAMES_FAILED = "GET_WORKSPACES_FRAMES_FAILED";
exports.ANALYSE_WORKSPACES_FRAMES_LOADING = "ANALYSE_WORKSPACES_FRAMES_LOADING";
exports.ANALYSE_WORKSPACES_FRAMES_SUCCESS = "ANALYSE_WORKSPACES_FRAMES_SUCCESS";
exports.ANALYSE_WORKSPACES_FRAMES_FAILED = "ANALYSE_WORKSPACES_FRAMES_FAILED";
exports.POST_WORKSPACES_FRAMES_LOADING = "POST_WORKSPACES_FRAMES_LOADING";
exports.POST_WORKSPACES_FRAMES_SUCCESS = "POST_WORKSPACES_FRAMES_SUCCESS";
exports.POST_WORKSPACES_FRAMES_FAILED = "POST_WORKSPACES_FRAMES_FAILED";
exports.PUT_WORKSPACES_FRAMES_LOADING = "PUT_WORKSPACES_FRAMES_LOADING";
exports.PUT_WORKSPACES_FRAMES_SUCCESS = "PUT_WORKSPACES_FRAMES_SUCCESS";
exports.PUT_WORKSPACES_FRAMES_FAILED = "PUT_WORKSPACES_FRAMES_FAILED";
exports.MERGE_WORKSPACES_FRAMES_LOADING = "MERGE_WORKSPACES_FRAMES_LOADING";
exports.MERGE_WORKSPACES_FRAMES_SUCCESS = "MERGE_WORKSPACES_FRAMES_SUCCESS";
exports.MERGE_WORKSPACES_FRAMES_FAILED = "MERGE_WORKSPACES_FRAMES_FAILED";
exports.DOWNLOAD_WORKSPACES_FRAMES_LOADING = "DOWNLOAD_WORKSPACES_FRAMES_LOADING";
exports.DOWNLOAD_WORKSPACES_FRAMES_SUCCESS = "DOWNLOAD_WORKSPACES_FRAMES_SUCCESS";
exports.DOWNLOAD_WORKSPACES_FRAMES_FAILED = "DOWNLOAD_WORKSPACES_FRAMES_FAILED";
exports.DELETE_WORKSPACES_FRAMES_LOADING = "DELETE_WORKSPACES_FRAMES_LOADING";
exports.DELETE_WORKSPACES_FRAMES_SUCCESS = "DELETE_WORKSPACES_FRAMES_SUCCESS";
exports.DELETE_WORKSPACES_FRAMES_FAILED = "DELETE_WORKSPACES_FRAMES_FAILED";
exports.DELETE_MULTIPLE_WORKSPACES_FRAMES_LOADING = "DELETE_MULTIPLE_WORKSPACES_FRAMES_LOADING";
exports.DELETE_MULTIPLE_WORKSPACES_FRAMES_SUCCESS = "DELETE_MULTIPLE_WORKSPACES_FRAMES_SUCCESS";
exports.DELETE_MULTIPLE_WORKSPACES_FRAMES_FAILED = "DELETE_MULTIPLE_WORKSPACES_FRAMES_FAILED";
exports.CHANGE_TEXT = "CHANGE_TEXT";
exports.SET_PICK_ELEMENTS_FOR = "SET_PICK_ELEMENTS_FOR";
exports.SET_PICK_ELEMENTS_FOR_LOADING = "SET_PICK_ELEMENTS_FOR_LOADING";
exports.SET_PICK_ELEMENTS_FOR_FAILED = "SET_PICK_ELEMENTS_FOR_FAILED";
exports.SET_ACTIVE_NODES_LOADING = "SET_ACTIVE_NODES_LOADING";
exports.SET_ACTIVE_NODES_SUCCESS = "SET_ACTIVE_NODES_SUCCESS";
exports.SET_ACTIVE_NODES_FAILED = "SET_ACTIVE_NODES_FAILED";
exports.REMOVE_ACTIVE_NODES_LOADING = "REMOVE_ACTIVE_NODES_LOADING";
exports.REMOVE_ACTIVE_NODES_SUCCESS = "REMOVE_ACTIVE_NODES_SUCCESS";
exports.REMOVE_ACTIVE_NODES_FAILED = "REMOVE_ACTIVE_NODES_FAILED";
exports.SET_ACTIVE_EDGES_LOADING = "SET_ACTIVE_EDGES_LOADING";
exports.SET_ACTIVE_EDGES_SUCCESS = "SET_ACTIVE_EDGES_SUCCESS";
exports.SET_ACTIVE_EDGES_FAILED = "SET_ACTIVE_EDGES_FAILED";
exports.REMOVE_ACTIVE_EDGES_LOADING = "REMOVE_ACTIVE_EDGES_LOADING";
exports.REMOVE_ACTIVE_EDGES_SUCCESS = "REMOVE_ACTIVE_EDGES_SUCCESS";
exports.REMOVE_ACTIVE_EDGES_FAILED = "REMOVE_ACTIVE_EDGES_FAILED";
exports.SET_MERGE_CONFLICT = "SET_MERGE_CONFLICT";
exports.CHANGE_MERGE_CONFLICT = "CHANGE_MERGE_CONFLICT";
exports.POST_WORKSPACE_FRAME_SLIDE_FAILED = "POST_WORKSPACE_FRAME_SLIDE_FAILED";
exports.POST_WORKSPACE_FRAME_SLIDE_LOADING = "POST_WORKSPACE_FRAME_SLIDE_LOADING";
exports.POST_WORKSPACE_FRAME_SLIDE_SUCCESS = "POST_WORKSPACE_FRAME_SLIDE_SUCCESS";
exports.PUT_WORKSPACE_FRAME_SLIDES_LOADING = "PUT_WORKSPACE_FRAME_SLIDES_LOADING";
exports.PUT_WORKSPACE_FRAME_SLIDES_SUCCESS = "PUT_WORKSPACE_FRAME_SLIDES_SUCCESS";
exports.PUT_WORKSPACE_FRAME_SLIDES_FAILED = "PUT_WORKSPACE_FRAME_SLIDES_FAILED";
exports.DELETE_WORKSPACE_FRAME_SLIDES_SUCCESS = "DELETE_WORKSPACE_FRAME_SLIDES_SUCCESS";
exports.DELETE_WORKSPACE_FRAME_SLIDES_LOADING = "DELETE_WORKSPACE_FRAME_SLIDES_LOADING";
exports.DELETE_WORKSPACE_FRAME_SLIDES_FAILED = "DELETE_WORKSPACE_FRAME_SLIDES_FAILED";
exports.PUT_WORKSPACE_FRAME_SLIDE_CONTENT_SUCCESS = "PUT_WORKSPACE_FRAME_SLIDE_CONTENT_SUCCESS";
exports.PUT_WORKSPACE_FRAME_SLIDE_CONTENT_FAILED = "PUT_WORKSPACE_FRAME_SLIDE_CONTENT_FAILED";
exports.OVERRIDE_WORKSPACE_FRAME_ELEMENT = "OVERRIDE_WORKSPACE_FRAME_ELEMENT";
exports.GET_WORKSPACE_FRAME_ELEMENTS_LOADING = "GET_WORKSPACE_FRAMES_ELEMENTS_LOADING";
exports.GET_WORKSPACE_FRAME_ELEMENTS_FAILED = "GET_WORKSPACE_FRAMES_ELEMENTS_FAILED";
exports.GET_WORKSPACE_FRAME_ELEMENTS_SUCCESS = "GET_WORKSPACE_FRAMES_ELEMENTS_SUCCESS";
exports.CLEAR_WORKSPACE_FRAME_ELEMENTS = "CLEAR_WORKSPACE_FRAMES_ELEMENTS";
exports.OPEN_SLIDE_VIEWER = "OPEN_SLIDE_VIEWER";
exports.CLOSE_SLIDE_VIEWER = "CLOSE_SLIDE_VIEWER";
exports.FRAME_ANALYSIS_UPDATE_EVENT = "FRAME_ANALYSIS_UPDATE_EVENT";
exports.TOGGLE_EDITABLE_SLIDE_VIEWER = "TOGGLE_EDITABLE_SLIDE_VIEWER";
exports.SET_EDIT_FRAME = "SET_EDIT_FRAME";
