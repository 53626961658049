"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    thumbnailSelectedItem: {
        boxShadow: "0px 0px 8px 1px ".concat(theme.palette.primary.main, "80"),
    },
    thumbnailItem: {
        all: "unset",
        transition: "transform .3s",
        ":hover": {
            cursor: "pointer",
            transform: "scale(1.1)",
        },
    },
    thumbnailContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        alignItems: "center",
        marginTop: "5px",
    },
    iconButton: {
        ":hover": {
            "> svg": {
                color: theme.palette.primary.main,
            },
        },
        "&--selected": {
            "> svg": {
                color: theme.palette.primary.main,
            },
        },
    },
    ZoomDropdownInput: {
        minWidth: 120,
        "&& > .css-1dz15vc-MuiFormLabel-root-MuiInputLabel-root": {
            top: "calc(-25%)",
        },
        "&& > div": {
            padding: 0,
            textAlign: "center",
        },
    },
    sidebarContent: {
        width: 0,
        transition: "width .25s ease",
        opacity: 0,
        scrollbarWidth: "thin",
        scrollbarColor: "".concat(theme.palette.primary.main, " ").concat(theme.palette.primary.light),
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-track": {
            background: theme.palette.primary.light,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "".concat(theme.palette.primary.main),
            borderRadius: 10,
        },
        "&__outer": {
            backgroundColor: "#fff",
            color: theme.palette.text.primary,
            display: "flex",
            overflow: "hidden",
            width: "auto",
            opacity: 1,
        },
        "&--opened": {
            backgroundColor: "#fff",
            color: theme.palette.text.primary,
            display: "flex",
            overflow: "hidden",
            width: "240px",
            opacity: 1,
        },
    },
    sidebarTabs: {
        display: "flex",
        height: "100%",
        width: "100%",
    },
    sidebarHeaders: {
        background: "#fff",
        padding: ".25rem",
        zIndex: 1,
        borderRight: "1px solid ".concat(theme.palette.primary.light),
        borderLeft: "1px solid ".concat(theme.palette.primary.light),
    },
    sidebarHeader: {
        padding: ".125rem",
    },
}); });
exports.default = useStyles;
