"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Colors_1 = require("@enums/Colors");
var mui_1 = require("tss-react/mui");
var styles_1 = require("@customTypes/styles");
var flowHeight = "100vh";
var openTransistion = {
    "-webkit-transition": "all 0.3s ease-in-out",
    "-moz-transition": "all 0.3s ease-in-out",
    "-o-transition": "all 0.3s ease-in-out",
    transition: "all 0.3s ease-in-out",
};
var useStyles = (0, mui_1.makeStyles)()(function (theme) {
    var _a;
    return ({
        root: {
            height: flowHeight,
            zIndex: 1,
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        table: {
            "& > div": {
                overflow: "auto",
            },
            "& table": (_a = {
                    "& td": {
                        wordBreak: "keep-all",
                    }
                },
                _a[theme.breakpoints.down("lg")] = {
                    "& td": {
                        height: 60,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                },
                _a),
            marginBottom: 50,
        },
        meta: {
            position: "fixed",
            zIndex: 1000,
            backgroundColor: Colors_1.otherGenericColors.panel,
            marginLeft: 10,
            top: 10,
        },
        logo: {
            width: 50,
            marginLeft: 12,
            userSelect: "none",
        },
        verDivder: {
            margin: 10,
        },
        horDivider: {
            margin: 10,
            height: 1,
        },
        editorToolbar: {
            position: "fixed",
            zIndex: 1000,
            backgroundColor: Colors_1.otherGenericColors.panel,
            top: 10,
            p: 0,
            justifyContent: "center",
            display: "flex",
            width: "388px",
            "@media (min-width:783px)": {
                width: "664px",
            },
            "@media (min-width:1007px)": {
                width: "800px",
            },
            "@media (min-width:1215px)": {
                width: "925px",
            },
        },
        reportMetaButton: __assign(__assign({ position: "fixed", zIndex: 1000, backgroundColor: Colors_1.otherGenericColors.panel }, openTransistion), { right: 0, marginRight: 10, top: "50%", transform: "translateY(-50%)", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }),
        slideMetaButton: __assign(__assign({ position: "fixed", zIndex: 1000, backgroundColor: Colors_1.otherGenericColors.panel }, openTransistion), { marginLeft: 10, top: "50%", transform: "translateY(-50%)", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }),
        slideMetaOpenEffect: __assign(__assign({}, openTransistion), { marginLeft: 300 }),
        framePanel: __assign(__assign({ position: "fixed", zIndex: 1000, backgroundColor: Colors_1.otherGenericColors.panel, marginLeft: 10, top: 68, height: "calc(100% - 160px)" }, openTransistion), { width: 0, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", pointerEvents: "auto", padding: 10, opacity: "0", visibility: "hidden" }),
        framePanelOpen: __assign(__assign({}, openTransistion), { width: 280, opacity: "1", visibility: "visible" }),
        framePanelDivider: {
            height: 1,
            width: "100%",
        },
        framePanelHeaderContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: 5,
        },
        framePanelHeader: {
            fontWeight: "bold",
        },
        framePanelBody: __assign({ height: "100%", width: "100%", marginBottom: 10, marginTop: 10, overflowY: "scroll", paddingRight: 5, paddingLeft: 5 }, (0, styles_1.JuristicScroll)(theme)),
        addSlideContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
        },
        addSlideButton: {
            backgroundColor: theme.palette.background.default,
        },
        slide: {
            width: "100%",
            display: "flex",
        },
        topButtonContainer: {
            width: "100%",
            marginTop: 15,
        },
        buttons: {
            "&:hover": {
                backgroundColor: "transparent",
                color: theme.palette.primary.main,
            },
            fontSize: 24,
        },
        activeButton: {
            color: theme.palette.primary.main,
        },
    });
});
exports.default = useStyles;
