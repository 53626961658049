"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function DateManager(data) {
    return function getDataFromMatch(match) {
        var dataIndexes = data[match.original] || {};
        var values = dataIndexes[match.pageIndex] || {};
        if (!values)
            return null;
        return values.find(function (v) { return v.matchIndex === match.matchIndex; }) || null;
    };
}
exports.default = DateManager;
