"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.elementFilter = exports.traverseParentsUntilUniqueSplit = exports.getSplitElement = exports.getElementBounds = void 0;
var getElementBounds = function (el) {
    var rect = el.getBoundingClientRect();
    return {
        x: window.pageXOffset + rect.left,
        y: window.pageYOffset + rect.top,
        width: el.offsetWidth,
        height: el.offsetHeight,
    };
};
exports.getElementBounds = getElementBounds;
var getSplitElement = function (text, otherColor) {
    var splitElContainer = document.createElement("div");
    splitElContainer.style.height = "8px";
    splitElContainer.style.width = "82%";
    splitElContainer.style.display = "flex";
    splitElContainer.style.position = "relative";
    splitElContainer.style.alignItems = "center";
    splitElContainer.className = "splitting_element";
    splitElContainer.style.borderTop = "4px dashed " + otherColor;
    var splitTextContainer = document.createElement("div");
    splitTextContainer.style.position = "absolute";
    splitTextContainer.style.right = "1px";
    splitTextContainer.style.top = "1px";
    splitTextContainer.style.padding = "5px";
    splitTextContainer.style.position = "absolute";
    splitTextContainer.style.right = "-110px";
    splitTextContainer.style.top = "-14px";
    splitTextContainer.style.backgroundColor = otherColor;
    splitTextContainer.style.borderRadius = "6px";
    splitTextContainer.style.display = "flex";
    splitTextContainer.className = "splitting_element_innerDiv";
    var splitText = document.createElement("p");
    splitText.innerText = text;
    splitText.style.width = "100%";
    splitText.style.color = "white";
    splitText.style.margin = "auto";
    splitText.style.fontSize = "10px";
    splitText.className = "splitting_element_innerText";
    splitTextContainer.appendChild(splitText);
    splitElContainer.appendChild(splitTextContainer);
    return splitElContainer;
};
exports.getSplitElement = getSplitElement;
var traverseParentsUntilUniqueSplit = function (el, currEmail) {
    var splitTest = currEmail === null || currEmail === void 0 ? void 0 : currEmail.split(el.outerHTML);
    if ((splitTest === null || splitTest === void 0 ? void 0 : splitTest.length) === 2) {
        return el.outerHTML;
    }
    return (0, exports.traverseParentsUntilUniqueSplit)(el.parentElement, currEmail);
};
exports.traverseParentsUntilUniqueSplit = traverseParentsUntilUniqueSplit;
var elementFilter = function (el, currEmail) {
    var childOfHtmlDiv = false;
    for (var p = el && el.parentElement; p; p = p.parentElement) {
        if (p.id === "elementPickerContainer") {
            childOfHtmlDiv = true;
        }
    }
    var uniqueCount = 2;
    if (el.className.includes("splitting_element")) {
        var splits = document.getElementsByClassName("splitting_element");
        uniqueCount = splits.length + 1;
    }
    var splitTest = currEmail === null || currEmail === void 0 ? void 0 : currEmail.split(el.outerHTML);
    var unique = (splitTest === null || splitTest === void 0 ? void 0 : splitTest.length) === uniqueCount;
    return childOfHtmlDiv && unique;
};
exports.elementFilter = elementFilter;
