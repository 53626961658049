"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getToken = exports.getPlanId = void 0;
var localStorage_1 = require("@api/localStorage/localStorage");
var getPlanId = function (user) {
    var meta = user && user["https://juristic.io/meta"];
    if (meta) {
        return meta.dbUser.plan_id || meta.organization.plan_id;
    }
    return null;
};
exports.getPlanId = getPlanId;
var getToken = function (user) {
    var meta = user && user["https://juristic.io/meta"];
    if (meta) {
        return meta.access_token.token;
    }
    var token = ((0, localStorage_1.loadFromLocalStorage)() || {}).token;
    return token || "";
};
exports.getToken = getToken;
