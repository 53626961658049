const data = {
  "v": "5.7.11",
  "fr": 60,
  "ip": 0,
  "op": 202,
  "w": 190,
  "h": 230,
  "nm": "NoContent",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "Shape Layer 1",
      "parent": 10,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            250,
            246.75,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 0,
                "k": [
                  10.938,
                  10.938
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "nm": "Rectangle Path 1",
              "mn": "ADBE Vector Shape - Rect",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.478431402468,
                  0.360784313725,
                  0.345098039216,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  17.594,
                  -58.781
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  48.571
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 2",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 0,
                "k": [
                  10,
                  6.125
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "nm": "Rectangle Path 1",
              "mn": "ADBE Vector Shape - Rect",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.478431402468,
                  0.360784313725,
                  0.345098039216,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  -4.125,
                  -59.313
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "NoContent Outlines - Group 1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            25.814,
            202.504,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            155.461,
            331.282,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      11.87,
                      -10.978
                    ],
                    [
                      -11.87,
                      -10.978
                    ],
                    [
                      -7.87,
                      10.978
                    ],
                    [
                      6.87,
                      10.978
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.769000004787,
                  0.603999956916,
                  0.423999980852,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  155.461,
                  331.282
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "NoContent Outlines - Group 29",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 74,
              "s": [
                8
              ]
            },
            {
              "t": 202,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            25.591,
            193.856,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            155.237,
            322.635,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      -11.926,
                      -5.229
                    ],
                    [
                      12.916,
                      7.608
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      4.314,
                      10.108
                    ],
                    [
                      -5.304,
                      -10.109
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.552999997606,
                  0.776000019148,
                  0.246999987434,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  150.759,
                  312.76
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 29",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "NoContent Outlines - Group 30",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 104,
              "s": [
                8
              ]
            },
            {
              "t": 202,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            26.038,
            193.981,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            155.684,
            322.76,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      11.926,
                      -5.229
                    ],
                    [
                      -12.917,
                      7.608
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -4.314,
                      10.108
                    ],
                    [
                      5.305,
                      -10.109
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.552999997606,
                  0.776000019148,
                  0.246999987434,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  160.162,
                  312.76
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 30",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "NoContent Outlines - Group 31",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 129,
              "s": [
                8
              ]
            },
            {
              "t": 202,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            25.689,
            190.861,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            155.336,
            319.639,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      11.84,
                      -13.139
                    ],
                    [
                      -11.735,
                      16.637
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -0.354,
                      15.201
                    ],
                    [
                      0.249,
                      -15.2
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.552999997606,
                  0.776000019148,
                  0.246999987434,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  155.49,
                  304.764
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 31",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 10,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            245.471,
            191.862,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            245.471,
            191.862,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.837,
                          0
                        ],
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.838,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.838,
                          0
                        ],
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.837,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.517,
                          0
                        ],
                        [
                          0.001,
                          1.517
                        ],
                        [
                          -1.516,
                          0
                        ],
                        [
                          0.001,
                          -1.517
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      220.988,
                      184.364
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 6",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  246.342,
                  190.648
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  220.988,
                  184.364
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 6",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.933,
                          0
                        ],
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ],
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.933,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.5,
                          0
                        ],
                        [
                          0,
                          4.46
                        ],
                        [
                          -3.5,
                          0
                        ],
                        [
                          0,
                          -4.46
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      220.117,
                      185.64
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 10",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  245.471,
                  191.862
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  220.117,
                  185.64
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 10",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 10,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            268.01,
            191.862,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            268.01,
            191.862,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.837,
                          0
                        ],
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.838,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.838,
                          0
                        ],
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.837,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.516,
                          0
                        ],
                        [
                          0,
                          1.517
                        ],
                        [
                          -1.517,
                          0
                        ],
                        [
                          0,
                          -1.517
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      243.636,
                      184.23
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 7",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  268.989,
                  190.515
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  243.636,
                  184.23
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 7",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.933,
                          0
                        ],
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ],
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.933,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.5,
                          0
                        ],
                        [
                          0,
                          4.46
                        ],
                        [
                          -3.5,
                          0
                        ],
                        [
                          0,
                          -4.46
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      242.657,
                      185.64
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 9",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  268.01,
                  191.862
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  242.657,
                  185.64
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 9",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 4,
      "nm": "NoContent Outlines - Group 8",
      "parent": 10,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            256.071,
            203.516,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            230.718,
            197.294,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      2.485,
                      0
                    ],
                    [
                      0,
                      2.322
                    ]
                  ],
                  "o": [
                    [
                      0,
                      2.322
                    ],
                    [
                      -2.485,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      4.5,
                      -2.102
                    ],
                    [
                      0,
                      2.102
                    ],
                    [
                      -4.5,
                      -2.102
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  230.718,
                  197.294
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 8",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 4,
      "nm": "FACE",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 0,
              "s": [
                99.822,
                90.866,
                0
              ],
              "to": [
                0,
                0.542,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 100,
              "s": [
                99.822,
                94.116,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0.542,
                0
              ]
            },
            {
              "t": 201,
              "s": [
                99.822,
                90.866,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            254.822,
            225.866,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.908,
                          5.771
                        ],
                        [
                          12.536,
                          7.348
                        ],
                        [
                          -1.956,
                          -12.913
                        ],
                        [
                          -27.447,
                          -1.188
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          4.011,
                          0.293
                        ],
                        [
                          -3.968,
                          -1.777
                        ],
                        [
                          -3.959,
                          -1.586
                        ],
                        [
                          -9.309,
                          -5.457
                        ],
                        [
                          1.87,
                          12.34
                        ],
                        [
                          3.822,
                          -2.477
                        ],
                        [
                          -4.207,
                          0.391
                        ]
                      ],
                      "v": [
                        [
                          24.073,
                          12.969
                        ],
                        [
                          30.823,
                          11.675
                        ],
                        [
                          16.076,
                          -1.8
                        ],
                        [
                          -7.704,
                          -14.156
                        ],
                        [
                          -28.868,
                          -1.998
                        ],
                        [
                          25.145,
                          19.613
                        ],
                        [
                          30.823,
                          15.317
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      259.731,
                      186.229
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 11",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  285.969,
                  194.061
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  260.616,
                  187.839
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 11",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -1.87,
                          12.344
                        ],
                        [
                          9.31,
                          -5.457
                        ],
                        [
                          5.621,
                          -1.935
                        ],
                        [
                          4.519,
                          -1.732
                        ],
                        [
                          -5.771,
                          1.692
                        ],
                        [
                          5.282,
                          -0.978
                        ],
                        [
                          -6.053,
                          -3.622
                        ]
                      ],
                      "o": [
                        [
                          1.957,
                          -12.913
                        ],
                        [
                          -14.068,
                          8.247
                        ],
                        [
                          -3.065,
                          2.618
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          27.423,
                          -1.184
                        ]
                      ],
                      "v": [
                        [
                          30.326,
                          -1.999
                        ],
                        [
                          9.162,
                          -14.156
                        ],
                        [
                          -20.82,
                          0.187
                        ],
                        [
                          -32.282,
                          7.197
                        ],
                        [
                          -23.674,
                          7.882
                        ],
                        [
                          -32.282,
                          13.066
                        ],
                        [
                          -23.274,
                          19.613
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      200.164,
                      186.229
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 12",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  224.633,
                  194.061
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  199.279,
                  187.839
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 12",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          -27.447,
                          -1.188
                        ],
                        [
                          27.573,
                          0.003
                        ],
                        [
                          9.856,
                          5.898
                        ],
                        [
                          -1.87,
                          12.344
                        ]
                      ],
                      "o": [
                        [
                          1.87,
                          12.34
                        ],
                        [
                          -8.192,
                          5.31
                        ],
                        [
                          -27.029,
                          -0.004
                        ],
                        [
                          27.423,
                          -1.184
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -0.019,
                          -17.632
                        ],
                        [
                          53.994,
                          3.979
                        ],
                        [
                          0.976,
                          17.629
                        ],
                        [
                          -53.994,
                          3.98
                        ],
                        [
                          -0.394,
                          -17.632
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.991999983788,
                      0.949000000954,
                      0.917999982834,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      230.883,
                      201.863
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 13",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  256.236,
                  208.083
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  230.883,
                  201.861
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 13",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          -9.31,
                          -5.457
                        ],
                        [
                          -3.959,
                          -1.586
                        ],
                        [
                          1.638,
                          2.369
                        ],
                        [
                          5.398,
                          4.281
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.021,
                          -10.957
                        ],
                        [
                          4.896,
                          -4.18
                        ],
                        [
                          -14.069,
                          8.247
                        ],
                        [
                          1.957,
                          -12.913
                        ]
                      ],
                      "o": [
                        [
                          -1.957,
                          -12.913
                        ],
                        [
                          12.536,
                          7.348
                        ],
                        [
                          -2.716,
                          -3.193
                        ],
                        [
                          -4.598,
                          -6.652
                        ],
                        [
                          -8.404,
                          -6.664
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -7.044,
                          0.978
                        ],
                        [
                          -22.891,
                          4.108
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          5.622,
                          -1.935
                        ],
                        [
                          9.309,
                          -5.457
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.289,
                          18.375
                        ],
                        [
                          24.452,
                          6.217
                        ],
                        [
                          48.233,
                          18.573
                        ],
                        [
                          41.457,
                          9.864
                        ],
                        [
                          25.61,
                          -9.408
                        ],
                        [
                          3.088,
                          -17.114
                        ],
                        [
                          5.263,
                          -20.56
                        ],
                        [
                          -5.89,
                          -15.668
                        ],
                        [
                          -40.52,
                          12.31
                        ],
                        [
                          -48.232,
                          20.56
                        ],
                        [
                          -18.249,
                          6.217
                        ],
                        [
                          2.914,
                          18.375
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      227.575,
                      165.856
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 14",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  252.929,
                  172.078
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  227.575,
                  165.856
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 14",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 11,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 10,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            281.346,
            178.138,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            281.346,
            178.138,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          3.774,
                          3.055
                        ],
                        [
                          -1.565,
                          12.206
                        ],
                        [
                          11.562,
                          -9.733
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -11.094,
                          8.568
                        ],
                        [
                          5.479,
                          -11.921
                        ],
                        [
                          -0.469,
                          11.921
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      267.921,
                      163.541
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 15",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  291.149,
                  169.763
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  265.795,
                  163.541
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 15",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -18.666,
                          -0.929
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          11.073,
                          -7.853
                        ],
                        [
                          -9.196,
                          4.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.797,
                          11.921
                        ],
                        [
                          9.745,
                          -11.921
                        ],
                        [
                          -12.949,
                          -6.38
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      263.656,
                      163.541
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 16",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  287.971,
                  169.763
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  262.617,
                  163.541
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 16",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 12,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 10,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            227.649,
            178.388,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            227.649,
            178.388,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -3.775,
                          3.055
                        ],
                        [
                          1.565,
                          12.206
                        ],
                        [
                          -11.562,
                          -9.733
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          11.094,
                          8.568
                        ],
                        [
                          -5.48,
                          -11.921
                        ],
                        [
                          0.469,
                          11.921
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      189.492,
                      163.541
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 17",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  216.972,
                  169.763
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  191.618,
                  163.541
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 17",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          18.666,
                          -0.929
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          -11.073,
                          -7.853
                        ],
                        [
                          9.196,
                          4.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -3.796,
                          11.921
                        ],
                        [
                          -9.745,
                          -11.921
                        ],
                        [
                          12.949,
                          -6.38
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      193.757,
                      163.541
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 18",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  220.149,
                  169.763
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  194.796,
                  163.541
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 18",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 13,
      "ty": 4,
      "nm": "NoContent Outlines - Group 21",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            94.528,
            105.12,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            224.174,
            233.899,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            1.76,
                            -12.997
                          ],
                          [
                            2.191,
                            14.46
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -2.772,
                            20.478
                          ],
                          [
                            -3.4,
                            -22.436
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            20.697,
                            -25.173
                          ],
                          [
                            19.801,
                            9.93
                          ],
                          [
                            -18.162,
                            13.179
                          ],
                          [
                            -18.624,
                            -30.409
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 100,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            1.76,
                            -12.997
                          ],
                          [
                            1.574,
                            14.54
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -2.772,
                            20.478
                          ],
                          [
                            -2.563,
                            -23.675
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            20.697,
                            -25.173
                          ],
                          [
                            19.801,
                            9.93
                          ],
                          [
                            -20.162,
                            12.929
                          ],
                          [
                            -18.624,
                            -30.409
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "t": 202,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            1.76,
                            -12.997
                          ],
                          [
                            2.191,
                            14.46
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -2.772,
                            20.478
                          ],
                          [
                            -3.4,
                            -22.436
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            20.697,
                            -25.173
                          ],
                          [
                            19.801,
                            9.93
                          ],
                          [
                            -18.162,
                            13.179
                          ],
                          [
                            -18.624,
                            -30.409
                          ]
                        ],
                        "c": false
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.991999966491,
                  0.948999980852,
                  0.917999985639,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  223.621,
                  236.774
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 21",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 14,
      "ty": 4,
      "nm": "NoContent Outlines - Group 22",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            89.477,
            128.251,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            219.124,
            257.03,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            10.683,
                            5.745
                          ],
                          [
                            -0.156,
                            7.148
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -11.003,
                            -5.918
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            22.701,
                            20.081
                          ],
                          [
                            -11.698,
                            17.385
                          ],
                          [
                            -19.988,
                            -5.016
                          ],
                          [
                            -19.487,
                            -23.13
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 100,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            10.683,
                            5.745
                          ],
                          [
                            -0.816,
                            6.894
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -11.003,
                            -5.918
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            22.701,
                            20.081
                          ],
                          [
                            -13.698,
                            17.385
                          ],
                          [
                            -21.613,
                            -6.516
                          ],
                          [
                            -19.487,
                            -23.13
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "t": 202,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            10.683,
                            5.745
                          ],
                          [
                            -0.156,
                            7.148
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -11.003,
                            -5.918
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            22.701,
                            20.081
                          ],
                          [
                            -11.698,
                            17.385
                          ],
                          [
                            -19.988,
                            -5.016
                          ],
                          [
                            -19.487,
                            -23.13
                          ]
                        ],
                        "c": false
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  217.95,
                  258.15
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 22",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 15,
      "ty": 4,
      "nm": "NoContent Outlines - Group 19",
      "parent": 16,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 100,
              "s": [
                -5
              ]
            },
            {
              "t": 202,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            196.405,
            288.867,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            196.405,
            288.867,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            14.308,
                            -2.985
                          ],
                          [
                            -10.14,
                            -2.152
                          ],
                          [
                            0,
                            6.196
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            8.887,
                            1.886
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -9.432,
                            -7.635
                          ],
                          [
                            -2.16,
                            8.733
                          ],
                          [
                            12.301,
                            -3.741
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 100,
                    "s": [
                      {
                        "i": [
                          [
                            14.308,
                            -2.985
                          ],
                          [
                            -11.548,
                            -2.034
                          ],
                          [
                            0.91,
                            6.927
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            9.428,
                            1.66
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -9.432,
                            -7.635
                          ],
                          [
                            1.93,
                            8.924
                          ],
                          [
                            12.301,
                            -3.741
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "t": 202,
                    "s": [
                      {
                        "i": [
                          [
                            14.308,
                            -2.985
                          ],
                          [
                            -10.14,
                            -2.152
                          ],
                          [
                            0,
                            6.196
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            8.887,
                            1.886
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -9.432,
                            -7.635
                          ],
                          [
                            -2.16,
                            8.733
                          ],
                          [
                            12.301,
                            -3.741
                          ]
                        ],
                        "c": true
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.477999997606,
                  0.361000001197,
                  0.344999994016,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  195.158,
                  297.242
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 19",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 16,
      "ty": 4,
      "nm": "NoContent Outlines - Group 23",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 100,
              "s": [
                -10
              ]
            },
            {
              "t": 202,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            78.908,
            134.124,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            208.555,
            262.902,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      9.261,
                      -3
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -1.661,
                      8.862
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -8.854,
                      -4.592
                    ]
                  ],
                  "o": [
                    [
                      -5.877,
                      1.904
                    ],
                    [
                      -13.126,
                      5.218
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      2.419,
                      -12.913
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      7.691,
                      3.989
                    ]
                  ],
                  "v": [
                    [
                      9.883,
                      1.194
                    ],
                    [
                      3.669,
                      17.368
                    ],
                    [
                      -17.482,
                      17.484
                    ],
                    [
                      -17.482,
                      1.832
                    ],
                    [
                      -5.583,
                      -18.516
                    ],
                    [
                      8.122,
                      -17.994
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  203.79,
                  276.133
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 23",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 17,
      "ty": 4,
      "nm": "NoContent Outlines - Group 24",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            69.12,
            103.182,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            198.767,
            231.96,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0.305,
                      -15.489
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      0.564,
                      -12.67
                    ],
                    [
                      -0.565,
                      12.67
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  198.767,
                  231.96
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 24",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 18,
      "ty": 4,
      "nm": "NoContent Outlines - Group 25",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 100,
              "s": [
                -3
              ]
            },
            {
              "t": 202,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            129.664,
            92.071,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            259.311,
            220.849,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      -5.096,
                      -21.613
                    ],
                    [
                      3.96,
                      -1.438
                    ],
                    [
                      1.751,
                      4.445
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      2.591,
                      10.992
                    ],
                    [
                      -6.236,
                      2.263
                    ],
                    [
                      -4.739,
                      -12.025
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -1.796,
                      -31.178
                    ],
                    [
                      15.47,
                      8.973
                    ],
                    [
                      9.2,
                      28.915
                    ],
                    [
                      -1.452,
                      21.355
                    ],
                    [
                      -18.061,
                      -9.955
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  268.744,
                  240.964
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 25",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 19,
      "ty": 4,
      "nm": "NoContent Outlines - Group 20",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 100,
              "s": [
                11
              ]
            },
            {
              "t": 202,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 0,
              "s": [
                111.617,
                163.327,
                0
              ],
              "to": [
                -0.375,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 100,
              "s": [
                109.367,
                163.327,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                -0.375,
                0,
                0
              ]
            },
            {
              "t": 202,
              "s": [
                111.617,
                163.327,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            241.264,
            292.105,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      -14.308,
                      -2.984
                    ],
                    [
                      4.102,
                      -3.595
                    ],
                    [
                      0.857,
                      11.997
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      -7.826,
                      6.861
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      10.777,
                      -8.371
                    ],
                    [
                      7.867,
                      4.494
                    ],
                    [
                      -11.968,
                      -5.154
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.477999997606,
                  0.361000001197,
                  0.344999994016,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  241.904,
                  301.09
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 20",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 20,
      "ty": 4,
      "nm": "NoContent Outlines - Group 26",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            104.813,
            121.516,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            234.46,
            250.294,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -32.87
                          ],
                          [
                            15.059,
                            -10.208
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -3.537,
                            10.782
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -6.782,
                            4.598
                          ],
                          [
                            -11.908,
                            3.304
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -37.166,
                            -29.983
                          ],
                          [
                            -36.047,
                            -49.866
                          ],
                          [
                            31.531,
                            -46.996
                          ],
                          [
                            32.66,
                            -0.309
                          ],
                          [
                            22.107,
                            28.327
                          ],
                          [
                            16.161,
                            46.561
                          ],
                          [
                            -5.785,
                            47.561
                          ],
                          [
                            -5.785,
                            25.909
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 100,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -32.87
                          ],
                          [
                            15.059,
                            -10.208
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -2.716,
                            9.439
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -6.782,
                            4.598
                          ],
                          [
                            -11.162,
                            4.537
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -37.166,
                            -29.983
                          ],
                          [
                            -36.047,
                            -49.866
                          ],
                          [
                            31.531,
                            -46.996
                          ],
                          [
                            32.66,
                            -0.309
                          ],
                          [
                            22.107,
                            28.327
                          ],
                          [
                            12.661,
                            45.936
                          ],
                          [
                            -9.285,
                            46.936
                          ],
                          [
                            -5.785,
                            25.909
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "t": 202,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -32.87
                          ],
                          [
                            15.059,
                            -10.208
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -3.537,
                            10.782
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            -6.782,
                            4.598
                          ],
                          [
                            -11.908,
                            3.304
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -37.166,
                            -29.983
                          ],
                          [
                            -36.047,
                            -49.866
                          ],
                          [
                            31.531,
                            -46.996
                          ],
                          [
                            32.66,
                            -0.309
                          ],
                          [
                            22.107,
                            28.327
                          ],
                          [
                            16.161,
                            46.561
                          ],
                          [
                            -5.785,
                            47.561
                          ],
                          [
                            -5.785,
                            25.909
                          ]
                        ],
                        "c": false
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  236.397,
                  251.055
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 26",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 21,
      "ty": 4,
      "nm": "NoContent Outlines - Group 2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "t": 26,
              "s": [
                100
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            47.243,
            93.373,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            176.889,
            222.152,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      176.889,
                      225.739
                    ],
                    [
                      176.889,
                      218.565
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.819999964097,
                  0.827000038297,
                  0.830999995213,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 2",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 109,
                "s": [
                  0
                ]
              },
              {
                "t": 201,
                "s": [
                  100
                ]
              }
            ],
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 0,
                "s": [
                  0
                ]
              },
              {
                "t": 109,
                "s": [
                  100
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 22,
      "ty": 4,
      "nm": "NoContent Outlines - Group 3",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "t": 26,
              "s": [
                100
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            40.296,
            88.743,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            169.942,
            217.521,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      1.549,
                      0
                    ],
                    [
                      0,
                      1.549
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -1.549,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      1.549
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      -1.549
                    ],
                    [
                      -1.549,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      -1.549
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      1.549,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      2.087,
                      8.217
                    ],
                    [
                      2.087,
                      5.775
                    ],
                    [
                      -0.717,
                      2.971
                    ],
                    [
                      -3.521,
                      0.167
                    ],
                    [
                      -3.521,
                      0.07
                    ],
                    [
                      -0.717,
                      -2.735
                    ],
                    [
                      0.716,
                      -2.735
                    ],
                    [
                      3.521,
                      -5.539
                    ],
                    [
                      3.521,
                      -8.217
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.819999964097,
                  0.827000038297,
                  0.830999995213,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  169.942,
                  217.521
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 3",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 109,
                "s": [
                  0
                ]
              },
              {
                "t": 201,
                "s": [
                  100
                ]
              }
            ],
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 0,
                "s": [
                  0
                ]
              },
              {
                "t": 109,
                "s": [
                  100
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 23,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 25,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            174.914,
            238.557,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            200.268,
            244.778,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -1.557
                        ],
                        [
                          6.591,
                          0
                        ],
                        [
                          0,
                          1.556
                        ],
                        [
                          -6.591,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          1.556
                        ],
                        [
                          -6.591,
                          0
                        ],
                        [
                          0,
                          -1.557
                        ],
                        [
                          6.591,
                          0
                        ]
                      ],
                      "v": [
                        [
                          11.935,
                          0
                        ],
                        [
                          0,
                          2.818
                        ],
                        [
                          -11.935,
                          0
                        ],
                        [
                          0,
                          -2.818
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.96899998188,
                      0.579999983311,
                      0.11400000006,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      174.914,
                      231.383
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 4",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  200.268,
                  237.604
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  174.914,
                  231.383
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 4",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -6.592,
                          0
                        ],
                        [
                          0,
                          1.556
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          1.556
                        ],
                        [
                          6.591,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -11.935,
                          -13.583
                        ],
                        [
                          -11.935,
                          10.765
                        ],
                        [
                          0,
                          13.583
                        ],
                        [
                          11.935,
                          10.765
                        ],
                        [
                          11.935,
                          -13.583
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.98400002718,
                      0.689999997616,
                      0.250999987125,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      174.915,
                      244.966
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 5",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  200.268,
                  251.187
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  174.915,
                  244.966
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 5",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 24,
      "ty": 4,
      "nm": "NoContent Outlines - Group 27",
      "parent": 25,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            189.465,
            243.797,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            189.465,
            243.797,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      -1.199,
                      -6.08
                    ],
                    [
                      3.318,
                      -1.42
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      1.133,
                      5.75
                    ],
                    [
                      -3.318,
                      1.419
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -3.387,
                      -10.674
                    ],
                    [
                      3.708,
                      -5.496
                    ],
                    [
                      1.319,
                      10.157
                    ],
                    [
                      -4.842,
                      11.189
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  189.72,
                  243.539
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 27",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 25,
      "ty": 4,
      "nm": "NoContent Outlines - Group 28",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 0,
              "s": [
                76.906,
                92.925,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 100,
              "s": [
                76.906,
                95.425,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "t": 201,
              "s": [
                76.906,
                92.925,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            206.552,
            221.703,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      5.675,
                      -3.515
                    ],
                    [
                      5.772,
                      -2.853
                    ],
                    [
                      -9.098,
                      -4.989
                    ],
                    [
                      -6.687,
                      6.262
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      -3.669,
                      2.273
                    ],
                    [
                      -5.228,
                      2.585
                    ],
                    [
                      9.097,
                      4.989
                    ],
                    [
                      6.688,
                      -6.26
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      12.919,
                      -21.173
                    ],
                    [
                      2.494,
                      -3.95
                    ],
                    [
                      -16.093,
                      -1.604
                    ],
                    [
                      -15.603,
                      16.184
                    ],
                    [
                      14.981,
                      12.564
                    ],
                    [
                      24.701,
                      -4.751
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  188.339,
                  235.849
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 28",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 26,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            93.313,
            213.482,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            248.313,
            348.482,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          199.436,
                          342.261
                        ],
                        [
                          209.138,
                          342.261
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 32",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  229.641,
                  348.482
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  204.287,
                  342.261
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 32",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          274.348,
                          342.261
                        ],
                        [
                          284.939,
                          342.261
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 33",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  304.997,
                  348.482
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  279.643,
                  342.261
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 33",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          225.576,
                          342.261
                        ],
                        [
                          233.12,
                          342.261
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 34",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  254.701,
                  348.482
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  229.348,
                  342.261
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 34",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          160.98,
                          342.261
                        ],
                        [
                          174.464,
                          342.261
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 35",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  193.075,
                  348.482
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  167.721,
                  342.261
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 35",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 27,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            152.132,
            149.644,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            307.132,
            284.644,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                -2.731,
                                4.919
                              ],
                              [
                                5.142,
                                1.681
                              ],
                              [
                                4.221,
                                -3.239
                              ],
                              [
                                -2.44,
                                -4.07
                              ]
                            ],
                            "o": [
                              [
                                -2.399,
                                -5.241
                              ],
                              [
                                -3.609,
                                11.263
                              ],
                              [
                                4.414,
                                0.429
                              ],
                              [
                                6.833,
                                -5.607
                              ]
                            ],
                            "v": [
                              [
                                12.219,
                                -3.35
                              ],
                              [
                                0.432,
                                -14.214
                              ],
                              [
                                -12.219,
                                6.342
                              ],
                              [
                                -2.035,
                                14.214
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 100,
                        "s": [
                          {
                            "i": [
                              [
                                -2.731,
                                4.919
                              ],
                              [
                                5.142,
                                1.681
                              ],
                              [
                                4.221,
                                -3.239
                              ],
                              [
                                -2.44,
                                -4.07
                              ]
                            ],
                            "o": [
                              [
                                -2.399,
                                -5.241
                              ],
                              [
                                -3.609,
                                11.263
                              ],
                              [
                                4.414,
                                0.429
                              ],
                              [
                                6.833,
                                -5.607
                              ]
                            ],
                            "v": [
                              [
                                11.594,
                                -1.85
                              ],
                              [
                                -0.193,
                                -12.714
                              ],
                              [
                                -12.219,
                                6.342
                              ],
                              [
                                -2.035,
                                14.214
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 201,
                        "s": [
                          {
                            "i": [
                              [
                                -2.731,
                                4.919
                              ],
                              [
                                5.142,
                                1.681
                              ],
                              [
                                4.221,
                                -3.239
                              ],
                              [
                                -2.44,
                                -4.07
                              ]
                            ],
                            "o": [
                              [
                                -2.399,
                                -5.241
                              ],
                              [
                                -3.609,
                                11.263
                              ],
                              [
                                4.414,
                                0.429
                              ],
                              [
                                6.833,
                                -5.607
                              ]
                            ],
                            "v": [
                              [
                                12.219,
                                -3.35
                              ],
                              [
                                0.432,
                                -14.214
                              ],
                              [
                                -12.219,
                                6.342
                              ],
                              [
                                -2.035,
                                14.214
                              ]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      291.361,
                      272.696
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 36",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  316.715,
                  278.918
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  291.361,
                  272.696
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 36",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                6.833,
                                -5.607
                              ],
                              [
                                -0.86,
                                -3.203
                              ],
                              [
                                -1.032,
                                1.255
                              ],
                              [
                                3.741,
                                8.17
                              ]
                            ],
                            "o": [
                              [
                                2.435,
                                4.062
                              ],
                              [
                                0.422,
                                1.569
                              ],
                              [
                                8.92,
                                -10.846
                              ],
                              [
                                -2.732,
                                4.918
                              ]
                            ],
                            "v": [
                              [
                                -8.997,
                                1.767
                              ],
                              [
                                -4.153,
                                13.782
                              ],
                              [
                                -0.677,
                                14.541
                              ],
                              [
                                5.257,
                                -15.796
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 100,
                        "s": [
                          {
                            "i": [
                              [
                                6.833,
                                -5.607
                              ],
                              [
                                -0.86,
                                -3.203
                              ],
                              [
                                -1.17,
                                1.127
                              ],
                              [
                                3.741,
                                8.17
                              ]
                            ],
                            "o": [
                              [
                                2.435,
                                4.062
                              ],
                              [
                                0.422,
                                1.569
                              ],
                              [
                                9.249,
                                -8.906
                              ],
                              [
                                -2.732,
                                4.918
                              ]
                            ],
                            "v": [
                              [
                                -8.997,
                                1.767
                              ],
                              [
                                -5.403,
                                13.532
                              ],
                              [
                                -1.927,
                                14.291
                              ],
                              [
                                4.632,
                                -14.296
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 201,
                        "s": [
                          {
                            "i": [
                              [
                                6.833,
                                -5.607
                              ],
                              [
                                -0.86,
                                -3.203
                              ],
                              [
                                -1.032,
                                1.255
                              ],
                              [
                                3.741,
                                8.17
                              ]
                            ],
                            "o": [
                              [
                                2.435,
                                4.062
                              ],
                              [
                                0.422,
                                1.569
                              ],
                              [
                                8.92,
                                -10.846
                              ],
                              [
                                -2.732,
                                4.918
                              ]
                            ],
                            "v": [
                              [
                                -8.997,
                                1.767
                              ],
                              [
                                -4.153,
                                13.782
                              ],
                              [
                                -0.677,
                                14.541
                              ],
                              [
                                5.257,
                                -15.796
                              ]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      298.324,
                      285.143
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 37",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  322.868,
                  291.101
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  297.514,
                  284.879
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 37",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                -3.609,
                                11.264
                              ],
                              [
                                1.588,
                                0.09
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -15.361,
                                1.541
                              ],
                              [
                                -0.642,
                                -0.063
                              ]
                            ],
                            "o": [
                              [
                                -1.467,
                                -0.479
                              ],
                              [
                                -7.109,
                                -0.402
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0.689,
                                -0.07
                              ],
                              [
                                4.221,
                                -3.24
                              ]
                            ],
                            "v": [
                              [
                                16.969,
                                -10.026
                              ],
                              [
                                12.379,
                                -10.9
                              ],
                              [
                                -7.013,
                                -12.074
                              ],
                              [
                                -16.969,
                                7.393
                              ],
                              [
                                2.325,
                                10.532
                              ],
                              [
                                4.318,
                                10.531
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 100,
                        "s": [
                          {
                            "i": [
                              [
                                -3.609,
                                11.264
                              ],
                              [
                                1.588,
                                0.09
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -15.361,
                                1.541
                              ],
                              [
                                -0.642,
                                -0.063
                              ]
                            ],
                            "o": [
                              [
                                -1.467,
                                -0.479
                              ],
                              [
                                -7.109,
                                -0.402
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0.689,
                                -0.07
                              ],
                              [
                                4.221,
                                -3.24
                              ]
                            ],
                            "v": [
                              [
                                16.344,
                                -8.526
                              ],
                              [
                                11.754,
                                -9.4
                              ],
                              [
                                -7.013,
                                -12.074
                              ],
                              [
                                -16.969,
                                7.393
                              ],
                              [
                                2.325,
                                10.532
                              ],
                              [
                                4.318,
                                10.531
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 201,
                        "s": [
                          {
                            "i": [
                              [
                                -3.609,
                                11.264
                              ],
                              [
                                1.588,
                                0.09
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -15.361,
                                1.541
                              ],
                              [
                                -0.642,
                                -0.063
                              ]
                            ],
                            "o": [
                              [
                                -1.467,
                                -0.479
                              ],
                              [
                                -7.109,
                                -0.402
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0.689,
                                -0.07
                              ],
                              [
                                4.221,
                                -3.24
                              ]
                            ],
                            "v": [
                              [
                                16.969,
                                -10.026
                              ],
                              [
                                12.379,
                                -10.9
                              ],
                              [
                                -7.013,
                                -12.074
                              ],
                              [
                                -16.969,
                                7.393
                              ],
                              [
                                2.325,
                                10.532
                              ],
                              [
                                4.318,
                                10.531
                              ]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      274.824,
                      268.508
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 38",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  300.178,
                  274.115
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  274.824,
                  267.893
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 38",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 28,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            99.822,
            144.482,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            254.822,
            279.482,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          4.79,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          -4.791
                        ],
                        [
                          -4.79,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          4.79
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          -4.79,
                          0
                        ],
                        [
                          0,
                          4.79
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          4.79,
                          0
                        ],
                        [
                          0,
                          -4.791
                        ]
                      ],
                      "v": [
                        [
                          38.254,
                          -8.674
                        ],
                        [
                          -38.253,
                          -8.674
                        ],
                        [
                          -46.927,
                          0
                        ],
                        [
                          -38.253,
                          8.674
                        ],
                        [
                          38.254,
                          8.674
                        ],
                        [
                          46.927,
                          0
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.152999997139,
                      0.666999995708,
                      0.882000029087,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      233.806,
                      212.935
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 39",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  259.16,
                  219.156
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  233.806,
                  212.935
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 39",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ty": "gr",
                      "it": [
                        {
                          "ind": 0,
                          "ty": "sh",
                          "ix": 1,
                          "ks": {
                            "a": 0,
                            "k": {
                              "i": [
                                [
                                  1.657,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "o": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  1.656
                                ]
                              ],
                              "v": [
                                [
                                  0.391,
                                  21.22
                                ],
                                [
                                  -3.392,
                                  21.22
                                ],
                                [
                                  -3.392,
                                  -21.22
                                ],
                                [
                                  3.391,
                                  -21.22
                                ],
                                [
                                  3.391,
                                  18.22
                                ]
                              ],
                              "c": true
                            },
                            "ix": 2
                          },
                          "nm": "Path 1",
                          "mn": "ADBE Vector Shape - Group",
                          "hd": false
                        },
                        {
                          "ty": "st",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.109999999404,
                              0.109999999404,
                              1
                            ],
                            "ix": 3
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 4
                          },
                          "w": {
                            "a": 0,
                            "k": 2,
                            "ix": 5
                          },
                          "lc": 2,
                          "lj": 2,
                          "bm": 0,
                          "nm": "Stroke 1",
                          "mn": "ADBE Vector Graphic - Stroke",
                          "hd": false
                        },
                        {
                          "ty": "fl",
                          "c": {
                            "a": 0,
                            "k": [
                              0.152999997139,
                              0.666999995708,
                              0.882000029087,
                              1
                            ],
                            "ix": 4
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 5
                          },
                          "r": 1,
                          "bm": 0,
                          "nm": "Fill 1",
                          "mn": "ADBE Vector Graphic - Fill",
                          "hd": false
                        },
                        {
                          "ty": "tr",
                          "p": {
                            "a": 0,
                            "k": [
                              215.112,
                              321.041
                            ],
                            "ix": 2
                          },
                          "a": {
                            "a": 0,
                            "k": [
                              0,
                              0
                            ],
                            "ix": 1
                          },
                          "s": {
                            "a": 0,
                            "k": [
                              100,
                              100
                            ],
                            "ix": 3
                          },
                          "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 6
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 7
                          },
                          "sk": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                          },
                          "sa": {
                            "a": 0,
                            "k": 0,
                            "ix": 5
                          },
                          "nm": "Transform"
                        }
                      ],
                      "nm": "Group 40",
                      "np": 3,
                      "cix": 2,
                      "bm": 0,
                      "ix": 1,
                      "mn": "ADBE Vector Group",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          240.466,
                          327.262
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          215.112,
                          321.041
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "NoContent Outlines - Group 40",
                  "np": 1,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ty": "gr",
                      "it": [
                        {
                          "ind": 0,
                          "ty": "sh",
                          "ix": 1,
                          "ks": {
                            "a": 0,
                            "k": {
                              "i": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  1.656
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "o": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  -1.657,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "v": [
                                [
                                  3.391,
                                  21.22
                                ],
                                [
                                  -0.392,
                                  21.22
                                ],
                                [
                                  -3.392,
                                  18.22
                                ],
                                [
                                  -3.392,
                                  -21.22
                                ],
                                [
                                  3.391,
                                  -21.22
                                ]
                              ],
                              "c": true
                            },
                            "ix": 2
                          },
                          "nm": "Path 1",
                          "mn": "ADBE Vector Shape - Group",
                          "hd": false
                        },
                        {
                          "ty": "st",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.109999999404,
                              0.109999999404,
                              1
                            ],
                            "ix": 3
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 4
                          },
                          "w": {
                            "a": 0,
                            "k": 2,
                            "ix": 5
                          },
                          "lc": 2,
                          "lj": 2,
                          "bm": 0,
                          "nm": "Stroke 1",
                          "mn": "ADBE Vector Graphic - Stroke",
                          "hd": false
                        },
                        {
                          "ty": "fl",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.458999991417,
                              0.736999988556,
                              1
                            ],
                            "ix": 4
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 5
                          },
                          "r": 1,
                          "bm": 0,
                          "nm": "Fill 1",
                          "mn": "ADBE Vector Graphic - Fill",
                          "hd": false
                        },
                        {
                          "ty": "tr",
                          "p": {
                            "a": 0,
                            "k": [
                              210.073,
                              321.041
                            ],
                            "ix": 2
                          },
                          "a": {
                            "a": 0,
                            "k": [
                              0,
                              0
                            ],
                            "ix": 1
                          },
                          "s": {
                            "a": 0,
                            "k": [
                              100,
                              100
                            ],
                            "ix": 3
                          },
                          "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 6
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 7
                          },
                          "sk": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                          },
                          "sa": {
                            "a": 0,
                            "k": 0,
                            "ix": 5
                          },
                          "nm": "Transform"
                        }
                      ],
                      "nm": "Group 41",
                      "np": 3,
                      "cix": 2,
                      "bm": 0,
                      "ix": 1,
                      "mn": "ADBE Vector Group",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          235.427,
                          327.262
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          210.073,
                          321.041
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "NoContent Outlines - Group 41",
                  "np": 1,
                  "cix": 2,
                  "bm": 0,
                  "ix": 2,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      237.946,
                      327.262
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      237.946,
                      327.262
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Merged Shape Layer",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ty": "gr",
                      "it": [
                        {
                          "ind": 0,
                          "ty": "sh",
                          "ix": 1,
                          "ks": {
                            "a": 0,
                            "k": {
                              "i": [
                                [
                                  1.657,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "o": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  1.656
                                ]
                              ],
                              "v": [
                                [
                                  0.392,
                                  21.22
                                ],
                                [
                                  -3.392,
                                  21.22
                                ],
                                [
                                  -3.392,
                                  -21.22
                                ],
                                [
                                  3.392,
                                  -21.22
                                ],
                                [
                                  3.392,
                                  18.22
                                ]
                              ],
                              "c": true
                            },
                            "ix": 2
                          },
                          "nm": "Path 1",
                          "mn": "ADBE Vector Shape - Group",
                          "hd": false
                        },
                        {
                          "ty": "st",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.109999999404,
                              0.109999999404,
                              1
                            ],
                            "ix": 3
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 4
                          },
                          "w": {
                            "a": 0,
                            "k": 2,
                            "ix": 5
                          },
                          "lc": 2,
                          "lj": 2,
                          "bm": 0,
                          "nm": "Stroke 1",
                          "mn": "ADBE Vector Graphic - Stroke",
                          "hd": false
                        },
                        {
                          "ty": "fl",
                          "c": {
                            "a": 0,
                            "k": [
                              0.152999997139,
                              0.666999995708,
                              0.882000029087,
                              1
                            ],
                            "ix": 4
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 5
                          },
                          "r": 1,
                          "bm": 0,
                          "nm": "Fill 1",
                          "mn": "ADBE Vector Graphic - Fill",
                          "hd": false
                        },
                        {
                          "ty": "tr",
                          "p": {
                            "a": 0,
                            "k": [
                              273.956,
                              321.041
                            ],
                            "ix": 2
                          },
                          "a": {
                            "a": 0,
                            "k": [
                              0,
                              0
                            ],
                            "ix": 1
                          },
                          "s": {
                            "a": 0,
                            "k": [
                              100,
                              100
                            ],
                            "ix": 3
                          },
                          "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 6
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 7
                          },
                          "sk": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                          },
                          "sa": {
                            "a": 0,
                            "k": 0,
                            "ix": 5
                          },
                          "nm": "Transform"
                        }
                      ],
                      "nm": "Group 42",
                      "np": 3,
                      "cix": 2,
                      "bm": 0,
                      "ix": 1,
                      "mn": "ADBE Vector Group",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          299.31,
                          327.262
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          273.956,
                          321.041
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "NoContent Outlines - Group 42",
                  "np": 1,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ty": "gr",
                      "it": [
                        {
                          "ind": 0,
                          "ty": "sh",
                          "ix": 1,
                          "ks": {
                            "a": 0,
                            "k": {
                              "i": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  1.656
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "o": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  -1.657,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "v": [
                                [
                                  3.392,
                                  21.22
                                ],
                                [
                                  -0.392,
                                  21.22
                                ],
                                [
                                  -3.392,
                                  18.22
                                ],
                                [
                                  -3.392,
                                  -21.22
                                ],
                                [
                                  3.392,
                                  -21.22
                                ]
                              ],
                              "c": true
                            },
                            "ix": 2
                          },
                          "nm": "Path 1",
                          "mn": "ADBE Vector Shape - Group",
                          "hd": false
                        },
                        {
                          "ty": "st",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.109999999404,
                              0.109999999404,
                              1
                            ],
                            "ix": 3
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 4
                          },
                          "w": {
                            "a": 0,
                            "k": 2,
                            "ix": 5
                          },
                          "lc": 2,
                          "lj": 2,
                          "bm": 0,
                          "nm": "Stroke 1",
                          "mn": "ADBE Vector Graphic - Stroke",
                          "hd": false
                        },
                        {
                          "ty": "fl",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.458999991417,
                              0.736999988556,
                              1
                            ],
                            "ix": 4
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 5
                          },
                          "r": 1,
                          "bm": 0,
                          "nm": "Fill 1",
                          "mn": "ADBE Vector Graphic - Fill",
                          "hd": false
                        },
                        {
                          "ty": "tr",
                          "p": {
                            "a": 0,
                            "k": [
                              268.918,
                              321.041
                            ],
                            "ix": 2
                          },
                          "a": {
                            "a": 0,
                            "k": [
                              0,
                              0
                            ],
                            "ix": 1
                          },
                          "s": {
                            "a": 0,
                            "k": [
                              100,
                              100
                            ],
                            "ix": 3
                          },
                          "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 6
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 7
                          },
                          "sk": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                          },
                          "sa": {
                            "a": 0,
                            "k": 0,
                            "ix": 5
                          },
                          "nm": "Transform"
                        }
                      ],
                      "nm": "Group 43",
                      "np": 3,
                      "cix": 2,
                      "bm": 0,
                      "ix": 1,
                      "mn": "ADBE Vector Group",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          294.272,
                          327.262
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          268.918,
                          321.041
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "NoContent Outlines - Group 43",
                  "np": 1,
                  "cix": 2,
                  "bm": 0,
                  "ix": 2,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      296.791,
                      327.262
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      296.791,
                      327.262
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Merged Shape Layer",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ty": "gr",
                      "it": [
                        {
                          "ind": 0,
                          "ty": "sh",
                          "ix": 1,
                          "ks": {
                            "a": 0,
                            "k": {
                              "i": [
                                [
                                  1.657,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "o": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  1.656
                                ]
                              ],
                              "v": [
                                [
                                  0.392,
                                  21.22
                                ],
                                [
                                  -3.391,
                                  21.22
                                ],
                                [
                                  -3.391,
                                  -21.22
                                ],
                                [
                                  3.392,
                                  -21.22
                                ],
                                [
                                  3.392,
                                  18.22
                                ]
                              ],
                              "c": true
                            },
                            "ix": 2
                          },
                          "nm": "Path 1",
                          "mn": "ADBE Vector Shape - Group",
                          "hd": false
                        },
                        {
                          "ty": "st",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.109999999404,
                              0.109999999404,
                              1
                            ],
                            "ix": 3
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 4
                          },
                          "w": {
                            "a": 0,
                            "k": 2,
                            "ix": 5
                          },
                          "lc": 2,
                          "lj": 2,
                          "bm": 0,
                          "nm": "Stroke 1",
                          "mn": "ADBE Vector Graphic - Stroke",
                          "hd": false
                        },
                        {
                          "ty": "fl",
                          "c": {
                            "a": 0,
                            "k": [
                              0.152999997139,
                              0.666999995708,
                              0.882000029087,
                              1
                            ],
                            "ix": 4
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 5
                          },
                          "r": 1,
                          "bm": 0,
                          "nm": "Fill 1",
                          "mn": "ADBE Vector Graphic - Fill",
                          "hd": false
                        },
                        {
                          "ty": "tr",
                          "p": {
                            "a": 0,
                            "k": [
                              250.497,
                              321.041
                            ],
                            "ix": 2
                          },
                          "a": {
                            "a": 0,
                            "k": [
                              0,
                              0
                            ],
                            "ix": 1
                          },
                          "s": {
                            "a": 0,
                            "k": [
                              100,
                              100
                            ],
                            "ix": 3
                          },
                          "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 6
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 7
                          },
                          "sk": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                          },
                          "sa": {
                            "a": 0,
                            "k": 0,
                            "ix": 5
                          },
                          "nm": "Transform"
                        }
                      ],
                      "nm": "Group 44",
                      "np": 3,
                      "cix": 2,
                      "bm": 0,
                      "ix": 1,
                      "mn": "ADBE Vector Group",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          275.851,
                          327.262
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          250.497,
                          321.041
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "NoContent Outlines - Group 44",
                  "np": 1,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ty": "gr",
                      "it": [
                        {
                          "ind": 0,
                          "ty": "sh",
                          "ix": 1,
                          "ks": {
                            "a": 0,
                            "k": {
                              "i": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  1.656
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "o": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  -1.657,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "v": [
                                [
                                  3.391,
                                  21.22
                                ],
                                [
                                  -0.392,
                                  21.22
                                ],
                                [
                                  -3.392,
                                  18.22
                                ],
                                [
                                  -3.392,
                                  -21.22
                                ],
                                [
                                  3.391,
                                  -21.22
                                ]
                              ],
                              "c": true
                            },
                            "ix": 2
                          },
                          "nm": "Path 1",
                          "mn": "ADBE Vector Shape - Group",
                          "hd": false
                        },
                        {
                          "ty": "st",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.109999999404,
                              0.109999999404,
                              1
                            ],
                            "ix": 3
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 4
                          },
                          "w": {
                            "a": 0,
                            "k": 2,
                            "ix": 5
                          },
                          "lc": 2,
                          "lj": 2,
                          "bm": 0,
                          "nm": "Stroke 1",
                          "mn": "ADBE Vector Graphic - Stroke",
                          "hd": false
                        },
                        {
                          "ty": "fl",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.458999991417,
                              0.736999988556,
                              1
                            ],
                            "ix": 4
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 5
                          },
                          "r": 1,
                          "bm": 0,
                          "nm": "Fill 1",
                          "mn": "ADBE Vector Graphic - Fill",
                          "hd": false
                        },
                        {
                          "ty": "tr",
                          "p": {
                            "a": 0,
                            "k": [
                              245.459,
                              321.041
                            ],
                            "ix": 2
                          },
                          "a": {
                            "a": 0,
                            "k": [
                              0,
                              0
                            ],
                            "ix": 1
                          },
                          "s": {
                            "a": 0,
                            "k": [
                              100,
                              100
                            ],
                            "ix": 3
                          },
                          "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 6
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 7
                          },
                          "sk": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                          },
                          "sa": {
                            "a": 0,
                            "k": 0,
                            "ix": 5
                          },
                          "nm": "Transform"
                        }
                      ],
                      "nm": "Group 45",
                      "np": 3,
                      "cix": 2,
                      "bm": 0,
                      "ix": 1,
                      "mn": "ADBE Vector Group",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          270.813,
                          327.262
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          245.459,
                          321.041
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "NoContent Outlines - Group 45",
                  "np": 1,
                  "cix": 2,
                  "bm": 0,
                  "ix": 2,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      273.332,
                      327.262
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      273.332,
                      327.262
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Merged Shape Layer",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 3,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ty": "gr",
                      "it": [
                        {
                          "ind": 0,
                          "ty": "sh",
                          "ix": 1,
                          "ks": {
                            "a": 0,
                            "k": {
                              "i": [
                                [
                                  1.657,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "o": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  1.656
                                ]
                              ],
                              "v": [
                                [
                                  0.392,
                                  21.22
                                ],
                                [
                                  -3.392,
                                  21.22
                                ],
                                [
                                  -3.392,
                                  -21.22
                                ],
                                [
                                  3.392,
                                  -21.22
                                ],
                                [
                                  3.392,
                                  18.22
                                ]
                              ],
                              "c": true
                            },
                            "ix": 2
                          },
                          "nm": "Path 1",
                          "mn": "ADBE Vector Shape - Group",
                          "hd": false
                        },
                        {
                          "ty": "st",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.109999999404,
                              0.109999999404,
                              1
                            ],
                            "ix": 3
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 4
                          },
                          "w": {
                            "a": 0,
                            "k": 2,
                            "ix": 5
                          },
                          "lc": 2,
                          "lj": 2,
                          "bm": 0,
                          "nm": "Stroke 1",
                          "mn": "ADBE Vector Graphic - Stroke",
                          "hd": false
                        },
                        {
                          "ty": "fl",
                          "c": {
                            "a": 0,
                            "k": [
                              0.152999997139,
                              0.666999995708,
                              0.882000029087,
                              1
                            ],
                            "ix": 4
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 5
                          },
                          "r": 1,
                          "bm": 0,
                          "nm": "Fill 1",
                          "mn": "ADBE Vector Graphic - Fill",
                          "hd": false
                        },
                        {
                          "ty": "tr",
                          "p": {
                            "a": 0,
                            "k": [
                              190.028,
                              321.041
                            ],
                            "ix": 2
                          },
                          "a": {
                            "a": 0,
                            "k": [
                              0,
                              0
                            ],
                            "ix": 1
                          },
                          "s": {
                            "a": 0,
                            "k": [
                              100,
                              100
                            ],
                            "ix": 3
                          },
                          "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 6
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 7
                          },
                          "sk": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                          },
                          "sa": {
                            "a": 0,
                            "k": 0,
                            "ix": 5
                          },
                          "nm": "Transform"
                        }
                      ],
                      "nm": "Group 46",
                      "np": 3,
                      "cix": 2,
                      "bm": 0,
                      "ix": 1,
                      "mn": "ADBE Vector Group",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          215.382,
                          327.262
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          190.028,
                          321.041
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "NoContent Outlines - Group 46",
                  "np": 1,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ty": "gr",
                      "it": [
                        {
                          "ind": 0,
                          "ty": "sh",
                          "ix": 1,
                          "ks": {
                            "a": 0,
                            "k": {
                              "i": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  1.656
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "o": [
                                [
                                  0,
                                  0
                                ],
                                [
                                  -1.657,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ],
                                [
                                  0,
                                  0
                                ]
                              ],
                              "v": [
                                [
                                  3.392,
                                  21.22
                                ],
                                [
                                  -0.391,
                                  21.22
                                ],
                                [
                                  -3.391,
                                  18.22
                                ],
                                [
                                  -3.391,
                                  -21.22
                                ],
                                [
                                  3.392,
                                  -21.22
                                ]
                              ],
                              "c": true
                            },
                            "ix": 2
                          },
                          "nm": "Path 1",
                          "mn": "ADBE Vector Shape - Group",
                          "hd": false
                        },
                        {
                          "ty": "st",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.109999999404,
                              0.109999999404,
                              1
                            ],
                            "ix": 3
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 4
                          },
                          "w": {
                            "a": 0,
                            "k": 2,
                            "ix": 5
                          },
                          "lc": 2,
                          "lj": 2,
                          "bm": 0,
                          "nm": "Stroke 1",
                          "mn": "ADBE Vector Graphic - Stroke",
                          "hd": false
                        },
                        {
                          "ty": "fl",
                          "c": {
                            "a": 0,
                            "k": [
                              0.109999999404,
                              0.458999991417,
                              0.736999988556,
                              1
                            ],
                            "ix": 4
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 5
                          },
                          "r": 1,
                          "bm": 0,
                          "nm": "Fill 1",
                          "mn": "ADBE Vector Graphic - Fill",
                          "hd": false
                        },
                        {
                          "ty": "tr",
                          "p": {
                            "a": 0,
                            "k": [
                              184.99,
                              321.041
                            ],
                            "ix": 2
                          },
                          "a": {
                            "a": 0,
                            "k": [
                              0,
                              0
                            ],
                            "ix": 1
                          },
                          "s": {
                            "a": 0,
                            "k": [
                              100,
                              100
                            ],
                            "ix": 3
                          },
                          "r": {
                            "a": 0,
                            "k": 0,
                            "ix": 6
                          },
                          "o": {
                            "a": 0,
                            "k": 100,
                            "ix": 7
                          },
                          "sk": {
                            "a": 0,
                            "k": 0,
                            "ix": 4
                          },
                          "sa": {
                            "a": 0,
                            "k": 0,
                            "ix": 5
                          },
                          "nm": "Transform"
                        }
                      ],
                      "nm": "Group 47",
                      "np": 3,
                      "cix": 2,
                      "bm": 0,
                      "ix": 1,
                      "mn": "ADBE Vector Group",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          210.344,
                          327.262
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          184.99,
                          321.041
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "NoContent Outlines - Group 47",
                  "np": 1,
                  "cix": 2,
                  "bm": 0,
                  "ix": 2,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      212.863,
                      327.262
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      212.863,
                      327.262
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Merged Shape Layer",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 4,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              2.762
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -2.762,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "o": [
                            [
                              0,
                              0
                            ],
                            [
                              -2.762,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -2.762
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              34.823,
                              10.557
                            ],
                            [
                              -29.823,
                              10.557
                            ],
                            [
                              -34.823,
                              5.557
                            ],
                            [
                              -34.823,
                              -5.556
                            ],
                            [
                              -29.823,
                              -10.556
                            ],
                            [
                              34.823,
                              -10.556
                            ]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.109999999404,
                          0.109999999404,
                          1
                        ],
                        "ix": 3
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 4
                      },
                      "w": {
                        "a": 0,
                        "k": 2,
                        "ix": 5
                      },
                      "lc": 2,
                      "lj": 2,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.458999991417,
                          0.736999988556,
                          1
                        ],
                        "ix": 4
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 5
                      },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          213.026,
                          289.264
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          0,
                          0
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 48",
                  "np": 3,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      238.38,
                      295.485
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      213.026,
                      289.264
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "NoContent Outlines - Group 48",
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 5,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [
                              2.762,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -2.762
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "o": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              2.762,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              2.762
                            ]
                          ],
                          "v": [
                            [
                              29.823,
                              10.557
                            ],
                            [
                              -34.823,
                              10.557
                            ],
                            [
                              -34.823,
                              -10.556
                            ],
                            [
                              29.823,
                              -10.556
                            ],
                            [
                              34.823,
                              -5.556
                            ],
                            [
                              34.823,
                              5.557
                            ]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.109999999404,
                          0.109999999404,
                          1
                        ],
                        "ix": 3
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 4
                      },
                      "w": {
                        "a": 0,
                        "k": 2,
                        "ix": 5
                      },
                      "lc": 2,
                      "lj": 2,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.152999997139,
                          0.666999995708,
                          0.882000029087,
                          1
                        ],
                        "ix": 4
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 5
                      },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          245.545,
                          289.264
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          0,
                          0
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 49",
                  "np": 3,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      270.899,
                      295.485
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      245.545,
                      289.264
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "NoContent Outlines - Group 49",
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 6,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  254.639,
                  316.706
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  254.639,
                  316.706
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Merged Shape Layer",
          "np": 6,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.775,
                          -28.716
                        ],
                        [
                          4.775,
                          -28.716
                        ],
                        [
                          4.775,
                          28.716
                        ],
                        [
                          -4.775,
                          28.716
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.152999997139,
                      0.666999995708,
                      0.882000029087,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      197.654,
                      250.324
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 50",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  223.007,
                  256.546
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  197.654,
                  250.324
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 50",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.776,
                          -28.716
                        ],
                        [
                          4.775,
                          -28.716
                        ],
                        [
                          4.775,
                          28.716
                        ],
                        [
                          -4.776,
                          28.716
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.152999997139,
                      0.666999995708,
                      0.882000029087,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      270.565,
                      250.324
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 51",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  295.918,
                  256.546
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  270.565,
                  250.324
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "NoContent Outlines - Group 51",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;