"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var bezierPathFunctions_1 = require("./bezierPathFunctions");
var straightPathFunctions_1 = require("./straightPathFunctions");
var smoothStepPathFunctions_1 = require("./smoothStepPathFunctions");
var reactFlow_1 = require("@customTypes/reactFlow");
var pathFunctions_1 = require("./pathFunctions");
var customPathConverter = function (path, statePathKnots, oldType, newType) {
    if (statePathKnots.length === 0) {
        return [];
    }
    var knots = oldType === reactFlow_1.EdgeVariants.CUSTOM
        ? (0, bezierPathFunctions_1.getBezierPathKnotPositions)(path)
        : oldType === reactFlow_1.EdgeVariants.STRAIGHT
            ? (0, straightPathFunctions_1.getStraightPathKnotPositions)(path)
            : (0, smoothStepPathFunctions_1.getSmoothStepPathKnotPositions)(path);
    if (newType === reactFlow_1.EdgeVariants.CUSTOM || newType === reactFlow_1.EdgeVariants.STRAIGHT) {
        return (0, pathFunctions_1.getRelativeKnots)(knots);
    }
    return [];
};
exports.default = customPathConverter;
