"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var material_1 = require("@mui/material");
var appStyles_jss_1 = require("@templates/appStyles-jss");
var drawerWidth = 240;
var drawerBigWidth = 280;
exports.default = (0, mui_1.makeStyles)()(function (theme, _params, classes) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
    return ({
        appBar: (_a = {
                background: "rgba(0,0,0,0)",
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(["width", "margin", "background"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                })
            },
            _a["& .".concat(classes.menuButton)] = {
                backgroundColor: "transparent",
                boxShadow: "none",
                zIndex: 10
            },
            _a["&.".concat(classes.left)] = (_b = {},
                _b["& .".concat(classes.menuButton)] = {
                    marginLeft: 13
                },
                _b["& .".concat(classes.headerTitle)] = {
                    left: theme.spacing(2)
                },
                _b),
            _a["&.".concat(classes.leftBig)] = (_c = {},
                _c["& .".concat(classes.menuButton)] = (_d = {
                        marginLeft: 30,
                        marginRight: theme.spacing(2)
                    },
                    _d[theme.breakpoints.down("lg")] = {
                        marginLeft: 13
                    },
                    _d),
                _c["& .".concat(classes.headerTitle)] = {
                    left: 0
                },
                _c),
            _a["&.".concat(classes.right)] = (_e = {},
                _e["& .".concat(classes.menuButton)] = {
                    marginRight: 13
                },
                _e["& .".concat(classes.headerTitle)] = {
                    right: theme.spacing(2)
                },
                _e["& > div"] = {
                    flexDirection: "row-reverse"
                },
                _e["& .".concat(classes.flex)] = {
                    textAlign: "left"
                },
                _e),
            _a),
        attachedbar: (_f = {
                position: "relative"
            },
            _f["& .".concat(classes.menuButton)] = {
                margin: "0 ".concat(theme.spacing(2))
            },
            _f["& .".concat(classes.wrapper)] = (_g = {},
                _g[theme.breakpoints.down("lg")] = {
                    border: "1px solid ".concat(theme.palette.divider)
                },
                _g),
            _f),
        floatingBar: {
            position: "fixed"
        },
        appMenu: (_h = {
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "row",
                background: theme.palette.background.paper,
                boxShadow: theme.shadows[3]
            },
            _h[theme.breakpoints.down("lg")] = {
                padding: "".concat(theme.spacing(0.5), " 0")
            },
            _h[theme.breakpoints.up("lg")] = {
                background: (0, material_1.alpha)(theme.palette.background.paper, 0.8)
            },
            _h.color = theme.palette.text.primary,
            _h),
        flex: {
            flex: 1,
            textAlign: "right"
        },
        flexDefault: {
            flex: 1,
            textAlign: "right"
        },
        left: {},
        leftBig: {},
        right: {},
        appBarShift: (_j = {
                transition: theme.transitions.create(["width", "margin", "background"], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen
                })
            },
            _j["&.".concat(classes.left)] = (_k = {},
                _k["& .".concat(classes.menuButton)] = (_l = {},
                    _l[theme.breakpoints.up("lg")] = {
                        marginLeft: -20
                    },
                    _l),
                _k[theme.breakpoints.up("lg")] = {
                    marginLeft: drawerWidth,
                    width: "calc(100% - ".concat(drawerWidth, "px)")
                },
                _k),
            _j["&.".concat(classes.leftBig)] = (_m = {},
                _m["& .".concat(classes.menuButton)] = (_o = {},
                    _o[theme.breakpoints.up("lg")] = {
                        marginLeft: -20
                    },
                    _o),
                _m[theme.breakpoints.up("lg")] = {
                    marginLeft: drawerBigWidth,
                    width: "calc(100% - ".concat(drawerBigWidth, "px)")
                },
                _m),
            _j["&.".concat(classes.right)] = (_p = {},
                _p["& .".concat(classes.menuButton)] = (_q = {},
                    _q[theme.breakpoints.up("lg")] = {
                        marginRight: -20
                    },
                    _q),
                _p[theme.breakpoints.up("lg")] = {
                    marginRight: drawerWidth,
                    width: "calc(100% - ".concat(drawerWidth, "px)")
                },
                _p),
            _j["& .".concat(classes.menuButton)] = {
                backgroundColor: theme.palette.primary.main
            },
            _j["& .".concat(classes.headerAction)] = {
                marginLeft: theme.spacing(5)
            },
            _j),
        menuButton: {},
        hide: {
            display: "none"
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200
        },
        container: {
            display: "flex",
            flexWrap: "wrap"
        },
        dark: {},
        light: {},
        wrapper: (_r = {
                fontFamily: theme.typography.fontFamily,
                position: "relative",
                marginRight: theme.spacing(2),
                marginLeft: theme.spacing(1),
                borderRadius: 22,
                boxShadow: "0 0 1px ".concat(theme.palette.primary.main),
                display: "inline-block",
                "&:hover": {
                    background: (0, material_1.alpha)(theme.palette.common.white, 0.25)
                }
            },
            _r["&.".concat(classes.light)] = {
                background: theme.palette.common.white
            },
            _r["& .".concat(classes.miniInput)] = {
                width: 70
            },
            _r),
        searchWrapper: (_s = {},
            _s[theme.breakpoints.down("lg")] = {
                flex: 1,
                textAlign: "right"
            },
            _s),
        search: (_t = {
                width: theme.spacing(9),
                height: "100%",
                position: "absolute",
                pointerEvents: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            },
            _t[theme.breakpoints.down("lg")] = {
                display: "none"
            },
            _t),
        miniInput: {
            paddingLeft: 0,
            textIndent: "999999px"
        },
        gradientBg: {},
        solidBg: {},
        darker: (_u = {
                backgroundAttachment: "fixed",
                boxShadow: "0px 2px 2px -2px rgba(80,80,80, 0.2)"
            },
            _u["&.".concat(classes.gradientBg)] = {
                backgroundImage: theme.palette.mode === "dark"
                    ? // ? gradientBgDark(theme)
                        (0, appStyles_jss_1.gradientBgLight)(theme)
                    : (0, appStyles_jss_1.gradientBgLight)(theme)
            },
            _u["&.".concat(classes.solidBg)] = {
                backgroundColor: theme.palette.background.default
            },
            _u["& .".concat(classes.menuButton)] = {
                color: theme.palette.common.white
            },
            _u),
        fixed: (_v = {
                position: "fixed",
                left: 0,
                top: 0
            },
            _v[theme.breakpoints.up("lg")] = {
                top: theme.spacing(-8)
            },
            _v),
        separatorV: {
            borderLeft: "1px solid ".concat(theme.palette.grey[300]),
            height: 20,
            margin: "0 10px",
            opacity: 0.4
        },
        notifMenu: {
            "& li": {
                height: "auto",
                "& h3": {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                }
            }
        },
        listItemAvatar: {},
        badge: {},
        badgeMenu: {
            "& span": {
                top: 0,
                right: -30
            }
        },
        textNotif: {
            "& span": {
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "block"
            }
        },
        notifIcon: (_w = {
                "& i": {
                    width: 28,
                    height: 28,
                    fontSize: 28
                }
            },
            _w["&.".concat(classes.dark)] = {
                "& i": {
                    color: theme.palette.text.primary
                }
            },
            _w["&.".concat(classes.light)] = {
                "& i": {
                    color: theme.palette.common.white
                }
            },
            _w),
        brand: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 20px 5px",
            height: 64,
            position: "absolute",
            width: "100%",
            fontSize: 16,
            margin: 0,
            fontWeight: 500,
            textDecoration: "none",
            color: theme.palette.text.primary,
            "& img": {
                marginRight: 10,
                width: 30
            }
        },
        mainMenu: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2, 0),
            boxShadow: theme.shadows[3],
            position: "relative",
            transition: "padding 0.3s ease",
            "& > div": {
                display: "flex",
                justifyContent: "center"
            }
        },
        headMenu: {
            fontSize: 12,
            padding: "".concat(theme.spacing(0.5), " ").concat(theme.spacing(1), " ").concat(theme.spacing(0.5), " ").concat(theme.spacing(2)),
            minHeight: "auto",
            margin: "0 ".concat(theme.spacing(0.5)),
            lineHeight: "2em"
        },
        opened: {
            color: theme.palette.primary.main,
            boxShadow: "inset 0 0 0 1px ".concat(theme.palette.primary.main),
            "& svg": {
                fill: theme.palette.primary.main
            }
        },
        rightIcon: {
            marginLeft: theme.spacing(0.5),
            opacity: 0.3
        },
        selected: (_x = {
                background: theme.palette.primary.main,
                color: theme.palette.primary.light,
                "&:hover": {
                    background: theme.palette.primary.main
                },
                "& svg": {
                    fill: theme.palette.primary.light
                }
            },
            _x["& .".concat(classes.rightIcon)] = {
                opacity: 0.7
            },
            _x),
        paperMenu: {
            overflow: "auto",
            maxHeight: 500
        },
        popperClose: {
            pointerEvents: "none",
            zIndex: 2
        },
        dropDownMenu: {
            minWidth: 300,
            marginTop: theme.spacing(1.5),
            position: "relative"
        },
        active: {},
        menuItem: (_y = {
                "& span": {
                    fontSize: 14
                }
            },
            _y["&.".concat(classes.active)] = {
                borderLeft: "5px solid ".concat(theme.palette.primary.main),
                backgroundColor: theme.palette.mode === "dark"
                    ? (0, material_1.alpha)(theme.palette.secondary.main, 0.24)
                    : theme.palette.secondary.light,
                "& span": {
                    color: theme.palette.primary.main
                },
                "&:hover": {
                    backgroundColor: theme.palette.mode === "dark"
                        ? (0, material_1.alpha)(theme.palette.secondary.main, 0.24)
                        : theme.palette.secondary.light
                }
            },
            _y),
        megaMenu: {
            "& $title": {
                paddingLeft: theme.spacing(2)
            }
        },
        megaItem: (_z = {
                display: "inline-block",
                width: "auto",
                margin: theme.spacing(1),
                // borderRadius: theme.rounded.big,
                padding: "".concat(theme.spacing(0.25), " ").concat(theme.spacing(1)),
                "& span": {
                    fontSize: 14
                },
                "& div": {
                    padding: 0
                }
            },
            _z["&.".concat(classes.active)] = {
                border: "1px solid ".concat(theme.palette.primary.main),
                backgroundColor: theme.palette.mode === "dark"
                    ? (0, material_1.alpha)(theme.palette.secondary.main, 0.24)
                    : theme.palette.secondary.light,
                "& span": {
                    color: theme.palette.primary.main
                },
                "&:hover": {
                    backgroundColor: theme.palette.mode === "dark"
                        ? (0, material_1.alpha)(theme.palette.secondary.main, 0.24)
                        : theme.palette.secondary.light
                }
            },
            _z),
        bigIcon: {
            display: "block",
            marginTop: 40,
            "& i": {
                fontSize: 100,
                color: theme.palette.primary.main,
                margin: "0 auto",
                display: "inherit"
            }
        },
        button: {},
        headerProperties: {
            overflow: "hidden",
            position: "relative",
            display: "flex",
            flex: 1,
            alignItems: "center",
            zIndex: 1
        },
        fadeOut: {},
        invert: {},
        headerAction: (_0 = {
                margin: "0 ".concat(theme.spacing(3)),
                transition: "opacity 0.5s ease"
            },
            _0["& .".concat(classes.button)] = {
                margin: "0 ".concat(theme.spacing(1), " / 2"),
                "& i": {
                    color: (0, material_1.alpha)(theme.palette.common.white, 0.87),
                    width: 28,
                    height: 28,
                    fontSize: 28
                }
            },
            _0["&.".concat(classes.fadeOut)] = {
                opacity: 0
            },
            _0["&.".concat(classes.invert)] = (_1 = {},
                _1["& .".concat(classes.button)] = {
                    "& i": {
                        color: (0, material_1.alpha)(theme.palette.text.primary, 0.5)
                    }
                },
                _1),
            _0),
        show: {},
        headerTitle: (_2 = {
                transition: "all 0.3s ease",
                fontSize: theme.spacing(3),
                position: "absolute",
                textTransform: "capitalize",
                fontWeight: 700,
                top: 60,
                color: theme.palette.common.white,
                opacity: 0
            },
            _2["&.".concat(classes.show)] = {
                top: theme.spacing(1),
                opacity: 0.87
            },
            _2),
        swipeDrawerPaper: {
            width: drawerWidth
        },
        searchHeaderMenu: {
            flex: 1,
            flexDirection: "row-reverse",
            display: "flex",
            alignItems: "center"
        }
    });
});
