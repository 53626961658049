"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var reactflow_1 = require("reactflow");
function DrilldownAlert(_a) {
    var drilldownNode = _a.drilldownNode, stopDrilldown = _a.stopDrilldown, disbandDrilldownNode = _a.disbandDrilldownNode, dimFlowItems = _a.dimFlowItems, rfInstance = _a.rfInstance, loading = _a.loading;
    var t = (0, react_i18next_1.useTranslation)().t;
    var store = (0, reactflow_1.useStoreApi)();
    var handleMouseEnter = (0, react_1.useCallback)(function () {
        var state = store.getState();
        var nodeElements = state.getNodes();
        var edgeElements = state.edges;
        var nodes = nodeElements.filter(function (node) {
            return "drilldownId" in node.data && node.data.drilldownId === drilldownNode.id;
        });
        var nodeIds = nodes.map(function (node) { return node.id; });
        var edgeIds = edgeElements
            .filter(function (edge) { return nodeIds.includes(edge.source) && nodeIds.includes(edge.target); })
            .map(function (edge) { return edge.id; });
        dimFlowItems(nodeIds, edgeIds);
    }, [dimFlowItems]);
    var handleMouseLeave = (0, react_1.useCallback)(function () {
        dimFlowItems();
    }, [dimFlowItems]);
    var handleClick = (0, react_1.useCallback)(function () {
        if (rfInstance) {
            var state = store.getState();
            var nodeElements = state.getNodes();
            var nodes = nodeElements.filter(function (node) {
                return "drilldownId" in node.data && node.data.drilldownId === drilldownNode.id;
            });
            var bounds = (0, reactflow_1.getNodesBounds)(nodes);
            rfInstance.fitBounds(bounds, { duration: 500 });
        }
    }, [rfInstance]);
    return (react_1.default.createElement(material_1.Alert, { elevation: 4, icon: false, sx: {
            minWidth: 200,
            maxWidth: 300,
            backgroundColor: "white",
            ".MuiAlert-message": {
                width: "100%",
            },
        }, key: "drilldown-alert" + drilldownNode.id },
        react_1.default.createElement(material_1.Box, { sx: {
                width: "100%",
            }, display: "flex", flexDirection: "column", alignContent: "center", key: "drilldown-alert-box" + drilldownNode.id },
            react_1.default.createElement(material_1.Tooltip, { title: t("flow.node_context_menu.go_to_drilldown"), placement: "left", arrow: true },
                react_1.default.createElement(material_1.AlertTitle, { sx: { textAlign: "center", cursor: "pointer" }, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onClick: handleClick }, "".concat(t("flow.node_context_menu.drilldown_description", {
                    name: drilldownNode.data.label,
                })))),
            react_1.default.createElement(material_1.Box, { display: "flex", justifyContent: "center", sx: { width: "100%", mt: 1, mb: 0.5, gap: 1.5 } }, loading ? (react_1.default.createElement(material_1.CircularProgress, { size: 20 })) : (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(material_1.Button, { variant: "contained", color: "secondary", sx: {
                        borderRadius: "4px",
                        padding: "2px 6px",
                        fontSize: "10px",
                    }, onClick: function () { return stopDrilldown(drilldownNode.id); } }, t("generic.restack")),
                react_1.default.createElement(material_1.Button, { variant: "text", color: "error", sx: {
                        borderRadius: "4px",
                        padding: "2px 6px",
                        fontSize: "10px",
                    }, onClick: function () { return disbandDrilldownNode(drilldownNode.id); } }, t("generic.disband"))))))));
}
exports.default = DrilldownAlert;
