"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var react_i18next_1 = require("react-i18next");
var useErrorMessages = function () {
    var _a = (0, react_1.useState)(""), inputError = _a[0], setInputError = _a[1];
    var t = (0, react_i18next_1.useTranslation)().t;
    var handleInputError = (0, react_1.useCallback)(function (reason) {
        switch (reason) {
            case "maxDate":
                setInputError(t("dates.max"));
                break;
            case "minDate":
                setInputError(t("dates.time_invalid"));
                break;
            case "invalidDate":
                setInputError(t("dates.time_invalid"));
                break;
            default:
                setInputError("");
        }
    }, []);
    return { inputError: inputError, handleInputError: handleInputError };
};
exports.default = useErrorMessages;
