"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INITIALSLIDEVIEWER = exports.Type = void 0;
var Type;
(function (Type) {
    Type["ANALYSIS"] = "analysis";
    Type["SPLITTER"] = "splitter";
})(Type = exports.Type || (exports.Type = {}));
exports.INITIALSLIDEVIEWER = {
    open: false,
    frameId: undefined,
    mergeField: undefined,
};
