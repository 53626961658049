"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var tagsActions_1 = require("@components/Tags/reducers/tagsActions");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var useTagActions = function (user, where, col) {
    var dispatch = (0, redux_1.useAppDispatch)();
    var tags = (0, redux_1.useAppSelector)(function (state) { return state.tags.tags; });
    var location = (0, react_router_dom_1.useLocation)();
    var _a = (0, react_1.useState)([]), activeTags = _a[0], setActiveTags = _a[1];
    if (col) {
        col.def[col.index].options.filterList = activeTags;
    }
    (0, react_1.useEffect)(function () {
        var active = tags
            .filter(function (tag) { return tag.active; })
            .map(function (tag) { return "".concat(tag.emoji ? tag.emoji : "", " ").concat(tag.name); });
        setActiveTags(active);
    }, [tags]);
    (0, react_1.useEffect)(function () {
        dispatch((0, tagsActions_1.getTags)(user, where, location));
    }, [user]);
    var handleMakeActiveTag = function (tag) {
        dispatch((0, tagsActions_1.changeTagActivity)(tag));
    };
    var onFilterChange = function (changedColumn, filterList) {
        if (col && changedColumn === "tags") {
            var deleted_1 = activeTags.find(function (tag) { return !filterList[col.index].includes(tag); });
            if (deleted_1) {
                var tagObj = tags.find(function (tag) { return "".concat(tag.emoji ? tag.emoji : "", " ").concat(tag.name) === deleted_1; });
                handleMakeActiveTag(tagObj);
            }
        }
    };
    return {
        handleMakeActiveTag: handleMakeActiveTag,
        onFilterChange: onFilterChange,
        activeTags: activeTags,
    };
};
exports.default = useTagActions;
