"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadFromLocalStorage = exports.saveToLocalStorage = void 0;
// store anything to local state (therefore it is not persisted)
var saveToLocalStorage = function (state) {
    try {
        var serialisedState = JSON.stringify(state);
        localStorage.setItem("persistantState", serialisedState);
    }
    catch (e) {
        console.warn(e);
    }
};
exports.saveToLocalStorage = saveToLocalStorage;
// load string from localStarage and convert into an Object
// invalid output must be undefined
var loadFromLocalStorage = function () {
    try {
        var serialisedState = localStorage.getItem("persistantState");
        if (serialisedState === null)
            return undefined;
        return JSON.parse(serialisedState);
    }
    catch (e) {
        console.warn(e);
        return undefined;
    }
};
exports.loadFromLocalStorage = loadFromLocalStorage;
