"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getTaskCount(taskGroup) {
    var idSet = new Set();
    taskGroup.tasks.forEach(function (task) {
        if (typeof task.id === "number") {
            idSet.add(task.id);
            var subTasks = task.tasks;
            if (subTasks && subTasks.length > 0) {
                subTasks.forEach(function (subTask) {
                    if (typeof subTask.id === "number") {
                        idSet.add(subTask.id);
                    }
                });
            }
        }
    });
    return idSet.size;
}
exports.default = getTaskCount;
