"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
function useAttachNode() {
    var setNodes = (0, reactflow_1.useReactFlow)().setNodes;
    var store = (0, reactflow_1.useStoreApi)();
    var dispatch = (0, redux_1.useAppDispatch)();
    var onAttachNode = function (body) {
        return dispatch((0, workspaceActions_websocket_1.handleAttachNode)(body));
    };
    var attachNode = (0, react_1.useCallback)(function (node, parent, position) {
        var nodeInternals = store.getState().nodeInternals;
        var nextNodes = Array.from(nodeInternals.values()).map(function (n) {
            if (node.id === n.id) {
                return __assign(__assign({}, n), { position: position, parentNode: parent.id, extent: "parent" });
            }
            return n;
        });
        setNodes(nextNodes);
        onAttachNode({
            nodeId: node.data.id,
            nodeType: node.type,
            position: position,
            parentId: parent.data.id,
        });
    }, [setNodes, store]);
    return attachNode;
}
exports.default = useAttachNode;
