"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var groupConstants_1 = require("./groupConstants");
var constants_1 = require("@api/constants");
var data_1 = require("@customTypes/data");
var initialState = {
    groups: [],
    draftGroup: null,
    groupDropdownOptions: [],
    elements: data_1.INITIALELEMENTS,
    templates: data_1.INITIALTEMPLATES,
    id: 0,
    title: "",
    description: "",
    giveCasesToAll: false,
    isDrafts: false,
    users: [],
    usersDropDownOptions: [],
    loadings: constants_1.initialLoadings,
};
function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case groupConstants_1.GET_GROUPS_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { main: true }) });
        case groupConstants_1.GET_GROUPS_SUCCESS:
            return __assign(__assign({}, state), { groups: action.groups.filter(function (g) { return !g.drafts; }), draftGroup: action.groups.find(function (g) { return g.drafts; }) || null, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case groupConstants_1.GET_GROUPS_FAILED:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case groupConstants_1.CLEAR_GROUPS:
            return __assign(__assign({}, state), { groups: [], loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case groupConstants_1.POST_GROUP_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: true }) });
        case groupConstants_1.CREATE_NEW_GROUP:
        case groupConstants_1.POST_GROUP_SUCCESS:
            return __assign(__assign(__assign({}, state), ("group" in action && {
                groups: __spreadArray([action.group], state.groups, true),
                groupDropdownOptions: __spreadArray([
                    { label: action.group.title, value: action.group.id }
                ], state.groupDropdownOptions, true),
            })), { title: "", description: "", users: [], loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case groupConstants_1.SHOW_GROUP_LOADING:
            return __assign(__assign({}, state), { title: "", description: "", elements: data_1.INITIALELEMENTS, templates: data_1.INITIALTEMPLATES, loadings: __assign(__assign({}, state.loadings), { main: true }) });
        case groupConstants_1.SHOW_GROUP_SUCCESS:
            return __assign(__assign({}, state), { title: action.title, description: action.description, giveCasesToAll: action.give_cases_to_all, elements: action.elements, templates: action.tools, isDrafts: action.drafts, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case groupConstants_1.SHOW_GROUP_FAILED:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case groupConstants_1.SHOW_GROUP_LITE_CHANGE:
        case groupConstants_1.SHOW_GROUP_LITE_SUCCESS:
            return __assign(__assign({}, state), { id: action.id, title: action.title });
        case groupConstants_1.TITLE_CHANGE:
            return __assign(__assign({}, state), { title: action.item });
        case groupConstants_1.DESCRIPTION_CHANGE:
            return __assign(__assign({}, state), { description: action.item });
        case groupConstants_1.GIVE_ACCESS_TO_ALL_CHANGE:
            return __assign(__assign({}, state), { giveCasesToAll: action.bool });
        case groupConstants_1.ADD_GROUP_USERS:
            return __assign(__assign({}, state), { users: action.users });
        case groupConstants_1.GET_GROUP_DROPDOWN_SUCCESS:
            return __assign(__assign({}, state), { groupDropdownOptions: action.groupDropdownOptions });
        case groupConstants_1.CLEAR_GROUP_DROPDOWN:
            return __assign(__assign({}, state), { groupDropdownOptions: [] });
        case groupConstants_1.GROUP_DUPLICATE_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: true }) });
        case groupConstants_1.GROUP_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, groups: __spreadArray(__spreadArray([], state.groups, true), [action.group], false) });
        case groupConstants_1.GROUP_DUPLICATE_FAILED:
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case groupConstants_1.GET_GROUP_DROPDOWN_FAILED:
        case groupConstants_1.SHOW_GROUP_LITE_FAILED:
        case groupConstants_1.PUT_GROUP_FAILED:
        case groupConstants_1.DELETE_GROUP_SUCCESS:
        case groupConstants_1.DELETE_GROUP_FAILED:
        case groupConstants_1.POST_GROUP_FAILED:
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        default:
            return state;
    }
}
exports.default = reducer;
