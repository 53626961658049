"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_DATE_RANGE_AGGREGATOR_FAILED = exports.UPDATE_DATE_RANGE_AGGREGATOR_SUCCESS = exports.UPDATE_DATE_RANGE_AGGREGATOR_LOADING = exports.UPDATE_DATE_AGGREGATOR_FAILED = exports.UPDATE_DATE_AGGREGATOR_SUCCESS = exports.UPDATE_DATE_AGGREGATOR_LOADING = exports.UPDATE_NUMBER_AGGREGATOR_FAILED = exports.UPDATE_NUMBER_AGGREGATOR_SUCCESS = exports.UPDATE_NUMBER_AGGREGATOR_LOADING = exports.UPDATE_COLUMN_WIDTH_FAILED = exports.UPDATE_COLUMN_WIDTH_SUCCESS = exports.UPDATE_COLUMN_WIDTH_LOADING = exports.UPDATE_COLUMN_INDEX_FAILED = exports.UPDATE_COLUMN_INDEX_SUCCESS = exports.UPDATE_COLUMN_INDEX_LOADING = exports.DELETE_COLUMN_FAILED = exports.DELETE_COLUMN_SUCCESS = exports.DELETE_COLUMN_LOADING = exports.CHANGE_COLUMN_DESCRIPTION_FAILED = exports.CHANGE_COLUMN_DESCRIPTION_SUCCESS = exports.CHANGE_COLUMN_DESCRIPTION_LOADING = exports.CHANGE_COLUMN_TITLE_FAILED = exports.CHANGE_COLUMN_TITLE_SUCCESS = exports.CHANGE_COLUMN_TITLE_LOADING = exports.POST_COLUMN_FAILED = exports.POST_COLUMN_SUCCESS = exports.POST_COLUMN_LOADING = void 0;
exports.POST_COLUMN_LOADING = "POST_COLUMN_LOADING";
exports.POST_COLUMN_SUCCESS = "POST_COLUMN_SUCCESS";
exports.POST_COLUMN_FAILED = "POST_COLUMN_FAILED";
exports.CHANGE_COLUMN_TITLE_LOADING = "CHANGE_COLUMN_TITLE_LOADING";
exports.CHANGE_COLUMN_TITLE_SUCCESS = "CHANGE_COLUMN_TITLE_SUCCESS";
exports.CHANGE_COLUMN_TITLE_FAILED = "CHANGE_COLUMN_TITLE_FAILED";
exports.CHANGE_COLUMN_DESCRIPTION_LOADING = "CHANGE_COLUMN_DESCRIPTION_LOADING";
exports.CHANGE_COLUMN_DESCRIPTION_SUCCESS = "CHANGE_COLUMN_DESCRIPTION_SUCCESS";
exports.CHANGE_COLUMN_DESCRIPTION_FAILED = "CHANGE_COLUMN_DESCRIPTION_FAILED";
exports.DELETE_COLUMN_LOADING = "DELETE_COLUMN_LOADING";
exports.DELETE_COLUMN_SUCCESS = "DELETE_COLUMN_SUCCESS";
exports.DELETE_COLUMN_FAILED = "DELETE_COLUMN_FAILED";
exports.UPDATE_COLUMN_INDEX_LOADING = "UPDATE_COLUMN_INDEX_LOADING";
exports.UPDATE_COLUMN_INDEX_SUCCESS = "UPDATE_COLUMN_INDEX_SUCCESS";
exports.UPDATE_COLUMN_INDEX_FAILED = "UPDATE_COLUMN_INDEX_FAILED";
exports.UPDATE_COLUMN_WIDTH_LOADING = "UPDATE_COLUMN_WIDTH_LOADING";
exports.UPDATE_COLUMN_WIDTH_SUCCESS = "UPDATE_COLUMN_WIDTH_SUCCESS";
exports.UPDATE_COLUMN_WIDTH_FAILED = "UPDATE_COLUMN_WIDTH_FAILED";
exports.UPDATE_NUMBER_AGGREGATOR_LOADING = "UPDATE_NUMBER_AGGREGATOR_LOADING";
exports.UPDATE_NUMBER_AGGREGATOR_SUCCESS = "UPDATE_NUMBER_AGGREGATOR_SUCCESS";
exports.UPDATE_NUMBER_AGGREGATOR_FAILED = "UPDATE_NUMBER_AGGREGATOR_FAILED";
exports.UPDATE_DATE_AGGREGATOR_LOADING = "UPDATE_DATE_AGGREGATOR_LOADING";
exports.UPDATE_DATE_AGGREGATOR_SUCCESS = "UPDATE_DATE_AGGREGATOR_SUCCESS";
exports.UPDATE_DATE_AGGREGATOR_FAILED = "UPDATE_DATE_AGGREGATOR_FAILED";
exports.UPDATE_DATE_RANGE_AGGREGATOR_LOADING = "UPDATE_DATE_RANGE_AGGREGATOR_LOADING";
exports.UPDATE_DATE_RANGE_AGGREGATOR_SUCCESS = "UPDATE_DATE_RANGE_AGGREGATOR_SUCCESS";
exports.UPDATE_DATE_RANGE_AGGREGATOR_FAILED = "UPDATE_DATE_RANGE_AGGREGATOR_FAILED";
