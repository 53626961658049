"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    tabSize: "4",
    webkitTextSizeAdjust: "100%",
    wordBreak: "break-word",
    rpvAttachmentItemHoverBackgroundColor: "rgba(0, 0, 0, 0.1)",
    rpvAttachmentItemColor: "#000",
    rpvBookmarkItemHoverBackgroundColor: "rgba(0, 0, 0, 0.1)",
    rpvBookmarkItemColor: "#000",
    rpvCoreAnnotationLinkHoverBackgroundColor: "rgba(255, 255, 0, 0.2)",
    rpvCoreAnnotationPopupWrapperBackgroundColor: "#faf089",
    rpvCoreAnnotationPopupWrapperBoxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    rpvCoreAnnotationPopupContentBorderTopColor: "#1a202c",
    rpvCoreArrowBorderColor: "rgba(0, 0, 0, 0.3)",
    rpvCoreAskingPasswordColor: "#000",
    rpvCoreAskingPasswordWrapperBackgroundColor: "#fff",
    rpvCoreAskingPasswordWrapperBorderColor: "rgba(0, 0, 0, 0.3)",
    rpvCoreButtonBackgroundColor: "rgba(0, 0, 0, 0.3)",
    rpvCoreButtonColor: "#000",
    rpvCoreDocErrorBackgroundColor: "#fff",
    rpvCoreDocErrorTextBackgroundColor: "#c02424",
    rpvCoreDocErrorTextColor: "#fff",
    rpvCoreDocLoadingBackgroundColor: "#fff",
    rpvCoreInnerPageBackgroundColor: "#fff",
    rpvCoreMenuDividerBorderBottomColor: "rgba(0, 0, 0, 0.3)",
    rpvCoreMenuItemColor: "#000",
    rpvCoreMenuItemHoverBackgroundColor: "rgba(0, 0, 0, 0.1)",
    rpvCoreMenuItemDisabledColor: "rgba(0, 0, 0, 0.3)",
    rpvCoreMinimalButtonColor: "#000",
    rpvCoreMinimalButtonHoverBackgroundColor: "rgba(0, 0, 0, 0.1)",
    rpvCoreMinimalButtonDisabledColor: "rgba(0, 0, 0, 0.3)",
    rpvCoreMinimalButtonSelectedBackgroundColor: "rgba(0, 0, 0, 0.1)",
    rpvCoreModalBodyBackgroundColor: "#fff",
    rpvCoreModalBodyBorderColor: "rgba(0, 0, 0, 0.3)",
    rpvCoreModalOverlayBackgroundColor: "rgba(0, 0, 0, 0.5)",
    rpvCorePageLayerBoxShadow: "2px 2px 8px 0 rgba(0, 0, 0, 0.2)",
    rpvCorePopoverBodyBackgroundColor: "#fff",
    rpvCorePopoverBodyBorderColor: "rgba(0, 0, 0, 0.3)",
    rpvCorePopoverBodyColor: "#000",
    rpvCorePrimaryButtonBackgroundColor: "#2566e8",
    rpvCorePrimaryButtonColor: "#fff",
    rpvCoreProgressBarBackgroundColor: "rgba(0, 0, 0, 0.1)",
    rpvCoreProgressBarProgressBackgroundColor: "#2566e8",
    rpvCoreProgressBarProgressColor: "#fff",
    rpvCoreSeparatorBorderBottomColor: "rgba(0, 0, 0, 0.3)",
    rpvCoreSpinnerBorderColor: "rgba(0, 0, 0, 0.4)",
    rpvCoreSpinnerBorderTransparentColor: "transparent",
    rpvCoreSplitterBackgroundColor: "transparent",
    rpvCoreSplitterHoverBackgroundColor: "rgba(0, 0, 0, 0.2)",
    rpvCoreTextLayerTextSelectionBackgroundColor: "rgb(0, 0, 255, 1)",
    rpvCoreTextLayerTextSelectionColor: "transparent",
    rpvCoreTextboxBackgroundColor: "#fff",
    rpvCoreTextboxBorderColor: "rgba(0, 0, 0, 0.2)",
    rpvCoreTextboxColor: "#000",
    rpvCoreTooltipBodyBackgroundColor: "#000",
    rpvCoreTooltipBodyColor: "#fff",
    rpvThumbnailContainerBoxShadow: "rgba(0, 0, 0, 0.2) 2px 2px 8px 0px",
    rpvThumbnailItemSelectedBackgroundColor: "rgba(0, 0, 0, 0.3)",
    rpvDefaultLayoutBodyBackgroundColor: "#fff",
    rpvDefaultLayoutContainerBorderColor: "rgba(0, 0, 0, 0.3)",
    rpvDefaultLayoutToolbarBackgroundColor: "#eee",
    rpvDefaultLayoutToolbarBorderBottomColor: "rgba(0, 0, 0, 0.1)",
    rpvDefaultLayoutSidebarBorderColor: "rgba(0, 0, 0, 0.2)",
    rpvDefaultLayoutSidebarOpenedBackgroundColor: "#fff",
    rpvDefaultLayoutSidebarHeadersBackgroundColor: "#eee",
    rpvDefaultLayoutSidebarContentOpenedBackgroundColor: "#fff",
    rpvDefaultLayoutSidebarContentOpenedBorderColor: "rgba(0, 0, 0, 0.2)",
    rpvDefaultLayoutSidebarContentOpenedColor: "#000",
    rpvDropAreaBackgroundColor: "#fff",
    rpvDropAreaBodyBorderColor: "rgba(0, 0, 0, 0.3)",
    rpvDropAreaBodyColor: "#000",
    rpvFullScreenPagesBackgroundColor: "#fff",
    rpvPrintProgressBodyBackgroundColor: "#fff",
    rpvPrintProgressBodyBorderColor: "rgba(0, 0, 0, 0.3)",
    rpvPrintProgressBodyColor: "#000",
    rpvSearchHighlightBackgroundColor: "rgba(255, 255, 0, 0.4)",
    rpvSearchHighlightCurrentBackgroundColor: "rgba(0, 128, 0, 0.4)",
    rpvZoomPopoverTargetArrowBorderColor: "rgba(0, 0, 0, 0.6)",
    rpvToolbarLabelColor: "#000",
    arcPaletteForegroundPrimary: "#E7F2F7FF",
    arcPaletteForegroundTertiary: "#7EB8D6FF",
    arcBackgroundSimpleColor: "#7EB8D6FF",
    arcPaletteCutoutColor: "#275E7AFF",
    arcPaletteHover: "#57899E7A",
    arcPaletteTitle: "#DAE6EAF4",
    arcPaletteBackgroundExtra: "#030F13F4",
    arcPaletteForegroundSecondary: "#7EB8D6FF",
    arcPaletteMaxContrastColor: "#E7F2F7FF",
    arcPaletteBackground: "#081D26F6",
    arcPaletteFocus: "#477D94CE",
    arcPaletteSubtitle: "#767C7FF9",
    arcPaletteMinContrastColor: "#275E7AFF",
    visibility: "visible",
    backgroundRepeat: "no-repeat",
    overflow: "visible",
    webkitFontSmoothing: "antialiased",
    textAlign: "center",
    whiteSpace: "nowrap",
    display: "inline-flex",
    webkitBoxAlign: "center",
    alignItems: "center",
    webkitBoxPack: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    webkitTapHighlightColor: "transparent",
    outline: "0",
    border: "0",
    margin: "0",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    textDecoration: "none",
    fontFamily: "Open Sans,sans-serif",
    fontSize: "0.875rem",
    lineHeight: "1.75",
    textTransform: "uppercase",
    minWidth: "64px",
    padding: "6px 16px",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    color: "#fff",
    backgroundColor: "#73B1FF",
    borderRadius: "10px",
    fontWeight: "600",
    boxShadow: "none",
    webkitAppearance: "button",
    touchAction: "manipulation",
    marginRight: "5px",
    marginBottom: "10px",
};
