"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var redux_1 = require("@hooks/redux");
var boardActions_websocket_1 = require("@pages/TaskBoard/reducers/WebSocket/boardActions.websocket");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
function useGanttDateRangePicker(_a) {
    var activeView = _a.activeView;
    var dispatch = (0, redux_1.useAppDispatch)();
    var location = (0, react_router_dom_1.useLocation)();
    var boardId = (0, constants_1.getId)(location);
    var columns = (0, redux_1.useAppSelector)(function (state) { return state.board.columns; });
    var _b = (0, react_1.useState)(), ganttDateRange = _b[0], setGanttDateRange = _b[1];
    var _c = (0, react_1.useState)([]), dateRangeOptions = _c[0], setDateRangeOptions = _c[1];
    (0, react_1.useEffect)(function () {
        setDateRangeOptions(columns
            .filter(function (c) { return c.columnType === "dateRange"; })
            .map(function (c) { return ({
            value: c.id,
            label: c.title,
        }); }));
    }, [columns]);
    (0, react_1.useEffect)(function () {
        var dateRangeId = activeView === null || activeView === void 0 ? void 0 : activeView.dateRangeColumnId;
        var dateRange = dateRangeOptions.find(function (c) { return c.value === dateRangeId; });
        if (dateRangeId !== null && dateRange) {
            setGanttDateRange(dateRange);
        }
        else {
            setGanttDateRange(undefined);
        }
    }, [activeView === null || activeView === void 0 ? void 0 : activeView.dateRangeColumnId, dateRangeOptions]);
    var handleDateRangeChange = (0, react_1.useCallback)(function (value) {
        (activeView === null || activeView === void 0 ? void 0 : activeView.id) &&
            dispatch((0, boardActions_websocket_1.handleChangeGanttDateRange)(+boardId, activeView === null || activeView === void 0 ? void 0 : activeView.id, value.value));
    }, [boardId, activeView]);
    return { ganttDateRange: ganttDateRange, dateRangeOptions: dateRangeOptions, handleDateRangeChange: handleDateRangeChange };
}
exports.default = useGanttDateRangePicker;
