"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ATTRIBUTE_DUPLICATE_FAILED = exports.ATTRIBUTE_DUPLICATE_SUCCESS = exports.ATTRIBUTE_DUPLICATE_LOADING = exports.GET_ATTRIBUTE_DESCENDANTS_FAILED = exports.GET_ATTRIBUTE_DESCENDANTS_SUCCESS = exports.FORCE_DELETE_ATTRIBUTE_FAILED = exports.FORCE_DELETE_ATTRIBUTE_SUCCESS = exports.CLEAR_ATTRIBUTES_DESCENDANTS = exports.CLEAR_ATTRIBUTE_STATE = exports.ADD_TYPE = exports.SELECTION_VALUES = exports.DESCRIPTION_CHANGE = exports.LABEL_CHANGE = exports.CURRENT_ATTRIBUTE = exports.DELETE_ATTRIBUTE_FAILED = exports.DELETE_ATTRIBUTE_SUCCESS = exports.PUT_ATTRIBUTE_FAILED = exports.PUT_ATTRIBUTE_SUCCESS = exports.SHOW_ATTRIBUTE_FAILED = exports.SHOW_ATTRIBUTE_SUCCESS = exports.POST_ATTRIBUTE_FAILED = exports.POST_ATTRIBUTE_SUCCESS = exports.GET_ATTRIBUTES_FAILED = exports.GET_ATTRIBUTES_SUCCESS = exports.GET_ATTRIBUTES_LOADING = void 0;
exports.GET_ATTRIBUTES_LOADING = "GET_ATTRIBUTES_LOADING";
exports.GET_ATTRIBUTES_SUCCESS = "GET_ATTRIBUTES_SUCCESS";
exports.GET_ATTRIBUTES_FAILED = "GET_ATTRIBUTES_FAILED";
exports.POST_ATTRIBUTE_SUCCESS = "POST_ATTRIBUTE_SUCCESS";
exports.POST_ATTRIBUTE_FAILED = "POST_ATTRIBUTE_FAILED";
exports.SHOW_ATTRIBUTE_SUCCESS = "SHOW_ATTRIBUTE_SUCCESS";
exports.SHOW_ATTRIBUTE_FAILED = "SHOW_ATTRIBUTE_FAILED";
exports.PUT_ATTRIBUTE_SUCCESS = "PUT_ATTRIBUTE_SUCCESS";
exports.PUT_ATTRIBUTE_FAILED = "PUT_ATTRIBUTE_FAILED";
exports.DELETE_ATTRIBUTE_SUCCESS = "DELETE_ATTRIBUTE_SUCCESS";
exports.DELETE_ATTRIBUTE_FAILED = "DELETE_ATTRIBUTE_FAILED";
exports.CURRENT_ATTRIBUTE = "CURRENT_ATTRIBUTE";
exports.LABEL_CHANGE = "ATTRIBUTE_LABEL_CHANGE";
exports.DESCRIPTION_CHANGE = "ATTRIBUTE_DESCRIPTION_CHANGE";
exports.SELECTION_VALUES = "ATTRIBUTE_SELECTION_VALUES";
exports.ADD_TYPE = "ATTRIBUTE_ADD_TYPE";
exports.CLEAR_ATTRIBUTE_STATE = "CLEAR_ATTRIBUTE_STATE";
exports.CLEAR_ATTRIBUTES_DESCENDANTS = "CLEAR_ATTRIBUTES_DESCENDANTS";
exports.FORCE_DELETE_ATTRIBUTE_SUCCESS = "FORCE_DELETE_ATTRIBUTE_SUCCESS";
exports.FORCE_DELETE_ATTRIBUTE_FAILED = "FORCE_DELETE_ATTRIBUTE_FAILED";
exports.GET_ATTRIBUTE_DESCENDANTS_SUCCESS = "GET_ATTRIBUTE_DESCENDANTS_SUCCESS";
exports.GET_ATTRIBUTE_DESCENDANTS_FAILED = "GET_ATTRIBUTE_DESCENDANTS_FAILED";
exports.ATTRIBUTE_DUPLICATE_LOADING = "ATTRIBUTE_DUPLICATE_LOADING";
exports.ATTRIBUTE_DUPLICATE_SUCCESS = "ATTRIBUTE_DUPLICATE_SUCCESS";
exports.ATTRIBUTE_DUPLICATE_FAILED = "ATTRIBUTE_DUPLICATE_FAILED";
