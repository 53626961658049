"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth0_react_1 = require("@auth0/auth0-react");
var react_1 = require("react");
function useHasAccess(access) {
    var _a;
    var user = (0, auth0_react_1.useAuth0)().user;
    var accesses = (((_a = user === null || user === void 0 ? void 0 : user["https://juristic.io/meta"]) === null || _a === void 0 ? void 0 : _a.dbUser) || { accesses: [] }).accesses;
    var hasAccess = (0, react_1.useMemo)(function () { return accesses.includes(access); }, [accesses]);
    return hasAccess;
}
exports.default = useHasAccess;
