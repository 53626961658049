"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.body = exports.title = exports.DEFAULT_BODY = exports.DEFAULT_TITLE = void 0;
exports.DEFAULT_TITLE = '<h1><span style="font-family: Georgia; font-size: 24pt;"></span></h1>';
exports.DEFAULT_BODY = '<span style="font-family: Arial; font-size: 10pt;"></span>';
exports.title = {
    fontSize: "24pt",
    fontFamily: "Georgia",
    paragraphFormat: "H1",
};
exports.body = {
    fontSize: "10pt",
    fontFamily: "Arial",
    paragraphFormat: "P",
};
var moreTextButtons = [
    "fontSize",
    "fontFamily",
    "inlineStyle",
    "bold",
    "italic",
    "underline",
    "strikeThrough",
    "textColor",
    "backgroundColor",
    "subscript",
    "superscript",
    "clearFormatting",
];
var moreParagraphButtons = [
    "formatOL",
    "formatUL",
    "alignLeft",
    "alignCenter",
    "alignRight",
    "alignJustify",
    "lineHeight",
    "outdent",
    "indent",
    "quote",
];
var options = {
    fontFamily: {
        "Arial,Helvetica,sans-serif": "Arial",
        "Georgia,serif": "Georgia",
    },
    fontSize: ["8", "9", "10", "11", "12", "14", "18", "24", "30", "36"],
    toolbarButtons: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 5,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 5,
        },
        moreRich: {
            buttons: ["insertTable", "insertHR"],
            buttonsVisible: 0,
        },
        moreMisc: {
            buttons: ["undo", "redo"],
            align: "right",
            buttonsVisible: 2,
        },
    },
    toolbarButtonsMD: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 4,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 3,
        },
        moreRich: {
            buttons: ["insertTable", "insertHR"],
            buttonsVisible: 2,
        },
        moreMisc: {
            buttons: ["undo", "redo"],
            buttonsVisible: 2,
            align: "right",
        },
    },
    toolbarButtonsSM: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 3,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 2,
        },
        moreRich: {
            buttons: ["insertTable", "insertHR"],
            buttonsVisible: 0,
        },
        moreMisc: {
            buttons: ["undo", "redo"],
            buttonsVisible: 2,
            align: "right",
        },
    },
    toolbarButtonsXS: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 1,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 1,
        },
        moreRich: {
            buttons: ["insertTable", "insertHR", "undo", "redo"],
            buttonsVisible: 2,
        },
    },
    fontFamilySelection: true,
    fontSizeSelection: true,
    tableResizer: true,
    fontSizeDefaultSelection: "10",
    fontSizeUnit: "pt",
    pluginsEnabled: [
        "table",
        "spell",
        "quote",
        "save",
        "paragraphFormat",
        "help",
        "draggable",
        "align",
        "link",
        "lists",
        "colors",
        "entities",
        "lineHeight",
        "inlineStyle",
        "fontFamily",
        "fontSize",
    ],
    disableRightClick: true,
    keepFormatOnDelete: true,
    paragraphFormat: {
        N: "Paragraph",
        H1: "Headings",
    },
    inlineStyles: {
        "Georgia (Headings)": "font-size: 24pt; font-family: Georgia; line-height: 1.15;",
        "Arial (Body)": "font-size: 11pt; font-family: Arial; line-height: 1.15;",
        "Arial (Subtitle)": "font-size: 11pt; font-weight: bold; font-family: Arial; color: rgb(193,83,35); line-height: 1.15;",
        "Arial (Subtitle 2)": "font-size: 11pt; font-style: italic; font-family: Arial; color: rgb(193,83,35); line-height: 1.15;",
    },
    lineHeights: {
        Default: "normal",
        Single: "1",
        1.15: "1.15",
        1.5: "1.5",
        Double: "2",
    },
    tableStyles: {
        "pwc-keynote-table": "Keynote Table",
    },
    tableEditButtons: [
        "tableHeader",
        "tableStyle",
        "|",
        "tableRows",
        "tableColumns",
        "tableCells",
        "-",
        "tableCellBackground",
        "|",
        "tableCellVerticalAlign",
        "tableCellHorizontalAlign",
        "|",
        "tableRemove",
    ],
    tableResizerOffset: 15,
    events: {
        // eslint-disable-next-line func-names
        "table.inserted": function (table) {
            var _a, _b, _c;
            var columnCount = ((_c = (_b = (_a = table.firstChild) === null || _a === void 0 ? void 0 : _a.firstChild) === null || _b === void 0 ? void 0 : _b.childNodes) === null || _c === void 0 ? void 0 : _c.length) || 0;
            var thead = document.createElement("thead");
            var tr = document.createElement("tr");
            var th = document.createElement("th");
            thead.appendChild(tr);
            for (var i = 0; i < columnCount; i += 1) {
                var thClone = th.cloneNode();
                tr.appendChild(thClone);
            }
            table.prepend(thead);
            table.setAttribute("class", "pwc-keynote-table");
        },
    },
};
var config = {
    DEFAULT_TITLE: exports.DEFAULT_TITLE,
    DEFAULT_BODY: exports.DEFAULT_BODY,
    title: exports.title,
    body: exports.body,
    options: options,
};
exports.default = config;
