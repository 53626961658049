"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var styles = (0, mui_1.makeStyles)()(function (theme) {
    var _a;
    return ({
        root: {
            flexGrow: 1,
            marginBottom: theme.spacing(1),
        },
        rootGeneral: {
            padding: theme.spacing(3),
        },
        divider: {
            margin: "".concat(theme.spacing(1.5), " 0"),
            background: "none",
        },
        descBlock: (_a = {
                display: "flex",
                marginBottom: theme.spacing(3)
            },
            _a[theme.breakpoints.down("lg")] = {
                marginBottom: theme.spacing(3),
            },
            _a.alignItems = "flex-end",
            _a.justifyContent = "space-between",
            _a),
        titleText: {
            flex: 1,
            width: "100%",
        },
        papperBlock: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            color: theme.palette.text.primary,
            "&$noMargin": {
                margin: 0,
            },
        },
    });
});
exports.default = styles;
