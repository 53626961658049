"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useDebounce(effect, dependencies, delay) {
    var callback = (0, react_1.useCallback)(effect, dependencies);
    (0, react_1.useEffect)(function () {
        var timeout = setTimeout(callback, delay);
        return function () { return clearTimeout(timeout); };
    }, [callback, delay]);
}
exports.default = useDebounce;
