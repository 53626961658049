"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTFlowNode = exports.isDBNode = exports.isTFlowPowerpointHelper = exports.WElementVariant = void 0;
exports.WElementVariant = {
    CUSTOM: "custom",
    TEXT: "text",
    STICKY: "sticky",
    ICON: "icon",
    FILE: "file",
    GROUP: "customgroup",
    DRILLDOWN: "drilldown",
    POWERPOINT_HELPER: "powerpoint_helper",
    POWERPOINT: "powerpoint",
};
var isTFlowPowerpointHelper = function (node) {
    return !!node.type &&
        [exports.WElementVariant.POWERPOINT_HELPER, exports.WElementVariant.POWERPOINT].some(function (x) { return x === node.type; });
};
exports.isTFlowPowerpointHelper = isTFlowPowerpointHelper;
var isDBNode = function (node) {
    return node.type !== exports.WElementVariant.POWERPOINT_HELPER &&
        node.type !== exports.WElementVariant.POWERPOINT;
};
exports.isDBNode = isDBNode;
var isTFlowNode = function (workspaceNode) {
    return workspaceNode.type === exports.WElementVariant.CUSTOM ||
        workspaceNode.type === exports.WElementVariant.DRILLDOWN;
};
exports.isTFlowNode = isTFlowNode;
