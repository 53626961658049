"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var reducerFunctions_1 = require("./reducerFunctions");
var constants_1 = require("@api/constants");
var initialState = {
    loadings: constants_1.initialLoadings,
    // main state
    open: false,
    steps: [],
    currentStep: null,
    // mirror mode related state variables
    isMirrorMode: false,
    deselectedSteps: new Set([]),
    // !mirror mode related state variables
};
function reducer(state, action) {
    var _a, _b, _c, _d;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case "GET_WORKSPACE_STEPS_LOADING":
        case "STORE_WORKSPACE_STEP_LOADING":
        case "UPDATE_WORKSPACE_STEP_INDEX_LOADING":
        case "DELETE_WORKSPACE_STEP_LOADING":
            return __assign(__assign({}, state), (0, reducerFunctions_1.handleLoading)(state.loadings, true, action.loadingType));
        case "GET_WORKSPACE_STEPS_FAILED":
        case "STORE_WORKSPACE_STEP_FAILED":
        case "UPDATE_WORKSPACE_STEP_INDEX_FAILED":
        case "DELETE_WORKSPACE_STEP_FAILED":
            return __assign(__assign({}, state), (0, reducerFunctions_1.handleLoading)(state.loadings, false, action.loadingType));
        case "GET_WORKSPACE_STEPS_SUCCESS":
        case "STORE_WORKSPACE_STEP_SUCCESS":
        case "UPDATE_WORKSPACE_STEP_INDEX_SUCCESS":
        case "DELETE_WORKSPACE_STEP_SUCCESS":
            return __assign(__assign(__assign({}, state), { steps: action.steps, currentStep: (_a = action.steps.find(function (step) { var _a; return step.id === ((_a = state.currentStep) === null || _a === void 0 ? void 0 : _a.id); })) !== null && _a !== void 0 ? _a : null }), (0, reducerFunctions_1.handleLoading)(state.loadings, false, action.loadingType));
        case "WS_DELETE_WORKSPACE_STEP_SUCCESS":
        case "WS_GET_WORKSPACE_STEPS_SUCCESS":
        case "WS_STORE_WORKSPACE_STEP_SUCCESS":
        case "WS_UPDATE_WORKSPACE_STEP_INDEX_SUCCESS":
            return __assign(__assign({}, state), { steps: action.steps, currentStep: (_b = action.steps.find(function (step) { var _a; return step.id === ((_a = state.currentStep) === null || _a === void 0 ? void 0 : _a.id); })) !== null && _b !== void 0 ? _b : null });
        case "CLEAR_STEP_EDITOR":
            return __assign(__assign({}, initialState), { isMirrorMode: state.isMirrorMode, open: state.open });
        case "RESET_STEP_EDITOR":
            return initialState;
        case "TOGGLE_STEP_EDITOR": {
            var open = (_c = action.open) !== null && _c !== void 0 ? _c : !state.open;
            return __assign(__assign(__assign({}, state), { open: open }), (!open && {
                currentStep: null,
                deselectedSteps: new Set([]),
                isMirrorMode: false,
            }));
        }
        case "TOGGLE_MIRROR_MODE": {
            var isMirrorMode = (_d = action.open) !== null && _d !== void 0 ? _d : !state.isMirrorMode;
            return __assign(__assign(__assign({}, state), { isMirrorMode: isMirrorMode }), (!isMirrorMode && { deselectedSteps: new Set([]) }));
        }
        case "TOGGLE_SELECTED_STEPS": {
            var selection = new Set(state.deselectedSteps);
            selection.has(action.id) ? selection.delete(action.id) : selection.add(action.id);
            return __assign(__assign({}, state), { deselectedSteps: selection });
        }
        case "CLEAR_SELECTED_STEPS":
            return __assign(__assign({}, state), { deselectedSteps: initialState.deselectedSteps });
        case "APPLY_CHANGES_TO_STEPS":
            return __assign(__assign({}, state), (0, reducerFunctions_1.applyMirrorModeChanges)(state, action.changes));
        case "FORCE_UPDATE_STEP_ELEMENTS":
            return __assign(__assign({}, state), (0, reducerFunctions_1.forceUpdateStepElements)(state, action.payload));
        case "TOGGLE_EDIT_STEP":
            return __assign(__assign({}, state), (0, reducerFunctions_1.editStep)(state, action.id));
        case "INITIAL_STEP_DATA":
            return __assign(__assign({}, state), { steps: action.stepData.steps, currentStep: action.stepData.currentStep, open: true });
        case "UPDATE_WORKSPACE_STEP_TITLE":
            return __assign(__assign({}, state), (0, reducerFunctions_1.updateStepTitle)(state, action.title, action.stepId));
        default:
            return state;
    }
}
exports.default = reducer;
