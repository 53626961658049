"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Borrowed from https://github.com/aceakash/string-similarity/blob/master/src/index.js
 * @param first
 * @param second
 * @returns number between 0 and 1, where 1 is first === second
 */
function stringSimilarity(first, second) {
    first = first.replace(/\s+/g, "");
    second = second.replace(/\s+/g, "");
    if (first === second)
        return 1; // identical or empty
    if (first.length < 2 || second.length < 2)
        return 0; // if either is a 0-letter or 1-letter string
    var firstBigrams = new Map();
    for (var i = 0; i < first.length - 1; i++) {
        var bigram = first.substring(i, i + 2);
        var count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;
        firstBigrams.set(bigram, count);
    }
    var intersectionSize = 0;
    for (var i = 0; i < second.length - 1; i++) {
        var bigram = second.substring(i, i + 2);
        var count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;
        if (count > 0) {
            firstBigrams.set(bigram, count - 1);
            intersectionSize++;
        }
    }
    return (2.0 * intersectionSize) / (first.length + second.length - 2);
}
exports.default = stringSimilarity;
