"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOGGLE_COMPLETE_TASK_GROUP_SUCCESS = exports.TOGGLE_COMPLETE_TASK_GROUP_FAILED = exports.TOGGLE_COMPLETE_TASK_GROUP_LOADING = exports.TASK_GROUP_CHANGE = exports.UPDATE_TASK_GROUP_INDEX_FAILED = exports.COLLABORATOR_UPDATE_TASK_GROUP_INDEX_SUCCESS = exports.UPDATE_TASK_GROUP_INDEX_SUCCESS = exports.UPDATE_TASK_GROUP_INDEX_OPTIMISTIC = exports.UPDATE_TASK_GROUP_INDEX_LOADING = exports.EXPORT_TASK_GROUP_FAILED = exports.EXPORT_TASK_GROUP_SUCCESS = exports.EXPORT_TASK_GROUP_LOADING = exports.DUPLICATE_TASK_GROUP_FAILED = exports.DUPLICATE_TASK_GROUP_SUCCESS = exports.DUPLICATE_TASK_GROUP_LOADING = exports.COLLAPSE_TASK_GROUP_FAILED = exports.COLLAPSE_TASK_GROUP_SUCCESS = exports.COLLAPSE_TASK_GROUP_LOADING = exports.DELETE_TASK_GROUP_FAILED = exports.DELETE_TASK_GROUP_SUCCESS = exports.DELETE_TASK_GROUP_LOADING = exports.PUT_TASK_GROUP_FAILED = exports.PUT_TASK_GROUP_SUCCESS = exports.PUT_TASK_GROUP_LOADING = exports.POST_TASK_GROUP_FAILED = exports.POST_TASK_GROUP_SUCCESS = exports.POST_TASK_GROUP_LOADING = void 0;
exports.POST_TASK_GROUP_LOADING = "POST_TASK_GROUP_LOADING";
exports.POST_TASK_GROUP_SUCCESS = "POST_TASK_GROUP_SUCCESS";
exports.POST_TASK_GROUP_FAILED = "POST_TASK_GROUP_FAILED";
exports.PUT_TASK_GROUP_LOADING = "PUT_TASK_GROUP_LOADING";
exports.PUT_TASK_GROUP_SUCCESS = "PUT_TASK_GROUP_SUCCESS";
exports.PUT_TASK_GROUP_FAILED = "PUT_TASK_GROUP_FAILED";
exports.DELETE_TASK_GROUP_LOADING = "DELETE_TASK_GROUP_LOADING";
exports.DELETE_TASK_GROUP_SUCCESS = "DELETE_TASK_GROUP_SUCCESS";
exports.DELETE_TASK_GROUP_FAILED = "DELETE_TASK_GROUP_FAILED";
exports.COLLAPSE_TASK_GROUP_LOADING = "COLLAPSE_TASK_GROUP_LOADING";
exports.COLLAPSE_TASK_GROUP_SUCCESS = "COLLAPSE_TASK_GROUP_SUCCESS";
exports.COLLAPSE_TASK_GROUP_FAILED = "COLLAPSE_TASK_GROUP_FAILED";
exports.DUPLICATE_TASK_GROUP_LOADING = "DUPLICATE_TASK_GROUP_LOADING";
exports.DUPLICATE_TASK_GROUP_SUCCESS = "DUPLICATE_TASK_GROUP_SUCCESS";
exports.DUPLICATE_TASK_GROUP_FAILED = "DUPLICATE_TASK_GROUP_FAILED";
exports.EXPORT_TASK_GROUP_LOADING = "EXPORT_TASK_GROUP_LOADING";
exports.EXPORT_TASK_GROUP_SUCCESS = "EXPORT_TASK_GROUP_SUCCESS";
exports.EXPORT_TASK_GROUP_FAILED = "EXPORT_TASK_GROUP_FAILED";
exports.UPDATE_TASK_GROUP_INDEX_LOADING = "UPDATE_TASK_GROUP_INDEX_LOADING";
exports.UPDATE_TASK_GROUP_INDEX_OPTIMISTIC = "UPDATE_TASK_GROUP_INDEX_OPTIMISTIC";
exports.UPDATE_TASK_GROUP_INDEX_SUCCESS = "UPDATE_TASK_GROUP_INDEX_SUCCESS";
exports.COLLABORATOR_UPDATE_TASK_GROUP_INDEX_SUCCESS = "COLLABORATOR_UPDATE_TASK_GROUP_INDEX_SUCCESS";
exports.UPDATE_TASK_GROUP_INDEX_FAILED = "UPDATE_TASK_GROUP_INDEX_FAILED";
exports.TASK_GROUP_CHANGE = "TASK_GROUP_CHANGE";
exports.TOGGLE_COMPLETE_TASK_GROUP_LOADING = "TOGGLE_COMPLETE_TASK_GROUP_LOADING";
exports.TOGGLE_COMPLETE_TASK_GROUP_FAILED = "TOGGLE_COMPLETE_TASK_GROUP_FAILED";
exports.TOGGLE_COMPLETE_TASK_GROUP_SUCCESS = "TOGGLE_COMPLETE_TASK_GROUP_SUCCESS";
