"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.d = exports.arrayMoveElement = void 0;
/**
 * immutably moves an element in an array
 */
function arrayMoveElement(array, from, to) {
    var copiedArray = array.slice();
    copiedArray.splice(to < 0 ? copiedArray.length + to : to, 0, copiedArray.splice(from, 1)[0]);
    return copiedArray;
}
exports.arrayMoveElement = arrayMoveElement;
function d() { }
exports.d = d;
