"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var dashboardConstants_1 = require("./dashboardConstants");
var initialState = {
    runIntro: false,
    notifications: {
        unread: 0,
        notifications: [],
    },
};
function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case dashboardConstants_1.RUN_INTRO:
            return __assign(__assign({}, state), { runIntro: action.run });
        case dashboardConstants_1.GET_NOTIFICATIONS_SUCCESS:
            return __assign(__assign({}, state), { notifications: action.notifications });
        case dashboardConstants_1.READ_NOTIFICATIONS_SUCCESS:
            return __assign(__assign({}, state), { notifications: __assign(__assign({}, state.notifications), { unread: state.notifications.unread - 1, notifications: state.notifications.notifications.map(function (notif) {
                        if (notif.id === action.id) {
                            return __assign(__assign({}, notif), { read: true });
                        }
                        return notif;
                    }) }) });
        case dashboardConstants_1.GET_NOTIFICATIONS_FAILED:
        case dashboardConstants_1.POST_NOTIFICATIONS_FAILED:
            return state;
        default:
            return state;
    }
}
exports.default = reducer;
