"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SAMMENBESKATNING_SUCCESS = exports.GET_SAMMENBESKATNING_LOADING = exports.GET_SAMMENBESKATNING_FAILED = exports.GET_MAP_GEOMETRY_SUCCESS = exports.GET_MAP_GEOMETRY_LOADING = exports.GET_MAP_GEOMETRY_FAILED = exports.GET_ADDRESS_FROM_MAP_SUCCESS = exports.GET_ADDRESS_FROM_MAP_LOADING = exports.GET_ADDRESS_FROM_MAP_FAILED = exports.GET_ADDRESS_SUCCESS = exports.GET_ADDRESS_LOADING = exports.GET_ADDRESS_FAILED = exports.EXPAND_STRUCTURE_FAILED = exports.EXPAND_STRUCTURE_SUCCESS = exports.EXPAND_STRUCTURE_LOADING = exports.GET_STRUCTURE_FAILED = exports.GET_STRUCTURE_SUCCESS = exports.GET_STRUCTURE_LOADING = exports.CLEAR_STRUCTURE = exports.GET_COMPANY_FAILED = exports.GET_COMPANY_SUCCESS = exports.GET_COMPANY_LOADING = exports.GET_COMPANIES_FAILED = exports.GET_COMPANIES_SUCCESS = exports.GET_COMPANIES_LOADING = void 0;
exports.GET_COMPANIES_LOADING = "GET_COMPANIES_LOADING";
exports.GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
exports.GET_COMPANIES_FAILED = "GET_COMPANIES_FAILED";
exports.GET_COMPANY_LOADING = "GET_COMPANY_LOADING";
exports.GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
exports.GET_COMPANY_FAILED = "GET_COMPANY_FAILED";
exports.CLEAR_STRUCTURE = "CLEAR_STRUCTURE";
exports.GET_STRUCTURE_LOADING = "GET_STRUCTURE_LOADING";
exports.GET_STRUCTURE_SUCCESS = "GET_STRUCTURE_SUCCESS";
exports.GET_STRUCTURE_FAILED = "GET_STRUCTURE_FAILED";
exports.EXPAND_STRUCTURE_LOADING = "EXPAND_STRUCTURE_LOADING";
exports.EXPAND_STRUCTURE_SUCCESS = "EXPAND_STRUCTURE_SUCCESS";
exports.EXPAND_STRUCTURE_FAILED = "EXPAND_STRUCTURE_FAILED";
exports.GET_ADDRESS_FAILED = "GET_ADDRESS_FAILED";
exports.GET_ADDRESS_LOADING = "GET_ADDRESS_LOADING";
exports.GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
exports.GET_ADDRESS_FROM_MAP_FAILED = "GET_ADDRESS_FROM_MAP_FAILED";
exports.GET_ADDRESS_FROM_MAP_LOADING = "GET_ADDRESS_FROM_MAP_LOADING";
exports.GET_ADDRESS_FROM_MAP_SUCCESS = "GET_ADDRESS_FROM_MAP_SUCCESS";
exports.GET_MAP_GEOMETRY_FAILED = "GET_MAP_GEOMETRY_FAILED";
exports.GET_MAP_GEOMETRY_LOADING = "GET_MAP_GEOMETRY_LOADING";
exports.GET_MAP_GEOMETRY_SUCCESS = "GET_MAP_GEOMETRY_SUCCESS";
exports.GET_SAMMENBESKATNING_FAILED = "GET_SAMMENBESKATNING_FAILED";
exports.GET_SAMMENBESKATNING_LOADING = "GET_SAMMENBESKATNING_LOADING";
exports.GET_SAMMENBESKATNING_SUCCESS = "GET_SAMMENBESKATNING_SUCCESS";
