"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useContext = function () {
    var _a = (0, react_1.useState)(false), showContext = _a[0], setShowContext = _a[1];
    var handelShowContext = (0, react_1.useCallback)(function () {
        setShowContext(true);
    }, []);
    var handelHideContext = (0, react_1.useCallback)(function () {
        setShowContext(false);
    }, []);
    return {
        showContext: showContext,
        handelShowContext: handelShowContext,
        handelHideContext: handelHideContext,
    };
};
exports.default = useContext;
