"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable react-hooks/rules-of-hooks */
var React = __importStar(require("react"));
var core_1 = require("@react-pdf-viewer/core");
var jumpPlugin = function () {
    var store = React.useMemo(function () { return (0, core_1.createStore)(); }, []);
    return {
        install: function (pluginFunctions) {
            store.update("getScale", function () { return pluginFunctions.getViewerState().scale; });
            store.update("jumpToDestination", pluginFunctions.jumpToDestination);
            store.update("jumpToPage", pluginFunctions.jumpToPage);
        },
        jumpToDestination: function (destination) {
            var fn = store.get("jumpToDestination");
            if (fn) {
                fn(destination);
            }
        },
        jumpToPage: function (pageIndex) {
            var fn = store.get("jumpToPage");
            if (fn) {
                fn(pageIndex);
            }
        },
        getScale: function () {
            var _a;
            return ((_a = store.get("getScale")) === null || _a === void 0 ? void 0 : _a()) || 1;
        },
    };
};
exports.default = jumpPlugin;
