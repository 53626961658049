"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextService = exports.FontFace = void 0;
exports.FontFace = {
    Arial: "Arial",
    Comic_Sans: "Comic Sans MS",
    Georgia: "Georgia",
    Open_Sans: "Open Sans",
    Times_New_Roman: "Times New Roman",
};
var TextService = /** @class */ (function () {
    function TextService() {
    }
    TextService.textwidth = function (text, size, fontFace) {
        if (size === void 0) { size = 10; }
        var element = document.createElement("span");
        element.style.visibility = "hidden";
        element.style.position = "fixed";
        element.style.whiteSpace = "nowrap"; // To get the width as one line
        element.style.fontFamily = fontFace;
        element.style.fontSize = "".concat(size, "px");
        element.innerText = text;
        document.body.appendChild(element);
        var width = element.offsetWidth;
        document.body.removeChild(element);
        return width;
    };
    TextService.nodeDimensionsFromLabel = function (text, fontSize, fontFace) {
        return {
            width: 20 + Math.ceil(this.textwidth(text, fontSize, fontFace)),
            height: 50,
        };
    };
    return TextService;
}());
exports.TextService = TextService;
exports.default = TextService;
