"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unhighlightItems = exports.highlightItems = void 0;
var highlightItems = function (ids, type) {
    ids.forEach(function (id) {
        var htmlNode = type === "nodes"
            ? document.querySelector("[data-id=\"".concat(id, "\"]"))
            : document.querySelector(".id_".concat(id));
        htmlNode === null || htmlNode === void 0 ? void 0 : htmlNode.setAttribute("data-alert-pulse", "pulse");
        htmlNode === null || htmlNode === void 0 ? void 0 : htmlNode.classList.add("pulse");
    });
};
exports.highlightItems = highlightItems;
var unhighlightItems = function (ids, type) {
    ids.forEach(function (id) {
        var htmlNode = type === "nodes"
            ? document.querySelector("[data-id=\"".concat(id, "\"]"))
            : document.querySelector(".id_".concat(id));
        htmlNode === null || htmlNode === void 0 ? void 0 : htmlNode.removeAttribute("data-alert-pulse");
        htmlNode === null || htmlNode === void 0 ? void 0 : htmlNode.classList.remove("pulse");
    });
};
exports.unhighlightItems = unhighlightItems;
