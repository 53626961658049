"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutputContentTypes = exports.OutputTypes = void 0;
var OutputTypes;
(function (OutputTypes) {
    OutputTypes["REPORT"] = "report";
    OutputTypes["SLIDE"] = "slide";
})(OutputTypes = exports.OutputTypes || (exports.OutputTypes = {}));
var OutputContentTypes;
(function (OutputContentTypes) {
    OutputContentTypes["DRAFT"] = "draft";
    OutputContentTypes["UPLOAD"] = "upload";
})(OutputContentTypes = exports.OutputContentTypes || (exports.OutputContentTypes = {}));
