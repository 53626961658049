"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DELETE_BOARD_VIEW_FAILED = exports.DELETE_BOARD_VIEW_SUCCESS = exports.DELETE_BOARD_VIEW_LOADING = exports.SET_AS_DEFAULT_BOARD_VIEW_FAILED = exports.SET_AS_DEFAULT_BOARD_VIEW_SUCCESS = exports.SET_AS_DEFAULT_BOARD_VIEW_LOADING = exports.RENMAE_BOARD_VIEW_FAILED = exports.RENMAE_BOARD_VIEW_SUCCESS = exports.RENMAE_BOARD_VIEW_LOADING = exports.POST_BOARD_VIEW_FAILED = exports.POST_BOARD_VIEW_SUCCESS = exports.POST_BOARD_VIEW_LOADING = void 0;
exports.POST_BOARD_VIEW_LOADING = "POST_BOARD_VIEW_LOADING";
exports.POST_BOARD_VIEW_SUCCESS = "POST_BOARD_VIEW_SUCCESS";
exports.POST_BOARD_VIEW_FAILED = "POST_BOARD_VIEW_FAILED";
exports.RENMAE_BOARD_VIEW_LOADING = "RENMAE_BOARD_VIEW_LOADING";
exports.RENMAE_BOARD_VIEW_SUCCESS = "RENMAE_BOARD_VIEW_SUCCESS";
exports.RENMAE_BOARD_VIEW_FAILED = "RENMAE_BOARD_VIEW_FAILED";
exports.SET_AS_DEFAULT_BOARD_VIEW_LOADING = "SET_AS_DEFAULT_BOARD_VIEW_LOADING";
exports.SET_AS_DEFAULT_BOARD_VIEW_SUCCESS = "SET_AS_DEFAULT_BOARD_VIEW_SUCCESS";
exports.SET_AS_DEFAULT_BOARD_VIEW_FAILED = "SET_AS_DEFAULT_BOARD_VIEW_FAILED";
exports.DELETE_BOARD_VIEW_LOADING = "DELETE_BOARD_VIEW_LOADING";
exports.DELETE_BOARD_VIEW_SUCCESS = "DELETE_BOARD_VIEW_SUCCESS";
exports.DELETE_BOARD_VIEW_FAILED = "DELETE_BOARD_VIEW_FAILED";
