"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESET_NEW_PROPERTIES = exports.REMOVE_NEW_PROPERTY = exports.CHANGE_NEW_PROPERTY_JORDSTYKKE = exports.CHANGE_NEW_PROPERTY_TITLE = exports.ADD_NEW_PROPERTY = exports.CHANGE_PROPERTY_JORDSTYKKE = exports.CHANGE_PROPERTY_TITLE = exports.PROPERTY_DELETE_FAILED = exports.PROPERTY_DELETE_SUCCESS = exports.PROPERTY_DELETE_LOADING = exports.PROPERTY_SAVE_FAILED = exports.PROPERTY_SAVE_SUCCESS = exports.PROPERTY_SAVE_LOADING = exports.PROPERTY_INDEX_FAILED = exports.PROPERTY_INDEX_SUCCESS = exports.PROPERTY_INDEX_LOADING = exports.GET_JORDSTYKKER_AUTOCOMPLETE_FAILED = exports.GET_JORDSTYKKER_AUTOCOMPLETE_SUCCESS = exports.GET_JORDSTYKKER_AUTOCOMPLETE_LOADING = exports.GET_PROPERTY_CADESTREAL_FROM_BOUNDS_FAILED = exports.GET_PROPERTY_CADESTREAL_FROM_BOUNDS_SUCCESS = exports.GET_PROPERTY_CADESTREAL_FROM_BOUNDS_LOADING = exports.GET_PROPERTY_MAPCENTER_FAILED = exports.GET_PROPERTY_MAPCENTER_SUCCESS = exports.GET_PROPERTY_MAPCENTER_LOADING = void 0;
exports.GET_PROPERTY_MAPCENTER_LOADING = "GET_PROPERTY_MAPCENTER_LOADING";
exports.GET_PROPERTY_MAPCENTER_SUCCESS = "GET_PROPERTY_MAPCENTER_SUCCESS";
exports.GET_PROPERTY_MAPCENTER_FAILED = "GET_PROPERTY_MAPCENTER_FAILED";
exports.GET_PROPERTY_CADESTREAL_FROM_BOUNDS_LOADING = "GET_PROPERTY_CADESTREAL_FROM_BOUNDS_LOADING";
exports.GET_PROPERTY_CADESTREAL_FROM_BOUNDS_SUCCESS = "GET_PROPERTY_CADESTREAL_FROM_BOUNDS_SUCCESS";
exports.GET_PROPERTY_CADESTREAL_FROM_BOUNDS_FAILED = "GET_PROPERTY_CADESTREAL_FROM_BOUNDS_FAILED";
exports.GET_JORDSTYKKER_AUTOCOMPLETE_LOADING = "GET_JORDSTYKKER_AUTOCOMPLETE_LOADING";
exports.GET_JORDSTYKKER_AUTOCOMPLETE_SUCCESS = "GET_JORDSTYKKER_AUTOCOMPLETE_SUCCESS";
exports.GET_JORDSTYKKER_AUTOCOMPLETE_FAILED = "GET_JORDSTYKKER_AUTOCOMPLETE_FAILED";
exports.PROPERTY_INDEX_LOADING = "PROPERTY_INDEX_LOADING";
exports.PROPERTY_INDEX_SUCCESS = "PROPERTY_INDEX_SUCCESS";
exports.PROPERTY_INDEX_FAILED = "PROPERTY_INDEX_FAILED";
exports.PROPERTY_SAVE_LOADING = "PROPERTY_SAVE_LOADING";
exports.PROPERTY_SAVE_SUCCESS = "PROPERTY_SAVE_SUCCESS";
exports.PROPERTY_SAVE_FAILED = "PROPERTY_SAVE_FAILED";
exports.PROPERTY_DELETE_LOADING = "PROPERTY_DELETE_LOADING";
exports.PROPERTY_DELETE_SUCCESS = "PROPERTY_DELETE_SUCCESS";
exports.PROPERTY_DELETE_FAILED = "PROPERTY_DELETE_FAILED";
exports.CHANGE_PROPERTY_TITLE = "CHANGE_PROPERTY_TITLE";
exports.CHANGE_PROPERTY_JORDSTYKKE = "CHANGE_PROPERTY_JORDSTYKKE";
exports.ADD_NEW_PROPERTY = "ADD_NEW_PROPERTY";
exports.CHANGE_NEW_PROPERTY_TITLE = "CHANGE_NEW_PROPERTY_TITLE";
exports.CHANGE_NEW_PROPERTY_JORDSTYKKE = "CHANGE_NEW_PROPERTY_JORDSTYKKE";
exports.REMOVE_NEW_PROPERTY = "REMOVE_NEW_PROPERTY";
exports.RESET_NEW_PROPERTIES = "RESET_NEW_PROPERTIES";
