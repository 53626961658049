"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var tagsConstants_1 = require("./tagsConstants");
var initialState = {
    tags: [],
};
function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case tagsConstants_1.TAG_INDEX_SUCCESS:
            return __assign(__assign({}, state), { tags: action.tags });
        case tagsConstants_1.TAG_DELETE_SUCCESS:
            return __assign(__assign({}, state), { tags: state.tags.filter(function (tag) { return tag.id !== action.id; }) });
        case tagsConstants_1.TAG_POST_SUCCESS:
            return __assign(__assign({}, state), { tags: __spreadArray(__spreadArray([], state.tags, true), [action.tag], false) });
        case tagsConstants_1.TAG_UPDATE_SUCCESS:
            return __assign(__assign({}, state), { tags: state.tags.map(function (tag) {
                    if (tag.id === action.tag.id) {
                        return action.tag;
                    }
                    return tag;
                }) });
        case tagsConstants_1.CHANGE_TAGS_ACTIVE:
            return __assign(__assign({}, state), { tags: state.tags.map(function (tag) {
                    if (tag.id === action.tag.id) {
                        return __assign(__assign({}, tag), { active: !tag.active });
                    }
                    return tag;
                }) });
        case tagsConstants_1.TAG_DELETE_FAILED:
        case tagsConstants_1.TAG_UPDATE_FAILED:
        case tagsConstants_1.TAG_POST_FAILED:
        case tagsConstants_1.TAG_INDEX_FAILED:
        default:
            return state;
    }
}
exports.default = reducer;
