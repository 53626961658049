"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var reactflow_1 = require("reactflow");
var styles_1 = require("@mui/material/styles");
var canvasStyle = {
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 10,
    pointerEvents: "none"
};
var storeSelector = function (state) { return ({
    width: state.width,
    height: state.height,
    transform: state.transform
}); };
// a simple component to display the helper lines
// it puts a canvas on top of the React Flow pane and draws the lines using the canvas API
function HelperLinesRenderer(_a) {
    var horizontal = _a.horizontal, vertical = _a.vertical;
    var _b = (0, reactflow_1.useStore)(storeSelector), width = _b.width, height = _b.height, transform = _b.transform;
    var theme = (0, styles_1.useTheme)();
    var canvasRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        var canvas = canvasRef.current;
        var ctx = canvas === null || canvas === void 0 ? void 0 : canvas.getContext("2d");
        if (!ctx || !canvas) {
            return;
        }
        var dpi = window.devicePixelRatio;
        canvas.width = width * dpi;
        canvas.height = height * dpi;
        ctx.scale(dpi, dpi);
        ctx.clearRect(0, 0, width, height);
        ctx.strokeStyle = theme.palette.primary.main;
        if (typeof vertical === "number") {
            ctx.moveTo(vertical * transform[2] + transform[0], 0);
            ctx.lineTo(vertical * transform[2] + transform[0], height);
            ctx.stroke();
        }
        if (typeof horizontal === "number") {
            ctx.moveTo(0, horizontal * transform[2] + transform[1]);
            ctx.lineTo(width, horizontal * transform[2] + transform[1]);
            ctx.stroke();
        }
    }, [width, height, transform, horizontal, vertical]);
    return react_1.default.createElement("canvas", { ref: canvasRef, className: "react-flow__canvas", style: canvasStyle });
}
exports.default = HelperLinesRenderer;
