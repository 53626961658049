"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.body = exports.title = exports.DEFAULT_BODY = exports.DEFAULT_TITLE = void 0;
exports.DEFAULT_TITLE = 
// eslint-disable-next-line @typescript-eslint/quotes
'<h1><span style="font-family: Calibri, sans-serif; font-weight: bold; font-size: 32pt;"></span></h1>';
exports.DEFAULT_BODY = 
// eslint-disable-next-line @typescript-eslint/quotes
'<span style="font-family: Cambria, serif; font-size: 16pt;"></span>';
exports.title = {
    fontSize: "32pt",
    fontFamily: "Calibri, sans-serif",
    paragraphFormat: "H1",
    inlineStyle: "font-weight: bold;",
};
exports.body = {
    fontSize: "16pt",
    fontFamily: "Cambria, serif",
    paragraphFormat: "P",
};
var moreTextButtons = [
    "fontSize",
    "fontFamily",
    "inlineStyle",
    "bold",
    "italic",
    "underline",
    "strikeThrough",
    "textColor",
    "backgroundColor",
    "subscript",
    "superscript",
    "clearFormatting",
];
var moreParagraphButtons = [
    "formatOL",
    "formatUL",
    "alignLeft",
    "alignCenter",
    "alignRight",
    "alignJustify",
    "lineHeight",
    "outdent",
    "indent",
    "quote",
];
var options = {
    fontSize: ["8", "9", "10", "11", "12", "14", "18", "24", "30", "36"],
    toolbarButtons: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 5,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 5,
        },
        moreRich: {
            buttonsVisible: 0,
            buttons: ["insertTable", "insertHR"],
        },
        moreMisc: {
            buttonsVisible: 2,
            align: "right",
            buttons: ["undo", "redo"],
        },
    },
    toolbarButtonsMD: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 4,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 3,
        },
        moreRich: {
            buttons: ["insertTable", "insertHR"],
            buttonsVisible: 2,
        },
        moreMisc: {
            buttons: ["undo", "redo"],
            buttonsVisible: 2,
            align: "right",
        },
    },
    toolbarButtonsSM: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 3,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 2,
        },
        moreRich: {
            buttons: ["insertTable", "insertHR"],
            buttonsVisible: 0,
        },
        moreMisc: {
            buttons: ["undo", "redo"],
            buttonsVisible: 2,
            align: "right",
        },
    },
    toolbarButtonsXS: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 1,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 1,
        },
        moreRich: {
            buttons: ["insertTable", "insertHR", "undo", "redo"],
            buttonsVisible: 2,
        },
    },
    fontFamilySelection: true,
    fontSizeSelection: true,
    tableResizer: true,
    pluginsEnabled: [
        "table",
        "spell",
        "quote",
        "save",
        "paragraphFormat",
        "help",
        "draggable",
        "align",
        "link",
        "lists",
        "colors",
        "entities",
        "lineHeight",
        "inlineStyle",
        "fontFamily",
        "fontSize",
    ],
    disableRightClick: true,
    keepFormatOnDelete: true,
    inlineStyles: {
        Headings: "font-size: 32pt; font-family: Calibri; font-weight: bold;",
        Body: "font-size: 16pt; font-family: Cambria;",
        Subtitle: "font-size: 16pt; font-weight: bold; font-family: Cambria;",
        "Subtitle 2": "font-size: 16pt; font-style: italic; font-family: Cambria;",
    },
    lineHeights: {
        Default: "normal",
        Single: "1",
        1.15: "1.15",
        1.5: "1.5",
        Double: "2",
    },
    tableEditButtons: [
        "tableHeader",
        "tableStyle",
        "|",
        "tableRows",
        "tableColumns",
        "tableCells",
        "-",
        "tableCellBackground",
        "|",
        "tableCellVerticalAlign",
        "tableCellHorizontalAlign",
        "|",
        "tableRemove",
    ],
    tableResizerOffset: 15,
};
var config = {
    DEFAULT_TITLE: exports.DEFAULT_TITLE,
    DEFAULT_BODY: exports.DEFAULT_BODY,
    title: exports.title,
    body: exports.body,
    options: options,
};
exports.default = config;
