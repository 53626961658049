"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducer = exports.initialState = exports.REMOVE_API_REF = exports.ADD_API_REF = void 0;
exports.ADD_API_REF = "ADD_API_REF";
exports.REMOVE_API_REF = "REMOVE_API_REF";
exports.initialState = {
    apiRefs: new Map(),
};
var reducer = function (state, _a) {
    if (state === void 0) { state = exports.initialState; }
    var type = _a.type, payload = _a.payload;
    switch (type) {
        case exports.ADD_API_REF: {
            var apiRefs = new Map(state.apiRefs);
            apiRefs.set(payload.id, payload.apiRef);
            return __assign(__assign({}, state), { apiRefs: apiRefs });
        }
        case exports.REMOVE_API_REF: {
            var currentApiRefs = new Map(state.apiRefs);
            currentApiRefs.delete(payload.id);
            return __assign(__assign({}, state), { apiRefs: currentApiRefs });
        }
        default:
            return state;
    }
};
exports.reducer = reducer;
