"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.edgesText = exports.nodesText = void 0;
function nodesText(t, singular, plural, nodes, others, ids) {
    var count = (ids === null || ids === void 0 ? void 0 : ids.length) || 0;
    if (count === 1) {
        if (nodes === null || nodes === void 0 ? void 0 : nodes[0]) {
            return { count: count, text: "".concat(nodes[0], " ").concat(singular, " ").concat(t("generic.on")) };
        }
        if (others === null || others === void 0 ? void 0 : others[0]) {
            return {
                count: count,
                text: "".concat(t("workspaces.node_types.".concat(others[0])), " ").concat(singular, " ").concat(t("generic.on")),
            };
        }
    }
    else if (count > 1) {
        return {
            count: count,
            text: "".concat(count, " ").concat(t("tools.table.workspace_nodes_plural"), " ").concat(plural, " ").concat(t("generic.on")),
        };
    }
    return {
        count: count,
        text: "".concat(t("tools.table.workspace_nodes_plural"), " ").concat(plural, " ").concat(t("generic.on")),
    };
}
exports.nodesText = nodesText;
function edgesText(t, singular, plural, edges) {
    var count = (edges === null || edges === void 0 ? void 0 : edges.length) || 0;
    if (count === 1) {
        return {
            count: count,
            text: "".concat(t("tools.table.relationships"), " ").concat(singular, " ").concat(t("generic.on")),
        };
    }
    if (count > 1) {
        return {
            count: count,
            text: "".concat(count, " ").concat(t("tools.table.relationships_plural"), " ").concat(plural, " ").concat(t("generic.on")),
        };
    }
    return {
        count: count,
        text: "".concat(t("tools.table.relationships_plural"), " ").concat(plural, " ").concat(t("generic.on")),
    };
}
exports.edgesText = edgesText;
