"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var styles_1 = require("@customTypes/styles");
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (theme) { return ({
    drawer: {
        "& .MuiBackdrop-root": { backgroundColor: "transparent" },
        "& .MuiDrawer-paper": {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(1),
            padding: theme.spacing(2),
            width: 400,
        },
    },
    drawerHeader: { display: "flex", alignItems: "center", gap: theme.spacing(1) },
    scrollable: __assign({ width: "100%", position: "relative", overflowY: "auto" }, (0, styles_1.JuristicScroll)(theme, { size: 0.2 })),
    scrollableHeight: { maxHeight: 300 },
    columnFlex: { display: "flex", flexDirection: "column", gap: theme.spacing(1) },
}); });
