"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var reactFlow_1 = require("@customTypes/reactFlow");
var react_1 = require("react");
var useFlowContextMenus = function () {
    var _a = (0, react_1.useState)({ x: 0, y: 0 }), contextAnchor = _a[0], setContextAnchor = _a[1];
    var _b = (0, react_1.useState)(null), contextNode = _b[0], setContextNode = _b[1];
    var _c = (0, react_1.useState)(null), _contextEdge = _c[0], setContextEdge = _c[1];
    var _d = (0, react_1.useState)(null), contextSelection = _d[0], setContextSelction = _d[1];
    var _e = (0, react_1.useState)(reactFlow_1.ContextTypes.Pane), contextType = _e[0], setContextType = _e[1];
    var handleNodeContextMenu = (0, react_1.useCallback)(function (e, n) {
        setContextAnchor({
            x: e.pageX,
            y: e.pageY,
        });
        setContextNode(n);
        setContextType(reactFlow_1.ContextTypes.Node);
    }, []);
    var handlePaneContextMenu = (0, react_1.useCallback)(function (e) {
        setContextAnchor({
            x: e.pageX,
            y: e.pageY,
        });
        setContextType(reactFlow_1.ContextTypes.Pane);
    }, []);
    var handleSelectionContextMenu = (0, react_1.useCallback)(function (e, _nodes) {
        setContextAnchor({
            x: e.pageX,
            y: e.pageY,
        });
        setContextSelction(_nodes);
        setContextType(reactFlow_1.ContextTypes.Selection);
    }, []);
    var handleEdgeContextMenu = (0, react_1.useCallback)(function (e, ed) {
        setContextAnchor({
            x: e.pageX,
            y: e.pageY,
        });
        setContextEdge(ed);
        setContextType(reactFlow_1.ContextTypes.Edge);
    }, []);
    return {
        handleNodeContextMenu: handleNodeContextMenu,
        handlePaneContextMenu: handlePaneContextMenu,
        handleSelectionContextMenu: handleSelectionContextMenu,
        handleEdgeContextMenu: handleEdgeContextMenu,
        contextAnchor: contextAnchor,
        contextNode: contextNode,
        contextSelection: contextSelection,
        contextType: contextType,
    };
};
exports.default = useFlowContextMenus;
