"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var timelineActions_http_1 = require("@pages/Timelines/reducers/timelineActions.http");
var redux_1 = require("@hooks/redux");
var auth0_react_1 = require("@auth0/auth0-react");
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("@api/constants");
var react_1 = require("react");
function useElementModal(setElementPersons, setElementDocuments, elementPersons, elementDocuments, correctEmailsOpen, nodes, timelineNode) {
    var user = (0, auth0_react_1.useAuth0)().user;
    var location = (0, react_router_dom_1.useLocation)();
    var id = (0, constants_1.getId)(location);
    var dispatch = (0, redux_1.useAppDispatch)();
    var isCreateElementOpen = (0, redux_1.useAppSelector)(function (state) { return state.timeline.createElementOpen; });
    var currSplittingNodeRefference = (0, redux_1.useAppSelector)(function (state) { return state.timeline.currSplittingNodeRefference; });
    var showThumbnails = (0, redux_1.useAppSelector)(function (state) { return state.timeline.showThumbnails; });
    var isUpdatingNode = (0, redux_1.useAppSelector)(function (state) { return state.timeline.isUpdatingNode; });
    var handleCloseCreateElement = (0, react_1.useCallback)(function () {
        dispatch((0, timelineActions_http_1.setTimelineNode)(null));
        setElementPersons([]);
        setElementDocuments([]);
        dispatch((0, timelineActions_http_1.createElementChange)(false));
    }, []);
    var nextElWithoutDate = (0, react_1.useCallback)(function () {
        var badges = nodes.filter(function (e) {
            return !["deadline", "addItem", "stickyNote"].includes(e.type) &&
                !e.data.date &&
                e.id !== "".concat(timelineNode.id);
        });
        if (badges.length > 0) {
            dispatch((0, timelineActions_http_1.setTimelineNode)(badges[0].data));
        }
        else {
            handleCloseCreateElement();
        }
    }, [nodes, timelineNode]);
    var onSaveElement = (0, react_1.useCallback)(function (alternativeCloseFunc, customSplit) {
        var _a, _b, _c, _d;
        dispatch((0, timelineActions_http_1.setTimelineNode)(null));
        setElementPersons([]);
        setElementDocuments([]);
        var closeFunc = handleCloseCreateElement;
        if (correctEmailsOpen) {
            closeFunc = nextElWithoutDate;
        }
        if (alternativeCloseFunc) {
            closeFunc = alternativeCloseFunc;
        }
        var nodeFromState = __assign({}, nodes.find(function (n) { return n.id === "".concat(timelineNode.id); }));
        var hasDatetimeChange = ((_a = timelineNode === null || timelineNode === void 0 ? void 0 : timelineNode.date) === null || _a === void 0 ? void 0 : _a.diff((_b = nodeFromState === null || nodeFromState === void 0 ? void 0 : nodeFromState.data) === null || _b === void 0 ? void 0 : _b.date)) !==
            0 ||
            ((_c = timelineNode === null || timelineNode === void 0 ? void 0 : timelineNode.time) === null || _c === void 0 ? void 0 : _c.diff((_d = nodeFromState === null || nodeFromState === void 0 ? void 0 : nodeFromState.data) === null || _d === void 0 ? void 0 : _d.date)) !==
                0;
        if (isUpdatingNode) {
            dispatch((0, timelineActions_http_1.putElement)(user, timelineNode, elementPersons, elementDocuments, closeFunc, hasDatetimeChange, id, showThumbnails));
        }
        else {
            dispatch((0, timelineActions_http_1.saveElement)(user, id, location, timelineNode, currSplittingNodeRefference, customSplit, elementPersons, elementDocuments, closeFunc, showThumbnails));
        }
    }, [
        user,
        timelineNode,
        elementPersons,
        elementDocuments,
        nodes,
        correctEmailsOpen,
        showThumbnails,
        id,
        location,
        currSplittingNodeRefference,
    ]);
    var changeTimelineNode = (0, react_1.useCallback)(function (attr, val) {
        dispatch((0, timelineActions_http_1.changeTimelineNodeKey)(val, attr));
    }, []);
    return {
        handleCloseCreateElement: handleCloseCreateElement,
        onSaveElement: onSaveElement,
        changeTimelineNode: changeTimelineNode,
        isCreateElementOpen: isCreateElementOpen,
    };
}
exports.default = useElementModal;
