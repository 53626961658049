"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TOGGLE_USE_GLOBAL_FILE = exports.CHANGE_NAME_FILE = exports.CLOSE_FILE = exports.OPEN_FILE = exports.DELETE_FILES_FAILED = exports.DELETE_FILES_SUCCESS = exports.DELETE_FILES_LOADING = exports.PUT_FILES_FAILED = exports.PUT_FILES_SUCCESS = exports.PUT_FILES_LOADING = exports.POST_FILES_FAILED = exports.POST_FILES_SUCCESS = exports.POST_FILES_LOADING = exports.GET_FILES_FAILED = exports.GET_FILES_SUCCESS = exports.GET_FILES_LOADING = void 0;
exports.GET_FILES_LOADING = "GET_FILES_LOADING";
exports.GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
exports.GET_FILES_FAILED = "GET_FILES_FAILED";
exports.POST_FILES_LOADING = "POST_FILES_LOADING";
exports.POST_FILES_SUCCESS = "POST_FILES_SUCCESS";
exports.POST_FILES_FAILED = "POST_FILES_FAILED";
exports.PUT_FILES_LOADING = "PUT_FILES_LOADING";
exports.PUT_FILES_SUCCESS = "PUT_FILES_SUCCESS";
exports.PUT_FILES_FAILED = "PUT_FILES_FAILED";
exports.DELETE_FILES_LOADING = "DELETE_FILES_LOADING";
exports.DELETE_FILES_SUCCESS = "DELETE_FILES_SUCCESS";
exports.DELETE_FILES_FAILED = "DELETE_FILES_FAILED";
exports.OPEN_FILE = "OPEN_FILE";
exports.CLOSE_FILE = "CLOSE_FILE";
exports.CHANGE_NAME_FILE = "CHANGE_NAME_FILE";
exports.TOGGLE_USE_GLOBAL_FILE = "TOGGLE_USE_GLOBAL_FILE";
