"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var icons_material_1 = require("@mui/icons-material");
var material_1 = require("@mui/material");
var material_ui_popup_state_1 = __importStar(require("material-ui-popup-state"));
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
function PermissionSetContentItemsToggle(_a) {
    var permittedItem = _a.permittedItem, onUpdatePermittedItem = _a.onUpdatePermittedItem, canViewAll = _a.canViewAll;
    var t = (0, react_i18next_1.useTranslation)().t;
    return (react_1.default.createElement(material_ui_popup_state_1.default, { variant: "popover", popupId: "more" }, function (popupState) { return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(material_1.IconButton, __assign({}, (0, material_ui_popup_state_1.bindTrigger)(popupState), { disabled: canViewAll }), permittedItem.canEdit ? react_1.default.createElement(icons_material_1.Edit, null) : react_1.default.createElement(icons_material_1.Visibility, null)),
        react_1.default.createElement(material_1.Menu, __assign({}, (0, material_ui_popup_state_1.bindMenu)(popupState)),
            react_1.default.createElement(material_1.MenuList, { dense: true, disablePadding: true },
                react_1.default.createElement(material_1.MenuItem, { selected: !permittedItem.canEdit, disabled: !permittedItem.canEdit, onClick: function () {
                        onUpdatePermittedItem({
                            item: {
                                id: permittedItem.id,
                                type: permittedItem.type,
                                canEdit: false,
                            },
                        });
                        popupState.close();
                    } },
                    react_1.default.createElement(material_1.ListItemIcon, null,
                        react_1.default.createElement(icons_material_1.Visibility, { fontSize: "small" })),
                    react_1.default.createElement(material_1.ListItemText, null, t("flow.permissions.permissionInfo.view"))),
                react_1.default.createElement(material_1.MenuItem, { selected: permittedItem.canEdit, disabled: permittedItem.canEdit, onClick: function () {
                        onUpdatePermittedItem({
                            item: {
                                id: permittedItem.id,
                                type: permittedItem.type,
                                canEdit: true,
                            },
                        });
                        popupState.close();
                    } },
                    react_1.default.createElement(material_1.ListItemIcon, null,
                        react_1.default.createElement(icons_material_1.Edit, { fontSize: "small" })),
                    react_1.default.createElement(material_1.ListItemText, null, t("flow.permissions.permissionInfo.edit"))))))); }));
}
exports.default = (0, react_1.memo)(PermissionSetContentItemsToggle);
