"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var folderConstants_1 = require("@redux/constants/folderConstants");
var initialState = {
    folders: [],
    activeFolders: {},
    loadings: constants_1.initialLoadings,
};
function reducer(state, action) {
    var _a, _b, _c, _d;
    var _e, _f, _g, _h;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case folderConstants_1.ENTER_FOLDER: {
            var items = (_e = state.activeFolders[action.where]) === null || _e === void 0 ? void 0 : _e.items;
            return __assign(__assign({}, state), { activeFolders: __assign(__assign({}, state.activeFolders), (_a = {}, _a[action.where] = items
                    ? __assign(__assign({}, state.activeFolders[action.where]), { items: __spreadArray(__spreadArray([], items, true), [action.folder], false) }) : __assign(__assign({}, state.activeFolders[action.where]), { items: [action.folder] }), _a)) });
        }
        case folderConstants_1.GO_BACK_TO_FOLDER:
            return __assign(__assign({}, state), { activeFolders: __assign(__assign({}, state.activeFolders), (_b = {}, _b[action.where] = __assign(__assign({}, state.activeFolders[action.where]), { items: (_g = (_f = state.activeFolders[action.where]) === null || _f === void 0 ? void 0 : _f.items) === null || _g === void 0 ? void 0 : _g.slice(0, action.index + 1) }), _b)) });
        case folderConstants_1.LEAVE_FOLDERS:
            return __assign(__assign({}, state), { activeFolders: __assign(__assign({}, state.activeFolders), (_c = {}, _c[action.where] = ((_h = state.activeFolders[action.where]) === null || _h === void 0 ? void 0 : _h.location) !== action.location
                    ? { items: [], location: action.location }
                    : state.activeFolders[action.where], _c)) });
        case folderConstants_1.LEAVE_ALL_FOLDERS:
            return __assign(__assign({}, state), { activeFolders: {} });
        case folderConstants_1.GET_FOLDER_TREE_LOADING:
            return __assign(__assign({}, state), { folders: [], loadings: __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadings] = true, _d)) });
        case folderConstants_1.GET_FOLDER_TREE_FAILED:
            return __assign(__assign({}, state), { folders: [], loadings: constants_1.initialLoadings });
        case folderConstants_1.GET_FOLDER_TREE_SUCCESS:
            return __assign(__assign({}, state), { folders: action.folders, loadings: constants_1.initialLoadings });
        case folderConstants_1.CLEAR_FOLDERS:
            return __assign(__assign({}, state), { folders: [] });
        default:
            return state;
    }
}
exports.default = reducer;
