"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var timelineActions_http_1 = require("@pages/Timelines/reducers/timelineActions.http");
var redux_1 = require("@hooks/redux");
var personActions_1 = require("@pages/Persons/reducers/personActions");
var auth0_react_1 = require("@auth0/auth0-react");
function usePerson() {
    var user = (0, auth0_react_1.useAuth0)().user;
    var dispatch = (0, redux_1.useAppDispatch)();
    var isPersonModalOpen = (0, redux_1.useAppSelector)(function (state) { return state.timeline.personOpen; });
    var _a = (0, react_1.useState)([]), elementPersons = _a[0], setElementPersons = _a[1];
    var openPerson = (0, react_1.useCallback)(function () { return dispatch((0, timelineActions_http_1.timelineElementPersonChange)(true)); }, []);
    var handlePersonClose = (0, react_1.useCallback)(function () { return dispatch((0, timelineActions_http_1.timelineElementPersonChange)(false)); }, []);
    var onSavePerson = (0, react_1.useCallback)(function (person) {
        setElementPersons(function (prevState) {
            var newPersons = __spreadArray([], prevState, true);
            var newPersonsFiltered = newPersons.filter(function (p) {
                if (p.id) {
                    return p.id !== person.id;
                }
                return p.name !== person.name;
            });
            var newState = __spreadArray(__spreadArray([], newPersonsFiltered, true), [person], false);
            return newState;
        });
        dispatch((0, timelineActions_http_1.timelineElementPersonChange)(false));
    }, []);
    var handleOpenPerson = (0, react_1.useCallback)(function (personId) {
        dispatch((0, personActions_1.showPerson)(user, personId, openPerson));
    }, [user, openPerson]);
    var handlePersonOpen = (0, react_1.useCallback)(function (_id, name) {
        dispatch((0, personActions_1.changePerson)("", "initial"));
        if (+_id) {
            dispatch((0, personActions_1.showPerson)(user, _id, openPerson));
        }
        else {
            openPerson();
            var localChangedUser = elementPersons.find(function (person) { return person.id === _id; });
            if (localChangedUser) {
                dispatch((0, personActions_1.setPerson)(localChangedUser));
                return;
            }
            if (name) {
                dispatch((0, personActions_1.changePerson)(_id, "id"));
                dispatch((0, personActions_1.changePerson)(name, "name"));
            }
        }
    }, [elementPersons, user, openPerson]);
    return {
        isPersonModalOpen: isPersonModalOpen,
        elementPersons: elementPersons,
        handleOpenPerson: handleOpenPerson,
        handlePersonClose: handlePersonClose,
        onSavePerson: onSavePerson,
        setElementPersons: setElementPersons,
        handlePersonOpen: handlePersonOpen,
    };
}
exports.default = usePerson;
