"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
/**
 * @example
 * const [combinedPersonOptions, setLocalPersonOptions] =
 *   useCombineArrayStates(personOptions);
 *
 * const [combinedDocumentOptions, setLocalDocumentOptions] =
 *   useCombineArrayStates(documentOptions);
 *
 * const [combinedTagOptions, setLocalTagOptions] = useCombineArrayStates(documentOptions);
 */
function useCombineArrayStates(globalState) {
    var _a = (0, react_1.useState)([]), localState = _a[0], setLocalState = _a[1];
    var combinedState = (0, react_1.useMemo)(function () { return globalState.concat(localState); }, [globalState, localState]);
    return [combinedState, setLocalState, localState];
}
exports.default = useCombineArrayStates;
