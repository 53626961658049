"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHANGE_CHOOSEN_TEAM = exports.CHANGE_TEAM = exports.RESET_TEAM = exports.SET_TEAM = exports.TEAM_DELETE_FAILED = exports.TEAM_DELETE_SUCCESS = exports.TEAM_DELETE_LOADING = exports.TEAM_UPDATE_FAILED = exports.TEAM_UPDATE_SUCCESS = exports.TEAM_UPDATE_LOADING = exports.TEAM_STORE_FAILED = exports.TEAM_STORE_SUCCESS = exports.TEAM_STORE_LOADING = exports.POST_CHOOSEN_TEAM_FAILED = exports.POST_CHOOSEN_TEAM_SUCCESS = exports.POST_CHOOSEN_TEAM_LOADING = exports.TEAM_INDEX_FAILED = exports.TEAM_INDEX_SUCCESS = exports.TEAM_INDEX_LOADING = void 0;
exports.TEAM_INDEX_LOADING = "TEAM_INDEX_LOADING";
exports.TEAM_INDEX_SUCCESS = "TEAM_INDEX_SUCCESS";
exports.TEAM_INDEX_FAILED = "TEAM_INDEX_FAILED";
exports.POST_CHOOSEN_TEAM_LOADING = "POST_CHOOSEN_TEAM_LOADING";
exports.POST_CHOOSEN_TEAM_SUCCESS = "POST_CHOOSEN_TEAM_SUCCESS";
exports.POST_CHOOSEN_TEAM_FAILED = "POST_CHOOSEN_TEAM_FAILED";
exports.TEAM_STORE_LOADING = "TEAM_STORE_LOADING";
exports.TEAM_STORE_SUCCESS = "TEAM_STORE_SUCCESS";
exports.TEAM_STORE_FAILED = "TEAM_STORE_FAILED";
exports.TEAM_UPDATE_LOADING = "TEAM_UPDATE_LOADING";
exports.TEAM_UPDATE_SUCCESS = "TEAM_UPDATE_SUCCESS";
exports.TEAM_UPDATE_FAILED = "TEAM_UPDATE_FAILED";
exports.TEAM_DELETE_LOADING = "TEAM_DELETE_LOADING";
exports.TEAM_DELETE_SUCCESS = "TEAM_DELETE_SUCCESS";
exports.TEAM_DELETE_FAILED = "TEAM_DELETE_FAILED";
exports.SET_TEAM = "SET_TEAM";
exports.RESET_TEAM = "RESET_TEAM";
exports.CHANGE_TEAM = "CHANGE_TEAM";
exports.CHANGE_CHOOSEN_TEAM = "CHANGE_CHOOSEN_TEAM";
