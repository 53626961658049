"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProjectMousePosition = void 0;
var react_1 = require("react");
var reactflow_1 = require("reactflow");
function useProjectMousePosition(reactFlowContainer) {
    var store = (0, reactflow_1.useStoreApi)();
    var rendererPointToPoint = (0, react_1.useCallback)(function (_a, _b, snapToGrid, _c) {
        var x = _a.x, y = _a.y;
        var tx = _b[0], ty = _b[1], tScale = _b[2];
        var snapX = _c[0], snapY = _c[1];
        var position = {
            x: x * tScale + tx,
            y: y * tScale + ty,
        };
        if (snapToGrid) {
            position = {
                x: position.x / snapX,
                y: position.y / snapY,
            };
        }
        return position;
    }, []);
    var pointToRendererPoint = (0, react_1.useCallback)(function (_a, _b, snapToGrid, _c) {
        var x = _a.x, y = _a.y;
        var tx = _b[0], ty = _b[1], tScale = _b[2];
        var snapX = _c[0], snapY = _c[1];
        var position = {
            x: (x - tx) / tScale,
            y: (y - ty) / tScale,
        };
        if (snapToGrid) {
            return {
                x: snapX * Math.round(position.x / snapX),
                y: snapY * Math.round(position.y / snapY),
            };
        }
        return position;
    }, []);
    /**
     * Does the opposite of ReactFlowInstance.project and allows to optionally choose to snapToGrid or not
     */
    var unproject = (0, react_1.useCallback)(function (position, useSnapToGrid) {
        if (useSnapToGrid === void 0) { useSnapToGrid = false; }
        var _a = store.getState(), transform = _a.transform, shouldSnapToGrid = _a.snapToGrid, snapGrid = _a.snapGrid;
        if (useSnapToGrid) {
            return rendererPointToPoint(position, transform, shouldSnapToGrid, snapGrid);
        }
        return rendererPointToPoint(position, transform, false, snapGrid);
    }, []);
    /**
     * Does the same as ReactFlowInstance.project but allows to optionally choose to shouldSnapToGrid or not
     */
    var project = (0, react_1.useCallback)(function (position, useSnapToGrid) {
        if (useSnapToGrid === void 0) { useSnapToGrid = false; }
        var _a = store.getState(), transform = _a.transform, shouldSnapToGrid = _a.snapToGrid, snapGrid = _a.snapGrid;
        if (useSnapToGrid) {
            return pointToRendererPoint(position, transform, shouldSnapToGrid, snapGrid);
        }
        return pointToRendererPoint(position, transform, false, snapGrid);
    }, []);
    var calculateMouse = (0, react_1.useCallback)(function (position) {
        var container = reactFlowContainer.current;
        position = unproject(position);
        var rotation = null;
        if (container) {
            var height = container.offsetHeight;
            var width = container.offsetWidth;
            if (position.x > width) {
                position.x = width - 40;
                rotation = 180;
            }
            else if (position.x < 0) {
                position.x = 10;
                rotation = 0;
            }
            if (position.y > height) {
                position.y = height - 40;
                rotation = 270;
            }
            else if (position.y < 0) {
                position.y = 10;
                rotation = 90;
            }
        }
        return { position: position, rotation: rotation };
    }, [unproject, reactFlowContainer]);
    return { unproject: unproject, calculateMouse: calculateMouse, rendererPointToPoint: rendererPointToPoint, project: project };
}
exports.useProjectMousePosition = useProjectMousePosition;
exports.default = useProjectMousePosition;
