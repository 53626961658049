"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var auth0_react_1 = require("@auth0/auth0-react");
var redux_1 = require("@hooks/redux");
var folderActions_1 = require("@redux/actions/folderActions");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
function useFolders(_a) {
    var _this = this;
    var _b;
    var folderType = _a.folderType, index = _a.index;
    var dispatch = (0, redux_1.useAppDispatch)();
    var user = (0, auth0_react_1.useAuth0)().user;
    var location = (0, react_router_dom_1.useLocation)();
    var queryParams = (0, constants_1.getGroupOrLawsuitQueryParams)(location, true);
    var activeFolders = (_b = (0, redux_1.useAppSelector)(function (state) { var _a, _b; return (_b = (_a = state.folder.activeFolders) === null || _a === void 0 ? void 0 : _a[folderType]) === null || _b === void 0 ? void 0 : _b.items; })) !== null && _b !== void 0 ? _b : [];
    var _c = (0, react_1.useState)(null), activeFolderId = _c[0], setActiveFolderId = _c[1];
    var _d = (0, react_1.useState)(index || []), filteredIndex = _d[0], setFilteredIndex = _d[1];
    (0, react_1.useEffect)(function () {
        if (index) {
            if (activeFolders.length > 0) {
                var activeFolder_1 = activeFolders[activeFolders.length - 1];
                setFilteredIndex(index.filter(function (x) { return x.folderId === activeFolder_1.id; }));
            }
            else {
                setFilteredIndex(index.filter(function (x) { return !x.folderId; }));
            }
        }
    }, [index, activeFolders]);
    (0, react_1.useEffect)(function () {
        if (activeFolders.length > 0) {
            var activeFolder = activeFolders[activeFolders.length - 1];
            setActiveFolderId(activeFolder.id);
        }
        else {
            setActiveFolderId(null);
        }
    }, [activeFolders]);
    var onEnterFolder = (0, react_1.useCallback)(function (folder) {
        dispatch((0, folderActions_1.enterFolder)(folderType, folder));
    }, [folderType, dispatch]);
    var onGoBackToFolder = (0, react_1.useCallback)(function (_index) { return dispatch((0, folderActions_1.goBackToFolder)(folderType, _index)); }, [folderType, dispatch]);
    var onLeaveFolders = (0, react_1.useCallback)(function () {
        return dispatch((0, folderActions_1.leaveFolders)({
            where: folderType,
            location: "".concat(queryParams.type, "-").concat(queryParams.typeId),
        }));
    }, [folderType, dispatch]);
    var onLeaveAllFolders = (0, react_1.useCallback)(function () { return dispatch((0, folderActions_1.leaveAllFolders)()); }, [dispatch]);
    var onPostFolder = (0, react_1.useCallback)(function (_title) {
        var _a, _b;
        var _typeId = parseInt(queryParams.typeId, 10);
        var body = __assign(__assign({ title: _title, type: folderType }, (queryParams.type === "groups"
            ? { groupId: _typeId, lawsuitId: null }
            : { lawsuitId: _typeId, groupId: null })), { parentId: (_b = (_a = activeFolders[activeFolders.length - 1]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : null });
        dispatch((0, folderActions_1.postFolder)(user, body, folderType));
    }, [dispatch, folderType, activeFolders, queryParams]);
    var onPutFolderTitle = (0, react_1.useCallback)(function (_id, _title) {
        var body = {
            id: _id,
            title: _title,
        };
        dispatch((0, folderActions_1.putFolderTitle)(user, body, folderType));
    }, [dispatch, folderType, user]);
    var _e = (0, react_1.useState)(undefined), moveItem = _e[0], setMoveItem = _e[1];
    var onInitiateMoveFolder = (0, react_1.useCallback)(function (id, isFolder) {
        id
            ? dispatch((0, folderActions_1.getFolderTree)(user, { folderType: folderType }, location))
            : dispatch((0, folderActions_1.clearFolders)());
        setMoveItem(id && isFolder !== undefined ? { id: id, isFolder: isFolder } : undefined);
    }, [setMoveItem, folderType]);
    var onPutFolderLocation = (0, react_1.useCallback)(function (parentId) {
        if (moveItem) {
            var body = {
                id: moveItem.id,
                parentId: parentId === "all" ? null : parentId,
            };
            dispatch((0, folderActions_1.putFolderLocation)(user, body, folderType));
        }
    }, [dispatch, folderType, moveItem]);
    var onPutFolderElementLocation = (0, react_1.useCallback)(function (parentId) {
        if (moveItem) {
            var body = {
                elementId: moveItem.id,
                folderId: parentId === "all" ? null : parentId,
            };
            dispatch((0, folderActions_1.putFolderElementLocation)(user, body, folderType));
        }
    }, [folderType, moveItem, user, folderType]);
    var _f = (0, react_1.useState)(undefined), deleteFolderId = _f[0], setDeleteFolderId = _f[1];
    var onInitiateDeleteFolder = (0, react_1.useCallback)(function (_id) { return setDeleteFolderId(_id); }, [setDeleteFolderId]);
    var onDeleteFolder = (0, react_1.useCallback)(function (withChildren, id) {
        if (withChildren === void 0) { withChildren = false; }
        if (id) {
            dispatch((0, folderActions_1.deleteFolder)(user, { id: id, withChildren: withChildren }, folderType));
        }
        else if (deleteFolderId) {
            dispatch((0, folderActions_1.deleteFolder)(user, { id: deleteFolderId, withChildren: withChildren }, folderType));
        }
    }, [dispatch, folderType, deleteFolderId]);
    var onMoveElementToDestroyedParent = (0, react_1.useCallback)(function (id, folderId) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch((0, folderActions_1.moveElementToDestroyedParent)(user, { id: id, folderId: folderId }, folderType))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, [dispatch, folderType]);
    var handleDuplicateFolder = (0, react_1.useCallback)(function (id) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch((0, folderActions_1.duplicateFolder)(user, id, folderType));
            return [2 /*return*/];
        });
    }); }, [dispatch, folderType]);
    var handleDuplicateFolderFromElementOverview = (0, react_1.useCallback)(function (id) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch((0, folderActions_1.duplicateFolderFromElementOverview)(user, id, folderType));
            return [2 /*return*/];
        });
    }); }, [dispatch, folderType]);
    return {
        activeFolderId: activeFolderId,
        filteredIndex: filteredIndex,
        onEnterFolder: onEnterFolder,
        onGoBackToFolder: onGoBackToFolder,
        onLeaveFolders: onLeaveFolders,
        onLeaveAllFolders: onLeaveAllFolders,
        onPostFolder: onPostFolder,
        onPutFolderTitle: onPutFolderTitle,
        moveItem: moveItem,
        onInitiateMoveFolder: onInitiateMoveFolder,
        onPutFolderLocation: onPutFolderLocation,
        onPutFolderElementLocation: onPutFolderElementLocation,
        deleteFolderId: deleteFolderId,
        onInitiateDeleteFolder: onInitiateDeleteFolder,
        onDeleteFolder: onDeleteFolder,
        onMoveElementToDestroyedParent: onMoveElementToDestroyedParent,
        handleDuplicateFolder: handleDuplicateFolder,
        handleDuplicateFolderFromElementOverview: handleDuplicateFolderFromElementOverview,
    };
}
exports.default = useFolders;
