"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var history_1 = require("history");
var history = (0, history_1.createBrowserHistory)();
var prevPath = null;
history.listen(function (location) {
    if (location.pathname !== prevPath) {
        prevPath = location.pathname;
    }
});
exports.default = history;
