const data = {
  "v": "5.7.11",
  "fr": 60,
  "ip": 0,
  "op": 181,
  "w": 240,
  "h": 210,
  "nm": "Computer",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            46.059,
            194.004,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            170.059,
            320.504,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          149.262,
                          320.504
                        ],
                        [
                          158.964,
                          320.504
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  154.113,
                  320.504
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  154.113,
                  320.504
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 1",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          181.153,
                          320.504
                        ],
                        [
                          190.855,
                          320.504
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 2",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  186.004,
                  320.504
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  186.004,
                  320.504
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 2",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            22.08,
            183.026,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            146.08,
            309.526,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          11.87,
                          -10.978
                        ],
                        [
                          -11.87,
                          -10.978
                        ],
                        [
                          -7.87,
                          10.978
                        ],
                        [
                          6.87,
                          10.978
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      146.08,
                      309.526
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 3",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  146.08,
                  309.526
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  146.08,
                  309.526
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 3",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          11.87,
                          -10.978
                        ],
                        [
                          -11.87,
                          -10.978
                        ],
                        [
                          -7.87,
                          10.978
                        ],
                        [
                          6.87,
                          10.978
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.768999993801,
                      0.603999972343,
                      0.423999994993,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      146.08,
                      309.526
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 4",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  146.08,
                  309.526
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  146.08,
                  309.526
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 4",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "Computer Outlines - Group 5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 69,
              "s": [
                10
              ]
            },
            {
              "t": 181,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            21.731,
            174.628,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            145.731,
            301.128,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      -11.926,
                      -5.229
                    ],
                    [
                      12.916,
                      7.609
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      4.314,
                      10.109
                    ],
                    [
                      -5.304,
                      -10.109
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.552999997606,
                  0.776000019148,
                  0.246999987434,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  141.378,
                  291.003
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 5",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "Computer Outlines - Group 7",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 90,
              "s": [
                10
              ]
            },
            {
              "t": 181,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            22.178,
            174.503,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            146.178,
            301.003,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      11.926,
                      -5.229
                    ],
                    [
                      -12.917,
                      7.609
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -4.314,
                      10.109
                    ],
                    [
                      5.305,
                      -10.109
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.552999997606,
                  0.776000019148,
                  0.246999987434,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  150.781,
                  291.003
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 7",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "Computer Outlines - Group 9",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 107,
              "s": [
                10
              ]
            },
            {
              "t": 181,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            21.754,
            171.708,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            145.754,
            298.208,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      11.84,
                      -13.139
                    ],
                    [
                      -11.735,
                      16.637
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -0.354,
                      15.201
                    ],
                    [
                      0.249,
                      -15.201
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.552999997606,
                  0.776000019148,
                  0.246999987434,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  146.109,
                  283.008
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 9",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 4,
      "nm": "Merged Shape Layer 4",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            122.868,
            159.211,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            341.618,
            285.711,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.435,
                          34.793
                        ],
                        [
                          2.434,
                          34.793
                        ],
                        [
                          2.434,
                          -34.793
                        ],
                        [
                          -2.435,
                          -34.793
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.768999993801,
                      0.603999972343,
                      0.423999994993,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      339.695,
                      285.711
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 29",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  339.695,
                  285.711
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  339.695,
                  285.711
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 29",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.435,
                          34.793
                        ],
                        [
                          2.434,
                          34.793
                        ],
                        [
                          2.434,
                          -34.793
                        ],
                        [
                          -2.435,
                          -34.793
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.662999987602,
                      0.486000001431,
                      0.31400001049,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      343.542,
                      285.711
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 31",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  343.542,
                  285.711
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  343.542,
                  285.711
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 31",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 4,
      "nm": "Computer Outlines - Group 11",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            215.672,
            194.004,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            339.672,
            320.504,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      334.377,
                      320.504
                    ],
                    [
                      344.968,
                      320.504
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 11",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 4,
      "nm": "Computer Outlines - Group 25",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            166.046,
            112.48,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            290.046,
            238.98,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      16.391,
                      -2.495
                    ],
                    [
                      -16.391,
                      -2.495
                    ],
                    [
                      -16.391,
                      2.495
                    ],
                    [
                      16.391,
                      2.495
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.969000004787,
                  0.579999976065,
                  0.113999998803,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  290.046,
                  238.98
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 25",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 4,
      "nm": "Merged Shape Layer 3",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            149.618,
            159.211,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            341.618,
            285.711,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.435,
                          34.793
                        ],
                        [
                          2.434,
                          34.793
                        ],
                        [
                          2.434,
                          -34.793
                        ],
                        [
                          -2.435,
                          -34.793
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.768999993801,
                      0.603999972343,
                      0.423999994993,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      339.695,
                      285.711
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 29",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  339.695,
                  285.711
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  339.695,
                  285.711
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 29",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.435,
                          34.793
                        ],
                        [
                          2.434,
                          34.793
                        ],
                        [
                          2.434,
                          -34.793
                        ],
                        [
                          -2.435,
                          -34.793
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.662999987602,
                      0.486000001431,
                      0.31400001049,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      343.542,
                      285.711
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 31",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  343.542,
                  285.711
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  343.542,
                  285.711
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 31",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 11,
      "ty": 4,
      "nm": "Merged Shape Layer 2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            187.868,
            159.211,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            341.618,
            285.711,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.435,
                          34.793
                        ],
                        [
                          2.434,
                          34.793
                        ],
                        [
                          2.434,
                          -34.793
                        ],
                        [
                          -2.435,
                          -34.793
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.768999993801,
                      0.603999972343,
                      0.423999994993,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      339.695,
                      285.711
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 29",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  339.695,
                  285.711
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  339.695,
                  285.711
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 29",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.435,
                          34.793
                        ],
                        [
                          2.434,
                          34.793
                        ],
                        [
                          2.434,
                          -34.793
                        ],
                        [
                          -2.435,
                          -34.793
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.662999987602,
                      0.486000001431,
                      0.31400001049,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      343.542,
                      285.711
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 31",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  343.542,
                  285.711
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  343.542,
                  285.711
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 31",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 12,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            217.618,
            159.211,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            341.618,
            285.711,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.435,
                          34.793
                        ],
                        [
                          2.434,
                          34.793
                        ],
                        [
                          2.434,
                          -34.793
                        ],
                        [
                          -2.435,
                          -34.793
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.768999993801,
                      0.603999972343,
                      0.423999994993,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      339.695,
                      285.711
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 29",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  339.695,
                  285.711
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  339.695,
                  285.711
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 29",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -2.435,
                          34.793
                        ],
                        [
                          2.434,
                          34.793
                        ],
                        [
                          2.434,
                          -34.793
                        ],
                        [
                          -2.435,
                          -34.793
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.662999987602,
                      0.486000001431,
                      0.31400001049,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      343.542,
                      285.711
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 31",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  343.542,
                  285.711
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  343.542,
                  285.711
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 31",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 13,
      "ty": 4,
      "nm": "Computer Outlines - Group 32",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 40,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            199.482,
            74.918,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            323.482,
            201.418,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      -4.445
                    ],
                    [
                      3.037,
                      0
                    ],
                    [
                      0,
                      4.445
                    ],
                    [
                      -3.038,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      4.445
                    ],
                    [
                      -3.038,
                      0
                    ],
                    [
                      0,
                      -4.445
                    ],
                    [
                      3.037,
                      0
                    ]
                  ],
                  "v": [
                    [
                      5.5,
                      0
                    ],
                    [
                      0,
                      8.049
                    ],
                    [
                      -5.5,
                      0
                    ],
                    [
                      0,
                      -8.049
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.944999964097,
                  0.948999980852,
                  0.948999980852,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  323.482,
                  201.418
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 32",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 14,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 18,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            239.601,
            180.436,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            239.601,
            180.436,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.837,
                          0
                        ],
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.838,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.838,
                          0
                        ],
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.837,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.516,
                          -0.001
                        ],
                        [
                          0,
                          1.517
                        ],
                        [
                          -1.516,
                          -0.001
                        ],
                        [
                          0,
                          -1.517
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      240.471,
                      179.16
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 33",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  240.471,
                  179.16
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  240.471,
                  179.16
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 33",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.933,
                          0
                        ],
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ],
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.933,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.5,
                          0
                        ],
                        [
                          0,
                          4.459
                        ],
                        [
                          -3.5,
                          0
                        ],
                        [
                          0,
                          -4.459
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      239.601,
                      180.436
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 37",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  239.601,
                  180.436
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  239.601,
                  180.436
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 37",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 15,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 18,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            262.14,
            180.436,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            262.14,
            180.436,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.838,
                          0
                        ],
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.837,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.837,
                          0
                        ],
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.838,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.517,
                          0
                        ],
                        [
                          -0.001,
                          1.516
                        ],
                        [
                          -1.517,
                          0
                        ],
                        [
                          -0.001,
                          -1.516
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      263.119,
                      179.026
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 34",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  263.119,
                  179.026
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  263.119,
                  179.026
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 34",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.933,
                          0
                        ],
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ],
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.933,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.5,
                          0
                        ],
                        [
                          0,
                          4.459
                        ],
                        [
                          -3.5,
                          0
                        ],
                        [
                          0,
                          -4.459
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      262.14,
                      180.436
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 36",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  262.14,
                  180.436
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  262.14,
                  180.436
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 36",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 16,
      "ty": 4,
      "nm": "Computer Outlines - Group 35",
      "parent": 18,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            250.201,
            193.089,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            250.201,
            193.089,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      2.484,
                      0
                    ],
                    [
                      0,
                      2.321
                    ]
                  ],
                  "o": [
                    [
                      0,
                      2.321
                    ],
                    [
                      -2.486,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      4.5,
                      -2.102
                    ],
                    [
                      0,
                      2.102
                    ],
                    [
                      -4.5,
                      -2.102
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  250.201,
                  193.089
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 35",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 17,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 18,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            215.19,
            166.086,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            215.19,
            166.086,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -3.774,
                          3.055
                        ],
                        [
                          1.565,
                          12.207
                        ],
                        [
                          -11.562,
                          -9.734
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          11.094,
                          8.568
                        ],
                        [
                          -5.48,
                          -11.922
                        ],
                        [
                          0.469,
                          11.922
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      200.975,
                      156.336
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 39",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  203.101,
                  156.336
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  203.101,
                  156.336
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 39",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          18.666,
                          -0.93
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          -11.074,
                          -7.854
                        ],
                        [
                          9.196,
                          4.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -3.796,
                          11.921
                        ],
                        [
                          -9.744,
                          -11.921
                        ],
                        [
                          12.95,
                          -6.38
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      205.24,
                      156.336
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 41",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  206.279,
                  156.336
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  206.279,
                  156.336
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 41",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 18,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 41,
              "s": [
                3
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 90,
              "s": [
                3
              ]
            },
            {
              "t": 133,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            109.454,
            88.689,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            233.454,
            215.189,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          6.939,
                          5.67
                        ],
                        [
                          8.595,
                          5.158
                        ],
                        [
                          0.072,
                          -13.06
                        ],
                        [
                          -23.757,
                          -2.723
                        ]
                      ],
                      "o": [
                        [
                          -4.883,
                          -2.458
                        ],
                        [
                          -9.252,
                          -5.551
                        ],
                        [
                          -0.055,
                          10.064
                        ],
                        [
                          3.762,
                          -3.981
                        ]
                      ],
                      "v": [
                        [
                          13.03,
                          -3.758
                        ],
                        [
                          -0.655,
                          -12.105
                        ],
                        [
                          -19.914,
                          -3.956
                        ],
                        [
                          12.099,
                          17.656
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      273.261,
                      182.982
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 43",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  271.696,
                  184.105
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  271.696,
                  184.105
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 43",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -1.87,
                          12.344
                        ],
                        [
                          16.292,
                          -7.518
                        ],
                        [
                          5.621,
                          -1.936
                        ],
                        [
                          4.52,
                          -1.733
                        ],
                        [
                          -5.772,
                          1.692
                        ],
                        [
                          5.283,
                          -0.978
                        ],
                        [
                          -6.054,
                          -3.623
                        ]
                      ],
                      "o": [
                        [
                          1.956,
                          -12.913
                        ],
                        [
                          -14.807,
                          6.834
                        ],
                        [
                          -3.065,
                          2.617
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          27.422,
                          -1.184
                        ]
                      ],
                      "v": [
                        [
                          36.327,
                          -0.969
                        ],
                        [
                          3.163,
                          -13.126
                        ],
                        [
                          -26.82,
                          1.217
                        ],
                        [
                          -38.283,
                          8.228
                        ],
                        [
                          -29.673,
                          8.911
                        ],
                        [
                          -38.283,
                          14.096
                        ],
                        [
                          -29.273,
                          20.645
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      216.646,
                      179.994
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 45",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  215.739,
                  182.185
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  215.739,
                  182.185
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 45",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          -25.456,
                          -3.981
                        ],
                        [
                          27.572,
                          0.003
                        ],
                        [
                          9.856,
                          5.897
                        ],
                        [
                          -1.87,
                          12.344
                        ]
                      ],
                      "o": [
                        [
                          1.87,
                          12.34
                        ],
                        [
                          -6.152,
                          5.802
                        ],
                        [
                          -27.029,
                          -0.003
                        ],
                        [
                          27.422,
                          -1.184
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          16.98,
                          -17.632
                        ],
                        [
                          48.994,
                          3.979
                        ],
                        [
                          5.975,
                          17.629
                        ],
                        [
                          -48.994,
                          3.981
                        ],
                        [
                          16.606,
                          -17.632
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.991999983788,
                      0.949000000954,
                      0.917999982834,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      236.367,
                      196.658
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 47",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  236.367,
                  196.657
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  236.367,
                  196.657
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 47",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          -9.31,
                          -5.456
                        ],
                        [
                          -3.96,
                          -1.587
                        ],
                        [
                          1.638,
                          2.37
                        ],
                        [
                          5.399,
                          4.281
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.022,
                          -10.956
                        ],
                        [
                          4.895,
                          -4.181
                        ],
                        [
                          -14.069,
                          8.247
                        ],
                        [
                          1.956,
                          -12.913
                        ]
                      ],
                      "o": [
                        [
                          0.574,
                          -12.975
                        ],
                        [
                          12.535,
                          7.348
                        ],
                        [
                          -2.715,
                          -3.194
                        ],
                        [
                          -4.598,
                          -6.652
                        ],
                        [
                          -8.403,
                          -6.663
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -7.044,
                          0.978
                        ],
                        [
                          -22.891,
                          4.108
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          5.621,
                          -1.936
                        ],
                        [
                          9.309,
                          -5.456
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          15.288,
                          18.374
                        ],
                        [
                          34.548,
                          10.227
                        ],
                        [
                          48.232,
                          18.574
                        ],
                        [
                          41.457,
                          9.864
                        ],
                        [
                          25.609,
                          -9.408
                        ],
                        [
                          3.088,
                          -17.114
                        ],
                        [
                          5.262,
                          -20.561
                        ],
                        [
                          -5.891,
                          -15.669
                        ],
                        [
                          -40.521,
                          12.31
                        ],
                        [
                          -48.232,
                          20.561
                        ],
                        [
                          -18.25,
                          6.217
                        ],
                        [
                          14.914,
                          18.374
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      238.059,
                      160.651
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 49",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  238.059,
                  160.651
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  238.059,
                  160.651
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 49",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 19,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 18,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 82,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 92,
              "s": [
                -20
              ]
            },
            {
              "t": 102,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            264.439,
            165.836,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            264.439,
            165.836,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          3.775,
                          3.055
                        ],
                        [
                          -1.565,
                          12.207
                        ],
                        [
                          11.562,
                          -9.734
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -11.093,
                          8.568
                        ],
                        [
                          5.48,
                          -11.922
                        ],
                        [
                          -0.468,
                          11.922
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      275.403,
                      158.336
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 51",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  273.277,
                  158.336
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  273.277,
                  158.336
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 51",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -18.665,
                          -0.93
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          11.074,
                          -7.854
                        ],
                        [
                          -9.195,
                          4.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.796,
                          11.921
                        ],
                        [
                          9.743,
                          -11.921
                        ],
                        [
                          -12.95,
                          -6.38
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      271.139,
                      158.336
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 53",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  270.101,
                  158.336
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  270.101,
                  158.336
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 53",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 20,
      "ty": 4,
      "nm": "Computer Outlines - Group 54",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": -15,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 0,
              "s": [
                89.713,
                85.667,
                0
              ],
              "to": [
                0.417,
                0,
                0
              ],
              "ti": [
                -0.417,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 0.667
              },
              "o": {
                "x": 0.333,
                "y": 0.333
              },
              "t": 41,
              "s": [
                92.213,
                85.667,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 90,
              "s": [
                92.213,
                85.667,
                0
              ],
              "to": [
                -0.417,
                0,
                0
              ],
              "ti": [
                0.417,
                0,
                0
              ]
            },
            {
              "t": 133,
              "s": [
                89.713,
                85.667,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            213.713,
            209.417,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.674,
                            -3.516
                          ],
                          [
                            -6.027,
                            -2.263
                          ],
                          [
                            7.519,
                            1.222
                          ],
                          [
                            6.687,
                            6.261
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.669,
                            2.273
                          ],
                          [
                            11.046,
                            4.147
                          ],
                          [
                            -11.918,
                            -1.937
                          ],
                          [
                            -6.688,
                            -6.26
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -12.919,
                            -21.173
                          ],
                          [
                            -2.495,
                            -3.95
                          ],
                          [
                            17.112,
                            0.869
                          ],
                          [
                            16.013,
                            17.07
                          ],
                          [
                            -15.93,
                            11.274
                          ],
                          [
                            -24.701,
                            -4.751
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 32,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.674,
                            -3.516
                          ],
                          [
                            -6.027,
                            -2.263
                          ],
                          [
                            7.519,
                            1.222
                          ],
                          [
                            6.687,
                            6.261
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.669,
                            2.273
                          ],
                          [
                            11.046,
                            4.147
                          ],
                          [
                            -11.918,
                            -1.937
                          ],
                          [
                            -6.688,
                            -6.26
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -12.919,
                            -21.173
                          ],
                          [
                            -2.495,
                            -3.95
                          ],
                          [
                            17.112,
                            0.869
                          ],
                          [
                            16.013,
                            17.07
                          ],
                          [
                            -15.93,
                            11.274
                          ],
                          [
                            -24.701,
                            -4.751
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 41,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.674,
                            -3.516
                          ],
                          [
                            -6.027,
                            -2.263
                          ],
                          [
                            7.519,
                            1.222
                          ],
                          [
                            6.687,
                            6.261
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.669,
                            2.273
                          ],
                          [
                            11.046,
                            4.147
                          ],
                          [
                            -11.918,
                            -1.937
                          ],
                          [
                            -6.688,
                            -6.26
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -12.919,
                            -21.173
                          ],
                          [
                            -2.495,
                            -3.95
                          ],
                          [
                            17.299,
                            -1.281
                          ],
                          [
                            16.2,
                            14.92
                          ],
                          [
                            -15.93,
                            11.274
                          ],
                          [
                            -24.701,
                            -4.751
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 49,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.674,
                            -3.516
                          ],
                          [
                            -6.027,
                            -2.263
                          ],
                          [
                            7.519,
                            1.222
                          ],
                          [
                            6.687,
                            6.261
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.669,
                            2.273
                          ],
                          [
                            11.046,
                            4.147
                          ],
                          [
                            -11.918,
                            -1.937
                          ],
                          [
                            -6.688,
                            -6.26
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -12.919,
                            -21.173
                          ],
                          [
                            -2.495,
                            -3.95
                          ],
                          [
                            17.112,
                            0.869
                          ],
                          [
                            16.013,
                            17.07
                          ],
                          [
                            -15.93,
                            11.274
                          ],
                          [
                            -24.701,
                            -4.751
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 90,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.674,
                            -3.516
                          ],
                          [
                            -6.027,
                            -2.263
                          ],
                          [
                            7.519,
                            1.222
                          ],
                          [
                            6.687,
                            6.261
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.669,
                            2.273
                          ],
                          [
                            11.046,
                            4.147
                          ],
                          [
                            -11.918,
                            -1.937
                          ],
                          [
                            -6.688,
                            -6.26
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -12.919,
                            -21.173
                          ],
                          [
                            -2.495,
                            -3.95
                          ],
                          [
                            17.112,
                            0.869
                          ],
                          [
                            16.013,
                            17.07
                          ],
                          [
                            -15.93,
                            11.274
                          ],
                          [
                            -24.701,
                            -4.751
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "t": 133,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.674,
                            -3.516
                          ],
                          [
                            -6.027,
                            -2.263
                          ],
                          [
                            7.519,
                            1.222
                          ],
                          [
                            6.687,
                            6.261
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.669,
                            2.273
                          ],
                          [
                            11.046,
                            4.147
                          ],
                          [
                            -11.918,
                            -1.937
                          ],
                          [
                            -6.688,
                            -6.26
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -12.919,
                            -21.173
                          ],
                          [
                            -2.495,
                            -3.95
                          ],
                          [
                            17.112,
                            0.869
                          ],
                          [
                            16.013,
                            17.07
                          ],
                          [
                            -15.93,
                            11.274
                          ],
                          [
                            -24.701,
                            -4.751
                          ]
                        ],
                        "c": false
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  236.426,
                  223.812
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 54",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 21,
      "ty": 4,
      "nm": "Computer Outlines - Group 57",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 0,
              "s": [
                137.48,
                110.664,
                0
              ],
              "to": [
                0.417,
                0,
                0
              ],
              "ti": [
                -0.417,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 0.667
              },
              "o": {
                "x": 0.333,
                "y": 0.333
              },
              "t": 41,
              "s": [
                139.98,
                110.664,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 90,
              "s": [
                139.98,
                110.664,
                0
              ],
              "to": [
                -0.417,
                0,
                0
              ],
              "ti": [
                0.417,
                0,
                0
              ]
            },
            {
              "t": 133,
              "s": [
                137.48,
                110.664,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            261.48,
            237.164,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      2.887,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      -2.888
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      -2.887,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      -2.888
                    ]
                  ],
                  "v": [
                    [
                      3.551,
                      -4.312
                    ],
                    [
                      -3.552,
                      -4.312
                    ],
                    [
                      -8.779,
                      0.916
                    ],
                    [
                      -8.779,
                      4.312
                    ],
                    [
                      8.779,
                      4.312
                    ],
                    [
                      8.779,
                      0.916
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.984000052658,
                  0.689999988032,
                  0.250999989229,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  261.48,
                  237.164
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 57",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 22,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            168.555,
            119.697,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            292.555,
            246.197,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          52.091,
                          4.721
                        ],
                        [
                          -52.092,
                          4.721
                        ],
                        [
                          -52.092,
                          -4.721
                        ],
                        [
                          52.091,
                          -4.721
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.768999993801,
                      0.603999972343,
                      0.423999994993,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      296.582,
                      246.197
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 59",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  296.582,
                  246.197
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  296.582,
                  246.197
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 59",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          7.303,
                          4.721
                        ],
                        [
                          -7.302,
                          4.721
                        ],
                        [
                          -7.302,
                          -4.721
                        ],
                        [
                          7.303,
                          -4.721
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.662999987602,
                      0.486000001431,
                      0.31400001049,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      243.74,
                      246.197
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 61",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  243.74,
                  246.197
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  243.74,
                  246.197
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 61",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 23,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            195.11,
            81.544,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            319.11,
            208.044,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [
                              2.762,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              2.762
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "o": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              2.762,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -2.762
                            ]
                          ],
                          "v": [
                            [
                              17.965,
                              -28.441
                            ],
                            [
                              -22.966,
                              -28.441
                            ],
                            [
                              -22.966,
                              28.441
                            ],
                            [
                              17.965,
                              28.441
                            ],
                            [
                              22.965,
                              23.441
                            ],
                            [
                              22.965,
                              -23.441
                            ]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.109999999404,
                          0.109999999404,
                          1
                        ],
                        "ix": 3
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 4
                      },
                      "w": {
                        "a": 0,
                        "k": 2,
                        "ix": 5
                      },
                      "lc": 2,
                      "lj": 2,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.96899998188,
                          0.579999983311,
                          0.11400000006,
                          1
                        ],
                        "ix": 4
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 5
                      },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          323.011,
                          202.092
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          0,
                          0
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 67",
                  "np": 3,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      323.011,
                      202.092
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      323.011,
                      202.092
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Computer Outlines - Group 67",
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -2.762
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -2.761,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "o": [
                            [
                              0,
                              0
                            ],
                            [
                              -2.761,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              2.762
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              22.966,
                              -28.441
                            ],
                            [
                              -17.966,
                              -28.441
                            ],
                            [
                              -22.966,
                              -23.441
                            ],
                            [
                              -22.966,
                              23.441
                            ],
                            [
                              -17.966,
                              28.441
                            ],
                            [
                              22.966,
                              28.441
                            ]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.109999999404,
                          0.109999999404,
                          1
                        ],
                        "ix": 3
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 4
                      },
                      "w": {
                        "a": 0,
                        "k": 2,
                        "ix": 5
                      },
                      "lc": 2,
                      "lj": 2,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.98400002718,
                          0.689999997616,
                          0.250999987125,
                          1
                        ],
                        "ix": 4
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 5
                      },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          315.209,
                          202.092
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          0,
                          0
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 69",
                  "np": 3,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      315.209,
                      202.092
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      315.209,
                      202.092
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Computer Outlines - Group 69",
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  319.11,
                  202.092
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  319.11,
                  202.092
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Merged Shape Layer",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "o": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              7.843,
                              -5.951
                            ],
                            [
                              -7.843,
                              -5.951
                            ],
                            [
                              -7.843,
                              5.951
                            ],
                            [
                              7.843,
                              5.951
                            ]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.109999999404,
                          0.109999999404,
                          1
                        ],
                        "ix": 3
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 4
                      },
                      "w": {
                        "a": 0,
                        "k": 2,
                        "ix": 5
                      },
                      "lc": 2,
                      "lj": 2,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.96899998188,
                          0.579999983311,
                          0.11400000006,
                          1
                        ],
                        "ix": 4
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 5
                      },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          321.695,
                          236.485
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          0,
                          0
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 63",
                  "np": 3,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      321.695,
                      236.485
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      321.695,
                      236.485
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Computer Outlines - Group 63",
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "o": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              8.844,
                              -5.951
                            ],
                            [
                              -8.844,
                              -5.951
                            ],
                            [
                              -8.844,
                              5.951
                            ],
                            [
                              8.844,
                              5.951
                            ]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.109999999404,
                          0.109999999404,
                          1
                        ],
                        "ix": 3
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 4
                      },
                      "w": {
                        "a": 0,
                        "k": 2,
                        "ix": 5
                      },
                      "lc": 2,
                      "lj": 2,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.98400002718,
                          0.689999997616,
                          0.250999987125,
                          1
                        ],
                        "ix": 4
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 5
                      },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          317.69,
                          236.485
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          0,
                          0
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 65",
                  "np": 3,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      317.69,
                      236.485
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      317.69,
                      236.485
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Computer Outlines - Group 65",
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  319.193,
                  236.485
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  319.193,
                  236.485
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Merged Shape Layer",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 24,
      "ty": 4,
      "nm": "Computer Outlines - Group 73",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 42,
              "s": [
                -9
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 90,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 133,
              "s": [
                -9
              ]
            },
            {
              "t": 180,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 0,
              "s": [
                103.275,
                155.474,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 42,
              "s": [
                108.4,
                155.474,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 90,
              "s": [
                103.275,
                155.474,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 133,
              "s": [
                108.4,
                155.474,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "t": 180,
              "s": [
                103.275,
                155.474,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            227.275,
            281.974,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            14.308,
                            -2.985
                          ],
                          [
                            -4.102,
                            -3.595
                          ],
                          [
                            -0.857,
                            11.996
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            7.826,
                            6.861
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -10.776,
                            -8.371
                          ],
                          [
                            -7.867,
                            4.495
                          ],
                          [
                            11.969,
                            -5.153
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 42,
                    "s": [
                      {
                        "i": [
                          [
                            14.308,
                            -2.985
                          ],
                          [
                            -6.546,
                            -3.663
                          ],
                          [
                            3.224,
                            10.041
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            10.435,
                            5.838
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -10.776,
                            -8.371
                          ],
                          [
                            -2.226,
                            5.641
                          ],
                          [
                            11.969,
                            -5.153
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 90,
                    "s": [
                      {
                        "i": [
                          [
                            14.308,
                            -2.985
                          ],
                          [
                            -4.102,
                            -3.595
                          ],
                          [
                            -0.857,
                            11.996
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            7.826,
                            6.861
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -10.776,
                            -8.371
                          ],
                          [
                            -7.867,
                            4.495
                          ],
                          [
                            11.969,
                            -5.153
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 133,
                    "s": [
                      {
                        "i": [
                          [
                            14.308,
                            -2.985
                          ],
                          [
                            -6.546,
                            -3.663
                          ],
                          [
                            3.224,
                            10.041
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            10.435,
                            5.838
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -10.776,
                            -8.371
                          ],
                          [
                            -2.226,
                            5.641
                          ],
                          [
                            11.969,
                            -5.153
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "t": 180,
                    "s": [
                      {
                        "i": [
                          [
                            14.308,
                            -2.985
                          ],
                          [
                            -4.102,
                            -3.595
                          ],
                          [
                            -0.857,
                            11.996
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            7.826,
                            6.861
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -10.776,
                            -8.371
                          ],
                          [
                            -7.867,
                            4.495
                          ],
                          [
                            11.969,
                            -5.153
                          ]
                        ],
                        "c": true
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.477999997606,
                  0.361000001197,
                  0.344999994016,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  226.76,
                  291.333
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 73",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 25,
      "ty": 4,
      "nm": "Computer Outlines - Group 75",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            120.49,
            97.641,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            244.49,
            224.141,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      -1.76,
                      -12.997
                    ],
                    [
                      -2.191,
                      14.459
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      2.772,
                      20.478
                    ],
                    [
                      3.4,
                      -22.436
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -20.697,
                      -25.172
                    ],
                    [
                      -19.801,
                      9.931
                    ],
                    [
                      18.161,
                      13.18
                    ],
                    [
                      18.624,
                      -30.409
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.991999966491,
                  0.948999980852,
                  0.917999985639,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  245.043,
                  227.017
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 75",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 26,
      "ty": 4,
      "nm": "Computer Outlines - Group 77",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            125.541,
            120.773,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            249.541,
            247.273,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      -6.911,
                      3.971
                    ],
                    [
                      0.156,
                      7.148
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      10.833,
                      -6.225
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -21.95,
                      18.582
                    ],
                    [
                      11.697,
                      17.385
                    ],
                    [
                      19.987,
                      -5.016
                    ],
                    [
                      18.987,
                      -37.131
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  250.715,
                  248.394
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 77",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 27,
      "ty": 4,
      "nm": "Computer Outlines - Group 71",
      "parent": 28,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            272.259,
            279.298,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            272.259,
            279.298,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            -14.308,
                            -2.985
                          ],
                          [
                            10.14,
                            -2.152
                          ],
                          [
                            0,
                            6.195
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -8.886,
                            1.886
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            9.433,
                            -7.635
                          ],
                          [
                            2.161,
                            8.734
                          ],
                          [
                            -12.3,
                            -3.74
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 42,
                    "s": [
                      {
                        "i": [
                          [
                            -14.308,
                            -2.985
                          ],
                          [
                            14.499,
                            -0.656
                          ],
                          [
                            -1.569,
                            5.306
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -9.075,
                            0.411
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            9.433,
                            -7.635
                          ],
                          [
                            -4.199,
                            9.179
                          ],
                          [
                            -12.3,
                            -3.74
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 90,
                    "s": [
                      {
                        "i": [
                          [
                            -14.308,
                            -2.985
                          ],
                          [
                            10.14,
                            -2.152
                          ],
                          [
                            0,
                            6.195
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -8.886,
                            1.886
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            9.433,
                            -7.635
                          ],
                          [
                            2.161,
                            8.734
                          ],
                          [
                            -12.3,
                            -3.74
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 133,
                    "s": [
                      {
                        "i": [
                          [
                            -14.308,
                            -2.985
                          ],
                          [
                            14.499,
                            -0.656
                          ],
                          [
                            -1.569,
                            5.306
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -9.075,
                            0.411
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            9.433,
                            -7.635
                          ],
                          [
                            -4.199,
                            9.179
                          ],
                          [
                            -12.3,
                            -3.74
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "t": 180,
                    "s": [
                      {
                        "i": [
                          [
                            -14.308,
                            -2.985
                          ],
                          [
                            10.14,
                            -2.152
                          ],
                          [
                            0,
                            6.195
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -8.886,
                            1.886
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            9.433,
                            -7.635
                          ],
                          [
                            2.161,
                            8.734
                          ],
                          [
                            -12.3,
                            -3.74
                          ]
                        ],
                        "c": true
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.477999997606,
                  0.361000001197,
                  0.344999994016,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  273.506,
                  287.486
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 71",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 28,
      "ty": 4,
      "nm": "Computer Outlines - Group 79",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 42,
              "s": [
                4
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 90,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 133,
              "s": [
                4
              ]
            },
            {
              "t": 180,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            131.485,
            124.896,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            255.485,
            251.396,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      -9.261,
                      -3
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      1.661,
                      8.863
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      8.854,
                      -4.592
                    ]
                  ],
                  "o": [
                    [
                      5.877,
                      1.903
                    ],
                    [
                      13.127,
                      5.217
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -2.419,
                      -12.913
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -7.691,
                      3.989
                    ]
                  ],
                  "v": [
                    [
                      -9.882,
                      1.195
                    ],
                    [
                      -3.669,
                      17.369
                    ],
                    [
                      17.482,
                      17.484
                    ],
                    [
                      17.482,
                      1.832
                    ],
                    [
                      5.583,
                      -18.516
                    ],
                    [
                      -8.122,
                      -17.994
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  264.874,
                  266.376
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 79",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 29,
      "ty": 4,
      "nm": "Computer Outlines - Group 83",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            110.205,
            114.038,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            234.205,
            240.538,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -32.87
                          ],
                          [
                            -15.059,
                            -10.207
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            4.947,
                            12.792
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            6.782,
                            4.597
                          ],
                          [
                            11.908,
                            3.305
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            37.166,
                            -29.983
                          ],
                          [
                            36.047,
                            -49.866
                          ],
                          [
                            -31.531,
                            -46.997
                          ],
                          [
                            -32.66,
                            -0.309
                          ],
                          [
                            -22.107,
                            28.326
                          ],
                          [
                            -16.161,
                            46.561
                          ],
                          [
                            5.785,
                            47.561
                          ],
                          [
                            3.535,
                            18.409
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 42,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -32.87
                          ],
                          [
                            -15.059,
                            -10.207
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            6.822,
                            8.292
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            6.782,
                            4.597
                          ],
                          [
                            7.018,
                            6.265
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            37.166,
                            -29.983
                          ],
                          [
                            36.047,
                            -49.866
                          ],
                          [
                            -31.531,
                            -46.997
                          ],
                          [
                            -32.66,
                            -0.309
                          ],
                          [
                            -22.107,
                            28.326
                          ],
                          [
                            -9.911,
                            44.936
                          ],
                          [
                            12.035,
                            45.936
                          ],
                          [
                            3.535,
                            18.409
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 90,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -32.87
                          ],
                          [
                            -15.059,
                            -10.207
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            4.947,
                            12.792
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            6.782,
                            4.597
                          ],
                          [
                            11.908,
                            3.305
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            37.166,
                            -29.983
                          ],
                          [
                            36.047,
                            -49.866
                          ],
                          [
                            -31.531,
                            -46.997
                          ],
                          [
                            -32.66,
                            -0.309
                          ],
                          [
                            -22.107,
                            28.326
                          ],
                          [
                            -16.161,
                            46.561
                          ],
                          [
                            5.785,
                            47.561
                          ],
                          [
                            3.535,
                            18.409
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 133,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -32.87
                          ],
                          [
                            -15.059,
                            -10.207
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            6.822,
                            8.292
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            6.782,
                            4.597
                          ],
                          [
                            7.018,
                            6.265
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            37.166,
                            -29.983
                          ],
                          [
                            36.047,
                            -49.866
                          ],
                          [
                            -31.531,
                            -46.997
                          ],
                          [
                            -32.66,
                            -0.309
                          ],
                          [
                            -22.107,
                            28.326
                          ],
                          [
                            -9.911,
                            44.936
                          ],
                          [
                            12.035,
                            45.936
                          ],
                          [
                            3.535,
                            18.409
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "t": 180,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            -32.87
                          ],
                          [
                            -15.059,
                            -10.207
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            4.947,
                            12.792
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            6.782,
                            4.597
                          ],
                          [
                            11.908,
                            3.305
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            37.166,
                            -29.983
                          ],
                          [
                            36.047,
                            -49.866
                          ],
                          [
                            -31.531,
                            -46.997
                          ],
                          [
                            -32.66,
                            -0.309
                          ],
                          [
                            -22.107,
                            28.326
                          ],
                          [
                            -16.161,
                            46.561
                          ],
                          [
                            5.785,
                            47.561
                          ],
                          [
                            3.535,
                            18.409
                          ]
                        ],
                        "c": false
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  232.268,
                  241.299
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 83",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 30,
      "ty": 4,
      "nm": "Computer Outlines - Group 85",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            137.486,
            85.697,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            261.486,
            212.197,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      -5.674,
                      -3.516
                    ],
                    [
                      -5.772,
                      -2.853
                    ],
                    [
                      9.098,
                      -4.989
                    ],
                    [
                      6.687,
                      6.261
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      3.669,
                      2.273
                    ],
                    [
                      5.228,
                      2.585
                    ],
                    [
                      -9.097,
                      4.989
                    ],
                    [
                      -6.687,
                      -6.26
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -12.919,
                      -21.173
                    ],
                    [
                      -2.494,
                      -3.95
                    ],
                    [
                      16.093,
                      -1.604
                    ],
                    [
                      15.603,
                      16.184
                    ],
                    [
                      -14.981,
                      12.564
                    ],
                    [
                      -24.701,
                      -4.751
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  280.325,
                  226.092
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 85",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 31,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            62.886,
            142.167,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            186.886,
            268.667,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                2.731,
                                4.92
                              ],
                              [
                                -5.142,
                                1.682
                              ],
                              [
                                -4.222,
                                -3.238
                              ],
                              [
                                2.44,
                                -4.069
                              ]
                            ],
                            "o": [
                              [
                                2.399,
                                -5.241
                              ],
                              [
                                3.61,
                                11.263
                              ],
                              [
                                -4.415,
                                0.43
                              ],
                              [
                                -6.833,
                                -5.607
                              ]
                            ],
                            "v": [
                              [
                                -12.219,
                                -3.35
                              ],
                              [
                                -0.433,
                                -14.214
                              ],
                              [
                                12.219,
                                6.342
                              ],
                              [
                                2.035,
                                14.214
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 42,
                        "s": [
                          {
                            "i": [
                              [
                                2.731,
                                4.92
                              ],
                              [
                                -5.142,
                                1.682
                              ],
                              [
                                -4.222,
                                -3.238
                              ],
                              [
                                2.44,
                                -4.069
                              ]
                            ],
                            "o": [
                              [
                                2.399,
                                -5.241
                              ],
                              [
                                3.61,
                                11.263
                              ],
                              [
                                -4.415,
                                0.43
                              ],
                              [
                                -6.833,
                                -5.607
                              ]
                            ],
                            "v": [
                              [
                                -10.844,
                                -1.725
                              ],
                              [
                                0.692,
                                -13.464
                              ],
                              [
                                12.219,
                                6.342
                              ],
                              [
                                3.035,
                                13.839
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 90,
                        "s": [
                          {
                            "i": [
                              [
                                2.731,
                                4.92
                              ],
                              [
                                -5.142,
                                1.682
                              ],
                              [
                                -4.222,
                                -3.238
                              ],
                              [
                                2.44,
                                -4.069
                              ]
                            ],
                            "o": [
                              [
                                2.399,
                                -5.241
                              ],
                              [
                                3.61,
                                11.263
                              ],
                              [
                                -4.415,
                                0.43
                              ],
                              [
                                -6.833,
                                -5.607
                              ]
                            ],
                            "v": [
                              [
                                -12.219,
                                -3.35
                              ],
                              [
                                -0.433,
                                -14.214
                              ],
                              [
                                12.219,
                                6.342
                              ],
                              [
                                2.035,
                                14.214
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 133,
                        "s": [
                          {
                            "i": [
                              [
                                2.731,
                                4.92
                              ],
                              [
                                -5.142,
                                1.682
                              ],
                              [
                                -4.222,
                                -3.238
                              ],
                              [
                                2.44,
                                -4.069
                              ]
                            ],
                            "o": [
                              [
                                2.399,
                                -5.241
                              ],
                              [
                                3.61,
                                11.263
                              ],
                              [
                                -4.415,
                                0.43
                              ],
                              [
                                -6.833,
                                -5.607
                              ]
                            ],
                            "v": [
                              [
                                -10.844,
                                -1.725
                              ],
                              [
                                0.692,
                                -13.464
                              ],
                              [
                                12.219,
                                6.342
                              ],
                              [
                                3.035,
                                13.839
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 180,
                        "s": [
                          {
                            "i": [
                              [
                                2.731,
                                4.92
                              ],
                              [
                                -5.142,
                                1.682
                              ],
                              [
                                -4.222,
                                -3.238
                              ],
                              [
                                2.44,
                                -4.069
                              ]
                            ],
                            "o": [
                              [
                                2.399,
                                -5.241
                              ],
                              [
                                3.61,
                                11.263
                              ],
                              [
                                -4.415,
                                0.43
                              ],
                              [
                                -6.833,
                                -5.607
                              ]
                            ],
                            "v": [
                              [
                                -12.219,
                                -3.35
                              ],
                              [
                                -0.433,
                                -14.214
                              ],
                              [
                                12.219,
                                6.342
                              ],
                              [
                                2.035,
                                14.214
                              ]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      177.303,
                      262.939
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 87",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  177.303,
                  262.939
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  177.303,
                  262.939
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 87",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                -6.833,
                                -5.607
                              ],
                              [
                                0.859,
                                -3.203
                              ],
                              [
                                1.032,
                                1.255
                              ],
                              [
                                -3.741,
                                8.17
                              ]
                            ],
                            "o": [
                              [
                                -2.435,
                                4.062
                              ],
                              [
                                -0.422,
                                1.569
                              ],
                              [
                                -8.92,
                                -10.846
                              ],
                              [
                                2.731,
                                4.919
                              ]
                            ],
                            "v": [
                              [
                                8.998,
                                1.766
                              ],
                              [
                                4.153,
                                13.782
                              ],
                              [
                                0.676,
                                14.541
                              ],
                              [
                                -5.257,
                                -15.797
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 42,
                        "s": [
                          {
                            "i": [
                              [
                                -6.833,
                                -5.607
                              ],
                              [
                                0.859,
                                -3.203
                              ],
                              [
                                1.416,
                                0.796
                              ],
                              [
                                -2.584,
                                9.285
                              ]
                            ],
                            "o": [
                              [
                                -2.435,
                                4.062
                              ],
                              [
                                -0.422,
                                1.569
                              ],
                              [
                                -12.766,
                                -7.178
                              ],
                              [
                                2.731,
                                4.919
                              ]
                            ],
                            "v": [
                              [
                                9.998,
                                1.391
                              ],
                              [
                                9.653,
                                13.532
                              ],
                              [
                                6.176,
                                14.291
                              ],
                              [
                                -3.882,
                                -14.172
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 90,
                        "s": [
                          {
                            "i": [
                              [
                                -6.833,
                                -5.607
                              ],
                              [
                                0.859,
                                -3.203
                              ],
                              [
                                1.032,
                                1.255
                              ],
                              [
                                -3.741,
                                8.17
                              ]
                            ],
                            "o": [
                              [
                                -2.435,
                                4.062
                              ],
                              [
                                -0.422,
                                1.569
                              ],
                              [
                                -8.92,
                                -10.846
                              ],
                              [
                                2.731,
                                4.919
                              ]
                            ],
                            "v": [
                              [
                                8.998,
                                1.766
                              ],
                              [
                                4.153,
                                13.782
                              ],
                              [
                                0.676,
                                14.541
                              ],
                              [
                                -5.257,
                                -15.797
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 133,
                        "s": [
                          {
                            "i": [
                              [
                                -6.833,
                                -5.607
                              ],
                              [
                                0.859,
                                -3.203
                              ],
                              [
                                1.416,
                                0.796
                              ],
                              [
                                -2.584,
                                9.285
                              ]
                            ],
                            "o": [
                              [
                                -2.435,
                                4.062
                              ],
                              [
                                -0.422,
                                1.569
                              ],
                              [
                                -12.766,
                                -7.178
                              ],
                              [
                                2.731,
                                4.919
                              ]
                            ],
                            "v": [
                              [
                                9.998,
                                1.391
                              ],
                              [
                                9.653,
                                13.532
                              ],
                              [
                                6.176,
                                14.291
                              ],
                              [
                                -3.882,
                                -14.172
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 180,
                        "s": [
                          {
                            "i": [
                              [
                                -6.833,
                                -5.607
                              ],
                              [
                                0.859,
                                -3.203
                              ],
                              [
                                1.032,
                                1.255
                              ],
                              [
                                -3.741,
                                8.17
                              ]
                            ],
                            "o": [
                              [
                                -2.435,
                                4.062
                              ],
                              [
                                -0.422,
                                1.569
                              ],
                              [
                                -8.92,
                                -10.846
                              ],
                              [
                                2.731,
                                4.919
                              ]
                            ],
                            "v": [
                              [
                                8.998,
                                1.766
                              ],
                              [
                                4.153,
                                13.782
                              ],
                              [
                                0.676,
                                14.541
                              ],
                              [
                                -5.257,
                                -15.797
                              ]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      170.341,
                      275.387
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 89",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  171.15,
                  275.123
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  171.15,
                  275.123
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 89",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                3.61,
                                11.263
                              ],
                              [
                                -1.589,
                                0.09
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                15.362,
                                1.541
                              ],
                              [
                                0.641,
                                -0.062
                              ]
                            ],
                            "o": [
                              [
                                1.466,
                                -0.479
                              ],
                              [
                                7.109,
                                -0.402
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -0.687,
                                -0.068
                              ],
                              [
                                -4.222,
                                -3.238
                              ]
                            ],
                            "v": [
                              [
                                -16.969,
                                -10.025
                              ],
                              [
                                -12.379,
                                -10.899
                              ],
                              [
                                7.013,
                                -12.073
                              ],
                              [
                                16.969,
                                7.394
                              ],
                              [
                                -2.326,
                                10.532
                              ],
                              [
                                -4.317,
                                10.531
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 42,
                        "s": [
                          {
                            "i": [
                              [
                                3.61,
                                11.263
                              ],
                              [
                                -1.574,
                                0.233
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                15.362,
                                1.541
                              ],
                              [
                                0.641,
                                -0.062
                              ]
                            ],
                            "o": [
                              [
                                1.466,
                                -0.479
                              ],
                              [
                                8.289,
                                -1.227
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -0.687,
                                -0.068
                              ],
                              [
                                -4.222,
                                -3.238
                              ]
                            ],
                            "v": [
                              [
                                -15.844,
                                -9.275
                              ],
                              [
                                -11.254,
                                -10.149
                              ],
                              [
                                7.013,
                                -12.073
                              ],
                              [
                                16.969,
                                7.394
                              ],
                              [
                                -2.326,
                                10.532
                              ],
                              [
                                -4.317,
                                10.531
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 90,
                        "s": [
                          {
                            "i": [
                              [
                                3.61,
                                11.263
                              ],
                              [
                                -1.589,
                                0.09
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                15.362,
                                1.541
                              ],
                              [
                                0.641,
                                -0.062
                              ]
                            ],
                            "o": [
                              [
                                1.466,
                                -0.479
                              ],
                              [
                                7.109,
                                -0.402
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -0.687,
                                -0.068
                              ],
                              [
                                -4.222,
                                -3.238
                              ]
                            ],
                            "v": [
                              [
                                -16.969,
                                -10.025
                              ],
                              [
                                -12.379,
                                -10.899
                              ],
                              [
                                7.013,
                                -12.073
                              ],
                              [
                                16.969,
                                7.394
                              ],
                              [
                                -2.326,
                                10.532
                              ],
                              [
                                -4.317,
                                10.531
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 133,
                        "s": [
                          {
                            "i": [
                              [
                                3.61,
                                11.263
                              ],
                              [
                                -1.574,
                                0.233
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                15.362,
                                1.541
                              ],
                              [
                                0.641,
                                -0.062
                              ]
                            ],
                            "o": [
                              [
                                1.466,
                                -0.479
                              ],
                              [
                                8.289,
                                -1.227
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -0.687,
                                -0.068
                              ],
                              [
                                -4.222,
                                -3.238
                              ]
                            ],
                            "v": [
                              [
                                -15.844,
                                -9.275
                              ],
                              [
                                -11.254,
                                -10.149
                              ],
                              [
                                7.013,
                                -12.073
                              ],
                              [
                                16.969,
                                7.394
                              ],
                              [
                                -2.326,
                                10.532
                              ],
                              [
                                -4.317,
                                10.531
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 180,
                        "s": [
                          {
                            "i": [
                              [
                                3.61,
                                11.263
                              ],
                              [
                                -1.589,
                                0.09
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                15.362,
                                1.541
                              ],
                              [
                                0.641,
                                -0.062
                              ]
                            ],
                            "o": [
                              [
                                1.466,
                                -0.479
                              ],
                              [
                                7.109,
                                -0.402
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -0.687,
                                -0.068
                              ],
                              [
                                -4.222,
                                -3.238
                              ]
                            ],
                            "v": [
                              [
                                -16.969,
                                -10.025
                              ],
                              [
                                -12.379,
                                -10.899
                              ],
                              [
                                7.013,
                                -12.073
                              ],
                              [
                                16.969,
                                7.394
                              ],
                              [
                                -2.326,
                                10.532
                              ],
                              [
                                -4.317,
                                10.531
                              ]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      193.84,
                      258.751
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 91",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  193.84,
                  258.136
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  193.84,
                  258.136
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 91",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 32,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            138.564,
            194.004,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            262.564,
            320.504,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          213.395,
                          320.504
                        ],
                        [
                          223.097,
                          320.504
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 92",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  218.246,
                  320.504
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  218.246,
                  320.504
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 92",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          301.142,
                          320.504
                        ],
                        [
                          311.733,
                          320.504
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 93",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  306.437,
                  320.504
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  306.437,
                  320.504
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 93",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 33,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            116.886,
            168.228,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            240.886,
            294.728,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          11.994,
                          0
                        ],
                        [
                          0,
                          -6.375
                        ]
                      ],
                      "o": [
                        [
                          0,
                          -6.375
                        ],
                        [
                          -11.994,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          21.717,
                          5.771
                        ],
                        [
                          0,
                          -5.772
                        ],
                        [
                          -21.718,
                          5.771
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 2,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.152999997139,
                      0.666999995708,
                      0.882000029087,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      241.259,
                      314.732
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 27",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  241.259,
                  314.732
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  241.259,
                  314.732
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Computer Outlines - Group 27",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [
                              -1.657,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "o": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              1.657
                            ]
                          ],
                          "v": [
                            [
                              -0.391,
                              10.219
                            ],
                            [
                              3.391,
                              10.219
                            ],
                            [
                              3.391,
                              -10.22
                            ],
                            [
                              -3.391,
                              -10.22
                            ],
                            [
                              -3.391,
                              7.219
                            ]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.109999999404,
                          0.109999999404,
                          1
                        ],
                        "ix": 3
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 4
                      },
                      "w": {
                        "a": 0,
                        "k": 2,
                        "ix": 5
                      },
                      "lc": 2,
                      "lj": 2,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.152999997139,
                          0.666999995708,
                          0.882000029087,
                          1
                        ],
                        "ix": 4
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 5
                      },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          239.429,
                          300.284
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          0,
                          0
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 95",
                  "np": 3,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      239.429,
                      300.284
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      239.429,
                      300.284
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Computer Outlines - Group 95",
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              1.657
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "o": [
                            [
                              0,
                              0
                            ],
                            [
                              1.656,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -3.392,
                              10.219
                            ],
                            [
                              0.392,
                              10.219
                            ],
                            [
                              3.392,
                              7.219
                            ],
                            [
                              3.392,
                              -10.22
                            ],
                            [
                              -3.392,
                              -10.22
                            ]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.109999999404,
                          0.109999999404,
                          1
                        ],
                        "ix": 3
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 4
                      },
                      "w": {
                        "a": 0,
                        "k": 2,
                        "ix": 5
                      },
                      "lc": 2,
                      "lj": 2,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.458999991417,
                          0.736999988556,
                          1
                        ],
                        "ix": 4
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 5
                      },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          244.467,
                          300.284
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          0,
                          0
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 97",
                  "np": 3,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      244.467,
                      300.284
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      244.467,
                      300.284
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Computer Outlines - Group 97",
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              2.761
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              2.762,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "o": [
                            [
                              0,
                              0
                            ],
                            [
                              2.762,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -2.762
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "v": [
                            [
                              -34.823,
                              10.557
                            ],
                            [
                              29.823,
                              10.557
                            ],
                            [
                              34.823,
                              5.557
                            ],
                            [
                              34.823,
                              -5.556
                            ],
                            [
                              29.823,
                              -10.556
                            ],
                            [
                              -34.823,
                              -10.556
                            ]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.109999999404,
                          0.109999999404,
                          1
                        ],
                        "ix": 3
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 4
                      },
                      "w": {
                        "a": 0,
                        "k": 2,
                        "ix": 5
                      },
                      "lc": 2,
                      "lj": 2,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.458999991417,
                          0.736999988556,
                          1
                        ],
                        "ix": 4
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 5
                      },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          257.145,
                          279.508
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          0,
                          0
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 99",
                  "np": 3,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      257.145,
                      279.508
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      257.145,
                      279.508
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Computer Outlines - Group 99",
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 3,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "gr",
                  "it": [
                    {
                      "ind": 0,
                      "ty": "sh",
                      "ix": 1,
                      "ks": {
                        "a": 0,
                        "k": {
                          "i": [
                            [
                              -2.762,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              -2.762
                            ],
                            [
                              0,
                              0
                            ]
                          ],
                          "o": [
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              -2.762,
                              0
                            ],
                            [
                              0,
                              0
                            ],
                            [
                              0,
                              2.761
                            ]
                          ],
                          "v": [
                            [
                              -29.823,
                              10.557
                            ],
                            [
                              34.823,
                              10.557
                            ],
                            [
                              34.823,
                              -10.556
                            ],
                            [
                              -29.823,
                              -10.556
                            ],
                            [
                              -34.823,
                              -5.556
                            ],
                            [
                              -34.823,
                              5.557
                            ]
                          ],
                          "c": true
                        },
                        "ix": 2
                      },
                      "nm": "Path 1",
                      "mn": "ADBE Vector Shape - Group",
                      "hd": false
                    },
                    {
                      "ty": "st",
                      "c": {
                        "a": 0,
                        "k": [
                          0.109999999404,
                          0.109999999404,
                          0.109999999404,
                          1
                        ],
                        "ix": 3
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 4
                      },
                      "w": {
                        "a": 0,
                        "k": 2,
                        "ix": 5
                      },
                      "lc": 2,
                      "lj": 2,
                      "bm": 0,
                      "nm": "Stroke 1",
                      "mn": "ADBE Vector Graphic - Stroke",
                      "hd": false
                    },
                    {
                      "ty": "fl",
                      "c": {
                        "a": 0,
                        "k": [
                          0.152999997139,
                          0.666999995708,
                          0.882000029087,
                          1
                        ],
                        "ix": 4
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 5
                      },
                      "r": 1,
                      "bm": 0,
                      "nm": "Fill 1",
                      "mn": "ADBE Vector Graphic - Fill",
                      "hd": false
                    },
                    {
                      "ty": "tr",
                      "p": {
                        "a": 0,
                        "k": [
                          224.626,
                          279.508
                        ],
                        "ix": 2
                      },
                      "a": {
                        "a": 0,
                        "k": [
                          0,
                          0
                        ],
                        "ix": 1
                      },
                      "s": {
                        "a": 0,
                        "k": [
                          100,
                          100
                        ],
                        "ix": 3
                      },
                      "r": {
                        "a": 0,
                        "k": 0,
                        "ix": 6
                      },
                      "o": {
                        "a": 0,
                        "k": 100,
                        "ix": 7
                      },
                      "sk": {
                        "a": 0,
                        "k": 0,
                        "ix": 4
                      },
                      "sa": {
                        "a": 0,
                        "k": 0,
                        "ix": 5
                      },
                      "nm": "Transform"
                    }
                  ],
                  "nm": "Group 101",
                  "np": 3,
                  "cix": 2,
                  "bm": 0,
                  "ix": 1,
                  "mn": "ADBE Vector Group",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      224.626,
                      279.508
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      224.626,
                      279.508
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Computer Outlines - Group 101",
              "np": 1,
              "cix": 2,
              "bm": 0,
              "ix": 4,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  240.886,
                  289.728
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  240.886,
                  289.728
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Merged Shape Layer",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;