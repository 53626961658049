"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onToggleCompleteTaskGroup = exports.onUpdateTaskGroupIndex = exports.onDuplicateTaskGroup = exports.onCollapseTaskGroup = exports.onDeleteTaskGroup = exports.onPutTaskGroup = exports.onPostTaskGroup = void 0;
var types = __importStar(require("@pages/TaskBoard/reducers/taskGroupConstants"));
function onPostTaskGroup() {
    return function (dispatch) { return function (taskGroup) {
        dispatch({
            type: types.POST_TASK_GROUP_SUCCESS,
            taskGroup: taskGroup,
        });
    }; };
}
exports.onPostTaskGroup = onPostTaskGroup;
function onPutTaskGroup() {
    return function (dispatch) { return function (taskGroup) {
        dispatch({
            type: types.PUT_TASK_GROUP_SUCCESS,
            taskGroup: taskGroup,
        });
    }; };
}
exports.onPutTaskGroup = onPutTaskGroup;
function onDeleteTaskGroup() {
    return function (dispatch) { return function (taskGroupId) {
        dispatch({
            type: types.DELETE_TASK_GROUP_SUCCESS,
            taskGroupId: taskGroupId,
        });
    }; };
}
exports.onDeleteTaskGroup = onDeleteTaskGroup;
function onCollapseTaskGroup() {
    return function (dispatch) { return function (taskGroupId, collapsed) {
        dispatch({
            type: types.COLLAPSE_TASK_GROUP_SUCCESS,
            taskGroupId: taskGroupId,
            collapsed: collapsed,
        });
    }; };
}
exports.onCollapseTaskGroup = onCollapseTaskGroup;
function onDuplicateTaskGroup() {
    return function (dispatch) { return function (taskGroup) {
        dispatch({
            type: types.DUPLICATE_TASK_GROUP_SUCCESS,
            taskGroup: taskGroup,
        });
    }; };
}
exports.onDuplicateTaskGroup = onDuplicateTaskGroup;
function onUpdateTaskGroupIndex() {
    return function (dispatch) {
        return function (_a) {
            var taskGroupId = _a.taskGroupId, to = _a.to, from = _a.from;
            dispatch({
                type: types.COLLABORATOR_UPDATE_TASK_GROUP_INDEX_SUCCESS,
                taskGroupId: taskGroupId,
                to: to,
                from: from,
            });
        };
    };
}
exports.onUpdateTaskGroupIndex = onUpdateTaskGroupIndex;
function onToggleCompleteTaskGroup() {
    return function (dispatch) { return function (response) {
        return dispatch(__assign({ type: types.TOGGLE_COMPLETE_TASK_GROUP_SUCCESS }, response));
    }; };
}
exports.onToggleCompleteTaskGroup = onToggleCompleteTaskGroup;
