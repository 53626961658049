"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    field: {
        width: "100%",
        marginBottom: 10,
    },
    colorPicker: {
        right: 10,
        bottom: 100,
    },
}); });
exports.default = useStyles;
