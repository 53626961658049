"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGlobalMovingEdgeHandle = exports.setGlobalMovingEdgeHandle = exports.getGlobalMovingEdge = exports.setGlobalMovingEdge = exports.useMoveEdge = void 0;
var react_1 = require("react");
var react_singleton_hook_1 = require("react-singleton-hook");
var globalMovingEdgeInitState = null;
var globalMovingHandleInitState = null;
var globalMovingEdge = globalMovingEdgeInitState;
var _setGlobalMovingEdge = function () {
    throw new Error("You must useMoveEdge before setting its state");
};
var globalMovingHandle = globalMovingHandleInitState;
var _setGlobalMovingHandle = function () {
    throw new Error("You must useMoveEdge before setting its state");
};
exports.useMoveEdge = (0, react_singleton_hook_1.singletonHook)(globalMovingEdgeInitState, function () {
    var _a = (0, react_1.useState)(globalMovingEdgeInitState), edge = _a[0], setEdge = _a[1];
    var _b = (0, react_1.useState)(globalMovingHandleInitState), handle = _b[0], setHandle = _b[1];
    globalMovingEdge = edge;
    globalMovingHandle = handle;
    _setGlobalMovingEdge = setEdge;
    _setGlobalMovingHandle = setHandle;
    return { globalMovingEdge: edge, globalMovingHandle: handle };
});
var setGlobalMovingEdge = function (edge) {
    return _setGlobalMovingEdge(edge);
};
exports.setGlobalMovingEdge = setGlobalMovingEdge;
var getGlobalMovingEdge = function () { return globalMovingEdge; };
exports.getGlobalMovingEdge = getGlobalMovingEdge;
var setGlobalMovingEdgeHandle = function (handle) {
    return _setGlobalMovingHandle(handle);
};
exports.setGlobalMovingEdgeHandle = setGlobalMovingEdgeHandle;
var getGlobalMovingEdgeHandle = function () {
    return globalMovingHandle;
};
exports.getGlobalMovingEdgeHandle = getGlobalMovingEdgeHandle;
