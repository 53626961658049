"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REMOVE_DROPDOWN_CONDITION_LABEL = exports.ADD_DROPDOWN_CONDITION_LABEL = exports.REMOVE_CONDITION = exports.UPDATE_CONDITION = exports.ADD_CONDITION = exports.REMOVE_TRIGGER = exports.UPDATE_TRIGGER = exports.ADD_TRIGGER = exports.REMOVE_HANDLER = exports.UPDATE_HANDLER = exports.ADD_HANDLER = void 0;
exports.ADD_HANDLER = "ADD_HANDLER";
exports.UPDATE_HANDLER = "UPDATE_HANDLER";
exports.REMOVE_HANDLER = "REMOVE_HANDLER";
exports.ADD_TRIGGER = "ADD_TRIGGER";
exports.UPDATE_TRIGGER = "UPDATE_TRIGGER";
exports.REMOVE_TRIGGER = "REMOVE_TRIGGER";
exports.ADD_CONDITION = "ADD_CONDITION";
exports.UPDATE_CONDITION = "UPDATE_CONDITION";
exports.REMOVE_CONDITION = "REMOVE_CONDITION";
exports.ADD_DROPDOWN_CONDITION_LABEL = "ADD_DROPDOWN_CONDITION_LABEL";
exports.REMOVE_DROPDOWN_CONDITION_LABEL = "REMOVE_DROPDOWN_CONDITION_LABEL";
