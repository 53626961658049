"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
function extractCellKeys(task) {
    var _expanded = task.expanded, _id = task.id, _index = task.index, _manuelIndex = task.manuelIndex, _parentId = task.parentId, _tasks = task.tasks, _title = task.title, _cc = task.commentCount, _loading = task.loadingPlaceholder, _reorder = task.__reorder__, cells = __rest(task, ["expanded", "id", "index", "manuelIndex", "parentId", "tasks", "title", "commentCount", "loadingPlaceholder", "__reorder__"]);
    var validCells = cells;
    return validCells;
}
exports.default = extractCellKeys;
