"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var tss_react_1 = require("tss-react");
var ellipsis = (0, tss_react_1.keyframes)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\nto {\n  width: 12px;\n}"], ["\nto {\n  width: 12px;\n}"])));
var useUIStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    linearProgressBarContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 5,
    },
    linearProgressTextContainer: {
        display: "flex",
        width: "90%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-end",
    },
    linearProgressBar: {
        width: "90%",
    },
    textLoading: {
        "&:after": {
            overflow: "hidden",
            display: "inline-block",
            verticalAlign: "bottom",
            animation: "".concat(ellipsis, " steps(4, end) 3s infinite"),
            content: "'\\2026'",
            width: "0px",
        },
    },
    backButton: {
        "&:hover": {
            "& > svg": {
                color: theme.palette.primary.main,
            },
        },
    },
    colouredDot: {
        border: "1px solid",
        borderRadius: "50%",
        borderColor: "rgba(0,0,0, 0.2)",
    },
    completedIcon: {
        color: theme.palette.success.main,
    },
    completedIconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    divButton: {
        all: "unset",
        display: "block",
        position: "relative",
        "&:hover": {
            cursor: "pointer",
        },
    },
    "DayPicker-root": {
        padding: 8,
    },
    weekdayPickerGroup: {
        gap: 4,
    },
    weekdayPickerButton: {
        textTransform: "capitalize",
        width: 45,
        height: 45,
        border: "none",
        "&&&": {
            borderRadius: "50%",
            marginLeft: 0,
        },
        "&.Mui-selected": {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            "&:hover": {
                background: theme.palette.primary.dark,
            },
        },
    },
}); });
exports.default = useUIStyles;
var templateObject_1;
