"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHANGE_TAGS_ACTIVE = exports.TAG_DELETE_FAILED = exports.TAG_DELETE_SUCCESS = exports.TAG_UPDATE_FAILED = exports.TAG_UPDATE_SUCCESS = exports.TAG_POST_FAILED = exports.TAG_POST_SUCCESS = exports.TAG_INDEX_FAILED = exports.TAG_INDEX_SUCCESS = void 0;
exports.TAG_INDEX_SUCCESS = "TAG_INDEX_SUCCESS";
exports.TAG_INDEX_FAILED = "TAG_INDEX_FAILED";
exports.TAG_POST_SUCCESS = "TAG_POST_SUCCESS";
exports.TAG_POST_FAILED = "TAG_POST_FAILED";
exports.TAG_UPDATE_SUCCESS = "TAG_UPDATE_SUCCESS";
exports.TAG_UPDATE_FAILED = "TAG_UPDATE_FAILED";
exports.TAG_DELETE_SUCCESS = "TAG_DELETE_SUCCESS";
exports.TAG_DELETE_FAILED = "TAG_DELETE_FAILED";
exports.CHANGE_TAGS_ACTIVE = "CHANGE_TAGS_ACTIVE";
