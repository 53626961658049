"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_EXCLUDED_OUTPUT = exports.REMOVE_EXCLUDED_OUTPUT = exports.CLEAR_OUTPUT = exports.OUTPUT_SECTION_DIVIDER_TITLE_CHANGE = exports.OUTPUT_TOGGLE_SECTION_DIVIDER = exports.OUTPUT_TOGGLE_DISABLED = exports.OUTPUT_TOGGLE_FULL_STRUCTURE = exports.OUTPUT_DUPLICATE_FAILED = exports.OUTPUT_DUPLICATE_SUCCESS = exports.OUTPUT_DUPLICATE_LOADING = exports.PUT_OUTPUT_SLIDE_CONTENT_FAILED = exports.PUT_OUTPUT_SLIDE_CONTENT_SUCCESS = exports.DELETE_OUTPUT_SLIDES_FAILED = exports.DELETE_OUTPUT_SLIDES_SUCCESS = exports.DELETE_OUTPUT_SLIDES_LOADING = exports.PUT_OUTPUT_SLIDES_FAILED = exports.PUT_OUTPUT_SLIDES_SUCCESS = exports.PUT_OUTPUT_SLIDES_LOADING = exports.POST_OUTPUT_SLIDE_SUCCESS = exports.POST_OUTPUT_SLIDE_FAILED = exports.POST_OUTPUT_SLIDE_LOADING = exports.DOWNLOAD_OUTPUT_FAILED = exports.DOWNLOAD_OUTPUT_SUCCESS = exports.DOWNLOAD_OUTPUT_LOADING = exports.CHANGE_OUTPUT_CONDITION_VISIBILITY = exports.CHANGE_TAGS = exports.OUTPUT_DELETE_CONDITION = exports.OUTPUT_CHANGE_CONDITION = exports.OUTPUT_ADD_CONDITION = exports.OUTPUT_TEXT_CHANGE = exports.FILE_TYPE_CHANGE = exports.ADD_OUTPUT_FILE = exports.OUTPUT_CONTENT_TYPE_CHANGE = exports.OUTPUT_TYPE_CHANGE = exports.DESCRIPTION_CHANGE = exports.TITLE_CHANGE = exports.GET_CONDITION_DROPDOWN_FAILED = exports.GET_CONDITION_DROPDOWN_SUCCESS = exports.DELETE_OUTPUT_FAILED = exports.DELETE_OUTPUT_SUCCESS = exports.PUT_OUTPUT_FAILED = exports.PUT_OUTPUT_SUCCESS = exports.SHOW_OUTPUT_FAILED = exports.SHOW_OUTPUT_SUCCESS = exports.POST_OUTPUT_FAILED = exports.POST_OUTPUT_SUCCESS = exports.GET_OUTPUTS_DROPDOWN_FAILED = exports.GET_OUTPUTS_DROPDOWN_SUCCESS = exports.GET_OUTPUTS_FAILED = exports.GET_OUTPUTS_SUCCESS = void 0;
exports.CHANGE_EXCLUDED_OUTPUT = void 0;
// API
exports.GET_OUTPUTS_SUCCESS = "GET_OUTPUTS_SUCCESS";
exports.GET_OUTPUTS_FAILED = "GET_OUTPUTS_FAILED";
exports.GET_OUTPUTS_DROPDOWN_SUCCESS = "GET_OUTPUTS_DROPDOWN_SUCCESS";
exports.GET_OUTPUTS_DROPDOWN_FAILED = "GET_OUTPUTS_DROPDOWN_FAILED";
exports.POST_OUTPUT_SUCCESS = "POST_OUTPUT_SUCCESS";
exports.POST_OUTPUT_FAILED = "POST_OUTPUT_FAILED";
exports.SHOW_OUTPUT_SUCCESS = "SHOW_OUTPUT_SUCCESS";
exports.SHOW_OUTPUT_FAILED = "SHOW_OUTPUT_FAILED";
exports.PUT_OUTPUT_SUCCESS = "PUT_OUTPUT_SUCCESS";
exports.PUT_OUTPUT_FAILED = "PUT_OUTPUT_FAILED";
exports.DELETE_OUTPUT_SUCCESS = "DELETE_OUTPUT_SUCCESS";
exports.DELETE_OUTPUT_FAILED = "DELETE_OUTPUT_FAILED";
exports.GET_CONDITION_DROPDOWN_SUCCESS = "GET_CONDITION_DROPDOWN_SUCCESS";
exports.GET_CONDITION_DROPDOWN_FAILED = "GET_CONDITION_DROPDOWN_FAILED";
exports.TITLE_CHANGE = "OUTPUT_TITLE_CHANGE";
exports.DESCRIPTION_CHANGE = "OUTPUT_DESCRIPTION_CHANGE";
exports.OUTPUT_TYPE_CHANGE = "OUTPUT_TYPE_CHANGE";
exports.OUTPUT_CONTENT_TYPE_CHANGE = "OUTPUT_CONTENT_TYPE_CHANGE";
exports.ADD_OUTPUT_FILE = "ADD_OUTPUT_FILE";
exports.FILE_TYPE_CHANGE = "OUTPUT_FILE_TYPE_CHANGE";
exports.OUTPUT_TEXT_CHANGE = "OUTPUT_TEXT_CHANGE";
exports.OUTPUT_ADD_CONDITION = "OUTPUT_ADD_CONDITION";
exports.OUTPUT_CHANGE_CONDITION = "OUTPUT_CHANGE_CONDITION";
exports.OUTPUT_DELETE_CONDITION = "OUTPUT_DELETE_CONDITION";
exports.CHANGE_TAGS = "CHANGE_TAGS";
exports.CHANGE_OUTPUT_CONDITION_VISIBILITY = "CHANGE_OUTPUT_CONDITION_VISIBILITY";
exports.DOWNLOAD_OUTPUT_LOADING = "DOWNLOAD_OUTPUT_LOADING";
exports.DOWNLOAD_OUTPUT_SUCCESS = "DOWNLOAD_OUTPUT_SUCCESS";
exports.DOWNLOAD_OUTPUT_FAILED = "DOWNLOAD_OUTPUT_FAILED";
exports.POST_OUTPUT_SLIDE_LOADING = "POST_OUTPUT_SLIDE_LOADING";
exports.POST_OUTPUT_SLIDE_FAILED = "POST_OUTPUT_SLIDE_FAILED";
exports.POST_OUTPUT_SLIDE_SUCCESS = "POST_OUTPUT_SLIDE_SUCCESS";
exports.PUT_OUTPUT_SLIDES_LOADING = "PUT_OUTPUT_SLIDES_LOADING";
exports.PUT_OUTPUT_SLIDES_SUCCESS = "PUT_OUTPUT_SLIDES_SUCCESS";
exports.PUT_OUTPUT_SLIDES_FAILED = "PUT_OUTPUT_SLIDES_FAILED";
exports.DELETE_OUTPUT_SLIDES_LOADING = "DELETE_OUTPUT_SLIDES_LOADING";
exports.DELETE_OUTPUT_SLIDES_SUCCESS = "DELETE_OUTPUT_SLIDES_SUCCESS";
exports.DELETE_OUTPUT_SLIDES_FAILED = "DELETE_OUTPUT_SLIDES_FAILED";
exports.PUT_OUTPUT_SLIDE_CONTENT_SUCCESS = "PUT_OUTPUT_SLIDE_CONTENT_SUCCESS";
exports.PUT_OUTPUT_SLIDE_CONTENT_FAILED = "PUT_OUTPUT_SLIDE_CONTENT_FAILED";
exports.OUTPUT_DUPLICATE_LOADING = "OUTPUT_DUPLICATE_LOADING";
exports.OUTPUT_DUPLICATE_SUCCESS = "OUTPUT_DUPLICATE_SUCCESS";
exports.OUTPUT_DUPLICATE_FAILED = "OUTPUT_DUPLICATE_FAILED";
exports.OUTPUT_TOGGLE_FULL_STRUCTURE = "OUTPUT_TOGGLE_FULL_STRUCTURE";
exports.OUTPUT_TOGGLE_DISABLED = "OUTPUT_TOGGLE_DISABLED";
exports.OUTPUT_TOGGLE_SECTION_DIVIDER = "OUTPUT_TOGGLE_SECTION_DIVIDER";
exports.OUTPUT_SECTION_DIVIDER_TITLE_CHANGE = "OUTPUT_SECTION_DIVIDER_TITLE_CHANGE";
exports.CLEAR_OUTPUT = "CLEAR_OUTPUT";
exports.REMOVE_EXCLUDED_OUTPUT = "REMOVE_EXCLUDED_OUTPUT";
exports.ADD_EXCLUDED_OUTPUT = "ADD_EXCLUDED_OUTPUT";
exports.CHANGE_EXCLUDED_OUTPUT = "CHANGE_EXCLUDED_OUTPUT";
