"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var themePalette = {
    juristic: {
        palette: {
            primary: {
                light: "#E7F2FF",
                main: "#73B1FF",
                dark: "#193765",
                contrastText: "#fff",
            },
            secondary: {
                light: "#fff",
                main: "#E7F2FF",
                dark: "#cee4ff",
                contrastText: "#193765",
            },
            error: {
                light: "#fff",
                main: "#FF8DA0",
                dark: "#ff4160",
                contrastText: "#fff",
            },
            warning: {
                light: "#f2e1b1",
                main: "#FFDC79",
                dark: "#ffc82d",
                contrastText: "#fff",
            },
            info: {
                light: "#6F7F96",
                main: "#6F7F96",
                dark: "#6F7F96",
                contrastText: "#fff",
            },
            success: {
                light: "#c7eddd",
                main: "#73CFA8",
                dark: "#3fb784",
                contrastText: "#fff",
            },
            background: {
                default: "#F3F5F8",
                paper: "#fff",
            },
        },
        typography: {
            fontFamily: ["Open Sans", "sans-serif"],
            fontWeight: 400,
        },
    },
    accura: {
        palette: {
            primary: {
                light: "#e7f4f5",
                main: "#10929e",
                dark: "#002e30",
                contrastText: "#fff",
            },
            secondary: {
                light: "#fff",
                main: "#e7f4f5",
                dark: "#10929e",
                contrastText: "#002e30",
            },
            error: {
                light: "#fff",
                main: "#FF8DA0",
                dark: "#ff4160",
                contrastText: "#fff",
            },
            warning: {
                light: "#f2e1b1",
                main: "#FFDC79",
                dark: "#ffc82d",
                contrastText: "#fff",
            },
            info: {
                light: "#6F7F96",
                main: "#6F7F96",
                dark: "#6F7F96",
                contrastText: "#fff",
            },
            success: {
                light: "#c7eddd",
                main: "#73CFA8",
                dark: "#3fb784",
                contrastText: "#fff",
            },
            background: {
                default: "#F3F5F8",
                paper: "#fff",
            },
        },
        typography: {
            // fontFamily: ["Open Sans", "sans-serif"],
            fontFamily: ["sans-serif"],
            fontWeight: 450,
        },
    },
    pwc: {
        palette: {
            primary: {
                light: "#E7F2FF",
                main: "#73B1FF",
                dark: "#193765",
                contrastText: "#fff",
            },
            secondary: {
                light: "#fff",
                main: "#E7F2FF",
                dark: "#cee4ff",
                contrastText: "#193765",
            },
            error: {
                light: "#fff",
                main: "#FF8DA0",
                dark: "#ff4160",
                contrastText: "#fff",
            },
            warning: {
                light: "#f2e1b1",
                main: "#FFDC79",
                dark: "#ffc82d",
                contrastText: "#fff",
            },
            info: {
                light: "#6F7F96",
                main: "#6F7F96",
                dark: "#6F7F96",
                contrastText: "#fff",
            },
            success: {
                light: "#c7eddd",
                main: "#73CFA8",
                dark: "#3fb784",
                contrastText: "#fff",
            },
            background: {
                default: "#F3F5F8",
                paper: "#fff",
            },
        },
        typography: {
            fontFamily: ["Open Sans", "sans-serif"],
            fontWeight: 400,
        },
    },
};
exports.default = themePalette;
