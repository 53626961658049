"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var constants_1 = require("./constants");
var redux_1 = require("@hooks/redux");
var react_router_dom_1 = require("react-router-dom");
var constants_2 = require("@api/constants");
var columnActions_websocket_1 = require("@pages/TaskBoard/reducers/WebSocket/columnActions.websocket");
var react_i18next_1 = require("react-i18next");
var useColumns = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.filteredColumns, filteredColumns = _c === void 0 ? [] : _c, onAddColumn = _b.onAddColumn;
    var t = (0, react_i18next_1.useTranslation)().t;
    var dispatch = (0, redux_1.useAppDispatch)();
    var location = (0, react_router_dom_1.useLocation)();
    var columns = (0, redux_1.useAppSelector)(function (state) { return state.board.columns; });
    var boardId = (0, constants_2.getId)(location);
    var initialColumnCenterValues = (0, react_1.useMemo)(function () {
        return filteredColumns.length
            ? constants_1.columnCenterValues.filter(function (col) { return filteredColumns.includes(col.type); })
            : constants_1.columnCenterValues;
    }, []);
    var _d = (0, react_1.useState)(initialColumnCenterValues), filteredColumnCenterValues = _d[0], setFilteredColumnCenterValues = _d[1];
    var half = Math.ceil(filteredColumnCenterValues.length / 2);
    var firstHalfColumnCenterValues = filteredColumnCenterValues.slice(0, half);
    var secondHalfColumnCenterValues = filteredColumnCenterValues.slice(half);
    var filterList = function (e) {
        var searchValue = e.target.value.toLowerCase();
        if (searchValue === "") {
            setFilteredColumnCenterValues(initialColumnCenterValues);
            return;
        }
        var filteredValues = initialColumnCenterValues.filter(function (item) {
            return t("flow.".concat(item.type)).toLowerCase().includes(searchValue);
        });
        setFilteredColumnCenterValues(filteredValues);
    };
    var addColumn = function (columnTypeId) {
        dispatch((0, columnActions_websocket_1.postColumn)(columnTypeId, columns.length, Number(boardId), onAddColumn));
    };
    return {
        firstHalfColumnCenterValues: firstHalfColumnCenterValues,
        secondHalfColumnCenterValues: secondHalfColumnCenterValues,
        filterList: filterList,
        addColumn: addColumn,
    };
};
exports.default = useColumns;
