"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var timelineActions_websocket_1 = require("@pages/Timelines/reducers/WebSocket/timelineActions.websocket");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
function useElementsSelected(dispatch, editable) {
    var _a = (0, react_1.useState)([]), selectedNodes = _a[0], setSelectedNodes = _a[1];
    var storeApi = (0, reactflow_1.useStoreApi)();
    var onChange = (0, react_1.useCallback)(function (_a) {
        var nodes = _a.nodes;
        if (!editable) {
            return;
        }
        if (nodes.length > 0) {
            var nodesSelected = nodes.map(function (node) { return node.id; });
            if (nodesSelected.length > 1) {
                setTimeout(function () {
                    storeApi.setState(function () { return ({ nodesSelectionActive: true }); }); // show selection box
                }, 1);
            }
            setSelectedNodes(nodesSelected);
            nodesSelected.length > 0 && dispatch((0, timelineActions_websocket_1.editElementsStarted)(nodesSelected));
        }
        else {
            dispatch((0, timelineActions_websocket_1.editElementsFinished)(selectedNodes));
        }
    }, [editable, storeApi, dispatch]);
    (0, reactflow_1.useOnSelectionChange)({
        onChange: onChange,
    });
    var handleNodeDeselect = (0, react_1.useCallback)(function (_a) {
        var nodeId = _a.nodeId, _b = _a.force, force = _b === void 0 ? false : _b;
        if (nodeId) {
            if (!selectedNodes.includes(nodeId)) {
                (selectedNodes.length > 0 || force) &&
                    dispatch((0, timelineActions_websocket_1.editElementsFinished)(selectedNodes));
                setSelectedNodes([]);
            }
        }
        else {
            (selectedNodes.length > 0 || force) &&
                dispatch((0, timelineActions_websocket_1.editElementsFinished)(selectedNodes));
            setSelectedNodes([]);
        }
    }, [selectedNodes]);
    return {
        handleNodeDeselect: handleNodeDeselect,
    };
}
exports.default = useElementsSelected;
