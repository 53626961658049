"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findNavigationIndices = void 0;
function findNavigationIndices(navigationStack, id, parentId) {
    var index = -1;
    var parentIndex = -1;
    navigationStack.some(function (nav, i) {
        if (nav.id === id)
            index = i;
        if (nav.id === parentId)
            parentIndex = i;
        // Exit early if both indices are found
        return index !== -1 && parentIndex !== -1;
    });
    return { index: index, parentIndex: parentIndex };
}
exports.findNavigationIndices = findNavigationIndices;
