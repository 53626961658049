"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
var react_1 = require("react");
var uiActions_1 = require("@redux/actions/uiActions");
var conditionActions_1 = require("@pages/Conditions/reducers/conditionActions");
var react_router_dom_1 = require("react-router-dom");
var useFlowGenerics = function (reactFlowContainer, dispatch, user, type) {
    var _a = (0, react_1.useState)(null), reactFlowDimensions = _a[0], setReactFlowDimensions = _a[1];
    var location = (0, react_router_dom_1.useLocation)();
    (0, react_1.useEffect)(function () {
        if (reactFlowContainer) {
            setReactFlowDimensions({
                height: reactFlowContainer.current.clientHeight,
                width: reactFlowContainer.current.clientWidth,
            });
        }
    }, []);
    (0, react_1.useEffect)(function () {
        dispatch(uiActions_1.closeMenuAction);
        return function () {
            dispatch(uiActions_1.openMenuAction);
        };
    }, [user]);
    var toggleSubMenu = (0, react_1.useCallback)(function () { return dispatch(uiActions_1.toggleAction); }, []);
    (0, react_1.useEffect)(function () {
        if (type === "condition") {
            dispatch((0, conditionActions_1.getRelationships)(user, location));
            dispatch((0, conditionActions_1.getNodes)(user, location));
            dispatch((0, conditionActions_1.getBuildTypeValueOptions)(user, location));
        }
        else if (type === "workspace") {
            dispatch((0, workspaceActions_http_1.getRelationships)(user, location));
            dispatch((0, workspaceActions_http_1.getNodes)(user, location));
        }
    }, [user, location, type]);
    return { reactFlowDimensions: reactFlowDimensions, toggleSubMenu: toggleSubMenu };
};
exports.default = useFlowGenerics;
