"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var auth0_react_1 = require("@auth0/auth0-react");
var PPTXNodeUtils_1 = require("@components/Workspace/Node/utils/PPTXNodeUtils");
var workspace_1 = require("@customTypes/reducers/workspace");
var converters_1 = require("@helpers/measuringUnits/converters");
var redux_1 = require("@hooks/redux");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var reactflow_1 = require("reactflow");
var X_SLIDE_PADDING_IN_PX = 30;
var Y_SLIDE_PADDING_IN_PX = 30;
var CUSTOMER_SLIDE_PROPERTIES = (_a = {},
    _a[constants_1.CUSTOMER.PWC] = {
        slideWidth: 11,
        slideHeight: 8.5,
    },
    _a[constants_1.CUSTOMER.SIMONSEN_VOGT_WIIG] = {
        slideWidth: 13.33,
        slideHeight: 7.5,
    },
    _a[constants_1.CUSTOMER.BIRD_BIRD] = {
        slideWidth: 13.33,
        slideHeight: 7.5,
    },
    _a[constants_1.CUSTOMER.ROESGAARD] = {
        slideWidth: 13.33,
        slideHeight: 7.5,
    },
    _a[constants_1.CUSTOMER.MOALEM] = {
        slideWidth: 10.83,
        slideHeight: 7.5,
    },
    _a[constants_1.CUSTOMER.ALLEN_OVERY] = {
        slideWidth: 13.33,
        slideHeight: 7.5,
    },
    _a[constants_1.CUSTOMER.SNELLMAN] = {
        slideWidth: 13.33,
        slideHeight: 7.5,
    },
    _a[constants_1.CUSTOMER.WONG] = {
        slideWidth: 10,
        slideHeight: 5.63,
    },
    _a.default = {
        slideWidth: 10,
        slideHeight: 5.63,
    },
    _a);
var getSlideDimensionsInPx = function (customer) {
    var _a;
    var slideDimensions = CUSTOMER_SLIDE_PROPERTIES.default;
    if (customer) {
        // @ts-ignore
        slideDimensions =
            // @ts-ignore
            (_a = CUSTOMER_SLIDE_PROPERTIES[customer]) !== null && _a !== void 0 ? _a : CUSTOMER_SLIDE_PROPERTIES.default;
    }
    return {
        h: (0, converters_1.inch2px)(slideDimensions.slideHeight),
        w: (0, converters_1.inch2px)(slideDimensions.slideWidth),
    };
};
var PWC_FLOW_BOUNDARIES_IN_PX = {
    body: {
        top: (0, converters_1.cm2px)(5.95),
        left: (0, converters_1.cm2px)(1.27),
        height: (0, converters_1.cm2px)(13.54),
        width: (0, converters_1.cm2px)(25.4),
    },
    leftBody: {
        top: (0, converters_1.cm2px)(5.95),
        left: (0, converters_1.cm2px)(1.27),
        height: (0, converters_1.cm2px)(13.54),
        width: (0, converters_1.cm2px)(12.5),
    },
};
var usePowerPointFitter = function (mirror, PPTXView) {
    var rfInstance = (0, reactflow_1.useReactFlow)();
    var user = (0, auth0_react_1.useAuth0)().user;
    var customer = (0, constants_1.getCustomer)(user);
    var dispatch = (0, redux_1.useAppDispatch)();
    var location = (0, react_router_dom_1.useLocation)();
    var fitSelectedElements = (0, react_1.useCallback)(function () {
        var nodeElements = rfInstance.getNodes();
        var selectedNodes = nodeElements.filter(function (node) { return node.selected; });
        var edgeElements = rfInstance.getEdges();
        var selectedEdges = edgeElements.filter(function (edge) { return edge.selected; });
        /*     const layoutedElements = getLayoutedElements(selectedNodes, selectedEdges);
         */
        var _a = getSlideDimensionsInPx(customer), h = _a.h, w = _a.w;
        var _b = selectedNodes.reduce(function (acc, node) {
            // if the node has a parent, it can never be the most left, right, top or bottom node
            if (node.parentNode) {
                return acc;
            }
            var _a = node.position, x = _a.x, y = _a.y;
            var _b = node.data, width = _b.width, height = _b.height;
            acc.mostLeftX = Math.min(acc.mostLeftX, x);
            acc.mostTopY = Math.min(acc.mostTopY, y);
            acc.mostRightX = Math.max(acc.mostRightX, x + (width !== null && width !== void 0 ? width : 50));
            acc.mostBottomY = Math.max(acc.mostBottomY, y + (height !== null && height !== void 0 ? height : 50));
            return acc;
        }, {
            mostLeftX: Infinity,
            mostTopY: Infinity,
            mostRightX: -Infinity,
            mostBottomY: -Infinity,
        }), mostLeftX = _b.mostLeftX, mostTopY = _b.mostTopY, mostRightX = _b.mostRightX, mostBottomY = _b.mostBottomY;
        var nodeWidthSpan = mostRightX - mostLeftX;
        var nodeHeightSpan = mostBottomY - mostTopY;
        var paddingX = X_SLIDE_PADDING_IN_PX;
        var paddingY = Y_SLIDE_PADDING_IN_PX;
        var offsetX = 0;
        var offsetY = 0;
        if (customer === constants_1.CUSTOMER.PWC) {
            if (PPTXView === PPTXNodeUtils_1.PPTX_VIEW.structure) {
                paddingX = 0;
                paddingY = 0;
                var body = PWC_FLOW_BOUNDARIES_IN_PX.body;
                w = body.width;
                h = body.height;
                offsetX = body.left;
                offsetY = body.top;
            }
            else if (PPTXView === PPTXNodeUtils_1.PPTX_VIEW.standard) {
                paddingX = 0;
                paddingY = 0;
                var leftBody = PWC_FLOW_BOUNDARIES_IN_PX.leftBody;
                w = leftBody.width;
                h = leftBody.height;
                offsetX = leftBody.left;
                offsetY = leftBody.top;
            }
        }
        var nodeWidthRatio = (w - paddingX) / nodeWidthSpan;
        var nodeHeightRatio = (h - paddingY) / nodeHeightSpan;
        var shouldScaleDown = nodeWidthRatio < 1 || nodeHeightRatio < 1;
        // largest ratio is the one that should be used
        var ratio = Math.min(nodeWidthRatio, nodeHeightRatio);
        if (shouldScaleDown) {
            nodeWidthSpan *= ratio;
            nodeHeightSpan *= ratio;
        }
        paddingX = w - nodeWidthSpan;
        paddingY = h - nodeHeightSpan;
        var nodeChanges = [];
        var changedEdges = new Set();
        var edgeFontSize = 10;
        selectedNodes.sort(function (a, b) {
            if (a.type === workspace_1.WElementVariant.GROUP && b.type !== workspace_1.WElementVariant.GROUP) {
                return -1;
            }
            if (a.type !== workspace_1.WElementVariant.GROUP && b.type === workspace_1.WElementVariant.GROUP) {
                return 1;
            }
            return 0;
        });
        selectedNodes.forEach(function (node) {
            // if the nodes are larger than the slide, scale them down
            var _a, _b;
            var adjustedRatio = shouldScaleDown ? ratio : 1;
            if (node.parentNode) {
                node.position.x *= adjustedRatio;
                node.position.y *= adjustedRatio;
            }
            else {
                node.position.x =
                    (node.position.x - mostLeftX) * adjustedRatio + offsetX + paddingX / 2;
                node.position.y =
                    (node.position.y - mostTopY) * adjustedRatio + offsetY + paddingY / 2;
            }
            nodeChanges.push({
                id: node.id,
                type: "position",
                position: { x: node.position.x, y: node.position.y },
            });
            var hasAttributesForScaling = ((_a = node.data.width) !== null && _a !== void 0 ? _a : false) && ((_b = node.data.height) !== null && _b !== void 0 ? _b : false);
            if (shouldScaleDown && hasAttributesForScaling) {
                // @ts-ignore
                node.data.width *= ratio;
                // @ts-ignore
                node.data.height *= ratio;
                // @ts-ignore
                if (node.data.fontSize) {
                    // @ts-ignore
                    node.data.fontSize *= ratio;
                }
                nodeChanges.push({
                    id: node.id,
                    type: "dimensions",
                    // @ts-ignore
                    dimensions: { width: node.data.width, height: node.data.height },
                    updateStyle: true,
                });
            }
        });
        if (shouldScaleDown) {
            selectedEdges.forEach(function (edge) {
                if (edge.data && edge.labelStyle && edge.labelStyle.fontSize) {
                    edgeFontSize = edge.labelStyle.fontSize * ratio;
                    edge.labelStyle.fontSize = edgeFontSize;
                    changedEdges.add(edge.id);
                }
            });
            rfInstance.setEdges(function (edges) {
                return edges.map(function (edge) {
                    if (changedEdges.has(edge.id)) {
                        return __assign(__assign({}, edge), { labelStyle: __assign(__assign({}, edge.labelStyle), { fontSize: edgeFontSize }) });
                    }
                    return edge;
                });
            });
        }
        rfInstance.setNodes(function (nodes) { return (0, reactflow_1.applyNodeChanges)(nodeChanges, nodes); });
        setTimeout(function () {
            rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.fitView({ duration: 500, padding: 0.5 });
        }, 100);
        // @ts-ignore
        dispatch((0, workspaceActions_websocket_1.saveAutoLayout)(selectedNodes, selectedEdges, mirror()));
    }, [rfInstance, customer, dispatch, mirror, location, PPTXView]);
    return { fitSelectedElements: fitSelectedElements };
};
exports.default = usePowerPointFitter;
