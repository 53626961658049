"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityLogTabValue = void 0;
var ActivityLogTabValue;
(function (ActivityLogTabValue) {
    ActivityLogTabValue[ActivityLogTabValue["All"] = 0] = "All";
    ActivityLogTabValue[ActivityLogTabValue["CaseOrGroup"] = 1] = "CaseOrGroup";
    ActivityLogTabValue[ActivityLogTabValue["Workspace"] = 2] = "Workspace";
    ActivityLogTabValue[ActivityLogTabValue["Timeline"] = 3] = "Timeline";
    ActivityLogTabValue[ActivityLogTabValue["Element"] = 4] = "Element";
})(ActivityLogTabValue = exports.ActivityLogTabValue || (exports.ActivityLogTabValue = {}));
