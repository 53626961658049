"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@mui/material");
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function () { return ({
    row: {
        display: "flex",
        flexDirection: "row",
        marginTop: 15,
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        margin: 0,
    },
    input: {
        width: "67px",
        input: {
            textAlign: "right",
        },
    },
}); });
var fontSizes = {
    4: "4",
    6: "6",
    8: "8",
    9: "9",
    10: "10",
    11: "11",
    12: "12",
    14: "14",
    16: "16",
    18: "18",
    20: "20",
    24: "24",
    28: "28",
    36: "36",
};
function FontSizeInput(props) {
    var label = props.label, value = props.value, onChange = props.onChange;
    var classes = useStyles().classes;
    var t = (0, react_i18next_1.useTranslation)().t;
    var _a = react_1.default.useState(""), inputValue = _a[0], setInputValue = _a[1];
    (0, react_1.useEffect)(function () {
        if (parseFloat(inputValue) !== value &&
            inputValue.charAt(inputValue.length - 1) !== ".") {
            setInputValue(value ? value.toString() : "");
        }
    }, [value]);
    var onValueChange = (0, react_1.useCallback)(function (_, newValue) {
        // regex that removes all non numeric characters (. is allowed)
        onChange(parseFloat(newValue ? newValue.replace(/[^0-9.]/g, "") : ""));
    }, [onChange]);
    var onInputChange = (0, react_1.useCallback)(function (_, newValue) {
        // regex that removes all non numeric characters (. is allowed)
        var decimalString = newValue.replace(/[^0-9.]/g, "");
        var endsOnDotOrDotZero = decimalString.match(/\..*0$/g);
        setInputValue(decimalString);
        if (!endsOnDotOrDotZero) {
            onChange(parseFloat(decimalString));
        }
    }, [onChange]);
    return (react_1.default.createElement("div", { className: classes.row },
        react_1.default.createElement(material_1.Typography, { variant: "subtitle2" }, label),
        react_1.default.createElement(material_1.Autocomplete, { value: inputValue, inputValue: inputValue, inputMode: "decimal", onChange: onValueChange, getOptionLabel: function (option) {
                return typeof option === "string" ? option : option.toString();
            }, onInputChange: onInputChange, freeSolo: true, forcePopupIcon: true, disableClearable: true, renderInput: function (params) { return (react_1.default.createElement(material_1.TextField, __assign({}, params, { label: t(""), InputProps: __assign(__assign({}, params.InputProps), { endAdornment: params.InputProps.endAdornment, className: classes.input, style: { padding: "0px 26px 0px 1px" } }), sx: { marginTop: 0, marginBottom: 0 } }))); }, options: Object.values(fontSizes) })));
}
exports.default = FontSizeInput;
