"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateHandle = exports.CUSTOM_DUMMY_HANDLE_ID = void 0;
var CustomConnectionLine_1 = require("@components/Workspace/Edge/CustomConnectionLine");
var reactflow_1 = require("reactflow");
exports.CUSTOM_DUMMY_HANDLE_ID = "-1";
// eslint-disable-next-line import/prefer-default-export
var generateHandle = function (closestNodeObj) {
    var node = closestNodeObj.node, closestPoint = closestNodeObj.closestPoint;
    var _a = node, positionAbsolute = _a.positionAbsolute, height = _a.height, width = _a.width;
    var position = (0, CustomConnectionLine_1.getPositionFromTouchPoint)(closestPoint, node);
    var customTargetHandleY = 0;
    var customTargetHandleX = 0;
    if (position === reactflow_1.Position.Left || position === reactflow_1.Position.Right) {
        customTargetHandleY =
            ((closestPoint.y - positionAbsolute.y) /
                (positionAbsolute.y + height - positionAbsolute.y)) *
                100;
    }
    else {
        customTargetHandleX =
            ((closestPoint.x - positionAbsolute.x) /
                (positionAbsolute.x + width - positionAbsolute.x)) *
                100;
    }
    return {
        position: position,
        x: customTargetHandleX,
        y: customTargetHandleY,
    };
};
exports.generateHandle = generateHandle;
