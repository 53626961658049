"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var utils_1 = require("@components/Flow/HelperLines/utils");
var useChangeElements = function (_a) {
    var dispatch = _a.dispatch, reactFlowInstance = _a.reactFlowInstance, nodeElements = _a.nodeElements, edgeElements = _a.edgeElements, showHelperLines = _a.showHelperLines, showPPTXView = _a.showPPTXView, PPTXNode = _a.PPTXNode;
    var _b = (0, react_1.useState)(undefined), helperLineHorizontal = _b[0], setHelperLineHorizontal = _b[1];
    var _c = (0, react_1.useState)(undefined), helperLineVertical = _c[0], setHelperLineVertical = _c[1];
    // const [helperLinePwcHorizontal, setHelperLinePwcHorizontal] = useState<
    //   number | undefined
    // >(undefined);
    // const [helperLinePwcVertical, setHelperLinePwcVertical] = useState<number | undefined>(
    //   undefined
    // );
    var customApplyNodeChanges = (0, react_1.useCallback)(function (changes, nodes) {
        if (!reactFlowInstance)
            return;
        // reset the helper lines (clear existing lines, if any)
        var nodeChanges = [];
        setHelperLineHorizontal(undefined);
        setHelperLineVertical(undefined);
        // if (orgData?.cvr === "42303542" && !customRulesAlerts) {
        //   setHelperLinePwcHorizontal(undefined);
        //   setHelperLinePwcVertical(undefined);
        // }
        // if (orgData?.cvr === "42303542" && !customRulesAlerts) {
        //   const helperLinesPwc = getHelperLinesPwc(changes[0], nodes);
        // this will be true if it's a single node being dragged
        // inside we calculate the helper lines and snap position for the position where the node is being moved to
        if (changes.length >= 1 &&
            !(changes.length === nodes.length) &&
            changes[0].type === "position" &&
            changes[0].dragging &&
            changes[0].position &&
            showHelperLines) {
            var _a = (0, utils_1.getHelperLines)(changes, nodes), helperLines = _a.helperLines, updatedChanges = _a.updatedChanges;
            setHelperLineHorizontal(helperLines.horizontal);
            setHelperLineVertical(helperLines.vertical);
            var shouldSnapNode = helperLines.snapPosition.x !== undefined ||
                helperLines.snapPosition.y !== undefined;
            if (shouldSnapNode) {
                // @ts-ignore
                nodeChanges = (0, reactflow_1.applyNodeChanges)(updatedChanges, nodes);
            }
        }
        if (nodeChanges.length > 0) {
            if (showPPTXView) {
                nodeChanges.push(__assign({}, PPTXNode));
            }
            reactFlowInstance.setNodes(nodeChanges);
        }
    }, [showHelperLines, reactFlowInstance, showPPTXView, PPTXNode]);
    var onNodesChange = (0, react_1.useCallback)(function (changes) {
        customApplyNodeChanges(changes, nodeElements);
    }, [nodeElements, showHelperLines, customApplyNodeChanges]);
    var onEdgesChange = (0, react_1.useCallback)(function (changes) {
        if (changes.length === 0) {
            return;
        }
        dispatch((0, workspaceActions_http_1.changeEdges)((0, reactflow_1.applyEdgeChanges)(changes, edgeElements)));
    }, [edgeElements]);
    return {
        onNodesChange: onNodesChange,
        onEdgesChange: onEdgesChange,
        helperLineHorizontal: helperLineHorizontal,
        helperLineVertical: helperLineVertical,
    };
};
exports.default = useChangeElements;
