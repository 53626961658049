"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMousePosition = void 0;
function getMousePosition(e) {
    if ("clientX" in e && "clientY" in e) {
        return { clientX: e.clientX, clientY: e.clientY };
    }
    return null;
}
exports.getMousePosition = getMousePosition;
