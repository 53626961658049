"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var INITIALINTROS = {
    intro1: true,
    intro2: true,
    intro3: true,
};
var initialState = {
    intros: INITIALINTROS,
    users: [],
    management: [],
    shareableUsers: [],
    dropdownOptions: [],
    coworkers: [],
    loadings: constants_1.initialLoadings,
};
function reducer(state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case "POST_HELP_MESSAGE_LOADING": {
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { panel: true }) });
        }
        case "GET_DROPDOWN_SUCCESS":
            return __assign(__assign({}, state), { dropdownOptions: action.users, loadings: constants_1.initialLoadings });
        case "GET_MANAGEMENT_SUCCESS":
            return __assign(__assign({}, state), { management: action.users, loadings: constants_1.initialLoadings });
        case "GET_SHAREABLE_USERS_SUCCESS":
            return __assign(__assign({}, state), { shareableUsers: action.users, loadings: constants_1.initialLoadings });
        case "GET_COWORKERS_SUCCESS":
            return __assign(__assign({}, state), { coworkers: action.users, loadings: constants_1.initialLoadings });
        case "CLEAR_COWORKERS":
            return __assign(__assign({}, state), { coworkers: [] });
        case "GET_MANAGEMENT_LOADING":
            return __assign(__assign({}, state), { management: initialState.management });
        case "GET_SHAREABLE_USERS_LOADING":
            return __assign(__assign({}, state), { shareableUsers: initialState.shareableUsers, loadings: __assign(__assign({}, state.loadings), { panel: true }) });
        case "INTRO_ENDED":
            return __assign(__assign({}, state), { intros: __assign(__assign({}, state.intros), (_a = {}, _a["intro" + action.step] = false, _a)) });
        case "UNINVITE_USER_SUCCESS":
        case "INVITE_USER_SUCCESS":
        case "INVITE_USER_FAILED":
        case "UNINVITE_USER_FAILED":
        case "GET_USERS_FAILED":
        case "GET_MANAGEMENT_FAILED":
        case "GET_SHAREABLE_USERS_FAILED":
        case "GET_DROPDOWN_FAILED":
        case "UPDATE_ROLE_SUCCESS":
        case "GET_COWORKERS_FAILED":
        case "UPDATE_ROLE_FAILED":
        case "POST_HELP_MESSAGE_FAILED":
        case "POST_HELP_MESSAGE_SUCCESS":
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case "REMOVE_SHAREABLE_USER_FROM_STATE":
            return __assign(__assign({}, state), { shareableUsers: state.shareableUsers.filter(function (user) { return user.id !== action.user.id; }) });
        default:
            return state;
    }
}
exports.default = reducer;
