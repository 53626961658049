"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var brand = {
    name: "Juristic",
    desc: "Legal toolbox",
    prefix: "juristic",
    footerText: "Juristic All Rights Reserved 2021",
    logoText: "Juristic",
};
exports.default = brand;
