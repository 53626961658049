"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.closeNotification = exports.showNotification = void 0;
function showNotification(severity, message, helperText, duration, code) {
    return function (dispatch) {
        return dispatch({
            type: "SHOW_NOTIFICATION",
            severity: severity,
            message: message,
            helperText: helperText,
            code: code,
            duration: duration,
        });
    };
}
exports.showNotification = showNotification;
function closeNotification() {
    return function (dispatch) {
        return dispatch({
            type: "CLOSE_NOTIFICATION",
        });
    };
}
exports.closeNotification = closeNotification;
