"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function () { return ({
    root: {
        backgroundColor: "white",
        position: "relative",
    },
    standard: {
        width: "33.87cm",
        height: "19.05cm",
    },
    logo: {
        position: "absolute",
        height: "0.5in",
        top: "0.5in",
        right: "0.5in",
    },
    slideBody: {
        position: "absolute",
        top: "5.07cm",
        left: "2.33cm",
        height: "12.09cm",
        width: "29.21cm",
    },
    slideBodyTextLeft: {
        position: "absolute",
        top: "5.07cm",
        left: "2.33cm",
        height: "12.09cm",
        width: "14.39cm",
    },
    slideBodyTextRight: {
        position: "absolute",
        top: "5.07cm",
        left: "17.15cm",
        height: "12.09cm",
        width: "14.39cm",
    },
    banner: {
        position: "absolute",
        top: "0.81cm",
        width: "33.87cm",
        height: "4.08cm",
    },
    bannerText: {
        position: "absolute",
        left: "2.33cm",
        top: "1.01cm",
        width: "29.21cm",
        height: "3.68cm",
    },
    footer: {
        position: "absolute",
        fontSize: 8,
    },
    upperRightFooter: {
        height: "0.6cm",
        width: "7.62cm",
        top: "17.46cm",
        left: "23.92cm",
        textAlign: "right",
    },
    lowerRightFooter: {
        height: "0.6cm",
        width: "7.62cm",
        top: "17.86cm",
        left: "23.92cm",
        textAlign: "right",
    },
}); });
