"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRedirect = exports.RedirectRules = exports.Role = void 0;
var auth0_react_1 = require("@auth0/auth0-react");
var react_router_dom_1 = require("react-router-dom");
var Role;
(function (Role) {
    Role["CLIENT"] = "client";
    Role["LAWYER"] = "lawyer";
})(Role = exports.Role || (exports.Role = {}));
var pathMap = (_a = {},
    _a[Role.CLIENT] = "client",
    _a[Role.LAWYER] = "app",
    _a);
exports.RedirectRules = [
    { route: { path: "/app/*" }, only: [Role.LAWYER] },
    { route: { path: "/client/*" }, only: [Role.CLIENT, Role.LAWYER] },
    { route: { path: "/public/*" } },
];
// useRedirect hook
var useRedirect = function () {
    var user = (0, auth0_react_1.useAuth0)().user;
    var pathname = (0, react_router_dom_1.useLocation)().pathname;
    var meta = user === null || user === void 0 ? void 0 : user["https://juristic.io/meta"];
    if (!meta)
        return {};
    var role = meta.dbUser.type;
    var redirectRule = exports.RedirectRules.find(function (rule) { return (0, react_router_dom_1.matchPath)(rule.route, pathname); });
    var hasMissingProps = !role || !redirectRule;
    var shouldRedirect = (redirectRule === null || redirectRule === void 0 ? void 0 : redirectRule.only) && redirectRule.only.indexOf(role) < 0;
    if (hasMissingProps) {
        return {};
    }
    if (shouldRedirect) {
        var replace = pathname.split("/")[1];
        var replaceWith = pathMap[role];
        // Redirect to the same path but with the correct role
        var redirectPath = pathname.replace(replace, replaceWith);
        // If the path does not exist RedirectToRoot will catch it and redirect the client to the root
        return { redirect: redirectPath };
    }
    return {};
};
exports.useRedirect = useRedirect;
