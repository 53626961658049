"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var auth0_react_1 = require("@auth0/auth0-react");
var redux_1 = require("./redux");
var react_1 = require("react");
var uiActions_1 = require("@redux/actions/uiActions");
var i18next_1 = require("i18next");
function useLanguage() {
    var user = (0, auth0_react_1.useAuth0)().user;
    var dispatch = (0, redux_1.useAppDispatch)();
    var meta = user && user["https://juristic.io/meta"];
    var dbUser = meta === null || meta === void 0 ? void 0 : meta.dbUser;
    var language = dbUser === null || dbUser === void 0 ? void 0 : dbUser.language;
    var stateLanguage = (0, redux_1.useAppSelector)(function (state) { return state.ui.language; });
    var _a = (0, react_1.useState)(false), hasRun = _a[0], setHasRun = _a[1];
    (0, react_1.useEffect)(function () {
        if (!hasRun && language) {
            dispatch((0, uiActions_1.setLanguage)(language));
            setHasRun(true);
        }
    }, [language]);
    (0, react_1.useEffect)(function () {
        (0, i18next_1.changeLanguage)(stateLanguage);
    }, [stateLanguage]);
}
exports.default = useLanguage;
