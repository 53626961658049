"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var activityLog_1 = require("@customTypes/reducers/activityLog");
function FilterEvents(events, type) {
    if (type === activityLog_1.ActivityLogTabValue.CaseOrGroup) {
        return events.filter(function (event) {
            return ["lawsuits", "groups"].includes(event.relatedModel.type);
        });
    }
    if (type === activityLog_1.ActivityLogTabValue.Workspace) {
        return events.filter(function (event) {
            return [
                "workspaces",
                "workspace_frames",
                "workspace_group_nodes",
                "workspace_icons",
                "workspace_nodes",
                "workspace_relationships",
                "workspace_stickies",
                "workspace_texts",
            ].includes(event.relatedModel.type);
        });
    }
    if (type === activityLog_1.ActivityLogTabValue.Timeline) {
        return events.filter(function (event) {
            return [
                "timelines",
                "timeline_nodes",
                "timeline_persons",
                "timeline_sticky_notes",
            ].includes(event.relatedModel.type);
        });
    }
    if (type === activityLog_1.ActivityLogTabValue.Element) {
        return events.filter(function (event) {
            return [
                "alerts",
                "attributs",
                "conditions",
                "documents",
                "nodes",
                "outputs",
                "relationships",
            ].includes(event.relatedModel.type);
        });
    }
    return events;
}
exports.default = FilterEvents;
