"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function pentagon(height, width) {
    function toRadians(deg) {
        return (deg / 180) * Math.PI;
    }
    function calculatePoints() {
        var x = width / 2;
        var y = height / width;
        var _36R = toRadians(36);
        var _18R = toRadians(18);
        var sin18D = Math.sin(_18R);
        var cos18D = Math.cos(_18R);
        var xTan36D = Math.tan(_36R) * x;
        var xOverCos36D = x / Math.cos(_36R);
        var points = [
            {
                x: x,
                y: 0,
            },
            {
                x: x * 2,
                y: xTan36D * y,
            },
            {
                x: xOverCos36D * (sin18D + 1),
                y: (xOverCos36D * cos18D + xTan36D) * y,
            },
            {
                x: xOverCos36D * sin18D,
                y: (xOverCos36D * cos18D + xTan36D) * y,
            },
            {
                x: 0,
                y: xTan36D * y,
            },
        ];
        return points;
    }
    var points = calculatePoints();
    function toString() {
        return points.map(function (point) { return "".concat(point.x, ", ").concat(point.y); }).join(" ");
    }
    return toString();
}
exports.default = pentagon;
