"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genericFormatter = exports.currencyFormatter = void 0;
var currencyFormatter = function (x, currency, format) {
    if (!x && x !== 0) {
        return undefined;
    }
    var option = {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
    };
    var formatter = new Intl.NumberFormat(format, option);
    return formatter.format(x);
};
exports.currencyFormatter = currencyFormatter;
var genericFormatter = function (x, format) {
    if (!x && x !== 0) {
        return undefined;
    }
    var formatter = new Intl.NumberFormat(format);
    return formatter.format(x);
};
exports.genericFormatter = genericFormatter;
