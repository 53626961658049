"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateFilter = exports.DateFilterMap = void 0;
var date_fns_1 = require("date-fns");
exports.DateFilterMap = {
    blank: "0",
    today: "1",
    tomorrow: "2",
    yesterday: "3",
    thisWeek: "4",
    lastWeek: "5",
    nextWeek: "6",
    thisMonth: "7",
    lastMonth: "8",
    nextMonth: "9",
    pastDates: "10",
    futureDates: "11",
};
function isNextWeek(date) {
    var nextWeek = (0, date_fns_1.addWeeks)(new Date(), 1);
    return (0, date_fns_1.isSameWeek)(date, nextWeek);
}
function isLastWeek(date) {
    var nextWeek = (0, date_fns_1.subWeeks)(new Date(), 1);
    return (0, date_fns_1.isSameWeek)(date, nextWeek);
}
function isNextMonth(date) {
    var nextMonth = (0, date_fns_1.addMonths)(new Date(), 1);
    return (0, date_fns_1.isSameMonth)(date, nextMonth);
}
function isLastMonth(date) {
    var pastMonth = (0, date_fns_1.subMonths)(new Date(), 1);
    return (0, date_fns_1.isSameMonth)(date, pastMonth);
}
function isBlank(date) {
    return !date;
}
exports.DateFilter = {
    0: isBlank,
    1: date_fns_1.isToday,
    2: date_fns_1.isTomorrow,
    3: date_fns_1.isYesterday,
    4: date_fns_1.isThisWeek,
    5: isLastWeek,
    6: isNextWeek,
    7: date_fns_1.isThisMonth,
    8: isLastMonth,
    9: isNextMonth,
    10: date_fns_1.isPast,
    11: date_fns_1.isFuture,
};
