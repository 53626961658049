"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var conditionValidatorMap = {
    dropdown_meets_condition: utils_1.valuesAreValid,
    empty_column: utils_1.valuesAreValid,
    number_meets_condition: utils_1.valuesAreValid,
    specified_person: utils_1.valuesAreValid,
    specified_status: utils_1.valuesAreValid,
    specified_task_group: utils_1.valuesAreValid,
};
function conditionValidator(conditions) {
    return conditions.every(function (condition) {
        return condition.type !== "new_condition" &&
            conditionValidatorMap[condition.type](condition);
    });
}
exports.default = conditionValidator;
