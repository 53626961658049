"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultSeverityMessage = void 0;
var constants_1 = require("@api/constants");
exports.defaultSeverityMessage = {
    success: "Success!",
    warning: "Warning!",
    error: constants_1.genericErrorMessage,
    info: "Info!",
};
