"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable consistent-return */
/* eslint-disable no-inner-declarations */
var react_1 = require("react");
function useWindowDimensions() {
    var hasWindow = typeof window !== "undefined";
    var getWindowDimensions = function () {
        var width = hasWindow ? window.innerWidth : null;
        var height = hasWindow ? window.innerHeight : null;
        return {
            width: width,
            height: height
        };
    };
    var _a = (0, react_1.useState)(getWindowDimensions()), windowDimensions = _a[0], setWindowDimensions = _a[1];
    (0, react_1.useEffect)(function () {
        if (hasWindow) {
            var handleResize_1 = function () {
                setWindowDimensions(getWindowDimensions());
            };
            window.addEventListener("resize", handleResize_1);
            return function () { return window.removeEventListener("resize", handleResize_1); };
        }
    }, [hasWindow]);
    return windowDimensions;
}
exports.default = useWindowDimensions;
