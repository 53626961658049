"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var reactFlow_1 = require("@customTypes/reactFlow");
var styles_1 = require("@customTypes/styles");
var react_1 = require("react");
var useItemSidePanel = function (handleCursor) {
    var _a = (0, react_1.useState)(true), isMouseActive = _a[0], setMouseActive = _a[1];
    var _b = (0, react_1.useState)(false), isStickyActive = _b[0], setStickyActive = _b[1];
    var _c = (0, react_1.useState)(false), isTextActive = _c[0], setTextActive = _c[1];
    var _d = (0, react_1.useState)(false), isNodeActive = _d[0], setNodeActive = _d[1];
    var _e = (0, react_1.useState)(false), isGroupNodeActive = _e[0], setGroupNodeActive = _e[1];
    var _f = (0, react_1.useState)(undefined), isPowerpointHelperNodeActive = _f[0], setPowerpointHelperNodeActive = _f[1];
    var _g = (0, react_1.useState)(reactFlow_1.Figur.ROUNDRECTANGLE), shapeActive = _g[0], setShapeActive = _g[1];
    var toggleMouse = (0, react_1.useCallback)(function () {
        setNodeActive(false);
        setStickyActive(false);
        setTextActive(false);
        setGroupNodeActive(false);
        setPowerpointHelperNodeActive(undefined);
        handleCursor(styles_1.CursorType.Auto);
        setMouseActive(true);
    }, []);
    var toggleSticky = (0, react_1.useCallback)(function () {
        setStickyActive(function (prevVal) {
            if (!prevVal) {
                setMouseActive(false);
                setNodeActive(false);
                setGroupNodeActive(false);
                setTextActive(false);
                setPowerpointHelperNodeActive(undefined);
                handleCursor(styles_1.CursorType.Crosshair);
            }
            if (prevVal) {
                setMouseActive(true);
                handleCursor(styles_1.CursorType.Auto);
            }
            return !prevVal;
        });
    }, []);
    var toggleGroupNode = (0, react_1.useCallback)(function () {
        setGroupNodeActive(function (prevVal) {
            if (!prevVal) {
                setMouseActive(false);
                setNodeActive(false);
                setTextActive(false);
                setStickyActive(false);
                setPowerpointHelperNodeActive(undefined);
                handleCursor(styles_1.CursorType.Crosshair);
            }
            if (prevVal) {
                setMouseActive(true);
                handleCursor(styles_1.CursorType.Auto);
            }
            return !prevVal;
        });
    }, []);
    var toggleText = (0, react_1.useCallback)(function () {
        setTextActive(function (prevVal) {
            if (!prevVal) {
                setMouseActive(false);
                setNodeActive(false);
                setStickyActive(false);
                setGroupNodeActive(false);
                setPowerpointHelperNodeActive(undefined);
                handleCursor(styles_1.CursorType.Crosshair);
            }
            if (prevVal) {
                setMouseActive(true);
                handleCursor(styles_1.CursorType.Auto);
            }
            return !prevVal;
        });
    }, []);
    var toggleNode = (0, react_1.useCallback)(function () {
        setNodeActive(function (prevVal) {
            if (!prevVal) {
                setMouseActive(false);
                setStickyActive(false);
                setTextActive(false);
                setGroupNodeActive(false);
                setPowerpointHelperNodeActive(undefined);
                handleCursor(styles_1.CursorType.Crosshair);
            }
            if (prevVal) {
                setMouseActive(true);
                handleCursor(styles_1.CursorType.Auto);
            }
            return !prevVal;
        });
    }, []);
    var togglePowerpointHelperNode = (0, react_1.useCallback)(function (size) {
        setPowerpointHelperNodeActive(function (prevVal) {
            if (!prevVal) {
                setNodeActive(false);
                setMouseActive(false);
                setStickyActive(false);
                setTextActive(false);
                setGroupNodeActive(false);
                handleCursor(styles_1.CursorType.Crosshair);
            }
            if (prevVal) {
                setMouseActive(true);
                handleCursor(styles_1.CursorType.Auto);
            }
            return size;
        });
    }, []);
    return {
        mouseActive: isMouseActive,
        stickyActive: isStickyActive,
        toggleMouse: toggleMouse,
        toggleSticky: toggleSticky,
        nodeActive: isNodeActive,
        toggleNode: toggleNode,
        shapeActive: shapeActive,
        setShapeActive: setShapeActive,
        toggleGroupNode: toggleGroupNode,
        groupNodeActive: isGroupNodeActive,
        powerpointHelperNodeActive: isPowerpointHelperNodeActive,
        togglePowerpointHelperNode: togglePowerpointHelperNode,
        textActive: isTextActive,
        toggleText: toggleText,
    };
};
exports.default = useItemSidePanel;
