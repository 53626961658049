"use strict";
/* eslint-disable import/prefer-default-export */
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFavicon = void 0;
var getFavicon = function (uri) {
    var url = new URL(uri);
    var favicon = "".concat(url.protocol, "//").concat(url.host, "/favicon.ico");
    return favicon;
};
exports.getFavicon = getFavicon;
