"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var attributeConstants_1 = require("./attributeConstants");
var folderConstants_1 = require("@redux/constants/folderConstants");
var attribute_1 = require("@customTypes/reducers/attribute");
var constants_1 = require("@api/constants");
var initialState = {
    loading: false,
    attributes: [],
    id: null,
    label: "",
    description: "",
    type: attribute_1.AttributType.VALUE,
    selectionOptions: [],
    groupOrLawsuit: "",
    attributesDescendants: [],
    deletingFolder: 0,
    loadings: constants_1.initialLoadings,
};
function reducer(state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case attributeConstants_1.ATTRIBUTE_DUPLICATE_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case attributeConstants_1.GET_ATTRIBUTES_LOADING:
            return __assign(__assign({}, state), { loading: true });
        case attributeConstants_1.GET_ATTRIBUTES_SUCCESS:
            return __assign(__assign({}, state), { attributes: action.attributes, loading: false });
        case attributeConstants_1.GET_ATTRIBUTES_FAILED:
        case attributeConstants_1.POST_ATTRIBUTE_FAILED:
        case attributeConstants_1.SHOW_ATTRIBUTE_FAILED:
        case attributeConstants_1.PUT_ATTRIBUTE_SUCCESS:
        case attributeConstants_1.PUT_ATTRIBUTE_FAILED:
        case attributeConstants_1.DELETE_ATTRIBUTE_FAILED:
        case attributeConstants_1.FORCE_DELETE_ATTRIBUTE_FAILED:
        case attributeConstants_1.GET_ATTRIBUTE_DESCENDANTS_FAILED:
        case attributeConstants_1.ATTRIBUTE_DUPLICATE_FAILED:
            return __assign(__assign({}, state), { loading: false, loadings: constants_1.initialLoadings });
        case attributeConstants_1.POST_ATTRIBUTE_SUCCESS:
            return __assign(__assign({}, state), { id: action.id, label: "", description: "", type: attribute_1.AttributType.VALUE });
        case attributeConstants_1.SHOW_ATTRIBUTE_SUCCESS:
            return __assign(__assign({}, state), { id: action.id, label: action.label, description: action.description, type: action.valueType, selectionOptions: action.selectionOptions ? action.selectionOptions : [], groupOrLawsuit: action.groupOrLawsuit });
        case attributeConstants_1.CURRENT_ATTRIBUTE:
            return __assign(__assign({}, state), { id: action.id });
        case attributeConstants_1.LABEL_CHANGE:
            return __assign(__assign({}, state), { label: action.label });
        case attributeConstants_1.DESCRIPTION_CHANGE:
            return __assign(__assign({}, state), { description: action.description });
        case attributeConstants_1.SELECTION_VALUES:
            return __assign(__assign({}, state), { selectionOptions: action.selectionOptions || [] });
        case attributeConstants_1.ADD_TYPE:
            return __assign(__assign({}, state), { type: action.value });
        case attributeConstants_1.CLEAR_ATTRIBUTE_STATE:
            return __assign(__assign({}, initialState), { attributes: state.attributes });
        case attributeConstants_1.DELETE_ATTRIBUTE_SUCCESS:
            return __assign(__assign({}, state), { attributesDescendants: action.attributesDescendants });
        case attributeConstants_1.CLEAR_ATTRIBUTES_DESCENDANTS:
            return __assign(__assign({}, state), { attributesDescendants: initialState.attributesDescendants });
        case attributeConstants_1.FORCE_DELETE_ATTRIBUTE_SUCCESS:
            return __assign(__assign({}, state), { attributes: state.attributes.filter(function (attribute) { return attribute.id !== action.attributeId; }) });
        case attributeConstants_1.GET_ATTRIBUTE_DESCENDANTS_SUCCESS:
            return __assign(__assign({}, state), { attributesDescendants: action.attributesDescendants });
        case attributeConstants_1.ATTRIBUTE_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { attributes: action.attribut
                    ? __spreadArray(__spreadArray([], state.attributes, true), [action.attribut], false) : state.attributes, loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.moveElementToDestroyedParentFailed)("attributs"):
        case (0, folderConstants_1.postFolderFailed)("attributs"):
        case (0, folderConstants_1.putFolderTitleFailed)("attributs"):
        case (0, folderConstants_1.putFolderLocationFailed)("attributs"):
        case (0, folderConstants_1.putFolderElementLocationFailed)("attributs"):
        case (0, folderConstants_1.duplicateFolderFailed)("attributs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.deleteFolderFailed)("attributs"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.deleteFolderLoading)("attributs"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadings] = true, _b)) });
        case (0, folderConstants_1.moveElementToDestroyedParentLoading)("attributs"):
        case (0, folderConstants_1.putFolderLocationLoading)("attributs"):
        case (0, folderConstants_1.putFolderElementLocationLoading)("attributs"):
        case (0, folderConstants_1.putFolderTitleLoading)("attributs"):
        case (0, folderConstants_1.postFolderLoading)("attributs"):
        case (0, folderConstants_1.duplicateFolderLoading)("attributs"):
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_c = {}, _c[action.loadings] = true, _c)) });
        case (0, folderConstants_1.postFolderSuccess)("attributs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, attributes: __spreadArray([action.folder], state.attributes, true) });
        case (0, folderConstants_1.putFolderTitleSuccess)("attributs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, attributes: state.attributes.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderLocationSuccess)("attributs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, attributes: state.attributes.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderElementLocationSuccess)("attributs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, attributes: state.attributes.map(function (c) {
                    if (!c.isFolder && c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.deleteFolderSuccess)("attributs"): {
            if (action.descendants && action.descendants.length > 0) {
                return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, attributesDescendants: action.descendants, deletingFolder: action.folder.id });
            }
            if (action.deleteChildren) {
                return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, deletingFolder: 0, attributes: state.attributes
                        .filter(function (c) { return c.id !== action.folder.id; })
                        .filter(function (c) { return c.folderId !== action.folder.id; }) });
            }
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, deletingFolder: 0, attributes: state.attributes
                    .filter(function (c) { return c.id !== action.folder.id; })
                    .map(function (c) {
                    return c.folderId === action.folder.id
                        ? __assign(__assign({}, c), { folderId: action.folder.folderId }) : c;
                }) });
        }
        case (0, folderConstants_1.moveElementToDestroyedParentSuccess)("attributs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, attributes: state.attributes.map(function (c) {
                    if (c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.duplicateFolderSuccess)("attributs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, attributes: __spreadArray(__spreadArray([], action.folder, true), state.attributes, true) });
        default:
            return state;
    }
}
exports.default = reducer;
