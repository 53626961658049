"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function sequentialNames(t, array) {
    if (!array) {
        return array;
    }
    if (array.length === 1) {
        return array[0];
    }
    var beforeLast = array.slice(0, -1);
    var last = array.slice(-1);
    return "".concat(beforeLast.join(", "), " ").concat(t("generic.and"), " ").concat(last);
}
exports.default = sequentialNames;
