"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
function useDetachNodes() {
    var setNodes = (0, reactflow_1.useReactFlow)().setNodes;
    var store = (0, reactflow_1.useStoreApi)();
    var dispatch = (0, redux_1.useAppDispatch)();
    var detachNodes = (0, react_1.useCallback)(function (ids, removeParentId) {
        var nodeInternals = store.getState().nodeInternals;
        var elements = [];
        var nextNodes = Array.from(nodeInternals.values()).map(function (n) {
            var _a, _b, _c, _d;
            if (ids.includes(n.id) && n.parentNode) {
                var parentNode = nodeInternals.get(n.parentNode);
                var position = {
                    x: n.position.x + ((_b = (_a = parentNode === null || parentNode === void 0 ? void 0 : parentNode.positionAbsolute) === null || _a === void 0 ? void 0 : _a.x) !== null && _b !== void 0 ? _b : 0),
                    y: n.position.y + ((_d = (_c = parentNode === null || parentNode === void 0 ? void 0 : parentNode.positionAbsolute) === null || _c === void 0 ? void 0 : _c.y) !== null && _d !== void 0 ? _d : 0),
                };
                elements.push({
                    id: n.data.id,
                    type: n.type,
                    position: position,
                });
                return __assign(__assign({}, n), { position: position, expandParent: undefined, parentNode: undefined });
            }
            return n;
        });
        setNodes(nextNodes.filter(function (n) { return !removeParentId || n.id !== removeParentId; }));
        var parentId = removeParentId ? Number(removeParentId.split("-")[1]) : undefined;
        dispatch((0, workspaceActions_websocket_1.handleDetachNodes)({ elements: elements, parentId: parentId }));
    }, [setNodes, store]);
    return detachNodes;
}
exports.default = useDetachNodes;
