"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateRangeFilter = exports.DateRangeFilterMap = void 0;
var date_fns_1 = require("date-fns");
exports.DateRangeFilterMap = {
    blank: "0",
    current: "1",
    dueToday: "2",
    future: "3",
    past: "4",
};
function isBlank(date) {
    return !date;
}
function isCurrent(startDate, endDate) {
    var currentDate = new Date();
    return (0, date_fns_1.isAfter)(currentDate, startDate) && (0, date_fns_1.isBefore)(currentDate, endDate);
}
function isDueToday(_, endDate) {
    return (0, date_fns_1.isToday)(endDate);
}
function isFutureInterval(startDate, endDate) {
    return (0, date_fns_1.isFuture)(startDate) && (0, date_fns_1.isFuture)(endDate);
}
function isPastInterval(startDate, endDate) {
    return (0, date_fns_1.isPast)(startDate) && (0, date_fns_1.isPast)(endDate);
}
exports.DateRangeFilter = {
    0: isBlank,
    1: isCurrent,
    2: isDueToday,
    3: isFutureInterval,
    4: isPastInterval,
};
