"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme, _, classes) {
    var _a;
    return ({
        popoverRoot: {
            backgroundColor: theme.palette.background.paper,
        },
        content: {
            padding: theme.spacing(2),
        },
        // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js and https://github.com/mui-org/material-ui/blob/4f2a07e140c954b478a6670c009c23a59ec3e2d4/docs/src/pages/components/popper/ScrollPlayground.js
        popper: (_a = {
                zIndex: 2000
            },
            _a["&[x-placement*='bottom'] .".concat(classes.arrow)] = {
                top: 0,
                left: 0,
                marginTop: "-0.71em",
                marginLeft: 4,
                marginRight: 4,
                "&::before": {
                    transformOrigin: "0 100%",
                },
            },
            _a["&[x-placement*='top'] .".concat(classes.arrow)] = {
                bottom: 0,
                left: 0,
                marginBottom: "-0.71em",
                marginLeft: 4,
                marginRight: 4,
                "&::before": {
                    transformOrigin: "100% 0",
                },
            },
            _a["&[x-placement*='right'] .".concat(classes.arrow)] = {
                left: 0,
                marginLeft: "-0.71em",
                height: "1em",
                width: "0.71em",
                marginTop: 4,
                marginBottom: 4,
                "&::before": {
                    transformOrigin: "100% 100%",
                },
            },
            _a["&[x-placement*='left'] .".concat(classes.arrow)] = {
                right: 0,
                marginRight: "-0.71em",
                height: "1em",
                width: "0.71em",
                marginTop: 4,
                marginBottom: 4,
                "&::before": {
                    transformOrigin: "0 0",
                },
            },
            _a),
        // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
        arrow: {
            overflow: "hidden",
            position: "absolute",
            width: "1em",
            height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
            boxSizing: "border-box",
            color: theme.palette.background.paper,
            "&::before": {
                content: "''",
                margin: "auto",
                display: "block",
                width: "100%",
                height: "100%",
                boxShadow: theme.shadows[1],
                backgroundColor: "currentColor",
                transform: "rotate(45deg)",
            },
        },
    });
});
exports.default = useStyles;
