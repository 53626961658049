"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var react_1 = require("react");
function useCommentLogNotifications(_a) {
    var open = _a.open, user = _a.user;
    var comments = (0, redux_1.useAppSelector)(function (state) { return state.commentLog.comments; });
    var _b = (0, react_1.useState)(0), mentionCount = _b[0], setMentionCount = _b[1];
    (0, react_1.useEffect)(function () {
        if (open) {
            setMentionCount(0);
        }
        else {
            var mentions = comments.filter(function (c) { return c.mentions.some(function (m) { return m.id === (user === null || user === void 0 ? void 0 : user.id); }); });
            setMentionCount(mentions.length);
        }
    }, [open, comments, user]);
    return {
        commentCount: comments.length,
        mentionCount: mentionCount,
    };
}
exports.default = useCommentLogNotifications;
