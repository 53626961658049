"use strict";
/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/display-name */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var material_1 = require("@mui/material");
var timelineActions_http_1 = require("@pages/Timelines/reducers/timelineActions.http");
var redux_1 = require("@hooks/redux");
var auth0_react_1 = require("@auth0/auth0-react");
var react_router_dom_1 = require("react-router-dom");
var processTimelineData = function (content) {
    return content
        .map(function (line) {
        try {
            // Split line by pipe and remove first/last empty elements
            var elements = line.split("|").slice(1, -1);
            // Validate required number of elements
            if (elements.length !== 4) {
                console.warn("Invalid line format: ".concat(line));
                return undefined;
            }
            var _a = elements.map(function (elem) { return (elem === null || elem === void 0 ? void 0 : elem.trim()) || ""; }), date = _a[0], title = _a[1], description = _a[2], personsRaw = _a[3];
            // Process persons array
            var persons = personsRaw
                ? personsRaw
                    .split(",")
                    .map(function (person) { return person.trim(); })
                    .filter(function (person) { return person.length > 0; })
                : [];
            // Validate required fields
            if (!date || !title || !description) {
                console.warn("Missing required field in line: ".concat(line));
                return undefined;
            }
            return {
                date: date,
                title: title,
                description: description,
                persons: persons,
            };
        }
        catch (error) {
            console.error("Error processing line: ".concat(line), error);
            return undefined;
        }
    })
        .filter(function (node) { return node !== undefined; });
};
var CreateTimeline = function (prop) {
    var content = prop.content, promptTitle = prop.promptTitle, promptId = prop.promptId;
    var dispatch = (0, redux_1.useAppDispatch)();
    var user = (0, auth0_react_1.useAuth0)().user;
    var timelines = (0, redux_1.useAppSelector)(function (state) { return state.prompt.connections; }).timelines;
    var documents = (0, redux_1.useAppSelector)(function (state) { return state.prompt.connections; }).documents;
    var sessionLink = (0, react_router_dom_1.useLocation)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_1.useState)(false), isLoading = _a[0], SetIsLoading = _a[1];
    var onClose = function () {
        SetIsLoading(false);
    };
    var parseTable = function (table) {
        var lines = table.split("\n");
        if (!lines) {
            return [];
        }
        var content = lines.slice(2, -1);
        return processTimelineData(content);
    };
    var handleClick = function () {
        SetIsLoading(true);
        var nodes = parseTable(content);
        if (timelines.length === 1) {
            dispatch((0, timelineActions_http_1.addEventToTimeline)(user, {
                promptId: promptId,
                documentId: documents.length === 1 ? documents[0].id : null,
                timelineId: timelines[0].id,
                aiNodes: nodes,
            }, sessionLink.pathname.includes("juristiq") ? { handle: navigate } : undefined, onClose));
        }
        else {
            dispatch((0, timelineActions_http_1.postTimelinePopulate)(user, {
                promptId: promptId,
                title: promptTitle,
                documentId: documents.length === 1 ? documents[0].id : null,
                description: "",
                tags: [],
                caseId: null,
                siteId: null,
                folderId: null,
                aiNodes: nodes,
            }, undefined, undefined, { frontendType: "cases", handle: navigate }, onClose));
        }
    };
    return (react_1.default.createElement("div", null, isLoading ? (react_1.default.createElement(material_1.CircularProgress, { size: 20 })) : (react_1.default.createElement(material_1.Button, { onClick: function () { return handleClick(); }, size: "small", variant: "contained" }, timelines.length === 1 ? "Add events to timeline" : "Create timeline"))));
};
exports.default = CreateTimeline;
