"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialLookupLoadings = void 0;
var lookupConstants_1 = require("./lookupConstants");
var constants_1 = require("@api/constants");
var initialCompany = {
    CompanyName: "",
    Direktion: "",
    FinancialDataCurrencyCode: "DKK",
    financials: {},
    ISOCountryCode: "DK",
    Land: "",
    beneficialOwners: [],
    Bestyrelse: "",
    LFY: "",
    Links: [],
    Medarbejdere: 0,
    OrgNo: "",
    owners: [],
    timeline: [],
};
var initialAddress = {
    specificAddress: undefined,
    map: undefined,
};
exports.initialLookupLoadings = __assign(__assign({}, constants_1.initialLoadings), { map: false, properties: false, structure: false, sammenBeskatning: false });
var initialState = {
    lookups: {
        companies: [],
        properties: [],
    },
    loadings: exports.initialLookupLoadings,
    company: initialCompany,
    address: initialAddress,
    structure: { edges: [], nodes: [], expandedCompanies: [] },
    sammenBeskatning: null,
};
function reducer(state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case lookupConstants_1.GET_COMPANIES_SUCCESS:
            return __assign(__assign({}, state), { lookups: action.lookups, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case lookupConstants_1.GET_COMPANY_SUCCESS:
            return __assign(__assign({}, state), { company: action.company, lookups: initialState.lookups, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case lookupConstants_1.GET_ADDRESS_SUCCESS:
            return __assign(__assign({}, state), { address: __assign(__assign({}, state.address), { specificAddress: action.address }), lookups: initialState.lookups, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case lookupConstants_1.GET_MAP_GEOMETRY_SUCCESS:
            return __assign(__assign({}, state), { address: __assign(__assign({}, state.address), { map: action.map }), loadings: __assign(__assign({}, state.loadings), { map: false }) });
        case lookupConstants_1.GET_ADDRESS_FROM_MAP_SUCCESS:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case lookupConstants_1.GET_STRUCTURE_SUCCESS:
            return __assign(__assign({}, state), { structure: __assign(__assign({}, state.structure), action.payload), loadings: __assign(__assign({}, state.loadings), { structure: false }) });
        case lookupConstants_1.GET_SAMMENBESKATNING_SUCCESS: {
            return __assign(__assign({}, state), { sammenBeskatning: action.sammenBeskatning, loadings: __assign(__assign({}, state.loadings), { sammenBeskatning: false }) });
        }
        case lookupConstants_1.EXPAND_STRUCTURE_SUCCESS: {
            var newNodes = action.payload.nodes.reduce(function (acc, node) {
                return state.structure.nodes.find(function (n) { return n.id === node.id; }) ? acc : __spreadArray(__spreadArray([], acc, true), [node], false);
            }, []);
            var newEdges = action.payload.edges.reduce(function (acc, edge) {
                return state.structure.edges.find(function (e) { return e.source === edge.source && e.target === edge.target; })
                    ? acc
                    : __spreadArray(__spreadArray([], acc, true), [edge], false);
            }, []);
            return __assign(__assign({}, state), { structure: {
                    expandedCompanies: Array.from(new Set(__spreadArray(__spreadArray([], state.structure.expandedCompanies, true), [action.expanded], false))),
                    nodes: __spreadArray(__spreadArray([], state.structure.nodes, true), newNodes, true),
                    edges: __spreadArray(__spreadArray([], state.structure.edges, true), newEdges, true),
                }, loadings: __assign(__assign({}, state.loadings), { structure: false }) });
        }
        case lookupConstants_1.GET_COMPANIES_FAILED:
        case lookupConstants_1.GET_COMPANY_FAILED:
        case lookupConstants_1.GET_ADDRESS_FAILED:
        case lookupConstants_1.GET_MAP_GEOMETRY_FAILED:
        case lookupConstants_1.GET_ADDRESS_FROM_MAP_FAILED:
        case lookupConstants_1.GET_SAMMENBESKATNING_FAILED:
        case lookupConstants_1.GET_STRUCTURE_FAILED:
        case lookupConstants_1.EXPAND_STRUCTURE_FAILED:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = false, _a)) });
        case lookupConstants_1.GET_COMPANIES_LOADING:
        case lookupConstants_1.GET_COMPANY_LOADING:
        case lookupConstants_1.GET_ADDRESS_LOADING:
        case lookupConstants_1.GET_ADDRESS_FROM_MAP_LOADING:
        case lookupConstants_1.GET_MAP_GEOMETRY_LOADING:
        case lookupConstants_1.GET_SAMMENBESKATNING_LOADING:
        case lookupConstants_1.GET_STRUCTURE_LOADING:
        case lookupConstants_1.EXPAND_STRUCTURE_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadingType] = true, _b)) });
        case lookupConstants_1.CLEAR_STRUCTURE:
            return __assign(__assign({}, state), { structure: initialState.structure });
        default:
            return state;
    }
}
exports.default = reducer;
