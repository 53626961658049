"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    tagCount: {
        color: theme.palette.text.primary,
    },
    tagContainer: {
        width: "100%",
        height: "100%",
        maxHeight: "948px",
        paddingTop: 0,
    },
    text: {
        marginRight: 25,
    },
    listItem: {
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        boxShadow: theme.shade.light,
        marginTop: 10,
    },
    divider: {
        marginTop: 10,
        marginBottom: 10,
    },
    countContainer: {
        padding: 2,
        paddingRight: 8,
        paddingLeft: 8,
        backgroundColor: theme.palette.background.default,
    },
    activelistItem: {
        backgroundColor: theme.palette.secondary.main,
    },
    field: {
        width: "100%",
        marginTop: 0,
        marginBottom: 0,
        "& svg": {
            color: theme.palette.grey[400],
            fontSize: 18,
        },
    },
    fieldContainer: {
        display: "flex",
        flexDirection: "row",
        marginBottom: 10,
    },
    iconButton: {},
    listItemAvatar: {
        minWidth: 0,
        marginRight: 20,
    },
    deleteIcon: {
        color: theme.palette.error.dark,
    },
    editIcon: {
        color: theme.palette.primary.main,
    },
}); });
exports.default = useStyles;
