const data = {
  "v": "5.5.1",
  "fr": 60,
  "ip": 0,
  "op": 173,
  "w": 81,
  "h": 81,
  "nm": "icn-failed",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 3,
      "nm": "x-parent",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 0,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 80,
              "s": [
                40.5,
                40.5,
                0
              ],
              "to": null,
              "ti": null
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 105,
              "s": [
                38.75,
                40.5,
                0
              ],
              "to": null,
              "ti": null
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 115,
              "s": [
                42.25,
                40.5,
                0
              ],
              "to": null,
              "ti": null
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 125,
              "s": [
                38.75,
                40.5,
                0
              ],
              "to": null,
              "ti": null
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 135,
              "s": [
                42.25,
                40.5,
                0
              ],
              "to": null,
              "ti": null
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.167,
                "y": 0
              },
              "t": 145,
              "s": [
                39.5,
                40.5,
                0
              ],
              "to": null,
              "ti": null
            },
            {
              "i": {
                "x": 0.833,
                "y": 1
              },
              "o": {
                "x": 0.167,
                "y": 0
              },
              "t": 155,
              "s": [
                41.5,
                40.5,
                0
              ],
              "to": null,
              "ti": null
            },
            {
              "t": 162.5,
              "s": [
                40.5,
                40.5,
                0
              ]
            }
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 1800,
      "st": 0,
      "bm": 0,
      "completed": true
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "dash-3",
      "parent": 1,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            9.146,
            0,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            11.358,
            11.258,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      10.357,
                      -10.258
                    ],
                    [
                      -10.357,
                      10.258
                    ]
                  ],
                  "o": [
                    [
                      10.357,
                      -10.258
                    ],
                    [
                      -10.357,
                      10.258
                    ]
                  ],
                  "v": [
                    [
                      10.357,
                      -10.258
                    ],
                    [
                      -10.357,
                      10.258
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false,
              "_render": true
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  0.5569,
                  0.2275,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false,
              "_render": true
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  11.358,
                  11.258
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform",
              "_render": true
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false,
          "_render": true
        },
        {
          "ty": "tm",
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.302
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 105,
                "s": [
                  100
                ]
              },
              {
                "t": 130,
                "s": [
                  0
                ]
              }
            ],
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 105,
                "s": [
                  100
                ]
              },
              {
                "t": 130,
                "s": [
                  0
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false,
          "_render": true
        }
      ],
      "ip": 0,
      "op": 1800,
      "st": 0,
      "bm": 0,
      "completed": true
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "dash-1",
      "parent": 1,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            -2.517,
            7.282,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            13.774,
            13.874,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      12.775,
                      12.874
                    ],
                    [
                      -12.775,
                      -12.874
                    ]
                  ],
                  "o": [
                    [
                      12.775,
                      12.874
                    ],
                    [
                      -12.775,
                      -12.874
                    ]
                  ],
                  "v": [
                    [
                      12.775,
                      12.874
                    ],
                    [
                      -12.775,
                      -12.874
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false,
              "_render": true
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  1,
                  0.5569,
                  0.2275,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false,
              "_render": true
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  13.774,
                  13.874
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform",
              "_render": true
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false,
          "_render": true
        },
        {
          "ty": "tm",
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.302
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 80,
                "s": [
                  100
                ]
              },
              {
                "t": 105,
                "s": [
                  0
                ]
              }
            ],
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 80,
                "s": [
                  100
                ]
              },
              {
                "t": 105,
                "s": [
                  0
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false,
          "_render": true
        }
      ],
      "ip": 0,
      "op": 1800,
      "st": 0,
      "bm": 0,
      "completed": true
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "cross-1",
      "parent": 1,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            13.5,
            13.5,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      12,
                      12
                    ],
                    [
                      -12,
                      -12
                    ]
                  ],
                  "o": [
                    [
                      12,
                      12
                    ],
                    [
                      -12,
                      -12
                    ]
                  ],
                  "v": [
                    [
                      12,
                      12
                    ],
                    [
                      -12,
                      -12
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false,
              "_render": true
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.851,
                  0.149,
                  0,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 8,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false,
              "_render": true
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  13.5,
                  13.5
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform",
              "_render": true
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false,
          "_render": true
        },
        {
          "ty": "tm",
          "s": {
            "a": 0,
            "k": 100,
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.341
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.371
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 72.5,
                "s": [
                  100
                ]
              },
              {
                "t": 132.5,
                "s": [
                  0
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false,
          "_render": true
        }
      ],
      "ip": 0,
      "op": 1800,
      "st": 0,
      "bm": 0,
      "completed": true
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "cross-2",
      "parent": 1,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            13.5,
            13.5,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      12,
                      -12
                    ],
                    [
                      -12,
                      12
                    ]
                  ],
                  "o": [
                    [
                      12,
                      -12
                    ],
                    [
                      -12,
                      12
                    ]
                  ],
                  "v": [
                    [
                      12,
                      -12
                    ],
                    [
                      -12,
                      12
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false,
              "_render": true
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.851,
                  0.149,
                  0,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 8,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false,
              "_render": true
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  13.5,
                  13.5
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform",
              "_render": true
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false,
          "_render": true
        },
        {
          "ty": "tm",
          "s": {
            "a": 0,
            "k": 100,
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.341
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.371
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 87.5,
                "s": [
                  100
                ]
              },
              {
                "t": 147.5,
                "s": [
                  0
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false,
          "_render": true
        }
      ],
      "ip": 0,
      "op": 1800,
      "st": 0,
      "bm": 0,
      "completed": true
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "circle-red",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 47.5,
              "s": [
                0
              ]
            },
            {
              "t": 80,
              "s": [
                100
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "t": 120,
              "s": [
                360
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            40.5,
            40.5,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            37.5,
            37.5,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      -19.054,
                      -34.5
                    ],
                    [
                      34.5,
                      -19.054
                    ],
                    [
                      19.054,
                      34.5
                    ],
                    [
                      -34.5,
                      19.054
                    ]
                  ],
                  "o": [
                    [
                      19.054,
                      -34.5
                    ],
                    [
                      34.5,
                      19.054
                    ],
                    [
                      -19.054,
                      34.5
                    ],
                    [
                      -34.5,
                      -19.054
                    ]
                  ],
                  "v": [
                    [
                      0,
                      -34.5
                    ],
                    [
                      34.5,
                      0
                    ],
                    [
                      0,
                      34.5
                    ],
                    [
                      -34.5,
                      0
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false,
              "_render": true
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.851,
                  0.149,
                  0,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 8,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false,
              "_render": true
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  37.5,
                  37.5
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform",
              "_render": true
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false,
          "_render": true
        },
        {
          "ty": "tm",
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.123
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 0,
                "s": [
                  0
                ]
              },
              {
                "t": 120,
                "s": [
                  100
                ]
              }
            ],
            "ix": 1
          },
          "e": {
            "a": 0,
            "k": 0,
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false,
          "_render": true
        }
      ],
      "ip": 0,
      "op": 1800,
      "st": 0,
      "bm": 0,
      "completed": true
    }
  ],
  "markers": [],
  "__complete": true
}
module.exports = data;