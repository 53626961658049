"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDirection = void 0;
// returns an array of two numbers (0, 1 or -1) representing the direction of the resize
// 0 = no change, 1 = increase, -1 = decrease
// eslint-disable-next-line import/prefer-default-export
function getDirection(_a) {
    var width = _a.width, prevWidth = _a.prevWidth, height = _a.height, prevHeight = _a.prevHeight, invertX = _a.invertX, invertY = _a.invertY;
    var deltaWidth = width - prevWidth;
    var deltaHeight = height - prevHeight;
    var direction = [
        deltaWidth > 0 ? 1 : deltaWidth < 0 ? -1 : 0,
        deltaHeight > 0 ? 1 : deltaHeight < 0 ? -1 : 0,
    ];
    if (deltaWidth && invertX) {
        direction[0] *= -1;
    }
    if (deltaHeight && invertY) {
        direction[1] *= -1;
    }
    return direction;
}
exports.getDirection = getDirection;
