"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function () { return ({
    noToolsPaper: {
        padding: 20,
        paddingRight: 24,
        paddingLeft: 24,
    },
}); });
exports.default = useStyles;
