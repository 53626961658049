"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var redux_1 = require("@hooks/redux");
var userConfigActions_1 = require("@redux/actions/userConfigActions");
var react_1 = require("react");
function useCustomizeMyWork(_a) {
    var isOpen = _a.isOpen, user = _a.user;
    var dispatch = (0, redux_1.useAppDispatch)();
    var usersDropDownOptions = (0, redux_1.useAppSelector)(function (state) { return state.users.coworkers; });
    var myWorkConfig = (0, redux_1.useAppSelector)(function (state) { return state.userConfig.myWork; });
    var _b = (0, react_1.useState)("people"), expanded = _b[0], setExpanded = _b[1];
    var _c = (0, react_1.useState)(myWorkConfig.people || []), people = _c[0], setPeople = _c[1];
    var didPeopleChange = people !== (myWorkConfig.people || []);
    var onChangePeople = (0, react_1.useCallback)(function (ps) {
        setPeople(ps);
    }, []);
    var _d = (0, react_1.useState)(myWorkConfig.boards || []), boards = _d[0], setBoards = _d[1];
    var _e = (0, react_1.useState)([]), changedBoards = _e[0], setChangedBoards = _e[1];
    var onToggleBoardVisibility = (0, react_1.useCallback)(function (_id) {
        setChangedBoards(function (prev) { return __spreadArray(__spreadArray([], prev, true), [_id], false); });
        setBoards(function (prev) {
            var updated = prev.map(function (b) {
                if (b.board.value === _id) {
                    return __assign(__assign({}, b), { board: __assign(__assign({}, b.board), { isHidden: !b.board.isHidden }) });
                }
                return b;
            });
            return updated;
        });
    }, []);
    var onChangeColumn = (0, react_1.useCallback)(function (_id, _column, key) {
        setChangedBoards(function (prev) { return __spreadArray(__spreadArray([], prev, true), [_id], false); });
        setBoards(function (prev) {
            var updated = prev.map(function (b) {
                var _a;
                if (b.board.value === _id) {
                    return __assign(__assign({}, b), (_a = {}, _a[key] = _column, _a));
                }
                return b;
            });
            return updated;
        });
    }, []);
    var _f = (0, react_1.useState)(""), boardSearch = _f[0], setBoardSearch = _f[1];
    var boardSearchResults = boards.filter(function (b) {
        return b.board.label.toLowerCase().includes(boardSearch.toLowerCase()) &&
            !b.board.isHidden;
    });
    var onSearchBoard = (0, react_1.useCallback)(function (e) {
        setBoardSearch(e.target.value);
    }, []);
    var reset = (0, react_1.useCallback)(function () {
        setPeople(myWorkConfig.people || []);
        setBoards(myWorkConfig.boards || []);
        setBoardSearch("");
        setChangedBoards([]);
    }, [myWorkConfig]);
    var onSwitchAccordion = (0, react_1.useCallback)(function (panel) { return function (event, isExpanded) {
        reset();
        var toOpen = isExpanded ? panel : null;
        setExpanded(toOpen);
    }; }, [myWorkConfig]);
    var onUpdateMyWorkPeople = (0, react_1.useCallback)(function () {
        var peopleIds = people.map(function (p) { return Number(p.userId); });
        dispatch((0, userConfigActions_1.updateMyWorkPeople)(user, { peopleIds: peopleIds }));
    }, [people]);
    var onUpdateVisibleBoards = (0, react_1.useCallback)(function () {
        var boardOptions = boards
            .flatMap(function (b) { return b.board; })
            .filter(function (board) { return changedBoards.includes(board.value); });
        dispatch((0, userConfigActions_1.updateBoardVisibility)(user, { boardOptions: boardOptions }));
    }, [boards, changedBoards]);
    var onUpdateBoardColumns = (0, react_1.useCallback)(function () {
        dispatch((0, userConfigActions_1.updateBoardColumns)(user, {
            boards: boards
                .filter(function (b) { return changedBoards.includes(b.board.value); })
                .map(function (b) {
                var _a, _b;
                return ({
                    id: b.board.value,
                    statusColumnId: (_a = b.statusColumn) === null || _a === void 0 ? void 0 : _a.value,
                    dateColumnId: (_b = b.dateColumn) === null || _b === void 0 ? void 0 : _b.value,
                });
            }),
        }));
    }, [boards, changedBoards]);
    (0, react_1.useEffect)(function () {
        reset();
    }, [myWorkConfig]);
    var getConfig = (0, react_1.useCallback)(function () { return dispatch((0, userConfigActions_1.getMyWorkConfig)(user)); }, []);
    (0, react_1.useEffect)(function () {
        if (!isOpen)
            return;
        getConfig();
    }, [isOpen]);
    return {
        expanded: expanded,
        people: people,
        boards: boards,
        boardSearch: boardSearch,
        boardSearchResults: boardSearchResults,
        usersDropDownOptions: usersDropDownOptions,
        didPeopleChange: didPeopleChange,
        changedBoards: changedBoards,
        onSearchBoard: onSearchBoard,
        onChangeColumn: onChangeColumn,
        onChangePeople: onChangePeople,
        onToggleBoardVisibility: onToggleBoardVisibility,
        onSwitchAccordion: onSwitchAccordion,
        onUpdateMyWorkPeople: onUpdateMyWorkPeople,
        onUpdateVisibleBoards: onUpdateVisibleBoards,
        onUpdateBoardColumns: onUpdateBoardColumns,
        getConfig: getConfig,
    };
}
exports.default = useCustomizeMyWork;
