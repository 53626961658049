"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var reactFlow_1 = require("@customTypes/reactFlow");
var useCustomFit = function (rfInstance, reactFlowContainer, rightPanelRef) {
    var customFitScreen = (0, react_1.useCallback)(function (zoomAction) {
        if (rfInstance && reactFlowContainer.current && rightPanelRef.current) {
            var rightPanelWidth = rightPanelRef.current.getBoundingClientRect().width;
            rfInstance.fitView({ maxZoom: 1.5 });
            var _a = rfInstance.getViewport(), x = _a.x, y = _a.y, zoom = _a.zoom;
            rfInstance.setViewport({
                x: x - rightPanelWidth / 2 - 10,
                y: y,
                zoom: !zoomAction ? zoom : zoomAction === reactFlow_1.Zoom.IN ? zoom * 1.2 : zoom * 0.8,
            }, {
                duration: 500,
            });
        }
    }, [rfInstance, reactFlowContainer, rightPanelRef]);
    return { customFitScreen: customFitScreen };
};
exports.default = useCustomFit;
