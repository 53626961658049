"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
exports.default = (0, mui_1.makeStyles)()(function (_) { return ({
    chronoLine: {
        position: "absolute",
        left: 0,
        width: "100%",
        height: "2px",
        backgroundColor: "#474747",
        zIndex: -1,
    },
    marks: {
        position: "absolute",
        zIndex: -1,
    },
    marker: {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        borderColor: "black",
        borderTop: 1,
        borderBottom: 1,
        borderLeft: 0.5,
        borderRight: 0.5,
        borderStyle: "solid",
    },
    markerAlt: {
        backgroundColor: "#e9e9e9",
    },
    markerLine: {
        width: "1px",
        backgroundColor: "#474747",
        height: "10px",
    },
    markerFont: {
        fontSize: "10px",
    },
    markerText: {
        fontSize: "10px",
        color: "#474747",
        transformOrigin: "left",
        transform: "rotate(270deg) translateX(-100%)",
        whiteSpace: "nowrap",
    },
}); });
