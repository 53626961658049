"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config_1 = require("../../pwc/config/config");
var moreTextButtons = [
    "fontSize",
    "fontFamily",
    "inlineStyle",
    "bold",
    "italic",
    "underline",
    "strikeThrough",
    "textColor",
    "backgroundColor",
    "subscript",
    "superscript",
    "clearFormatting",
];
var moreParagraphButtons = [
    "formatOL",
    "formatUL",
    "alignLeft",
    "alignCenter",
    "alignRight",
    "alignJustify",
    "lineHeight",
    "outdent",
    "indent",
    "quote",
];
var options = {
    fontSize: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "14", "18", "24", "30", "36"],
    toolbarButtons: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 5,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 5,
        },
        moreRich: {
            buttons: ["insertTable", "insertHR"],
            buttonsVisible: 0,
        },
        moreMisc: {
            buttons: ["undo", "redo"],
            buttonsVisible: 2,
            align: "right",
        },
    },
    toolbarButtonsMD: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 4,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 3,
        },
        moreRich: {
            buttons: ["insertTable", "insertHR"],
            buttonsVisible: 2,
        },
        moreMisc: {
            buttons: ["undo", "redo"],
            buttonsVisible: 2,
            align: "right",
        },
    },
    toolbarButtonsSM: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 3,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 2,
        },
        moreRich: {
            buttons: ["insertTable", "insertHR"],
            buttonsVisible: 0,
        },
        moreMisc: {
            buttons: ["undo", "redo"],
            buttonsVisible: 2,
            align: "right",
        },
    },
    toolbarButtonsXS: {
        moreText: {
            buttons: moreTextButtons,
            buttonsVisible: 1,
        },
        moreParagraph: {
            buttons: moreParagraphButtons,
            buttonsVisible: 1,
        },
        moreRich: {
            buttons: ["insertTable", "insertHR", "undo", "redo"],
            buttonsVisible: 2,
        },
    },
    fontFamilySelection: true,
    fontSizeSelection: true,
    tableResizer: true,
    pluginsEnabled: [
        "table",
        "spell",
        "quote",
        "save",
        "paragraphFormat",
        "help",
        "draggable",
        "align",
        "link",
        "lists",
        "colors",
        "entities",
        "lineHeight",
        "inlineStyle",
        "fontFamily",
        "fontSize",
    ],
    disableRightClick: true,
    keepFormatOnDelete: true,
    inlineStyles: {
        Headings: "font-size: 24pt; font-family: Georgia;",
        Body: "font-size: 10pt; font-family: Arial;",
        Subtitle: "font-size: 11pt; font-weight: bold; font-family: Arial",
        "Subtitle 2": "font-size: 11pt; font-style: italic; font-family: Arial",
    },
    lineHeights: {
        Default: "normal",
        Single: "1",
        1.15: "1.15",
        1.5: "1.5",
        Double: "2",
    },
    tableEditButtons: [
        "tableHeader",
        "tableStyle",
        "|",
        "tableRows",
        "tableColumns",
        "tableCells",
        "-",
        "tableCellBackground",
        "|",
        "tableCellVerticalAlign",
        "tableCellHorizontalAlign",
        "|",
        "tableRemove",
    ],
    tableResizerOffset: 15,
};
var config = {
    DEFAULT_TITLE: config_1.DEFAULT_TITLE,
    DEFAULT_BODY: config_1.DEFAULT_BODY,
    title: config_1.title,
    body: config_1.body,
    options: options,
};
exports.default = config;
