"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
function notify(obj) {
    var column = obj.column, person = obj.person, rest = __rest(obj, ["column", "person"]);
    if (column == null && person == null)
        return false;
    return (0, utils_1.valuesAreValid)(rest);
}
var handlerValidatorMap = {
    assign_person: utils_1.valuesAreValid,
    change_status: utils_1.valuesAreValid,
    clear_column: utils_1.valuesAreValid,
    create_group: utils_1.valuesAreValid,
    create_task: utils_1.valuesAreValid,
    delete_task: utils_1.valuesAreValid,
    duplicate_group: utils_1.valuesAreValid,
    duplicate_task: utils_1.valuesAreValid,
    increase_decrease_number: utils_1.valuesAreValid,
    move_task_to_group: utils_1.valuesAreValid,
    push_date: utils_1.valuesAreValid,
    set_date_to_today: utils_1.valuesAreValid,
    assign_initiator: utils_1.valuesAreValid,
    notify: notify,
};
function handlerValidator(handlers) {
    return (handlers.length > 0 &&
        handlers.every(function (handler) {
            return handler.type !== "new_handler" && handlerValidatorMap[handler.type](handler);
        }));
}
exports.default = handlerValidator;
