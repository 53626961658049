"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClockFilter = exports.labelClock = exports.ClockFilterMap = void 0;
var date_fns_1 = require("date-fns");
exports.ClockFilterMap = {
    blank: "0",
    earlyMorning: "1",
    morning: "2",
    afternoon: "3",
    evening: "4",
    night: "5",
};
function isBlank(hour) {
    return !hour ? exports.ClockFilterMap.blank : null;
}
function getHourNumber(hour) {
    var parsedHour = (0, date_fns_1.parse)(hour, "HH:mm:ss", new Date());
    if (!(0, date_fns_1.isValid)(parsedHour)) {
        parsedHour = (0, date_fns_1.parse)(hour, "HH:mm", new Date());
    }
    return (0, date_fns_1.getHours)(parsedHour);
}
function isEarlyMorning(hour) {
    var hourNumber = getHourNumber(hour);
    var isValid = hourNumber >= 0 && hourNumber < 6;
    return isValid ? exports.ClockFilterMap.earlyMorning : null;
}
function isMorning(hour) {
    var hourNumber = getHourNumber(hour);
    var isValid = hourNumber >= 6 && hourNumber < 12;
    return isValid ? exports.ClockFilterMap.morning : null;
}
function isAfternoon(hour) {
    var hourNumber = getHourNumber(hour);
    var isValid = hourNumber >= 12 && hourNumber < 18;
    return isValid ? exports.ClockFilterMap.afternoon : null;
}
function isEvening(hour) {
    var hourNumber = getHourNumber(hour);
    var isValid = hourNumber >= 18 && hourNumber < 22;
    return isValid ? exports.ClockFilterMap.evening : null;
}
function isNight(hour) {
    var hourNumber = getHourNumber(hour);
    var isValid = (hourNumber >= 22 && hourNumber <= 23) || (hourNumber >= 0 && hourNumber < 6);
    return isValid ? exports.ClockFilterMap.night : null;
}
function labelClock(hour) {
    return (isEarlyMorning(hour) ||
        isMorning(hour) ||
        isAfternoon(hour) ||
        isEvening(hour) ||
        isNight(hour));
}
exports.labelClock = labelClock;
exports.ClockFilter = {
    0: isBlank,
    1: isEarlyMorning,
    2: isMorning,
    3: isAfternoon,
    4: isEvening,
    5: isNight,
};
