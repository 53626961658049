"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onTaskCommentRemove = exports.onTaskCommentStore = exports.onUpdateManuelIndex = exports.onConvertItemToSubTask = exports.onConvertSubItemToTask = exports.onMoveToOtherParent = exports.onDuplicateTask = exports.onMoveToTaskGroup = exports.onUpdateIndex = exports.onDeleteTask = exports.onPutTask = exports.onPostTask = void 0;
var types = __importStar(require("../../taskConstants"));
var commentLogConstants_1 = require("@redux/constants/commentLogConstants");
function onPostTask() {
    return function (dispatch) {
        return function (_a) {
            var task = _a.task, taskGroupId = _a.taskGroupId;
            dispatch({
                type: types.POST_TASK_SUCCESS,
                task: task,
                taskGroupId: taskGroupId,
            });
        };
    };
}
exports.onPostTask = onPostTask;
function onPutTask() {
    return function (dispatch) {
        return function (_a) {
            var task = _a.task, taskGroupId = _a.taskGroupId;
            dispatch({
                type: types.PUT_TASK_SUCCESS,
                task: task,
                taskGroupId: taskGroupId,
            });
        };
    };
}
exports.onPutTask = onPutTask;
function onDeleteTask() {
    return function (dispatch) { return function (deltedTaskInTaskGroups) {
        dispatch({
            type: types.DELETE_TASK_SUCCESS,
            deltedTaskInTaskGroups: deltedTaskInTaskGroups,
        });
    }; };
}
exports.onDeleteTask = onDeleteTask;
function onUpdateIndex() {
    return function (dispatch) {
        return function (_a) {
            var from = _a.from, task = _a.task, taskGroupId = _a.taskGroupId, to = _a.to;
            dispatch({
                type: types.COLLABORATOR_UPDATE_TASK_INDEX_SUCCESS,
                to: to,
                taskId: +task.id,
                from: from,
                taskGroupId: taskGroupId,
            });
        };
    };
}
exports.onUpdateIndex = onUpdateIndex;
function onMoveToTaskGroup() {
    return function (dispatch) { return function (taskGroups) {
        dispatch({
            type: types.MOVE_TASK_SUCCESS,
            taskGroups: taskGroups,
        });
    }; };
}
exports.onMoveToTaskGroup = onMoveToTaskGroup;
function onDuplicateTask() {
    return function (dispatch) {
        return function (_a) {
            var task = _a.task, taskGroupId = _a.taskGroupId;
            dispatch({
                type: types.DUPLICATE_TASK_SUCCESS,
                task: task,
                taskGroupId: taskGroupId,
            });
        };
    };
}
exports.onDuplicateTask = onDuplicateTask;
function onMoveToOtherParent() {
    return function (dispatch) {
        return function (_a) {
            var parentId = _a.parentId, taskGroups = _a.taskGroups;
            dispatch({
                type: types.MOVE_TO_OTHER_PARENT_TASK_SUCCESS,
                parentId: parentId,
                taskGroups: taskGroups,
            });
        };
    };
}
exports.onMoveToOtherParent = onMoveToOtherParent;
function onConvertSubItemToTask() {
    return function (dispatch) { return function (taskGroups) {
        return dispatch({
            type: types.CONVERT_SUBITEM_TO_TASK_SUCCESS,
            taskGroups: taskGroups,
        });
    }; };
}
exports.onConvertSubItemToTask = onConvertSubItemToTask;
function onConvertItemToSubTask() {
    return function (dispatch) {
        return function (_a) {
            var taskGroups = _a.taskGroups, parentId = _a.parentId;
            return dispatch({
                type: types.CONVERT_ITEM_TO_SUBTASK_SUCCESS,
                taskGroups: taskGroups,
                parentId: parentId,
            });
        };
    };
}
exports.onConvertItemToSubTask = onConvertItemToSubTask;
function onUpdateManuelIndex() {
    return function (dispatch) {
        return function (_a) {
            var taskGroupId = _a.taskGroupId, task = _a.task;
            return dispatch({
                type: types.PUT_TASK_SUCCESS,
                taskGroupId: taskGroupId,
                task: task,
            });
        };
    };
}
exports.onUpdateManuelIndex = onUpdateManuelIndex;
function onTaskCommentStore(isClient, taskId) {
    return function (dispatch) { return function (payload) {
        if (isClient && payload.comment.isInternal)
            return;
        if (taskId && taskId === payload.comment.related.id) {
            dispatch({ type: commentLogConstants_1.WS_STORE_COMMENT_SUCCESS, payload: payload });
        }
    }; };
}
exports.onTaskCommentStore = onTaskCommentStore;
function onTaskCommentRemove(taskId) {
    return function (dispatch) { return function (payload) {
        if (taskId && taskId === payload.taskId) {
            dispatch({ type: commentLogConstants_1.WS_REMOVE_COMMENT_SUCCESS, payload: payload });
        }
    }; };
}
exports.onTaskCommentRemove = onTaskCommentRemove;
