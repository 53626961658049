"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onMirrorModeBroadcast = exports.onUpdatePositionStep = exports.onRemoveStep = exports.onStoreStep = void 0;
function onStoreStep() {
    return function (dispatch) { return function (steps) {
        return dispatch({ type: "WS_STORE_WORKSPACE_STEP_SUCCESS", steps: steps });
    }; };
}
exports.onStoreStep = onStoreStep;
function onRemoveStep(currentId, changeStep, handleWorkspaceTabChange) {
    return function (dispatch) {
        return function (_a) {
            var steps = _a.steps, id = _a.id;
            dispatch({ type: "WS_DELETE_WORKSPACE_STEP_SUCCESS", steps: steps });
            if (currentId) {
                if (steps.length === 0) {
                    changeStep(null);
                    handleWorkspaceTabChange();
                    return;
                }
                if (id === currentId) {
                    var step = steps[0];
                    changeStep(step.id);
                }
            }
        };
    };
}
exports.onRemoveStep = onRemoveStep;
function onUpdatePositionStep() {
    return function (dispatch) { return function (steps) {
        dispatch({
            type: "WS_UPDATE_WORKSPACE_STEP_INDEX_SUCCESS",
            steps: steps,
        });
    }; };
}
exports.onUpdatePositionStep = onUpdatePositionStep;
function onMirrorModeBroadcast(isStepEditorOpen) {
    return function (dispatch) { return function (changes) {
        isStepEditorOpen && dispatch({ type: "APPLY_CHANGES_TO_STEPS", changes: changes });
    }; };
}
exports.onMirrorModeBroadcast = onMirrorModeBroadcast;
