"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hexToHue = void 0;
/* eslint-disable import/prefer-default-export */
function hexToHue(hex) {
    // Convert hex to RGB first
    var r = 0;
    var g = 0;
    var b = 0;
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    }
    else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    var cmin = Math.min(r, g, b);
    var cmax = Math.max(r, g, b);
    var delta = cmax - cmin;
    var h = 0;
    if (delta === 0)
        h = 0;
    else if (cmax === r)
        h = ((g - b) / delta) % 6;
    else if (cmax === g)
        h = (b - r) / delta + 2;
    else
        h = (r - g) / delta + 4;
    h = Math.round(h * 60);
    if (h < 0)
        h += 360;
    return h;
}
exports.hexToHue = hexToHue;
