"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
function useSplitResizer() {
    var _a = (0, react_1.useState)({
        left: "70%",
        right: "30%",
    }), splitWidth = _a[0], setSplitWidth = _a[1];
    var _b = (0, react_1.useState)(false), isResizing = _b[0], setIsResizing = _b[1];
    var onSplitResizeStart = (0, react_1.useCallback)(function () {
        setIsResizing(true);
    }, []);
    var onSplitResizeEnd = (0, react_1.useCallback)(function () {
        setIsResizing(false);
    }, []);
    var onSplitResize = (0, react_1.useCallback)(function (e) {
        if (!isResizing) {
            return;
        }
        var offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
        if (offsetRight < 300) {
            offsetRight = 300;
        }
        var maxWidth = window.innerWidth;
        var percentageOffsetRight = (offsetRight / maxWidth) * 100;
        if (percentageOffsetRight > 10 && percentageOffsetRight < 60) {
            setSplitWidth({
                left: 100 - percentageOffsetRight + "%",
                right: percentageOffsetRight + "%",
            });
        }
    }, [isResizing]);
    (0, react_1.useEffect)(function () {
        document.addEventListener("mousemove", onSplitResize);
        document.addEventListener("mouseup", onSplitResizeEnd);
        return function () {
            document.removeEventListener("mousemove", onSplitResize);
            document.removeEventListener("mouseup", onSplitResizeEnd);
        };
    }, [isResizing]);
    return { splitWidth: splitWidth, onSplitResizeStart: onSplitResizeStart };
}
exports.default = useSplitResizer;
