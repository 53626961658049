"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var attributeActions_1 = require("@pages/Attributes/reducers/attributeActions");
function AttributeParamSwitch(_a) {
    var param = _a.param, user = _a.user, dispatch = _a.dispatch, open = _a.open;
    switch (param === null || param === void 0 ? void 0 : param.param) {
        case "Show": {
            var data_1 = param.data;
            if (data_1 === null || data_1 === void 0 ? void 0 : data_1.id) {
                setTimeout(function () {
                    dispatch((0, attributeActions_1.changeCurrentAttribute)(data_1.id));
                    dispatch((0, attributeActions_1.showAttribute)(user, data_1.id));
                    open();
                }, 200);
            }
            break;
        }
        default:
            break;
    }
}
exports.default = AttributeParamSwitch;
