"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (_theme) { return ({
    floatingPanel: {
        width: "calc(100vw - 200px) !important",
        display: "flex",
        flexDirection: "column",
        maxHeight: "calc(100vh - 200px) !important",
    },
    flowsScrollContainer: {
        margin: "16px 0px",
        padding: "0px 16px",
    },
    flowsContainer: {
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
    },
    buildingBlockTypeFlowContainer: {
        flex: 1,
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: "16px",
    },
}); });
exports.default = useStyles;
