"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Shapes = {
    rectangle: "M1 1V23H23V1H1Z",
    roundRectangle: "M1 6V18C1 20.7614 3.23858 23 6 23H18C20.7614 23 23 20.7614 23 18V6C23 3.23858 20.7614 1 18 1H6C3.23858 1 1 3.23858 1 6Z",
    circle: "M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z",
    triangle: "M2.47372 18.5L12 2L21.5263 18.5H2.47372Z",
    diamond: "M1.41421 12L12 1.41421L22.5858 12L12 22.5858L1.41421 12Z",
    person: "M21.15 22.15H22.15V21.15V19.5C22.15 18.9991 21.8659 18.6227 21.6816 18.4187C21.4684 18.1826 21.1939 17.9659 20.902 17.7711C20.3125 17.3775 19.5149 16.9827 18.6055 16.632C16.7862 15.9303 14.3758 15.35 12 15.35C9.62424 15.35 7.21381 15.9303 5.39452 16.632C4.48513 16.9827 3.68753 17.3775 3.09797 17.7711C2.80613 17.9659 2.5316 18.1826 2.31836 18.4187C2.13409 18.6227 1.85 18.9991 1.85 19.5V21.15V22.15H2.85H21.15ZM16.15 6C16.15 3.70772 14.2923 1.85 12 1.85C9.70772 1.85 7.85 3.70772 7.85 6C7.85 8.29228 9.70772 10.15 12 10.15C14.2923 10.15 16.15 8.29228 16.15 6ZM7 6C7 3.23728 9.23728 1 12 1C14.7627 1 17 3.23728 17 6C17 8.76272 14.7627 11 12 11C9.23728 11 7 8.76272 7 6ZM1 19.5C1 18.8698 1.30776 18.2438 1.99002 17.6141C2.67908 16.9781 3.68134 16.4082 4.87 15.932C7.24892 14.979 10.1225 14.5 12 14.5C13.8775 14.5 16.7511 14.979 19.13 15.932C20.3187 16.4082 21.3209 16.9781 22.01 17.6141C22.6922 18.2438 23 18.8698 23 19.5V23H1V19.5Z",
    parallelogram: "M18 17H1.5L6 1.5H22.5L18 17Z",
    star: "M13 1.13866L15.1908 7.65647C15.4645 8.47072 16.2276 9.01925 17.0866 9.01925L24.1021 9.01925L18.4854 12.964C17.7605 13.4731 17.4569 14.3983 17.7391 15.2379L19.8981 21.6612L14.1495 17.6238C13.4597 17.1394 12.5403 17.1394 11.8505 17.6238L6.10187 21.6612L8.26091 15.2379C8.54312 14.3983 8.23946 13.4731 7.51462 12.964L1.89792 9.01925L8.91344 9.01925C9.77245 9.01925 10.5355 8.47072 10.8092 7.65647L13 1.13866Z",
    arrow: "M1 14V6.5C1 5.94772 1.44772 5.5 2 5.5H9.5C10.0523 5.5 10.5 5.05228 10.5 4.5V2.91421C10.5 2.02331 11.5771 1.57714 12.2071 2.20711L19.2567 9.25665C19.661 9.66105 19.6445 10.3216 19.2205 10.7053L11.1709 17.9882C10.528 18.5699 9.5 18.1137 9.5 17.2467V16C9.5 15.4477 9.05229 15 8.5 15H2C1.44772 15 1 14.5523 1 14Z",
    document: "M10.5 4.5V8.5H6.5M10.5 4.5H18.5V20.5H6.5V8.5L10.5 4.5Z",
    sqcHybrid: "M3.94444 3.85511H3.44444V4.35511V11.107C3.37391 11.5584 3.33729 12.0212 3.33729 12.4926C3.33729 12.964 3.37391 13.4268 3.44444 13.8782V20.5481V21.0481H3.94444H10.0126C10.6506 21.2 11.3159 21.2803 12 21.2803C12.6841 21.2803 13.3495 21.2 13.9875 21.0481H20.0556H20.5556V20.5481V13.8787C20.6261 13.4271 20.6628 12.9641 20.6628 12.4926C20.6628 12.021 20.6261 11.5581 20.5556 11.1065V4.35511V3.85511H20.0556H13.604C13.0844 3.75647 12.5482 3.70488 12 3.70488C11.4519 3.70488 10.9157 3.75647 10.396 3.85511H3.94444ZM4.44444 13.7984V11.1868C4.97291 8.00328 7.40088 5.48171 10.4953 4.85511H13.5048C16.599 5.48168 19.0269 8.00301 19.5556 11.1863V13.7989C19.0476 16.8579 16.7857 19.3056 13.8641 20.0481H10.136C7.21421 19.3056 4.95228 16.8576 4.44444 13.7984ZM3 2.99261H21C21.2761 2.99261 21.5 3.21647 21.5 3.49261V21.4926C21.5 21.7688 21.2761 21.9926 21 21.9926H3C2.72386 21.9926 2.5 21.7688 2.5 21.4926V3.49261C2.5 3.21647 2.72386 2.99261 3 2.99261ZM12 5.08622C8.02653 5.08622 4.73731 8.04322 4.69777 12.4881C4.66214 16.4928 8.04428 19.921 12.0028 19.8991C15.9334 19.8773 19.3378 16.4789 19.3023 12.4881C19.2673 8.55465 15.9964 5.08622 12 5.08622Z",
    trapezoid: "M8.69257 4.83777H17.3066C17.5151 4.83777 17.7017 4.96718 17.7748 5.16248L23.0157 19.1625C23.138 19.4893 22.8964 19.8378 22.5474 19.8378H3.43339C3.08416 19.8378 2.84252 19.4889 2.96533 19.1619L8.22451 5.16194C8.29777 4.96693 8.48426 4.83777 8.69257 4.83777Z",
    pentagon: "TODO:",
};
exports.default = Shapes;
