"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable @typescript-eslint/default-param-last */
var constants_1 = require("@api/constants");
var workspaceFrame_1 = require("@customTypes/reducers/workspaceFrame");
var workspaceFrameConstants_1 = require("./workspaceFrameConstants");
var frameLoadings = __assign(__assign({}, constants_1.initialLoadings), { download: false, fetch: false });
var initialAnalysisLoader = {
    initial: { state: "not-started" },
    fetchingConditions: { state: "not-started", value: 0 },
    fetchingOutputs: { state: "not-started", value: 0 },
    creatingFrames: { state: "not-started", value: 0 },
    final: { state: "not-started" },
};
var initialState = {
    templateSlides: [],
    workspaceFrames: [],
    overridenWorkspaceFrameElements: new Map(),
    loadings: frameLoadings,
    pickingElements: false,
    pickingElementsFor: null,
    pickingElementsLoading: false,
    mergeSlideConflictModalOpen: false,
    conflictingFrames: [],
    nodeElements: [],
    edgeElements: [],
    preview: { loading: false, id: undefined },
    slideViewer: workspaceFrame_1.INITIALSLIDEVIEWER,
    analysisProgresiveLoader: initialAnalysisLoader,
    isEditableSlideViewerOpen: false,
    editFrameId: null,
};
var reorderFrames = function (frames, frame, newIndex, forced) {
    if (forced === void 0) { forced = false; }
    if (forced || newIndex !== frame.index) {
        var newFrames_1 = [];
        var framesToReorder = frames.filter(function (s) {
            return s.index >= Math.min(newIndex, frame.index) &&
                s.index <= Math.max(newIndex, frame.index);
        });
        var framesNotToReorder = frames.filter(function (s) {
            return s.index < Math.min(newIndex, frame.index) ||
                s.index > Math.max(newIndex, frame.index);
        });
        framesNotToReorder.forEach(function (s) { return newFrames_1.push(s); });
        framesToReorder.forEach(function (s) {
            var newSlide = __assign({}, s);
            if (newSlide.id === frame.id) {
                newSlide.index = newIndex;
            }
            else {
                newSlide.index += newIndex > frame.index ? -1 : 1;
            }
            newFrames_1.push(newSlide);
        });
        return newFrames_1.sort(function (a, b) { return a.index - b.index; });
    }
    return frames.sort(function (a, b) { return a.index - b.index; });
};
var reorderSlides = function (slides, slide, newIndex, forced) {
    if (forced === void 0) { forced = false; }
    if (forced || newIndex !== slide.index) {
        var newSlides_1 = [];
        var slidesToReorder = slides.filter(function (s) {
            return s.index >= Math.min(newIndex, slide.index) &&
                s.index <= Math.max(newIndex, slide.index);
        });
        var slidesNotToReorder = slides.filter(function (s) {
            return s.index < Math.min(newIndex, slide.index) ||
                s.index > Math.max(newIndex, slide.index);
        });
        slidesNotToReorder.forEach(function (s) { return newSlides_1.push(s); });
        slidesToReorder.forEach(function (s) {
            var newSlide = __assign({}, s);
            if (newSlide.id === slide.id) {
                newSlide.index = newIndex;
            }
            else {
                newSlide.index += newIndex > slide.index ? -1 : 1;
            }
            newSlides_1.push(newSlide);
        });
        return newSlides_1.sort(function (a, b) { return a.index - b.index; });
    }
    return slides.sort(function (a, b) { return a.index - b.index; });
};
function reducer(state, action) {
    var _a, _b, _c, _d;
    var _e, _f, _g, _h, _j, _k, _l;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case workspaceFrameConstants_1.PUT_WORKSPACES_FRAMES_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)), workspaceFrames: reorderFrames(state.workspaceFrames, state.workspaceFrames.find(function (s) { return s.id === action.id; }), action.index) });
        case workspaceFrameConstants_1.GET_SLIDES_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadingType] = true, _b)), pickingElements: false, pickingElementsFor: null });
        case workspaceFrameConstants_1.ANALYSE_WORKSPACES_FRAMES_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_c = {}, _c[action.loadingType] = true, _c.fetch = true, _c)) });
        case workspaceFrameConstants_1.TOGGLE_EDITABLE_SLIDE_VIEWER:
            return __assign(__assign({}, state), { isEditableSlideViewerOpen: action.bool });
        case workspaceFrameConstants_1.SET_EDIT_FRAME:
            return __assign(__assign({}, state), { editFrameId: action.id });
        case workspaceFrameConstants_1.GET_WORKSPACES_FRAMES_LOADING:
        case workspaceFrameConstants_1.POST_WORKSPACES_FRAMES_LOADING:
        case workspaceFrameConstants_1.DELETE_WORKSPACES_FRAMES_LOADING:
        case workspaceFrameConstants_1.DELETE_MULTIPLE_WORKSPACES_FRAMES_LOADING:
        case workspaceFrameConstants_1.REMOVE_ACTIVE_EDGES_LOADING:
        case workspaceFrameConstants_1.REMOVE_ACTIVE_NODES_LOADING:
        case workspaceFrameConstants_1.SET_ACTIVE_EDGES_LOADING:
        case workspaceFrameConstants_1.SET_ACTIVE_NODES_LOADING:
        case workspaceFrameConstants_1.MERGE_WORKSPACES_FRAMES_LOADING:
        case workspaceFrameConstants_1.POST_WORKSPACE_FRAME_SLIDE_LOADING:
        case workspaceFrameConstants_1.DOWNLOAD_WORKSPACES_FRAMES_LOADING:
        case workspaceFrameConstants_1.DELETE_WORKSPACE_FRAME_SLIDES_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadingType] = true, _d)) });
        case workspaceFrameConstants_1.GET_WORKSPACES_FRAMES_FAILED:
            return __assign(__assign({}, state), { loadings: frameLoadings, analysisProgresiveLoader: initialAnalysisLoader });
        case workspaceFrameConstants_1.ANALYSE_WORKSPACES_FRAMES_FAILED:
        case workspaceFrameConstants_1.POST_WORKSPACES_FRAMES_FAILED:
        case workspaceFrameConstants_1.DELETE_MULTIPLE_WORKSPACES_FRAMES_FAILED:
        case workspaceFrameConstants_1.PUT_WORKSPACES_FRAMES_FAILED:
        case workspaceFrameConstants_1.DOWNLOAD_WORKSPACES_FRAMES_FAILED:
        case workspaceFrameConstants_1.DELETE_WORKSPACES_FRAMES_FAILED:
        case workspaceFrameConstants_1.REMOVE_ACTIVE_EDGES_FAILED:
        case workspaceFrameConstants_1.REMOVE_ACTIVE_NODES_FAILED:
        case workspaceFrameConstants_1.SET_ACTIVE_EDGES_FAILED:
        case workspaceFrameConstants_1.SET_ACTIVE_NODES_FAILED:
        case workspaceFrameConstants_1.GET_SLIDES_FAILED:
        case workspaceFrameConstants_1.MERGE_WORKSPACES_FRAMES_FAILED:
        case workspaceFrameConstants_1.POST_WORKSPACE_FRAME_SLIDE_FAILED:
        case workspaceFrameConstants_1.PUT_WORKSPACE_FRAME_SLIDES_FAILED:
        case workspaceFrameConstants_1.DELETE_WORKSPACE_FRAME_SLIDES_FAILED:
        case workspaceFrameConstants_1.PUT_WORKSPACE_FRAME_SLIDE_CONTENT_FAILED:
            return __assign(__assign({}, state), { loadings: frameLoadings });
        case workspaceFrameConstants_1.GET_WORKSPACES_FRAMES_SUCCESS:
            return __assign(__assign({}, state), { workspaceFrames: action.workspaceFrames, loadings: frameLoadings, analysisProgresiveLoader: initialAnalysisLoader });
        case workspaceFrameConstants_1.ANALYSE_WORKSPACES_FRAMES_SUCCESS:
            return state;
        case workspaceFrameConstants_1.POST_WORKSPACES_FRAMES_SUCCESS:
            return __assign(__assign({}, state), { workspaceFrames: reorderFrames(__spreadArray(__spreadArray([], state.workspaceFrames, true), [action.workspaceFrame], false), action.workspaceFrame, action.workspaceFrame.index, action.reorder), loadings: frameLoadings });
        case workspaceFrameConstants_1.PUT_WORKSPACES_FRAMES_SUCCESS:
            return __assign(__assign({}, state), { workspaceFrames: state.workspaceFrames.map(function (workspaceSlide) {
                    return workspaceSlide.id === action.workspaceFrame.id
                        ? action.workspaceFrame
                        : workspaceSlide;
                }), loadings: frameLoadings });
        case workspaceFrameConstants_1.MERGE_WORKSPACES_FRAMES_SUCCESS:
            return __assign(__assign({}, state), { workspaceFrames: action.workspaceFrames, loadings: frameLoadings });
        case workspaceFrameConstants_1.DELETE_WORKSPACES_FRAMES_SUCCESS:
            return __assign(__assign({}, state), { workspaceFrames: state.workspaceFrames.filter(function (workspaceSlide) { return workspaceSlide.id !== action.id; }), loadings: frameLoadings });
        case workspaceFrameConstants_1.DELETE_MULTIPLE_WORKSPACES_FRAMES_SUCCESS:
            return __assign(__assign({}, state), { workspaceFrames: action.workspaceFrames, loadings: frameLoadings });
        case workspaceFrameConstants_1.CHANGE_TEXT: {
            return __assign(__assign({}, state), { workspaceFrames: state.workspaceFrames.map(function (workspaceSlide) {
                    return workspaceSlide.id === action.id
                        ? __assign(__assign({}, workspaceSlide), { title: action.text }) : workspaceSlide;
                }) });
        }
        case workspaceFrameConstants_1.GET_SLIDES_SUCCESS:
            return __assign(__assign({}, state), { templateSlides: action.templateSlides, loadings: __assign(__assign({}, state.loadings), { main: false }) });
        case workspaceFrameConstants_1.DOWNLOAD_WORKSPACES_FRAMES_SUCCESS: {
            return __assign(__assign({}, state), { loadings: frameLoadings });
        }
        case workspaceFrameConstants_1.SET_PICK_ELEMENTS_FOR_LOADING:
            return __assign(__assign({}, state), { pickingElementsLoading: true });
        case workspaceFrameConstants_1.SET_PICK_ELEMENTS_FOR_FAILED:
            return __assign(__assign({}, state), { pickingElementsLoading: false });
        case workspaceFrameConstants_1.SET_PICK_ELEMENTS_FOR:
            return __assign(__assign({}, state), { pickingElementsFor: (_e = action.frame) !== null && _e !== void 0 ? _e : null, pickingElements: Boolean(action.frame), pickingElementsLoading: false });
        case workspaceFrameConstants_1.SET_ACTIVE_NODES_SUCCESS:
            return __assign(__assign({}, state), { pickingElementsFor: ((_f = state.pickingElementsFor) === null || _f === void 0 ? void 0 : _f.id) === action.workspaceFrameId
                    ? __assign(__assign({}, state.pickingElementsFor), { nodes: __spreadArray(__spreadArray([], state.pickingElementsFor
                            .nodes, true), [
                            action.workspaceNodeId,
                        ], false) }) : state.pickingElementsFor, workspaceFrames: state.workspaceFrames.map(function (frame) {
                    return frame.id === action.workspaceFrameId
                        ? __assign(__assign({}, frame), { nodes: __spreadArray(__spreadArray([], frame.nodes, true), [
                                action.workspaceNodeId,
                            ], false) }) : frame;
                }) });
        case workspaceFrameConstants_1.REMOVE_ACTIVE_NODES_SUCCESS:
            return __assign(__assign({}, state), { pickingElementsFor: ((_g = state.pickingElementsFor) === null || _g === void 0 ? void 0 : _g.id) === action.workspaceFrameId
                    ? __assign(__assign({}, state.pickingElementsFor), { nodes: state.pickingElementsFor.nodes.filter(function (x) { return x !== action.workspaceNodeId; }) }) : state.pickingElementsFor, workspaceFrames: state.workspaceFrames.map(function (workspaceSlide) {
                    return workspaceSlide.id === action.workspaceFrameId
                        ? __assign(__assign({}, workspaceSlide), { nodes: workspaceSlide.nodes.filter(function (node) { return node !== action.workspaceNodeId; }) }) : workspaceSlide;
                }) });
        case workspaceFrameConstants_1.SET_ACTIVE_EDGES_SUCCESS:
            return __assign(__assign({}, state), { pickingElementsFor: ((_h = state.pickingElementsFor) === null || _h === void 0 ? void 0 : _h.id) === action.workspaceFrameId
                    ? __assign(__assign({}, state.pickingElementsFor), { edges: __spreadArray(__spreadArray([], state.pickingElementsFor
                            .edges, true), [
                            action.workspaceRelationshipId,
                        ], false) }) : state.pickingElementsFor, workspaceFrames: state.workspaceFrames.map(function (frame) {
                    return frame.id === action.workspaceFrameId
                        ? __assign(__assign({}, frame), { edges: __spreadArray(__spreadArray([], frame.edges, true), [
                                action.workspaceRelationshipId,
                            ], false) }) : frame;
                }) });
        case workspaceFrameConstants_1.REMOVE_ACTIVE_EDGES_SUCCESS:
            return __assign(__assign({}, state), { pickingElementsFor: ((_j = state.pickingElementsFor) === null || _j === void 0 ? void 0 : _j.id) === action.workspaceFrameId
                    ? __assign(__assign({}, state.pickingElementsFor), { edges: state.pickingElementsFor.edges.filter(function (x) { return x !== action.workspaceRelationshipId; }) }) : state.pickingElementsFor, workspaceFrames: state.workspaceFrames.map(function (frame) {
                    return frame.id === action.workspaceFrameId
                        ? __assign(__assign({}, frame), { edges: frame.edges.filter(function (edge) { return edge !== action.workspaceRelationshipId; }) }) : frame;
                }) });
        case workspaceFrameConstants_1.SET_MERGE_CONFLICT: {
            return __assign(__assign({}, state), { mergeSlideConflictModalOpen: action.conflictingFrames.length !== 0, conflictingFrames: action.conflictingFrames });
        }
        case workspaceFrameConstants_1.CHANGE_MERGE_CONFLICT: {
            return __assign(__assign({}, state), { conflictingFrames: state.conflictingFrames.map(function (conflictingSlide) {
                    return conflictingSlide.id === action.id
                        ? __assign(__assign({}, conflictingSlide), { useText: !conflictingSlide.useText }) : conflictingSlide;
                }) });
        }
        case workspaceFrameConstants_1.DELETE_WORKSPACE_FRAME_SLIDES_SUCCESS:
        case workspaceFrameConstants_1.POST_WORKSPACE_FRAME_SLIDE_SUCCESS:
            return __assign(__assign({}, state), { workspaceFrames: state.workspaceFrames.map(function (frame) {
                    if (frame.id === action.frameId) {
                        frame.workspaceFrameSlides = action.workspaceFrameSlides;
                    }
                    return frame;
                }), loadings: frameLoadings });
        case workspaceFrameConstants_1.PUT_WORKSPACE_FRAME_SLIDES_LOADING: {
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), { post: true }), workspaceFrames: state.workspaceFrames.map(function (frame) {
                    if (frame.id === action.workspaceFrameSlide.workspaceFrameId) {
                        return __assign(__assign({}, frame), { workspaceFrameSlides: reorderSlides(frame.workspaceFrameSlides, frame.workspaceFrameSlides.find(function (s) { return s.id === action.workspaceFrameSlide.id; }), action.workspaceFrameSlide.index) });
                    }
                    return frame;
                }) });
        }
        case workspaceFrameConstants_1.PUT_WORKSPACE_FRAME_SLIDES_SUCCESS: {
            return __assign(__assign({}, state), { loadings: frameLoadings, workspaceFrames: state.workspaceFrames.map(function (frame) {
                    if (frame.id === action.workspaceFrameSlide.workspaceFrameId) {
                        return __assign(__assign({}, frame), { workspaceFrameSlides: frame.workspaceFrameSlides
                                .map(function (slide) {
                                return slide.id === action.workspaceFrameSlide.id
                                    ? action.workspaceFrameSlide
                                    : slide;
                            })
                                .sort(function (a, b) { return a.index - b.index; }) });
                    }
                    return frame;
                }) });
        }
        case workspaceFrameConstants_1.PUT_WORKSPACE_FRAME_SLIDE_CONTENT_SUCCESS:
            return __assign(__assign({}, state), { workspaceFrames: state.workspaceFrames.map(function (frame) { return (__assign(__assign({}, frame), { workspaceFrameSlides: frame.workspaceFrameSlides.map(function (slide) {
                        if (slide.id === action.workspaceFrameSlideContent.workspaceFrameSlideId) {
                            return __assign(__assign({}, slide), { workspaceFrameSlideContents: slide.workspaceFrameSlideContents.map(function (slideContent) {
                                    return slideContent.id === action.workspaceFrameSlideContent.id
                                        ? action.workspaceFrameSlideContent
                                        : slideContent;
                                }) });
                        }
                        return slide;
                    }) })); }) });
        case workspaceFrameConstants_1.OVERRIDE_WORKSPACE_FRAME_ELEMENT:
            return __assign(__assign({}, state), { overridenWorkspaceFrameElements: state.overridenWorkspaceFrameElements.set(action.slideId, (_l = (_k = state.overridenWorkspaceFrameElements
                    .get(action.slideId)) === null || _k === void 0 ? void 0 : _k.set(action.nodeId, action.position)) !== null && _l !== void 0 ? _l : new Map([[action.nodeId, action.position]])) });
        case workspaceFrameConstants_1.GET_WORKSPACE_FRAME_ELEMENTS_LOADING:
            return __assign(__assign({}, state), { preview: {
                    loading: true,
                    id: action.id,
                } });
        case workspaceFrameConstants_1.GET_WORKSPACE_FRAME_ELEMENTS_FAILED:
            return __assign(__assign({}, state), { preview: {
                    loading: false,
                    id: undefined,
                } });
        case workspaceFrameConstants_1.GET_WORKSPACE_FRAME_ELEMENTS_SUCCESS:
            return __assign(__assign({}, state), { nodeElements: action.nodeElements, 
                // @ts-ignore
                edgeElements: action.edgeElements, preview: {
                    loading: false,
                    id: undefined,
                } });
        case workspaceFrameConstants_1.CLEAR_WORKSPACE_FRAME_ELEMENTS:
            return __assign(__assign({}, state), { nodeElements: [], edgeElements: [], loadings: frameLoadings });
        case workspaceFrameConstants_1.OPEN_SLIDE_VIEWER:
            return __assign(__assign({}, state), { slideViewer: {
                    open: true,
                    mergeField: action.mergeField,
                } });
        case workspaceFrameConstants_1.CLOSE_SLIDE_VIEWER:
            return __assign(__assign({}, state), { slideViewer: workspaceFrame_1.INITIALSLIDEVIEWER });
        case workspaceFrameConstants_1.FRAME_ANALYSIS_UPDATE_EVENT:
            return __assign(__assign({}, state), { analysisProgresiveLoader: __assign(__assign({}, state.analysisProgresiveLoader), action.payload) });
        default:
            return state;
    }
}
exports.default = reducer;
