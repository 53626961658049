"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TASK_COMMENT_COUNT = exports.DOWNLOAD_RESPONSE_LETTER_FAILED = exports.DOWNLOAD_RESPONSE_LETTER_SUCCESS = exports.DOWNLOAD_RESPONSE_LETTER_LOADING = exports.CONVERT_ITEM_TO_SUBTASK_FAILED = exports.CONVERT_ITEM_TO_SUBTASK_SUCCESS = exports.CONVERT_ITEM_TO_SUBTASK_LOADING = exports.CONVERT_SUBITEM_TO_TASK_FAILED = exports.CONVERT_SUBITEM_TO_TASK_SUCCESS = exports.CONVERT_SUBITEM_TO_TASK_LOADING = exports.MOVE_TO_OTHER_PARENT_TASK_FAILED = exports.MOVE_TO_OTHER_PARENT_TASK_SUCCESS = exports.MOVE_TO_OTHER_PARENT_TASK_LOADING = exports.DUPLICATE_MULTIPLE_TASKS_FAILED = exports.DUPLICATE_MULTIPLE_TASKS_SUCCESS = exports.DUPLICATE_MULTIPLE_TASKS_LOADING = exports.DUPLICATE_TASK_FAILED = exports.DUPLICATE_TASK_SUCCESS = exports.DUPLICATE_TASK_LOADING = exports.MOVE_TASK_FAILED = exports.MOVE_TASK_SUCCESS = exports.MOVE_TASK_LOADING = exports.EXPAND_TASK_FAILED = exports.EXPAND_TASK_SUCCESS = exports.EXPAND_TASK_LOADING = exports.UPDATE_TASK_INDEX_FAILED = exports.UPDATE_TASK_INDEX_OPTIMISTIC = exports.UPDATE_TASK_INDEX_SUCCESS = exports.COLLABORATOR_UPDATE_TASK_INDEX_SUCCESS = exports.UPDATE_TASK_INDEX_LOADING = exports.DELETE_TASK_FAILED = exports.DELETE_TASK_SUCCESS = exports.DELETE_TASK_LOADING = exports.PUT_TASK_FAILED = exports.PUT_TASK_SUCCESS = exports.PUT_TASK_LOADING = exports.POST_TASK_FROM_MY_WORK_FAILED = exports.POST_TASK_FROM_MY_WORK_SUCCESS = exports.POST_TASK_FROM_MY_WORK_LOADING = exports.POST_TASK_WS_SUCCESS = exports.POST_TASK_SUCCESS = exports.POST_TASK_FAILED = exports.POST_TASK_LOADING = void 0;
exports.POST_TASK_LOADING = "POST_TASK_LOADING";
exports.POST_TASK_FAILED = "POST_TASK_FAILED";
exports.POST_TASK_SUCCESS = "POST_TASK_SUCCESS";
exports.POST_TASK_WS_SUCCESS = "POST_TASK_WS_SUCCESS";
exports.POST_TASK_FROM_MY_WORK_LOADING = "POST_TASK_FROM_MY_WORK_LOADING";
exports.POST_TASK_FROM_MY_WORK_SUCCESS = "POST_TASK_FROM_MY_WORK_SUCCESS";
exports.POST_TASK_FROM_MY_WORK_FAILED = "POST_TASK_FROM_MY_WORK_FAILED";
exports.PUT_TASK_LOADING = "PUT_TASK_LOADING";
exports.PUT_TASK_SUCCESS = "PUT_TASK_SUCCESS";
exports.PUT_TASK_FAILED = "PUT_TASK_FAILED";
exports.DELETE_TASK_LOADING = "DELETE_TASK_LOADING";
exports.DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
exports.DELETE_TASK_FAILED = "DELETE_TASK_FAILED";
exports.UPDATE_TASK_INDEX_LOADING = "UPDATE_TASK_INDEX_LOADING";
exports.COLLABORATOR_UPDATE_TASK_INDEX_SUCCESS = "COLLABORATOR_UPDATE_TASK_INDEX_SUCCESS";
exports.UPDATE_TASK_INDEX_SUCCESS = "UPDATE_TASK_INDEX_SUCCESS";
exports.UPDATE_TASK_INDEX_OPTIMISTIC = "UPDATE_TASK_INDEX_OPTIMISTIC";
exports.UPDATE_TASK_INDEX_FAILED = "UPDATE_TASK_INDEX_FAILED";
exports.EXPAND_TASK_LOADING = "EXPAND_TASK_LOADING";
exports.EXPAND_TASK_SUCCESS = "EXPAND_TASK_SUCCESS";
exports.EXPAND_TASK_FAILED = "EXPAND_TASK_FAILED";
exports.MOVE_TASK_LOADING = "MOVE_TASK_LOADING";
exports.MOVE_TASK_SUCCESS = "MOVE_TASK_SUCCESS";
exports.MOVE_TASK_FAILED = "MOVE_TASK_FAILED";
exports.DUPLICATE_TASK_LOADING = "DUPLICATE_TASK_LOADING";
exports.DUPLICATE_TASK_SUCCESS = "DUPLICATE_TASK_SUCCESS";
exports.DUPLICATE_TASK_FAILED = "DUPLICATE_TASK_FAILED";
exports.DUPLICATE_MULTIPLE_TASKS_LOADING = "DUPLICATE_MULTIPLE_TASKS_LOADING";
exports.DUPLICATE_MULTIPLE_TASKS_SUCCESS = "DUPLICATE_MULTIPLE_TASKS_SUCCESS";
exports.DUPLICATE_MULTIPLE_TASKS_FAILED = "DUPLICATE_MULTIPLE_TASKS_FAILED";
exports.MOVE_TO_OTHER_PARENT_TASK_LOADING = "MOVE_TO_OTHER_PARENT_TASK_LOADING";
exports.MOVE_TO_OTHER_PARENT_TASK_SUCCESS = "MOVE_TO_OTHER_PARENT_TASK_SUCCESS";
exports.MOVE_TO_OTHER_PARENT_TASK_FAILED = "MOVE_TO_OTHER_PARENT_TASK_FAILED";
exports.CONVERT_SUBITEM_TO_TASK_LOADING = "CONVERT_SUBITEM_TO_TASK_LOADING";
exports.CONVERT_SUBITEM_TO_TASK_SUCCESS = "CONVERT_SUBITEM_TO_TASK_SUCCESS";
exports.CONVERT_SUBITEM_TO_TASK_FAILED = "CONVERT_SUBITEM_TO_TASK_FAILED";
exports.CONVERT_ITEM_TO_SUBTASK_LOADING = "CONVERT_ITEM_TO_SUBTASK_LOADING";
exports.CONVERT_ITEM_TO_SUBTASK_SUCCESS = "CONVERT_ITEM_TO_SUBTASK_SUCCESS";
exports.CONVERT_ITEM_TO_SUBTASK_FAILED = "CONVERT_ITEM_TO_SUBTASK_FAILED";
exports.DOWNLOAD_RESPONSE_LETTER_LOADING = "DOWNLOAD_RESPONSE_LETTER_LOADING";
exports.DOWNLOAD_RESPONSE_LETTER_SUCCESS = "DOWNLOAD_RESPONSE_LETTER_SUCCESS";
exports.DOWNLOAD_RESPONSE_LETTER_FAILED = "DOWNLOAD_RESPONSE_LETTER_FAILED";
// for comment counting
exports.TASK_COMMENT_COUNT = "TASK_COMMENT_COUNT";
