"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUploadImanage = exports.isUploadFile = void 0;
function isUploadFile(file) {
    return file instanceof File;
}
exports.isUploadFile = isUploadFile;
function isUploadImanage(file) {
    return file.type === "imanage";
}
exports.isUploadImanage = isUploadImanage;
