"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var DEFAULTSIZE = 25;
var DEFAULTFONTSIZE = 8;
function GetCustomerSx(size, customer) {
    if (size === void 0) { size = DEFAULTSIZE; }
    var avatarSx = {
        height: size,
        width: size,
        fontSize: "".concat((DEFAULTFONTSIZE / DEFAULTSIZE) * size, "px"), // size:25 => 0.6 , size:30 => 0.72
    };
    if (customer === constants_1.CUSTOMER.ACCURA) {
        avatarSx.height = (20 / DEFAULTSIZE) * size; // size:25 => 20 , size:30 => 24
        avatarSx.width = (32 / DEFAULTSIZE) * size; // size:25 => 32 , size:30 => 38.4, size:40 => 51.2
        avatarSx.borderRadius = 2;
        avatarSx.fontWeight = 400;
    }
    return avatarSx;
}
exports.default = GetCustomerSx;
