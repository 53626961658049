"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Orientations;
(function (Orientations) {
    Orientations["Left"] = "left";
    Orientations["Right"] = "right";
    Orientations["Up"] = "up";
    Orientations["Down"] = "down";
})(Orientations || (Orientations = {}));
exports.default = Orientations;
