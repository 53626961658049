"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var useStyles = (0, mui_1.makeStyles)()(function (theme) { return ({
    cell100: {
        height: "100%",
        width: "100%",
    },
    indexCell: {
        color: theme.palette.grey[600],
    },
    hoveredCell: {
        "& .MuiButtonBase-root": {
            boxShadow: "0 0 0px 1px ".concat(theme.palette.primary.main, " inset"),
        },
    },
    suppressCellHover: {
        "& .MuiButtonBase-root": {
            boxShadow: "none !important",
        },
    },
    cellHoverTextBorder: {
        boxShadow: "0 0 0px 1px ".concat(theme.palette.info.main, " inset"),
    },
    cellTypography: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        padding: "0 3px",
    },
}); });
exports.default = useStyles;
