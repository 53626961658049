"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var mediaQuerySmall = "@media (max-width: 750px)";
var mediaQuery = "@media (max-width: 900px)";
var extraSmallMQ = "@media (max-width: 600px)";
var useStyles = (0, mui_1.makeStyles)()(function (theme) {
    var _a, _b, _c, _d, _e, _f;
    return ({
        ChangePasswordPaper: {
            margin: "3rem auto",
            width: "80%",
            display: "flex",
            maxWidth: "1200px",
        },
        ChangePasswordTextField: {
            marginTop: "1rem auto",
            width: "90%",
        },
        TextFieldContainer: {
            display: "flex",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 5,
        },
        SignInButton: {
            marginTop: 15,
            width: "90%",
        },
        lottie: {
            width: "80%",
        },
        juristicLogo: {
            width: "50%",
            margin: "2rem",
            maxWidth: 250,
        },
        infoBox: (_a = {
                flex: 1,
                background: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: 15
            },
            _a[mediaQuerySmall] = {
                display: "none",
            },
            _a),
        signUpBox: { flex: 2, paddingTop: 80, paddingBottom: 80 },
        checkTextBox: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 5,
            paddingTop: 5,
        },
        showPasswordBtn: {},
        showPasswordIcon: {
            color: theme.palette.text.secondary,
        },
        inputStyle: {
            margin: "0 0.5rem",
            border: "1px solid ".concat(theme.palette.grey[300]),
            borderRadius: "4px",
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            "&:active": {
                border: "1px solid ".concat(theme.palette.grey[500]),
            },
            "&:focus": {
                border: "1px solid ".concat(theme.palette.grey[500]),
            },
        },
        flexRow: (_b = {
                display: "flex",
                flexDirection: "row"
            },
            _b[mediaQuery] = {
                flexDirection: "column",
            },
            _b),
        secondaryText: {
            color: theme.palette.text.secondary,
        },
        headerBox: {
            margin: "3rem auto",
        },
        OTPPaper: {
            margin: "3rem auto",
            padding: "2rem 2rem 1rem 2rem",
            width: "80%",
            maxWidth: "1000px",
            minWidth: "350px",
        },
        boxFlexEnd: (_c = {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "1rem",
                marginTop: "1rem"
            },
            _c[extraSmallMQ] = {
                flexDirection: "column-reverse",
                alignItems: "stretch",
                gap: "5px",
            },
            _c),
        OtpLottie: (_d = {
                width: "30%",
                minWidth: "200px"
            },
            _d[mediaQuery] = {
                width: "50%",
                alignSelf: "center",
            },
            _d),
        suffix: {
            marginLeft: "2px",
            backgroundColor: "#f4f4f4",
            padding: "3px",
            borderRadius: "3px",
        },
        signInLink: (_e = {
                flex: 2
            },
            _e[extraSmallMQ] = {
                flex: 0,
                alignSelf: "center",
            },
            _e),
        flexEnd: (_f = {
                alignSelf: "flex-end"
            },
            _f[extraSmallMQ] = {
                alignSelf: "auto",
            },
            _f),
        invalidPasswordPopper: {
            background: theme.palette.background.paper,
        },
    });
});
exports.default = useStyles;
