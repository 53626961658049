"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
var workspaceActions_http_1 = require("@pages/Workspaces/reducers/workspaceActions.http");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var socket_io_client_1 = require("socket.io-client");
var notificationActions_1 = require("@redux/actions/notificationActions");
var workspaceConstants_1 = require("@pages/Workspaces/reducers/workspaceConstants");
var redux_1 = require("@hooks/redux");
var constants_1 = require("@api/constants");
var useCvr = function (t, workspaceTabId, stepId, setShowInternationalDisclaimer, customer) {
    var dispatch = (0, redux_1.useAppDispatch)();
    var location = (0, react_router_dom_1.useLocation)();
    var _a = (0, react_1.useState)(null), company = _a[0], setCompany = _a[1];
    var _b = (0, react_1.useState)(customer === constants_1.CUSTOMER.SIMONSEN_VOGT_WIIG), shouldUseCvr = _b[0], setShouldUseCvr = _b[1];
    var _c = (0, react_1.useState)(customer === constants_1.CUSTOMER.SIMONSEN_VOGT_WIIG), shouldUseFlag = _c[0], setShouldUseFlag = _c[1];
    var handleChangeCompany = (0, react_1.useCallback)(function (v) { return setCompany(v); }, []);
    var handleUseCvr = (0, react_1.useCallback)(function () { return setShouldUseCvr(function (prev) { return !prev; }); }, []);
    var handleUseFlag = (0, react_1.useCallback)(function () { return setShouldUseFlag(function (prev) { return !prev; }); }, []);
    var clearCompany = (0, react_1.useCallback)(function () { return setCompany(null); }, []);
    var hasInternationalDisclaimer = (0, redux_1.useAppSelector)(function (state) { return state.workspace.showInternationalDisclaimer; });
    var _d = (0, react_1.useState)(undefined), subscription = _d[0], setSubscription = _d[1];
    var handleCvrSuccess = (0, react_1.useCallback)(function (nodes) {
        var shouldShowInternationalDisclaimer = nodes.some(function (n) { return "data_provider" in n.data && n.data.data_provider === "firmnav"; });
        if (hasInternationalDisclaimer && shouldShowInternationalDisclaimer) {
            setShowInternationalDisclaimer(true);
        }
        setTimeout(function () {
            var _a;
            (_a = document.getElementById("fitView")) === null || _a === void 0 ? void 0 : _a.click();
        }, 200);
    }, [hasInternationalDisclaimer]);
    var handleCvrError = (0, react_1.useCallback)(function () {
        dispatch((0, notificationActions_1.showNotification)("error", t("workspaces.drawing_error")));
        dispatch(workspaceActions_http_1.stopLoading);
    }, []);
    var handleCvrImport = (0, react_1.useCallback)(function (onSuccess) {
        if (!company) {
            return;
        }
        if (!subscription) {
            var sub = (0, workspaceActions_websocket_1.socketInstance)();
            if (sub instanceof socket_io_client_1.Socket) {
                setSubscription(sub);
            }
        }
        var modifiedCvrSuccess = function (nodes) {
            if (onSuccess) {
                onSuccess();
            }
            handleCvrSuccess(nodes);
        };
        dispatch((0, workspaceActions_websocket_1.importFromCVR)(workspaceTabId, company.value, stepId, shouldUseCvr, shouldUseFlag, [], modifiedCvrSuccess, handleCvrError));
    }, [workspaceTabId, stepId, shouldUseCvr, shouldUseFlag, company]);
    var _e = (0, react_1.useState)(0), tries = _e[0], setTries = _e[1];
    (0, react_1.useEffect)(function () {
        var sub = (0, workspaceActions_websocket_1.socketInstance)();
        var urlParams = new URLSearchParams(window.location.search);
        var posCvr = urlParams.get("cvr");
        var _shouldUseCvr = urlParams.get("shouldUseCvr") === "true";
        var _shouldUseFlag = urlParams.get("shouldUseFlag") === "true";
        var exp = urlParams.get("expandedCompanies");
        var expandedCompanies = [];
        if (exp && exp.trim().length > 0) {
            expandedCompanies = exp.split(",");
        }
        var shouldLoadCvr = posCvr && location.pathname.includes("/workspaces") && workspaceTabId;
        if (shouldLoadCvr) {
            dispatch({ type: workspaceConstants_1.GET_CVR_NODES_LOADING });
            if (sub instanceof socket_io_client_1.Socket && sub.connected) {
                setSubscription(sub);
                if (shouldLoadCvr) {
                    window.history.replaceState(null, "", window.location.pathname);
                    dispatch((0, workspaceActions_websocket_1.importFromCVR)(workspaceTabId, posCvr, stepId, _shouldUseCvr, _shouldUseFlag, expandedCompanies, handleCvrSuccess, handleCvrError));
                }
            }
            else if (tries < 5) {
                setTimeout(function () {
                    setTries(function (prev) { return prev + 1; });
                }, 1500);
            }
            else {
                dispatch({ type: workspaceConstants_1.GET_CVR_NODES_FAILED });
            }
        }
        else if (sub instanceof socket_io_client_1.Socket) {
            setSubscription(sub);
        }
    }, [workspaceTabId, stepId, tries]);
    return {
        subscription: subscription,
        handleCvrImport: handleCvrImport,
        shouldUseCvr: shouldUseCvr,
        handleUseCvr: handleUseCvr,
        shouldUseFlag: shouldUseFlag,
        handleUseFlag: handleUseFlag,
        clearCompany: clearCompany,
        company: company,
        handleChangeCompany: handleChangeCompany,
    };
};
exports.default = useCvr;
