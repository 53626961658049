"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initLabelColor = void 0;
var relationshipConstants_1 = require("./relationshipConstants");
var reactFlow_1 = require("@customTypes/reactFlow");
var converters_1 = require("@helpers/colors/converters");
var constants_1 = require("@api/constants");
var folderConstants_1 = require("@redux/constants/folderConstants");
exports.initLabelColor = {
    r: 0,
    g: 0,
    b: 0,
    a: 1,
};
var initialState = {
    relationships: [],
    label: "",
    values: [],
    description: "",
    notes: "",
    groupOrLawsuit: "",
    size: "Medium",
    color: exports.initLabelColor,
    useSuggestions: true,
    type: reactFlow_1.EdgeVariants.CUSTOM,
    showArrow: false,
    animated: false,
    lineThrough: false,
    hideValue: false,
    hideLabel: false,
    fontSize: 10,
    fontFamily: "Open Sans",
    fontColor: "#000000",
    labelBackgroundColor: (0, converters_1.hexToRgba)("#F3F5F8"),
    relationshipsDescendants: [],
    deletingFolder: 0,
    loadings: constants_1.initialLoadings,
    documents: [],
    documentModalOpen: false,
    curveScalar: 0.5,
    offsetBearing: null,
};
function reducer(state, action) {
    var _a, _b, _c;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case relationshipConstants_1.RELATIONSHIP_DUPLICATE_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case relationshipConstants_1.GET_RELATIONSHIPS_SUCCESS:
            return __assign(__assign({}, state), { relationships: action.relationships });
        case relationshipConstants_1.GET_RELATIONSHIPS_FAILED:
        case relationshipConstants_1.POST_RELATIONSHIP_FAILED:
        case relationshipConstants_1.SHOW_RELATIONSHIP_FAILED:
        case relationshipConstants_1.PUT_RELATIONSHIP_SUCCESS:
        case relationshipConstants_1.PUT_RELATIONSHIP_FAILED:
        case relationshipConstants_1.DELETE_RELATIONSHIP_FAILED:
        case relationshipConstants_1.FORCE_DELETE_RELATIONSHIP_FAILED:
        case relationshipConstants_1.GET_RELATIONSHIP_DESCENDANTS_FAILED:
        case relationshipConstants_1.RELATIONSHIP_DUPLICATE_FAILED:
            return state;
        case relationshipConstants_1.POST_RELATIONSHIP_SUCCESS:
            return __assign(__assign({}, state), { label: "", values: [], description: "", type: reactFlow_1.EdgeVariants.CUSTOM, groupOrLawsuit: "", size: "Medium", color: exports.initLabelColor });
        case relationshipConstants_1.SHOW_RELATIONSHIP_SUCCESS:
            return __assign(__assign({}, state), { label: action.label, values: action.values, description: action.description, type: action.edgeType, groupOrLawsuit: action.groupOrLawsuit, color: action.color, labelBackgroundColor: action.labelBackgroundColor, useSuggestions: Boolean(action.useSuggestions), showArrow: Boolean(action.showArrow), animated: Boolean(action.animated), lineThrough: Boolean(action.lineThrough), hideValue: Boolean(action.hideValue), hideLabel: Boolean(action.hideLabel), curveScalar: action.curveScalar, offsetBearing: action.offsetBearing, fontSize: action.fontSize, fontFamily: action.fontFamily, fontColor: action.fontColor });
        case relationshipConstants_1.LABEL_CHANGE:
            return __assign(__assign({}, state), { label: action.label });
        case relationshipConstants_1.DESCRIPTION_CHANGE:
            return __assign(__assign({}, state), { description: action.description });
        case relationshipConstants_1.NOTES_CHANGE:
            return __assign(__assign({}, state), { notes: action.notes });
        case relationshipConstants_1.VALUES_CHANGE:
            return __assign(__assign({}, state), { values: action.values });
        case relationshipConstants_1.CHANGE_SIZE:
            return __assign(__assign({}, state), { size: action.size });
        case relationshipConstants_1.CHANGE_COLOR:
            return __assign(__assign({}, state), { color: action.color });
        case relationshipConstants_1.CHANGE_LABEL_BACKGROUND_COLOR:
            return __assign(__assign({}, state), { labelBackgroundColor: action.color });
        case relationshipConstants_1.CHANGE_USE_SUGGESTION:
            return __assign(__assign({}, state), { useSuggestions: action.value });
        case relationshipConstants_1.CHANGE_TYPE: {
            return __assign(__assign({}, state), { type: action.edgeType });
        }
        case relationshipConstants_1.TOGGLE_ARROW: {
            return __assign(__assign({}, state), { showArrow: !state.showArrow });
        }
        case relationshipConstants_1.TOGGLE_ANIMATED: {
            return __assign(__assign({}, state), { animated: !state.animated });
        }
        case relationshipConstants_1.TOGGLE_LINE_THROUGH: {
            return __assign(__assign({}, state), { lineThrough: !state.lineThrough });
        }
        case relationshipConstants_1.TOGGLE_HIDE_VALUE: {
            return __assign(__assign({}, state), { hideValue: !state.hideValue });
        }
        case relationshipConstants_1.TOGGLE_HIDE_LABEL: {
            return __assign(__assign({}, state), { hideLabel: !state.hideLabel });
        }
        case relationshipConstants_1.DELETE_RELATIONSHIP_SUCCESS: {
            return __assign(__assign({}, state), { relationshipsDescendants: action.relationshipsDescendants });
        }
        case relationshipConstants_1.GET_RELATIONSHIP_DESCENDANTS_SUCCESS: {
            return __assign(__assign({}, state), { relationshipsDescendants: action.relationshipsDescendants });
        }
        case relationshipConstants_1.CLEAR_RELATIONSHIP_DESCENDANTS:
            return __assign(__assign({}, state), { relationshipsDescendants: initialState.relationshipsDescendants });
        case relationshipConstants_1.FORCE_DELETE_RELATIONSHIP_SUCCESS: {
            return __assign(__assign({}, state), { relationships: state.relationships.filter(function (relationship) { return relationship.id !== action.id; }) });
        }
        case relationshipConstants_1.RELATIONSHIP_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { relationships: action.relationship
                    ? __spreadArray(__spreadArray([], state.relationships, true), [action.relationship], false) : state.relationships, loadings: constants_1.initialLoadings });
        case relationshipConstants_1.TOGGLE_DOCUMENT_MODAL:
            return __assign(__assign({}, state), { documentModalOpen: action.open });
        case relationshipConstants_1.DOCUMENTS_CHANGE:
            return __assign(__assign({}, state), { documents: action.documents });
        case relationshipConstants_1.FONT_SIZE_CHANGE:
            return __assign(__assign({}, state), { fontSize: action.fontSize });
        case relationshipConstants_1.FONT_FAMILY_CHANGE:
            return __assign(__assign({}, state), { fontFamily: action.fontFamily });
        case relationshipConstants_1.FONT_COLOR_CHANGE:
            return __assign(__assign({}, state), { fontColor: action.fontColor });
        case relationshipConstants_1.POST_DOCUMENT_SUCCESS:
            return __assign(__assign({}, state), { documents: state.documents.map(function (documentId) {
                    if (documentId === 0) {
                        documentId = action.id;
                    }
                    return documentId;
                }), loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.moveElementToDestroyedParentFailed)("relationships"):
        case (0, folderConstants_1.postFolderFailed)("relationships"):
        case (0, folderConstants_1.putFolderTitleFailed)("relationships"):
        case (0, folderConstants_1.putFolderLocationFailed)("relationships"):
        case (0, folderConstants_1.putFolderElementLocationFailed)("relationships"):
        case (0, folderConstants_1.duplicateFolderFailed)("relationships"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.deleteFolderFailed)("relationships"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.deleteFolderLoading)("relationships"):
            return __assign(__assign({}, state), { deletingFolder: 0, loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadings] = true, _b)) });
        case (0, folderConstants_1.moveElementToDestroyedParentLoading)("relationships"):
        case (0, folderConstants_1.putFolderLocationLoading)("relationships"):
        case (0, folderConstants_1.putFolderElementLocationLoading)("relationships"):
        case (0, folderConstants_1.putFolderTitleLoading)("relationships"):
        case (0, folderConstants_1.postFolderLoading)("relationships"):
        case (0, folderConstants_1.duplicateFolderLoading)("relationships"):
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_c = {}, _c[action.loadings] = true, _c)) });
        case (0, folderConstants_1.postFolderSuccess)("relationships"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, relationships: __spreadArray([action.folder], state.relationships, true) });
        case (0, folderConstants_1.putFolderTitleSuccess)("relationships"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, relationships: state.relationships.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderLocationSuccess)("relationships"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, relationships: state.relationships.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderElementLocationSuccess)("relationships"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, relationships: state.relationships.map(function (c) {
                    if (!c.isFolder && c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.deleteFolderSuccess)("relationships"): {
            if (action.descendants && action.descendants.length > 0) {
                return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, deletingFolder: action.folder.id, relationshipsDescendants: action.descendants });
            }
            if (action.deleteChildren) {
                return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, deletingFolder: 0, relationships: state.relationships
                        .filter(function (c) { return c.id !== action.folder.id; })
                        .filter(function (c) { return c.folderId !== action.folder.id; }) });
            }
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, deletingFolder: 0, relationships: state.relationships
                    .filter(function (c) { return c.id !== action.folder.id; })
                    .map(function (c) {
                    return c.folderId === action.folder.id
                        ? __assign(__assign({}, c), { folderId: action.folder.folderId }) : c;
                }) });
        }
        case (0, folderConstants_1.moveElementToDestroyedParentSuccess)("relationships"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, relationships: state.relationships.map(function (c) {
                    if (c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.duplicateFolderSuccess)("relationships"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, relationships: __spreadArray(__spreadArray([], action.folder, true), state.relationships, true) });
        default:
            return state;
    }
}
exports.default = reducer;
