"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBezierPathInitiateDragHandles = exports.getBezierPathDragHandles = exports.customGetBezierPath = exports.divideBezierCurve = exports.getBezierPathKnotPositions = exports.calcBezierPath = exports.computeControlPoints = exports.splitBezierPath = exports.getControlWithCurvature = exports.calculateControlOffset = exports.extractPointsFromBezierPath = exports.DEFAULT_CURVATURE = void 0;
var reactflow_1 = require("reactflow");
var pathFunctions_1 = require("./pathFunctions");
exports.DEFAULT_CURVATURE = 0.25;
var extractPointsFromBezierPath = function (path) {
    // Split the input string into individual values
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    var values = path.match(/-?\d+(\.\d+)?/g).map(Number);
    // Extract control points and end point
    var p0 = { x: values[0], y: values[1] };
    var p1 = { x: values[2], y: values[3] };
    var p2 = { x: values[4], y: values[5] };
    var p3 = { x: values[6], y: values[7] };
    return { p0: p0, p1: p1, p2: p2, p3: p3 };
};
exports.extractPointsFromBezierPath = extractPointsFromBezierPath;
var calculateControlOffset = function (distance, curvature) {
    if (distance >= 0) {
        return 0.5 * distance;
    }
    return curvature * 25 * Math.sqrt(-distance);
};
exports.calculateControlOffset = calculateControlOffset;
var getControlWithCurvature = function (_a) {
    var pos = _a.pos, x1 = _a.x1, y1 = _a.y1, x2 = _a.x2, y2 = _a.y2, c = _a.c, otherPos = _a.otherPos;
    var ctX;
    var ctY;
    // eslint-disable-next-line default-case
    switch (pos) {
        case reactflow_1.Position.Left:
            ctX =
                x1 -
                    (pos === otherPos
                        ? (0, exports.calculateControlOffset)(y1 - y2, c) / 2
                        : (0, exports.calculateControlOffset)(x1 - x2, c));
            ctY = y1;
            break;
        case reactflow_1.Position.Right:
            ctX =
                x1 +
                    (pos === otherPos
                        ? (0, exports.calculateControlOffset)(y2 - y1, c) / 2
                        : (0, exports.calculateControlOffset)(x2 - x1, c));
            ctY = y1;
            break;
        case reactflow_1.Position.Top:
            ctX = x1;
            ctY =
                y1 -
                    (pos === otherPos
                        ? (0, exports.calculateControlOffset)(x1 - x2, c) / 2
                        : (0, exports.calculateControlOffset)(y1 - y2, c));
            break;
        case reactflow_1.Position.Bottom:
            ctX = x1;
            ctY =
                y1 +
                    (pos === otherPos
                        ? (0, exports.calculateControlOffset)(x2 - x1, c) / 2
                        : (0, exports.calculateControlOffset)(y2 - y1, c));
            break;
    }
    return [ctX, ctY];
};
exports.getControlWithCurvature = getControlWithCurvature;
var splitBezierPath = function (path) { return path.split(/(?=M)/); };
exports.splitBezierPath = splitBezierPath;
var computeControlPoints = function (K) {
    var p1 = [];
    var p2 = [];
    var n = K.length - 1;
    /* rhs vector */
    var a = [];
    var b = [];
    var c = [];
    var r = [];
    /* left most segment */
    a[0] = 0;
    b[0] = 2;
    c[0] = 1;
    r[0] = K[0] + 2 * K[1];
    /* internal segments */
    for (var i = 1; i < n - 1; i++) {
        a[i] = 1;
        b[i] = 4;
        c[i] = 1;
        r[i] = 4 * K[i] + 2 * K[i + 1];
    }
    /* right segment */
    a[n - 1] = 2;
    b[n - 1] = 7;
    c[n - 1] = 0;
    r[n - 1] = 8 * K[n - 1] + K[n];
    /* solves Ax=b with the Thomas algorithm (from Wikipedia) */
    for (var i = 1; i < n; i++) {
        var m = a[i] / b[i - 1];
        b[i] -= m * c[i - 1];
        r[i] -= m * r[i - 1];
    }
    p1[n - 1] = r[n - 1] / b[n - 1];
    for (var i = n - 2; i >= 0; --i)
        p1[i] = (r[i] - c[i] * p1[i + 1]) / b[i];
    /* we have p1, now compute p2 */
    for (var i = 0; i < n - 1; i++)
        p2[i] = 2 * K[i + 1] - p1[i + 1];
    p2[n - 1] = 0.5 * (K[n] + p1[n - 1]);
    return { p1: p1, p2: p2 };
};
exports.computeControlPoints = computeControlPoints;
var bezierPointsToPath = function (_a) {
    var p0 = _a.p0, p1 = _a.p1, p2 = _a.p2, p3 = _a.p3;
    return "M ".concat(p0.x, " ").concat(p0.y, " C ").concat(p1.x, " ").concat(p1.y, ", ").concat(p2.x, " ").concat(p2.y, ", ").concat(p3.x, " ").concat(p3.y);
};
var calcBezierPath = function (knots, sourcePosition, targetPosition, curvature) {
    if (curvature === void 0) { curvature = exports.DEFAULT_CURVATURE; }
    var bezierPathCount = knots.length - 1;
    /* grab (x,y) coordinates of the control points */
    var x = [];
    var y = [];
    for (var i = 0; i < knots.length; i++) {
        /* use parseInt to convert string to int */
        x[i] = knots[i].x;
        y[i] = knots[i].y;
    }
    /* computes control points p1 and p2 for x and y direction */
    var px = (0, exports.computeControlPoints)(x);
    var py = (0, exports.computeControlPoints)(y);
    /* updates path settings, the browser will draw the new spline */
    var totalPath = [];
    for (var i = 0; i < bezierPathCount; i++) {
        if (i === 0) {
            var _a = (0, exports.getControlWithCurvature)({
                pos: sourcePosition,
                x1: x[i],
                y1: y[i],
                x2: x[i + 1],
                y2: y[i + 1],
                c: curvature,
                otherPos: targetPosition,
            }), sourceControlX = _a[0], sourceControlY = _a[1];
            px.p1[i] = sourceControlX;
            py.p1[i] = sourceControlY;
        }
        if (i === bezierPathCount - 1) {
            var _b = (0, exports.getControlWithCurvature)({
                pos: targetPosition,
                x1: x[i + 1],
                y1: y[i + 1],
                x2: x[i],
                y2: y[i],
                c: curvature,
                otherPos: sourcePosition,
            }), targetControlX = _b[0], targetControlY = _b[1];
            px.p2[i] = targetControlX;
            py.p2[i] = targetControlY;
        }
        var newPath = bezierPointsToPath({
            p0: { x: x[i], y: y[i] },
            p1: { x: px.p1[i], y: py.p1[i] },
            p2: { x: px.p2[i], y: py.p2[i] },
            p3: { x: x[i + 1], y: y[i + 1] },
        });
        totalPath.push(newPath);
    }
    return totalPath.join(" ");
};
exports.calcBezierPath = calcBezierPath;
var getBezierPathKnotPositions = function (path) {
    var bezierPaths = (0, exports.splitBezierPath)(path);
    var newPathKnotPositions = [];
    for (var i = 0; i < bezierPaths.length; i++) {
        var bezierPath = bezierPaths[i];
        var bezierPathPoints = (0, exports.extractPointsFromBezierPath)(bezierPath);
        if (i === 0) {
            newPathKnotPositions.push(bezierPathPoints.p0);
        }
        newPathKnotPositions.push(bezierPathPoints.p3);
    }
    return newPathKnotPositions;
};
exports.getBezierPathKnotPositions = getBezierPathKnotPositions;
var lerp = function (a, b, t) {
    var s = 1 - t;
    return { x: a.x * s + b.x * t, y: a.y * s + b.y * t };
};
var divideBezierCurve = function (_a) {
    var p0 = _a.p0, p1 = _a.p1, p2 = _a.p2, p3 = _a.p3;
    var t = 0.5;
    var p4 = lerp(p0, p1, t);
    var p5 = lerp(p1, p2, t);
    var p6 = lerp(p2, p3, t);
    var p7 = lerp(p4, p5, t);
    var p8 = lerp(p5, p6, t);
    var p9 = lerp(p7, p8, t);
    var firstHalf = bezierPointsToPath({ p0: p0, p1: p4, p2: p7, p3: p9 });
    var secondHalf = bezierPointsToPath({ p0: p9, p1: p8, p2: p6, p3: p3 });
    return "".concat(firstHalf, " ").concat(secondHalf);
};
exports.divideBezierCurve = divideBezierCurve;
function customGetBezierPath(_a) {
    var sourceX = _a.sourceX, sourceY = _a.sourceY, _b = _a.sourcePosition, sourcePosition = _b === void 0 ? reactflow_1.Position.Bottom : _b, targetX = _a.targetX, targetY = _a.targetY, _c = _a.targetPosition, targetPosition = _c === void 0 ? reactflow_1.Position.Top : _c, curveScalar = _a.curveScalar, _d = _a.curvature, curvature = _d === void 0 ? exports.DEFAULT_CURVATURE : _d, pathKnots = _a.pathKnots, bearing = _a.bearing;
    var sourcePoint = { x: sourceX, y: sourceY };
    var targetPoint = { x: targetX, y: targetY };
    var pathKnotPositions = (0, pathFunctions_1.getAbsoluteKnots)(pathKnots || [], sourcePoint, targetPoint);
    var path = (0, exports.calcBezierPath)(pathKnotPositions, sourcePosition, targetPosition, curvature);
    var labelPoint = (0, pathFunctions_1.calcPointFromStringPath)(path, curveScalar || 0.5);
    var toolbarPosition = (0, pathFunctions_1.getToolbarPosition)(pathKnotPositions, labelPoint, bearing !== undefined ? bearing : null);
    var labelX = labelPoint.x, labelY = labelPoint.y;
    return [path, labelX, labelY, toolbarPosition];
}
exports.customGetBezierPath = customGetBezierPath;
var getBezierPathDragHandles = function (path) {
    var bezierPaths = (0, exports.splitBezierPath)(path);
    var newPathDragHandles = [];
    for (var i = 1; i < bezierPaths.length; i++) {
        var firstHalfBezier = bezierPaths[i];
        var firstHalfBezierPoints = (0, exports.extractPointsFromBezierPath)(firstHalfBezier);
        newPathDragHandles.push(firstHalfBezierPoints.p0);
    }
    return newPathDragHandles;
};
exports.getBezierPathDragHandles = getBezierPathDragHandles;
var getBezierPathInitiateDragHandles = function (path) {
    var bezierPaths = (0, exports.splitBezierPath)(path);
    // find middle point of each bezier curve
    var newPathInitiateDragHandles = [];
    for (var i = 0; i < bezierPaths.length; i++) {
        var bezierPath = bezierPaths[i];
        var bezierPathElement = document.createElementNS("http://www.w3.org/2000/svg", "path");
        bezierPathElement.setAttribute("d", bezierPath);
        var point = (0, pathFunctions_1.getPointFromT)(bezierPathElement, 0.5);
        bezierPathElement.remove();
        newPathInitiateDragHandles.push(point);
    }
    return newPathInitiateDragHandles;
};
exports.getBezierPathInitiateDragHandles = getBezierPathInitiateDragHandles;
