"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createClient = void 0;
/* eslint-disable no-undef-init */
var socket_io_client_1 = require("socket.io-client");
function createClient(socket) {
    return function io(url, config) {
        if (!socket) {
            socket = (0, socket_io_client_1.io)(url, __assign(__assign({}, config), { path: "/websocket", transports: ["websocket"] }));
        }
        if (!socket.connected) {
            socket.connect();
        }
        return socket;
    };
}
exports.createClient = createClient;
