"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var PPTXNodeUtils_1 = require("@components/Workspace/Node/utils/PPTXNodeUtils");
var react_1 = require("react");
var useFitToScreen = function (reactFlowInstance, customFitScreen) {
    var fitToScreen = (0, react_1.useCallback)(function () {
        if (customFitScreen) {
            customFitScreen();
        }
        else {
            var hasPPTXNode = !!(reactFlowInstance === null || reactFlowInstance === void 0 ? void 0 : reactFlowInstance.getNode(PPTXNodeUtils_1.PPTX_VIEW_NODE_ID));
            reactFlowInstance === null || reactFlowInstance === void 0 ? void 0 : reactFlowInstance.fitView(__assign({ duration: 500, padding: 0.5 }, (hasPPTXNode ? { nodes: [{ id: PPTXNodeUtils_1.PPTX_VIEW_NODE_ID }] } : {})));
        }
    }, [customFitScreen, reactFlowInstance]);
    return [fitToScreen];
};
exports.default = useFitToScreen;
