"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.onUpdateDateRangeAggregatorWidth = exports.onUpdateDateAggregatorWidth = exports.onUpdateNumberAggregatorWidth = exports.onUpdateBoardColumnWidth = exports.onUpdateBoardColumnIndex = exports.onRemoveColumn = exports.onChangeColumnDescription = exports.onChangeColumnTitle = exports.onPostColumn = void 0;
var types = __importStar(require("../../columnConstants"));
function onPostColumn() {
    return function (dispatch) { return function (column) {
        dispatch({
            type: types.POST_COLUMN_SUCCESS,
            column: column,
        });
    }; };
}
exports.onPostColumn = onPostColumn;
function onChangeColumnTitle() {
    return function (dispatch) { return function (column) {
        dispatch({
            type: types.CHANGE_COLUMN_TITLE_SUCCESS,
            column: column,
        });
    }; };
}
exports.onChangeColumnTitle = onChangeColumnTitle;
function onChangeColumnDescription() {
    return function (dispatch) { return function (column) {
        dispatch({
            type: types.CHANGE_COLUMN_DESCRIPTION_SUCCESS,
            column: column,
        });
    }; };
}
exports.onChangeColumnDescription = onChangeColumnDescription;
function onRemoveColumn() {
    return function (dispatch) { return function (columnId) {
        dispatch({
            type: types.DELETE_COLUMN_SUCCESS,
            columnId: columnId,
        });
    }; };
}
exports.onRemoveColumn = onRemoveColumn;
function onUpdateBoardColumnIndex() {
    return function (dispatch) {
        return function (_a) {
            var boardColumnId = _a.boardColumnId, from = _a.from, to = _a.to;
            dispatch({
                type: types.UPDATE_COLUMN_INDEX_SUCCESS,
                boardColumnId: boardColumnId,
                to: to,
                from: from,
            });
        };
    };
}
exports.onUpdateBoardColumnIndex = onUpdateBoardColumnIndex;
function onUpdateBoardColumnWidth() {
    return function (dispatch) {
        return function (_a) {
            var boardColumnId = _a.boardColumnId, width = _a.width;
            dispatch({
                type: types.UPDATE_COLUMN_WIDTH_SUCCESS,
                boardColumnId: boardColumnId,
                width: width,
            });
        };
    };
}
exports.onUpdateBoardColumnWidth = onUpdateBoardColumnWidth;
function onUpdateNumberAggregatorWidth() {
    return function (dispatch) {
        return function (_a) {
            var boardColumnId = _a.boardColumnId, aggregate = _a.aggregate, direction = _a.direction, unit = _a.unit, customUnit = _a.customUnit;
            var aggregator = {
                aggregate: aggregate,
                direction: direction,
                unit: unit,
                customUnit: customUnit,
            };
            dispatch({
                type: types.UPDATE_NUMBER_AGGREGATOR_SUCCESS,
                boardColumnId: boardColumnId,
                aggregator: aggregator,
            });
        };
    };
}
exports.onUpdateNumberAggregatorWidth = onUpdateNumberAggregatorWidth;
function onUpdateDateAggregatorWidth() {
    return function (dispatch) {
        return function (_a) {
            var boardColumnId = _a.boardColumnId, type = _a.type;
            dispatch({
                type: types.UPDATE_DATE_AGGREGATOR_SUCCESS,
                boardColumnId: boardColumnId,
                aggregator: type,
            });
        };
    };
}
exports.onUpdateDateAggregatorWidth = onUpdateDateAggregatorWidth;
function onUpdateDateRangeAggregatorWidth() {
    return function (dispatch) {
        return function (_a) {
            var boardColumnId = _a.boardColumnId, type = _a.type;
            dispatch({
                type: types.UPDATE_DATE_RANGE_AGGREGATOR_SUCCESS,
                boardColumnId: boardColumnId,
                aggregator: type,
            });
        };
    };
}
exports.onUpdateDateRangeAggregatorWidth = onUpdateDateRangeAggregatorWidth;
