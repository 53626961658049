"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var reactFlow_1 = require("@customTypes/reactFlow");
var react_1 = require("react");
var useCustomFit = function (rfInstance, reactFlowContainer, rightPanelRef) {
    var customFitScreen = (0, react_1.useCallback)(function (zoomAction) {
        if (rfInstance && reactFlowContainer.current && rightPanelRef.current) {
            var _a = reactFlowContainer.current.getBoundingClientRect(), viewportWidth = _a.width, viewportHeight = _a.height;
            var beforeFitZoom = rfInstance.getZoom();
            rfInstance.fitView({ maxZoom: 1.5 });
            var afterFitZoom = rfInstance.getZoom();
            var rightPanelWidth = rightPanelRef.current.getBoundingClientRect().width;
            rfInstance.setViewport({
                x: (viewportWidth - rightPanelWidth) / 2 - 5,
                y: viewportHeight / 2,
                zoom: !zoomAction
                    ? afterFitZoom
                    : zoomAction === reactFlow_1.Zoom.IN
                        ? beforeFitZoom * 1.2
                        : beforeFitZoom * 0.8,
            });
        }
    }, [rfInstance, reactFlowContainer, rightPanelRef]);
    return { customFitScreen: customFitScreen };
};
exports.default = useCustomFit;
