"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pasteUndoPayload = exports.edgesHandleMoveUndo = exports.newEdgesUndoPayload = exports.newNodesUndoPayload = void 0;
function newNodesUndoPayload(nodes) {
    return {
        variant: "element",
        action: "ELEMENT_CREATE",
        changes: nodes.map(function (node) { return ({ id: node.data.id, type: node.type }); }),
    };
}
exports.newNodesUndoPayload = newNodesUndoPayload;
function newEdgesUndoPayload(edges) {
    return {
        variant: "edge",
        action: "EDGE_CREATE",
        changes: edges.map(function (edge) { return ({ id: edge.data.id }); }),
    };
}
exports.newEdgesUndoPayload = newEdgesUndoPayload;
function edgesHandleMoveUndo(edges) {
    return {
        variant: "edge",
        action: "EDGE_HANDLE_MOVE",
        changes: edges.map(function (edge) { return ({ id: edge.data.id }); }),
    };
}
exports.edgesHandleMoveUndo = edgesHandleMoveUndo;
function pasteUndoPayload(nodes, edges) {
    return {
        variant: "combination",
        action: "PASTE",
        changes: [
            {
                action: "PASTE",
                nodes: nodes.map(function (n) { return ({ id: n.data.id, type: n.type }); }),
                edges: edges.map(function (e) { return ({ id: e.data.id }); }),
            },
        ],
    };
}
exports.pasteUndoPayload = pasteUndoPayload;
