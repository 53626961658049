"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.comparisonTypeToSymbol = exports.ComparisonTypes = void 0;
var ComparisonTypes;
(function (ComparisonTypes) {
    ComparisonTypes["IS_EQUAL_TO"] = "is equal to";
    ComparisonTypes["IS_NOT_EQUAL_TO"] = "is not equal to";
    ComparisonTypes["IS_GREATER_THAN"] = "is greater than";
    ComparisonTypes["IS_LESS_THAN"] = "is less than";
    ComparisonTypes["EXISTS"] = "exists";
    ComparisonTypes["DOES_NOT_EXIST"] = "does not exist";
    ComparisonTypes["CONTAINS"] = "contains";
    ComparisonTypes["DOES_NOT_CONTAIN"] = "does not contain";
    ComparisonTypes["ALL"] = "all";
})(ComparisonTypes = exports.ComparisonTypes || (exports.ComparisonTypes = {}));
var comparisonTypeToSymbol = function (type) {
    switch (type) {
        case ComparisonTypes.IS_EQUAL_TO:
            return "=";
        case ComparisonTypes.IS_NOT_EQUAL_TO:
            return "≠";
        case ComparisonTypes.IS_GREATER_THAN:
            return ">";
        case ComparisonTypes.IS_LESS_THAN:
            return "<";
        case ComparisonTypes.EXISTS:
            return "∃";
        case ComparisonTypes.DOES_NOT_EXIST:
            return "∄";
        case ComparisonTypes.CONTAINS:
            return "⊆";
        case ComparisonTypes.DOES_NOT_CONTAIN:
            return "⊈";
        case ComparisonTypes.ALL:
            return "*";
        default:
            return "";
    }
};
exports.comparisonTypeToSymbol = comparisonTypeToSymbol;
