"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mui_1 = require("tss-react/mui");
var Colors;
(function (Colors) {
    Colors["Grey"] = "#e5e5e5";
    Colors["DarkGrey"] = "#464646";
})(Colors || (Colors = {}));
exports.default = (0, mui_1.makeStyles)()(function () { return ({
    root: {
        backgroundColor: "white",
        position: "relative",
    },
    standard: {
        width: "27.94cm",
        height: "21.59cm",
    },
    logo: {
        position: "absolute",
        height: "0.5in",
        top: "0.5in",
        left: "0.5in",
    },
    slideBody: {
        position: "absolute",
        top: "5.95cm",
        left: "1.27cm",
        height: "13.54cm",
        width: "25.4cm",
    },
    slideBodyTextLeft: {
        position: "absolute",
        top: "5.95cm",
        left: "1.27cm",
        height: "13.54cm",
        width: "12.5cm",
    },
    slideBodyTextRight: {
        position: "absolute",
        top: "5.95cm",
        left: "14.18cm",
        height: "13.54cm",
        width: "12.5cm",
    },
    greyBanner: {
        position: "absolute",
        top: "1.7cm",
        height: "3.85cm",
        width: "27.94cm",
        backgroundColor: Colors.Grey,
    },
    greyBannerText: {
        position: "absolute",
        left: "0.5in",
        top: "0.83in",
        height: "1.2in",
        width: "10in",
    },
    verticalGreyBanner: {
        position: "absolute",
        left: "-0.01cm",
        height: "21.59cm",
        width: "8.51cm",
        backgroundColor: Colors.Grey,
    },
    DarkGreyBackground: {
        backgroundColor: Colors.DarkGrey,
    },
    frameTitle: {
        position: "absolute",
        top: "0.9cm",
        left: "1.28cm",
        height: "0.38cm",
        width: "8.43cm",
        fontSize: "8pt",
    },
    footer: {
        position: "absolute",
        fontSize: "8pt",
    },
    upperLeftFooter: {
        height: "0.34cm",
        width: "8.18cm",
        top: "19.98cm",
        left: "1.27cm",
    },
    lowerLeftFooter: {
        height: "0.34cm",
        width: "8.18cm",
        top: "20.35cm",
        left: "1.27cm",
    },
    upperMiddleFooter: {
        height: "0.34cm",
        width: "8.18cm",
        top: "19.98cm",
        left: "11.38cm",
    },
    lowerMiddleFooter: {
        height: "0.34cm",
        width: "8.18cm",
        top: "20.35cm",
        left: "11.38cm",
    },
    upperRightFooter: {
        height: "0.34cm",
        width: "4.65cm",
        top: "19.98cm",
        left: "22.02cm",
        textAlign: "right",
    },
    lowerRightFooter: {
        height: "0.34cm",
        width: "4.65cm",
        top: "20.35cm",
        left: "22.02cm",
        textAlign: "right",
    },
    normalFont: {
        fontFamily: "Arial",
        lineHeight: "1.15",
        fontSize: "11pt",
    },
    // partner slide:
    partnerSection: {
        position: "absolute",
        top: "5.95cm",
        left: "1.27cm",
        height: "7.34cm",
        width: "6.38cm",
    },
    addressSection: {
        position: "absolute",
        top: "15cm",
        left: "1.27cm",
        height: "3.54cm",
        width: "6.38cm",
        display: "flex",
        alignItems: "flex-end",
    },
    partnerLogo: {
        position: "absolute",
        top: "1.5cm",
        left: "0.61cm",
        width: "5cm",
    },
    clientSection: {
        position: "absolute",
        top: "2.11cm",
        left: "9.45cm",
        height: "3.05cm",
        width: "7.43cm",
        display: "flex",
        alignItems: "flex-end",
    },
    partnerText: {
        position: "absolute",
        top: "5.95cm",
        left: "9.45cm",
        height: "11.95cm",
        width: "17.22cm",
    },
    partnerBottomText: {
        position: "absolute",
        top: "18.29cm",
        left: "9.45cm",
        height: "1.3cm",
        width: "17.22cm",
        display: "flex",
        alignItems: "flex-end",
    },
    // frontpage slide:
    backgroundOverlay: {
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
    verticalWhiteBanner: {
        position: "absolute",
        left: "1.28cm",
        height: "21.59cm",
        width: "0.56cm",
        backgroundColor: "#FFF",
    },
    frontLogo: {
        position: "absolute",
        top: "17.01cm",
        left: "1.83cm",
        width: "5.34cm",
    },
    frontTitle: {
        position: "absolute",
        top: "3.51cm",
        left: "2.51cm",
        height: "6.22cm",
        width: "16.87cm",
        display: "flex",
        alignItems: "flex-end",
        color: "#FFF",
        fontSize: "34pt",
    },
    frontSubtitle: {
        position: "absolute",
        top: "9.82cm",
        left: "2.54cm",
        height: "0.77cm",
        width: "16.87cm",
        color: "#FFF",
        fontSize: "18pt",
    },
    frontDate: {
        position: "absolute",
        top: "12.32cm",
        left: "2.54cm",
        height: "0.43cm",
        width: "16.87cm",
        color: "#FFF",
        fontSize: "10pt",
    },
    frontType: {
        position: "absolute",
        top: "12.78cm",
        left: "2.54cm",
        height: "0.49cm",
        width: "16.87cm",
        color: "#FFF",
        fontSize: "10pt",
    },
    frontFooter: {
        top: "19.22cm",
        left: "12.36cm",
        height: "0.34cm",
        width: "14.3cm",
        textAlign: "right",
        color: "#FFF",
        fontSize: "8pt",
    },
    // Splitter slide:
    splitterTitle: {
        position: "absolute",
        top: "4.89cm",
        left: "1.27cm",
        height: "6.96cm",
        width: "6.88cm",
    },
    splitterText: {
        position: "absolute",
        top: "5.17cm",
        left: "9.47cm",
        height: "6.96cm",
        width: "17.2cm",
    },
}); });
