"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function TimelineParamSwitch(_a) {
    var param = _a.param, rfInstance = _a.rfInstance;
    switch (param === null || param === void 0 ? void 0 : param.param) {
        case "GoToNode": {
            var data = param.data;
            var node_1 = rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.getNode("".concat(data.id));
            if (node_1) {
                setTimeout(function () {
                    rfInstance === null || rfInstance === void 0 ? void 0 : rfInstance.fitView({ nodes: [node_1], maxZoom: 2, duration: 500 });
                }, 200);
            }
            return null;
        }
        default: {
            return null;
        }
    }
}
exports.default = TimelineParamSwitch;
