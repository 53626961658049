"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var constants_1 = require("@api/constants");
var linkConstants_1 = require("./linkConstants");
var initialLink = {
    id: 0,
    title: "",
    url: "",
    urlMask: "",
    lawsuitId: 0,
    createdAt: "",
    updatedAt: "",
};
var initialState = {
    links: [],
    link: initialLink,
    loadings: constants_1.initialLoadings,
};
function reducer(state, action) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case linkConstants_1.LINK_INDEX_LOADING:
        case linkConstants_1.LINK_STORE_LOADING:
        case linkConstants_1.LINK_UPDATE_LOADING:
        case linkConstants_1.LINK_DELETE_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case linkConstants_1.LINK_INDEX_FAILED:
        case linkConstants_1.LINK_STORE_FAILED:
        case linkConstants_1.LINK_UPDATE_FAILED:
        case linkConstants_1.LINK_DELETE_FAILED:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadingType] = false, _b)) });
        case linkConstants_1.LINK_INDEX_SUCCESS:
            return __assign(__assign({}, state), { links: action.links, loadings: __assign(__assign({}, state.loadings), (_c = {}, _c[action.loadingType] = false, _c)) });
        case linkConstants_1.LINK_STORE_SUCCESS:
            return __assign(__assign({}, state), { links: __spreadArray(__spreadArray([], state.links, true), [action.link], false), link: initialLink, loadings: __assign(__assign({}, state.loadings), (_d = {}, _d[action.loadingType] = false, _d)) });
        case linkConstants_1.LINK_UPDATE_SUCCESS:
            return __assign(__assign({}, state), { links: state.links.map(function (link) {
                    return link.id === action.link.id ? action.link : link;
                }), link: initialLink, loadings: __assign(__assign({}, state.loadings), (_e = {}, _e[action.loadingType] = false, _e)) });
        case linkConstants_1.LINK_DELETE_SUCCESS:
            return __assign(__assign({}, state), { links: state.links.filter(function (link) { return link.id !== action.id; }), loadings: __assign(__assign({}, state.loadings), (_f = {}, _f[action.loadingType] = false, _f)) });
        case linkConstants_1.SET_LINK:
            return __assign(__assign({}, state), { link: action.link });
        case linkConstants_1.RESET_LINK:
            return __assign(__assign({}, state), { link: initialLink });
        case linkConstants_1.CHANGE_LINK:
            return __assign(__assign({}, state), { link: __assign(__assign({}, state.link), (_g = {}, _g[action.key] = action.value, _g)) });
        default:
            return state;
    }
}
exports.default = reducer;
