"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultColors = exports.otherGenericColors = void 0;
var Colors;
(function (Colors) {
    Colors["PRIMARY"] = "primary";
    Colors["SECONDARY"] = "secondary";
})(Colors || (Colors = {}));
exports.otherGenericColors = {
    panel: "#fcfcfc",
};
exports.defaultColors = [
    "#E7F2FF",
    "#73B1FF",
    "#193765",
    "#cee4ff",
    "#FF8DA0",
    "#ff4160",
    "#f2e1b1",
    "#FFDC79",
    "#ffc82d",
    "#6F7F96",
    "#c7eddd",
    "#73CFA8",
    "#3fb784",
    "#F3F5F8",
    "#fff",
];
exports.default = Colors;
