"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function formatToSearch(input) {
    return Object.values(input).map(function (obj) {
        return Object.values(obj).reduce(function (acc, value) {
            acc.keyword = value[0].source;
            acc.matchCase = true;
            value.forEach(function (v) {
                if (!acc.indexes[v.pageIndex]) {
                    acc.indexes[v.pageIndex] = [];
                }
                acc.indexes[v.pageIndex].push(v.matchIndex);
            });
            return acc;
        }, { indexes: {} });
    });
}
exports.default = formatToSearch;
