"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var redux_1 = require("@hooks/redux");
var workspaceActions_websocket_1 = require("@pages/Workspaces/reducers/WebSocket/workspaceActions.websocket");
function useUndoRedo() {
    var dispatch = (0, redux_1.useAppDispatch)();
    var isMirrorMode = (0, redux_1.useAppSelector)(function (state) { return state.workspaceStep.isMirrorMode; });
    var undoState = (0, redux_1.useAppSelector)(function (state) { return state.workspace.undo; });
    var redoState = (0, redux_1.useAppSelector)(function (state) { return state.workspace.redo; });
    var isUndoingOrRedoing = (0, redux_1.useAppSelector)(function (state) { return state.workspace.isUndoingOrRedoing; });
    var undo = (0, react_1.useCallback)(function () {
        if (isUndoingOrRedoing)
            return;
        var item = undoState.stack.at(-1);
        item && dispatch((0, workspaceActions_websocket_1.undo)(item));
    }, [undoState, isUndoingOrRedoing]);
    var redo = (0, react_1.useCallback)(function () {
        if (isUndoingOrRedoing)
            return;
        var item = redoState.stack.at(-1);
        item && dispatch((0, workspaceActions_websocket_1.redo)(item));
    }, [redoState, isUndoingOrRedoing]);
    undo.reset = function () {
        dispatch((0, workspaceActions_websocket_1.undoReset)());
    };
    redo.reset = function () {
        dispatch((0, workspaceActions_websocket_1.redoReset)());
    };
    undo.isPossible = !isMirrorMode && undoState.isPossible;
    redo.isPossible = !isMirrorMode && redoState.isPossible;
    (0, react_1.useEffect)(function () {
        dispatch((0, workspaceActions_websocket_1.undoReset)());
        dispatch((0, workspaceActions_websocket_1.redoReset)());
    }, [isMirrorMode]);
    return {
        undo: undo,
        redo: redo,
    };
}
exports.default = useUndoRedo;
