"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ESearchTabs = void 0;
var ESearchTabs;
(function (ESearchTabs) {
    ESearchTabs[ESearchTabs["All"] = 0] = "All";
    ESearchTabs[ESearchTabs["CaseOrGroup"] = 1] = "CaseOrGroup";
    ESearchTabs[ESearchTabs["Board"] = 2] = "Board";
    ESearchTabs[ESearchTabs["Workspace"] = 3] = "Workspace";
    ESearchTabs[ESearchTabs["Timeline"] = 4] = "Timeline";
    ESearchTabs[ESearchTabs["Element"] = 5] = "Element";
})(ESearchTabs = exports.ESearchTabs || (exports.ESearchTabs = {}));
