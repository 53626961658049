"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useDoubbleClick = function (updateNodeDisplayName) {
    var _a = (0, react_1.useState)(null), nodeTextTarget = _a[0], setNodeTextTarget = _a[1];
    var _b = (0, react_1.useState)(null), nodeTarget = _b[0], setNodeTarget = _b[1];
    var addNodeTextTarget = function (target) {
        setNodeTextTarget(target);
    };
    var removeNodeTextTarget = (0, react_1.useCallback)(function () {
        if (nodeTextTarget) {
            nodeTextTarget.classList.remove("nodrag");
            nodeTextTarget.classList.remove("textCursor");
            nodeTextTarget.blur();
            nodeTextTarget.removeAttribute("contentEditable");
            setNodeTextTarget(null);
            setNodeTarget(null);
        }
    }, [nodeTextTarget]);
    var putCursorAtTheEnd = (0, react_1.useCallback)(function (target) {
        if (typeof window.getSelection !== "undefined" &&
            typeof document.createRange !== "undefined") {
            var range = document.createRange();
            range.selectNodeContents(target);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            // @ts-ignore
        }
        else if (typeof document.body.createTextRange !== "undefined") {
            // @ts-ignore
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(target);
            textRange.collapse(false);
            textRange.select();
        }
    }, []);
    var onNodeDoubleClick = (0, react_1.useCallback)(function (event, node) {
        var target = event.target;
        var ifDivtarget = target.querySelector("#nodeLabel");
        if (node.id !== (nodeTarget === null || nodeTarget === void 0 ? void 0 : nodeTarget.id)) {
            removeNodeTextTarget();
            if (target.nodeName === "H6" || (ifDivtarget === null || ifDivtarget === void 0 ? void 0 : ifDivtarget.nodeName) === "H6") {
                var actualTarget_1 = target.nodeName === "H6" ? target : ifDivtarget;
                setNodeTextTarget(actualTarget_1);
                actualTarget_1.onblur = function () {
                    if (node.data.displayName !== actualTarget_1.innerText) {
                        updateNodeDisplayName(actualTarget_1.innerText);
                    }
                };
                setNodeTarget(node);
                actualTarget_1.setAttribute("contentEditable", "true");
                actualTarget_1.classList.add("nodrag");
                actualTarget_1.classList.add("textCursor");
                actualTarget_1.focus();
                putCursorAtTheEnd(actualTarget_1);
            }
        }
    }, [nodeTarget, updateNodeDisplayName, putCursorAtTheEnd]);
    return {
        nodeTextTarget: nodeTextTarget,
        addNodeTextTarget: addNodeTextTarget,
        removeNodeTextTarget: removeNodeTextTarget,
        onNodeDoubleClick: onNodeDoubleClick,
    };
};
exports.default = useDoubbleClick;
