"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHANGE_PERSON_OPTIONS = exports.PERSON_DUPLICATE_FAILED = exports.PERSON_DUPLICATE_SUCCESS = exports.PERSON_DUPLICATE_LOADING = exports.GET_PERSON_DESCENDANTS_FAILED = exports.GET_PERSON_DESCENDANTS_SUCCESS = exports.CLEAR_PEOPLE_DESCENDANTS = exports.CHANGE_PERSON = exports.GET_PERSON_DROPDOWN_FAILED = exports.GET_PERSON_DROPDOWN_SUCCESS = exports.FORCE_DELETE_PERSON_FAILED = exports.FORCE_DELETE_PERSON_SUCCESS = exports.DELETE_PERSON_FAILED = exports.DELETE_PERSON_SUCCESS = exports.DELETE_PERSON_LOADING = exports.PUT_PERSON_FAILED = exports.PUT_PERSON_SUCCESS = exports.PUT_PERSON_LOADING = exports.SET_PERSON = exports.SHOW_PERSON_FAILED = exports.SHOW_PERSON_SUCCESS = exports.SHOW_PERSON_LOADING = exports.POST_PERSON_FAILED = exports.POST_PERSON_SUCCESS = exports.POST_PERSON_LOADING = exports.GET_PERSONS_FAILED = exports.GET_PERSONS_SUCCESS = exports.GET_PERSONS_LOADING = void 0;
exports.GET_PERSONS_LOADING = "GET_PERSONS_LOADING";
exports.GET_PERSONS_SUCCESS = "GET_PERSONS_SUCCESS";
exports.GET_PERSONS_FAILED = "GET_PERSONS_FAILED";
exports.POST_PERSON_LOADING = "POST_PERSON_LOADING";
exports.POST_PERSON_SUCCESS = "POST_PERSON_SUCCESS";
exports.POST_PERSON_FAILED = "POST_PERSON_FAILED";
exports.SHOW_PERSON_LOADING = "SHOW_PERSON_LOADING";
exports.SHOW_PERSON_SUCCESS = "SHOW_PERSON_SUCCESS";
exports.SHOW_PERSON_FAILED = "SHOW_PERSON_FAILED";
exports.SET_PERSON = "SET_PERSON";
exports.PUT_PERSON_LOADING = "PUT_PERSON_LOADING";
exports.PUT_PERSON_SUCCESS = "PUT_PERSON_SUCCESS";
exports.PUT_PERSON_FAILED = "PUT_PERSON_FAILED";
exports.DELETE_PERSON_LOADING = "DELETE_PERSON_LOADING";
exports.DELETE_PERSON_SUCCESS = "DELETE_PERSON_SUCCESS";
exports.DELETE_PERSON_FAILED = "DELETE_PERSON_FAILED";
exports.FORCE_DELETE_PERSON_SUCCESS = "FORCE_DELETE_PERSON_SUCCESS";
exports.FORCE_DELETE_PERSON_FAILED = "FORCE_DELETE_PERSON_FAILED";
exports.GET_PERSON_DROPDOWN_SUCCESS = "GET_PERSON_DROPDOWN_SUCCESS";
exports.GET_PERSON_DROPDOWN_FAILED = "GET_PERSON_DROPDOWN_FAILED";
exports.CHANGE_PERSON = "CHANGE_PERSON";
exports.CLEAR_PEOPLE_DESCENDANTS = "CLEAR_PEOPLE_DESCENDANTS";
exports.GET_PERSON_DESCENDANTS_SUCCESS = "GET_PERSON_DESCENDANTS_SUCCESS";
exports.GET_PERSON_DESCENDANTS_FAILED = "GET_PERSON_DESCENDANTS_FAILED";
exports.PERSON_DUPLICATE_LOADING = "PERSON_DUPLICATE_LOADING";
exports.PERSON_DUPLICATE_SUCCESS = "PERSON_DUPLICATE_SUCCESS";
exports.PERSON_DUPLICATE_FAILED = "PERSON_DUPLICATE_FAILED";
exports.CHANGE_PERSON_OPTIONS = "CHANGE_PERSON_OPTIONS";
