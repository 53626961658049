const data = {
  "v": "5.7.11",
  "fr": 60,
  "ip": 0,
  "op": 114,
  "w": 190,
  "h": 190,
  "nm": "Peek",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "Shape Layer 1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1.5,
            79,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            -87.5,
            -19.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 0,
                "k": [
                  131,
                  235
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "nm": "Rectangle Path 1",
              "mn": "ADBE Vector Shape - Rect",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  -87.5,
                  -19.5
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Rectangle 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 6,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            266.148,
            206.012,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            266.148,
            206.012,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0.239,
                          -0.802
                        ],
                        [
                          0.803,
                          0.239
                        ],
                        [
                          -0.241,
                          0.803
                        ],
                        [
                          -0.801,
                          -0.239
                        ]
                      ],
                      "o": [
                        [
                          -0.239,
                          0.802
                        ],
                        [
                          -0.801,
                          -0.24
                        ],
                        [
                          0.239,
                          -0.802
                        ],
                        [
                          0.803,
                          0.24
                        ]
                      ],
                      "v": [
                        [
                          1.454,
                          0.433
                        ],
                        [
                          -0.434,
                          1.454
                        ],
                        [
                          -1.452,
                          -0.433
                        ],
                        [
                          0.434,
                          -1.454
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      278.346,
                      205.039
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  267.347,
                  205.039
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  278.347,
                  205.039
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 1",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0.704,
                          -2.36
                        ],
                        [
                          1.852,
                          0.553
                        ],
                        [
                          -0.704,
                          2.36
                        ],
                        [
                          -1.852,
                          -0.553
                        ]
                      ],
                      "o": [
                        [
                          -0.704,
                          2.36
                        ],
                        [
                          -1.852,
                          -0.553
                        ],
                        [
                          0.704,
                          -2.361
                        ],
                        [
                          1.852,
                          0.553
                        ]
                      ],
                      "v": [
                        [
                          3.354,
                          1
                        ],
                        [
                          -1.275,
                          4.273
                        ],
                        [
                          -3.354,
                          -1
                        ],
                        [
                          1.275,
                          -4.273
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      277.148,
                      206.012
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 5",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  266.148,
                  206.012
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  277.148,
                  206.012
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 5",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 6,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            287.746,
            212.457,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            287.746,
            212.457,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0.239,
                          -0.802
                        ],
                        [
                          0.803,
                          0.239
                        ],
                        [
                          -0.24,
                          0.803
                        ],
                        [
                          -0.802,
                          -0.24
                        ]
                      ],
                      "o": [
                        [
                          -0.239,
                          0.803
                        ],
                        [
                          -0.802,
                          -0.24
                        ],
                        [
                          0.239,
                          -0.802
                        ],
                        [
                          0.803,
                          0.239
                        ]
                      ],
                      "v": [
                        [
                          1.454,
                          0.433
                        ],
                        [
                          -0.434,
                          1.454
                        ],
                        [
                          -1.452,
                          -0.433
                        ],
                        [
                          0.434,
                          -1.452
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      300.087,
                      211.386
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 2",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  289.088,
                  211.387
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  300.088,
                  211.387
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 2",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0.704,
                          -2.36
                        ],
                        [
                          1.853,
                          0.553
                        ],
                        [
                          -0.705,
                          2.36
                        ],
                        [
                          -1.853,
                          -0.553
                        ]
                      ],
                      "o": [
                        [
                          -0.704,
                          2.36
                        ],
                        [
                          -1.852,
                          -0.552
                        ],
                        [
                          0.704,
                          -2.36
                        ],
                        [
                          1.851,
                          0.552
                        ]
                      ],
                      "v": [
                        [
                          3.354,
                          1.001
                        ],
                        [
                          -1.275,
                          4.273
                        ],
                        [
                          -3.353,
                          -1.001
                        ],
                        [
                          1.276,
                          -4.273
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      298.746,
                      212.457
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 4",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  287.746,
                  212.457
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  298.746,
                  212.457
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 4",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "Peek Outlines - Group 3",
      "parent": 6,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            270.717,
            221.97,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            281.717,
            221.97,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      2.382,
                      0.711
                    ],
                    [
                      -0.664,
                      2.225
                    ]
                  ],
                  "o": [
                    [
                      -0.664,
                      2.225
                    ],
                    [
                      -2.381,
                      -0.711
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      4.644,
                      -0.439
                    ],
                    [
                      -0.871,
                      2.302
                    ],
                    [
                      -3.98,
                      -3.013
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  281.468,
                  222.225
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 3",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "FACE",
      "parent": 10,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 45,
              "s": [
                3
              ]
            },
            {
              "t": 97,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            261.388,
            243.587,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            261.388,
            243.587,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          3.052,
                          6.933
                        ],
                        [
                          9.911,
                          10.626
                        ],
                        [
                          1.817,
                          -12.934
                        ],
                        [
                          -25.961,
                          -8.987
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          3.76,
                          1.428
                        ],
                        [
                          -3.294,
                          -2.837
                        ],
                        [
                          -3.34,
                          -2.653
                        ],
                        [
                          -7.361,
                          -7.891
                        ],
                        [
                          -1.737,
                          12.359
                        ],
                        [
                          4.37,
                          -1.281
                        ],
                        [
                          -4.143,
                          -0.828
                        ]
                      ],
                      "v": [
                        [
                          20.674,
                          18.148
                        ],
                        [
                          27.513,
                          18.839
                        ],
                        [
                          17.235,
                          1.711
                        ],
                        [
                          -2.019,
                          -16.93
                        ],
                        [
                          -25.776,
                          -11.331
                        ],
                        [
                          19.802,
                          24.822
                        ],
                        [
                          26.471,
                          22.329
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      310.85,
                      221.369
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 6",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  300.669,
                  222.724
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  311.669,
                  222.724
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 6",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -5.322,
                          11.294
                        ],
                        [
                          10.481,
                          -2.567
                        ],
                        [
                          5.94,
                          -0.247
                        ],
                        [
                          4.826,
                          -0.368
                        ],
                        [
                          -6.014,
                          -0.03
                        ],
                        [
                          5.342,
                          0.573
                        ],
                        [
                          -4.765,
                          -5.201
                        ]
                      ],
                      "o": [
                        [
                          5.567,
                          -11.814
                        ],
                        [
                          -15.84,
                          3.88
                        ],
                        [
                          -3.685,
                          1.632
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          26.616,
                          6.707
                        ]
                      ],
                      "v": [
                        [
                          29.368,
                          4.089
                        ],
                        [
                          12.564,
                          -13.613
                        ],
                        [
                          -20.268,
                          -8.441
                        ],
                        [
                          -33.256,
                          -5.001
                        ],
                        [
                          -25.203,
                          -1.884
                        ],
                        [
                          -34.935,
                          0.622
                        ],
                        [
                          -28.174,
                          9.473
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      255.348,
                      205.841
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 7",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  242.274,
                  205.67
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  253.274,
                  205.67
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 7",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          -25.961,
                          -8.986
                        ],
                        [
                          26.42,
                          7.887
                        ],
                        [
                          7.757,
                          8.47
                        ],
                        [
                          -5.323,
                          11.294
                        ]
                      ],
                      "o": [
                        [
                          -1.736,
                          12.359
                        ],
                        [
                          -9.368,
                          2.746
                        ],
                        [
                          -25.9,
                          -7.732
                        ],
                        [
                          26.616,
                          6.707
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          6.161,
                          -20.927
                        ],
                        [
                          51.739,
                          15.226
                        ],
                        [
                          -2.968,
                          13.146
                        ],
                        [
                          -51.739,
                          -15.651
                        ],
                        [
                          5.803,
                          -21.034
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.991999983788,
                      0.949000000954,
                      0.917999982834,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      278.913,
                      230.964
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 8",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  267.913,
                  229.538
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  278.913,
                  229.538
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 8",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          -7.361,
                          -7.89
                        ],
                        [
                          -3.34,
                          -2.653
                        ],
                        [
                          0.892,
                          2.739
                        ],
                        [
                          3.948,
                          5.646
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          10.82,
                          -8.205
                        ],
                        [
                          5.887,
                          -2.606
                        ],
                        [
                          -15.84,
                          3.88
                        ],
                        [
                          5.567,
                          -11.814
                        ]
                      ],
                      "o": [
                        [
                          1.817,
                          -12.934
                        ],
                        [
                          9.911,
                          10.626
                        ],
                        [
                          -1.689,
                          -3.837
                        ],
                        [
                          -2.503,
                          -7.69
                        ],
                        [
                          -6.147,
                          -8.789
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -7.029,
                          -1.077
                        ],
                        [
                          -23.11,
                          -2.608
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          5.94,
                          -0.248
                        ],
                        [
                          10.48,
                          -2.567
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.492,
                          12.406
                        ],
                        [
                          27.249,
                          6.807
                        ],
                        [
                          46.503,
                          25.449
                        ],
                        [
                          42.5,
                          15.165
                        ],
                        [
                          32.826,
                          -7.834
                        ],
                        [
                          13.448,
                          -21.658
                        ],
                        [
                          16.516,
                          -24.339
                        ],
                        [
                          4.431,
                          -22.84
                        ],
                        [
                          -36.754,
                          -5.933
                        ],
                        [
                          -46.502,
                          -0.231
                        ],
                        [
                          -13.671,
                          -5.403
                        ],
                        [
                          3.134,
                          12.299
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      281.583,
                      197.631
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 9",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  270.583,
                  198.054
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  281.583,
                  198.054
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 9",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 6,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 45,
              "s": [
                9
              ]
            },
            {
              "t": 97,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            301.098,
            204.934,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            301.098,
            204.934,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          2.744,
                          4.007
                        ],
                        [
                          -4.99,
                          11.249
                        ],
                        [
                          13.863,
                          -6.021
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -11.543,
                          4.322
                        ],
                        [
                          10.197,
                          -10.573
                        ],
                        [
                          -2.32,
                          10.573
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      324.962,
                      201.524
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 10",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  313.307,
                  201.524
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  324.307,
                  201.524
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 10",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -17.62,
                          -6.228
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          12.857,
                          -4.359
                        ],
                        [
                          -10.099,
                          1.683
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          2.382,
                          11.163
                        ],
                        [
                          14.9,
                          -9.983
                        ],
                        [
                          -8.431,
                          -11.163
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      320.26,
                      200.934
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 11",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  311.848,
                  200.934
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  322.848,
                  200.934
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 11",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 6,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 45,
              "s": [
                9
              ]
            },
            {
              "t": 97,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            248.552,
            188.532,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            248.552,
            188.532,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -4.49,
                          1.848
                        ],
                        [
                          -1.99,
                          12.144
                        ],
                        [
                          -8.296,
                          -12.633
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          9.718,
                          11.262
                        ],
                        [
                          -0.304,
                          -13.111
                        ],
                        [
                          -1.422,
                          11.438
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      249.808,
                      178.502
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 12",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  241.247,
                  178.012
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  252.247,
                  178.012
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 12",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          18.152,
                          4.447
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          -8.365,
                          -10.692
                        ],
                        [
                          7.525,
                          6.942
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -6.457,
                          10.05
                        ],
                        [
                          -5.339,
                          -14.497
                        ],
                        [
                          14.822,
                          -2.699
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      254.844,
                      179.889
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 13",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  246.302,
                  178.032
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  257.302,
                  178.032
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 13",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 4,
      "nm": "Peek Outlines - Group 14",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            70.928,
            76.773,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            236.928,
            231.773,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      4.859,
                      4.576
                    ],
                    [
                      4.245,
                      4.841
                    ],
                    [
                      2.475,
                      -10.077
                    ],
                    [
                      -7.774,
                      -4.846
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      -3.143,
                      -2.958
                    ],
                    [
                      -3.846,
                      -4.385
                    ],
                    [
                      -2.474,
                      10.076
                    ],
                    [
                      7.773,
                      4.847
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      22.961,
                      6.191
                    ],
                    [
                      3.634,
                      0.561
                    ],
                    [
                      -3.426,
                      -16.792
                    ],
                    [
                      -20.487,
                      -11.732
                    ],
                    [
                      -9.102,
                      16.883
                    ],
                    [
                      10.134,
                      21.809
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  219.971,
                  219.026
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 14",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 4,
      "nm": "BODY",
      "parent": 12,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            257.739,
            307.418,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            246.739,
            307.418,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          5.367,
                          -11.967
                        ],
                        [
                          -17.868,
                          10.896
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          -8.455,
                          18.856
                        ],
                        [
                          16.305,
                          -9.944
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -4.967,
                          -32.786
                        ],
                        [
                          -22.738,
                          -2.5
                        ],
                        [
                          11.54,
                          21.89
                        ],
                        [
                          31.192,
                          -16.476
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.991999983788,
                      0.949000000954,
                      0.917999982834,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      262.49,
                      260.756
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 15",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  254.866,
                  257.052
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  265.866,
                  257.052
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 15",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          17.042,
                          -18.895
                        ],
                        [
                          8.57,
                          19.609
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          -11.932,
                          13.229
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          43.073,
                          -33.185
                        ],
                        [
                          19.401,
                          19.956
                        ],
                        [
                          -43.072,
                          2.754
                        ]
                      ],
                      "c": false
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      257.239,
                      281.585
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 16",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  246.239,
                  277.883
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  257.239,
                  277.883
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 16",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 11,
      "ty": 4,
      "nm": "Peek Outlines - Group 17",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            106.059,
            175.515,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            272.059,
            330.515,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      -0.002,
                      2.906
                    ],
                    [
                      8.933,
                      0.592
                    ],
                    [
                      0.686,
                      -2.348
                    ],
                    [
                      -13.479,
                      -0.008
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      -8.933,
                      -0.592
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      10.097,
                      0.006
                    ]
                  ],
                  "v": [
                    [
                      12.941,
                      0.768
                    ],
                    [
                      2.062,
                      -4.647
                    ],
                    [
                      -11.863,
                      1.313
                    ],
                    [
                      0.537,
                      5.233
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.477999997606,
                  0.361000001197,
                  0.344999994016,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  260.03,
                  329.001
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 17",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 12,
      "ty": 4,
      "nm": "PIVOT",
      "parent": 11,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 45,
              "s": [
                2
              ]
            },
            {
              "t": 97,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            267.282,
            331.136,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            267.282,
            331.136,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      -1.395,
                      -1.708
                    ],
                    [
                      -2.09,
                      2.999
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0.202,
                      10.741
                    ],
                    [
                      3.793,
                      4.642
                    ],
                    [
                      1.485,
                      -2.131
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -13.119,
                      -12.711
                    ],
                    [
                      -12.035,
                      16.572
                    ],
                    [
                      11.944,
                      16.586
                    ],
                    [
                      12.613,
                      -21.214
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  256.673,
                  314.755
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 19",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 13,
      "ty": 4,
      "nm": "Peek Outlines - Group 18",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            38.876,
            145.636,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            204.876,
            300.636,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      -1.535,
                      2.469
                    ],
                    [
                      -7.897,
                      -4.217
                    ],
                    [
                      0.658,
                      -2.355
                    ],
                    [
                      11.447,
                      7.114
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      7.897,
                      4.217
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -8.576,
                      -5.331
                    ]
                  ],
                  "v": [
                    [
                      -9.947,
                      -7.197
                    ],
                    [
                      2.15,
                      -6.046
                    ],
                    [
                      10.824,
                      6.371
                    ],
                    [
                      -1.775,
                      3.149
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.477999997606,
                  0.361000001197,
                  0.344999994016,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  204.656,
                  301.056
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 18",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 14,
      "ty": 4,
      "nm": "Peek Outlines - Group 20",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            80.427,
            103.789,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            246.427,
            258.789,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      2.385,
                      -1.026
                    ],
                    [
                      0.775,
                      5.945
                    ],
                    [
                      -7.161,
                      11.52
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      -3.358,
                      1.447
                    ],
                    [
                      -0.285,
                      -2.187
                    ],
                    [
                      17.352,
                      -27.917
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -23.671,
                      31.393
                    ],
                    [
                      -34.798,
                      46.716
                    ],
                    [
                      -55.164,
                      34.059
                    ],
                    [
                      -41.851,
                      8.729
                    ],
                    [
                      -5.159,
                      -48.163
                    ],
                    [
                      55.449,
                      -13.87
                    ],
                    [
                      45.903,
                      3.608
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  246.289,
                  259.428
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 20",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 15,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            64.766,
            110.596,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            219.766,
            265.596,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -4.407,
                          0.329
                        ],
                        [
                          -2.072,
                          0.397
                        ],
                        [
                          -1.798,
                          6.47
                        ],
                        [
                          3.095,
                          -0.606
                        ],
                        [
                          3.668,
                          -1.462
                        ],
                        [
                          -1.085,
                          -6.319
                        ]
                      ],
                      "o": [
                        [
                          2.373,
                          -0.178
                        ],
                        [
                          -2.495,
                          -11.747
                        ],
                        [
                          -2.766,
                          0.018
                        ],
                        [
                          -5.446,
                          1.067
                        ],
                        [
                          -2.353,
                          10.062
                        ],
                        [
                          4.743,
                          1.063
                        ]
                      ],
                      "v": [
                        [
                          3.58,
                          15.811
                        ],
                        [
                          10.236,
                          14.929
                        ],
                        [
                          12.273,
                          -16.14
                        ],
                        [
                          3.471,
                          -15.251
                        ],
                        [
                          -9.92,
                          -11.478
                        ],
                        [
                          -10.416,
                          14.471
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      192.855,
                      267.716
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 21",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  182.261,
                  267.597
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  193.261,
                  267.597
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 21",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -2.353,
                          10.063
                        ],
                        [
                          6.272,
                          -5.559
                        ],
                        [
                          -2.921,
                          -1.165
                        ],
                        [
                          -7.242,
                          -1.622
                        ]
                      ],
                      "o": [
                        [
                          -7.579,
                          3.021
                        ],
                        [
                          -2.353,
                          2.085
                        ],
                        [
                          4.601,
                          1.837
                        ],
                        [
                          -1.085,
                          -6.319
                        ]
                      ],
                      "v": [
                        [
                          11.354,
                          -12.975
                        ],
                        [
                          -9.001,
                          0.006
                        ],
                        [
                          -7.743,
                          7.102
                        ],
                        [
                          10.858,
                          12.975
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      171.581,
                      269.213
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 22",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  161.049,
                  269.213
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  172.049,
                  269.213
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 22",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -2.495,
                          -11.747
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          15.38,
                          -0.107
                        ]
                      ],
                      "o": [
                        [
                          16.328,
                          -3.128
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -1.798,
                          6.469
                        ]
                      ],
                      "v": [
                        [
                          -12.391,
                          15.588
                        ],
                        [
                          14.724,
                          3.384
                        ],
                        [
                          14.886,
                          -6.212
                        ],
                        [
                          -10.354,
                          -15.481
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      215.481,
                      267.057
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 23",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  205.228,
                  267.111
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  216.228,
                  267.111
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Peek Outlines - Group 23",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 16,
      "ty": 4,
      "nm": "Peek Outlines - Group 24",
      "parent": 12,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 18,
              "s": [
                -11
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 35,
              "s": [
                -1
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 53,
              "s": [
                -11
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 72,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 92,
              "s": [
                -11
              ]
            },
            {
              "t": 111,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            290.58,
            256.157,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            286.452,
            252.549,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.997,
                            -2.931
                          ],
                          [
                            -6.028,
                            -2.261
                          ],
                          [
                            8.553,
                            -5.875
                          ],
                          [
                            7.28,
                            5.561
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.878,
                            1.894
                          ],
                          [
                            5.461,
                            2.049
                          ],
                          [
                            -8.554,
                            5.874
                          ],
                          [
                            -7.28,
                            -5.56
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.294,
                            -20.095
                          ],
                          [
                            -3.199,
                            -4.002
                          ],
                          [
                            15.529,
                            -3.527
                          ],
                          [
                            16.822,
                            14.221
                          ],
                          [
                            -13.971,
                            13.678
                          ],
                          [
                            -25.374,
                            -2.577
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 18,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.997,
                            -2.931
                          ],
                          [
                            -6.028,
                            -2.261
                          ],
                          [
                            8.553,
                            -5.875
                          ],
                          [
                            7.28,
                            5.561
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.878,
                            1.894
                          ],
                          [
                            5.461,
                            2.049
                          ],
                          [
                            -8.554,
                            5.874
                          ],
                          [
                            -7.28,
                            -5.56
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.294,
                            -20.095
                          ],
                          [
                            -3.199,
                            -4.002
                          ],
                          [
                            13.768,
                            -5.198
                          ],
                          [
                            15.061,
                            12.55
                          ],
                          [
                            -13.971,
                            13.678
                          ],
                          [
                            -25.374,
                            -2.577
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 35,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.997,
                            -2.931
                          ],
                          [
                            -6.028,
                            -2.261
                          ],
                          [
                            8.553,
                            -5.875
                          ],
                          [
                            7.28,
                            5.561
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.878,
                            1.894
                          ],
                          [
                            5.461,
                            2.049
                          ],
                          [
                            -8.554,
                            5.874
                          ],
                          [
                            -7.28,
                            -5.56
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.294,
                            -20.095
                          ],
                          [
                            -3.199,
                            -4.002
                          ],
                          [
                            15.529,
                            -3.527
                          ],
                          [
                            16.822,
                            14.221
                          ],
                          [
                            -13.971,
                            13.678
                          ],
                          [
                            -25.374,
                            -2.577
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 53,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.997,
                            -2.931
                          ],
                          [
                            -6.028,
                            -2.261
                          ],
                          [
                            8.553,
                            -5.875
                          ],
                          [
                            7.28,
                            5.561
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.878,
                            1.894
                          ],
                          [
                            5.461,
                            2.049
                          ],
                          [
                            -8.554,
                            5.874
                          ],
                          [
                            -7.28,
                            -5.56
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.294,
                            -20.095
                          ],
                          [
                            -3.199,
                            -4.002
                          ],
                          [
                            13.768,
                            -5.198
                          ],
                          [
                            15.061,
                            12.55
                          ],
                          [
                            -13.971,
                            13.678
                          ],
                          [
                            -25.374,
                            -2.577
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0
                    },
                    "t": 72,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.997,
                            -2.931
                          ],
                          [
                            -6.028,
                            -2.261
                          ],
                          [
                            8.553,
                            -5.875
                          ],
                          [
                            7.28,
                            5.561
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.878,
                            1.894
                          ],
                          [
                            5.461,
                            2.049
                          ],
                          [
                            -8.554,
                            5.874
                          ],
                          [
                            -7.28,
                            -5.56
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.294,
                            -20.095
                          ],
                          [
                            -3.199,
                            -4.002
                          ],
                          [
                            15.529,
                            -3.527
                          ],
                          [
                            16.822,
                            14.221
                          ],
                          [
                            -13.971,
                            13.678
                          ],
                          [
                            -25.374,
                            -2.577
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 92,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.997,
                            -2.931
                          ],
                          [
                            -6.028,
                            -2.261
                          ],
                          [
                            8.553,
                            -5.875
                          ],
                          [
                            7.28,
                            5.561
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.878,
                            1.894
                          ],
                          [
                            5.461,
                            2.049
                          ],
                          [
                            -8.554,
                            5.874
                          ],
                          [
                            -7.28,
                            -5.56
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.294,
                            -20.095
                          ],
                          [
                            -3.199,
                            -4.002
                          ],
                          [
                            13.768,
                            -5.198
                          ],
                          [
                            15.061,
                            12.55
                          ],
                          [
                            -13.971,
                            13.678
                          ],
                          [
                            -25.374,
                            -2.577
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "t": 111,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -5.997,
                            -2.931
                          ],
                          [
                            -6.028,
                            -2.261
                          ],
                          [
                            8.553,
                            -5.875
                          ],
                          [
                            7.28,
                            5.561
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            3.878,
                            1.894
                          ],
                          [
                            5.461,
                            2.049
                          ],
                          [
                            -8.554,
                            5.874
                          ],
                          [
                            -7.28,
                            -5.56
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.294,
                            -20.095
                          ],
                          [
                            -3.199,
                            -4.002
                          ],
                          [
                            15.529,
                            -3.527
                          ],
                          [
                            16.822,
                            14.221
                          ],
                          [
                            -13.971,
                            13.678
                          ],
                          [
                            -25.374,
                            -2.577
                          ]
                        ],
                        "c": false
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  306.572,
                  261.97
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 24",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;