"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable @typescript-eslint/default-param-last */
var constants_1 = require("@api/constants");
var output_1 = require("@customTypes/reducers/output");
var folderConstants_1 = require("@redux/constants/folderConstants");
var outputConstants_1 = require("./outputConstants");
var reorderSlides = function (slides, slide, newIndex, forced) {
    if (forced === void 0) { forced = false; }
    if (forced || newIndex !== slide.index) {
        var newSlides_1 = [];
        var slidesToReorder = slides.filter(function (s) {
            return s.index >= Math.min(newIndex, slide.index) &&
                s.index <= Math.max(newIndex, slide.index);
        });
        var slidesNotToReorder = slides.filter(function (s) {
            return s.index < Math.min(newIndex, slide.index) ||
                s.index > Math.max(newIndex, slide.index);
        });
        slidesNotToReorder.forEach(function (s) { return newSlides_1.push(s); });
        slidesToReorder.forEach(function (s) {
            var newSlide = __assign({}, s);
            if (newSlide.id === slide.id) {
                newSlide.index = newIndex;
            }
            else {
                newSlide.index += newIndex > slide.index ? -1 : 1;
            }
            newSlides_1.push(newSlide);
        });
        return newSlides_1.sort(function (a, b) { return a.index - b.index; });
    }
    return slides.sort(function (a, b) { return a.index - b.index; });
};
var initialState = {
    outputs: [],
    title: "",
    description: "",
    isFullStructure: false,
    disabled: false,
    sectionDivider: false,
    sectionDividerTitle: "",
    type: output_1.OutputTypes.REPORT,
    outputContentType: output_1.OutputContentTypes.DRAFT,
    outputText: "",
    outputFile: {},
    groupOrLawsuit: "",
    outputConditions: [],
    conditionsDropDownOptions: [],
    outputTags: [],
    outputsDropDownOptions: [],
    outputSlides: [],
    excludedOutputs: [],
    loadings: constants_1.initialLoadings,
};
function reducer(state, action) {
    var _a, _b;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case outputConstants_1.GET_OUTPUTS_SUCCESS:
            return __assign(__assign({}, state), { outputs: action.outputs });
        case outputConstants_1.GET_OUTPUTS_DROPDOWN_SUCCESS:
            return __assign(__assign({}, state), { outputsDropDownOptions: action.dropdown });
        case outputConstants_1.DELETE_OUTPUT_SLIDES_LOADING:
        case outputConstants_1.POST_OUTPUT_SLIDE_LOADING:
        case outputConstants_1.DOWNLOAD_OUTPUT_LOADING:
        case outputConstants_1.OUTPUT_DUPLICATE_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case outputConstants_1.PUT_OUTPUT_SUCCESS:
            return state;
        case outputConstants_1.GET_OUTPUTS_FAILED:
        case outputConstants_1.SHOW_OUTPUT_FAILED:
        case outputConstants_1.POST_OUTPUT_FAILED:
        case outputConstants_1.PUT_OUTPUT_FAILED:
        case outputConstants_1.DOWNLOAD_OUTPUT_FAILED:
        case outputConstants_1.GET_OUTPUTS_DROPDOWN_FAILED:
        case outputConstants_1.DELETE_OUTPUT_SUCCESS:
        case outputConstants_1.DELETE_OUTPUT_FAILED:
        case outputConstants_1.GET_CONDITION_DROPDOWN_FAILED:
        case outputConstants_1.POST_OUTPUT_SLIDE_FAILED:
        case outputConstants_1.PUT_OUTPUT_SLIDE_CONTENT_FAILED:
        case outputConstants_1.PUT_OUTPUT_SLIDES_FAILED:
        case outputConstants_1.OUTPUT_DUPLICATE_FAILED:
        case outputConstants_1.DELETE_OUTPUT_SLIDES_FAILED:
            return state;
        case outputConstants_1.POST_OUTPUT_SUCCESS:
            return __assign(__assign({}, state), { title: "", description: "", isFullStructure: false, groupOrLawsuit: "", outputConditions: [], outputContentType: output_1.OutputContentTypes.DRAFT, outputFile: {}, outputText: "", outputs: __spreadArray(__spreadArray([], state.outputs, true), [action.output], false) });
        case outputConstants_1.SHOW_OUTPUT_SUCCESS:
            return __assign(__assign({}, state), { title: action.label, description: action.description, isFullStructure: action.isFullStructure, type: action.outputType, groupOrLawsuit: action.groupOrLawsuit || "", outputConditions: action.conditions, outputTags: action.tags, outputContentType: action.contentType, outputText: action.contentType === output_1.OutputContentTypes.DRAFT && action.output
                    ? action.output
                    : "", outputFile: action.contentType === output_1.OutputContentTypes.UPLOAD && action.output
                    ? action.output
                    : {}, outputSlides: action.outputSlides, excludedOutputs: action.excludedOutputs, disabled: action.disabled, sectionDivider: action.sectionDivider, sectionDividerTitle: action.sectionDividerTitle });
        case outputConstants_1.GET_CONDITION_DROPDOWN_SUCCESS:
            return __assign(__assign({}, state), { conditionsDropDownOptions: action.conditions });
        case outputConstants_1.TITLE_CHANGE:
            return __assign(__assign({}, state), { title: action.title });
        case outputConstants_1.DESCRIPTION_CHANGE:
            return __assign(__assign({}, state), { description: action.description });
        case outputConstants_1.OUTPUT_TOGGLE_FULL_STRUCTURE:
            return __assign(__assign({}, state), { isFullStructure: !state.isFullStructure });
        case outputConstants_1.OUTPUT_TOGGLE_DISABLED:
            return __assign(__assign({}, state), { disabled: !state.disabled });
        case outputConstants_1.OUTPUT_TOGGLE_SECTION_DIVIDER:
            return __assign(__assign({}, state), { sectionDivider: !state.sectionDivider });
        case outputConstants_1.OUTPUT_SECTION_DIVIDER_TITLE_CHANGE:
            return __assign(__assign({}, state), { sectionDividerTitle: action.title });
        case outputConstants_1.OUTPUT_TYPE_CHANGE:
            return __assign(__assign({}, state), { type: action.outputType });
        case outputConstants_1.OUTPUT_CONTENT_TYPE_CHANGE:
            return __assign(__assign({}, state), { outputContentType: action.contentType });
        case outputConstants_1.ADD_OUTPUT_FILE:
            return __assign(__assign({}, state), { outputFile: action.file });
        case outputConstants_1.OUTPUT_TEXT_CHANGE:
            return __assign(__assign({}, state), { outputText: action.outputText });
        case outputConstants_1.OUTPUT_ADD_CONDITION:
            return __assign(__assign({}, state), { outputConditions: __spreadArray(__spreadArray([], state.outputConditions, true), [action.condition], false) });
        case outputConstants_1.OUTPUT_CHANGE_CONDITION:
            return __assign(__assign({}, state), { outputConditions: state.outputConditions.map(function (condition, index) {
                    if (index === action.index) {
                        return action.condition;
                    }
                    return condition;
                }) });
        case outputConstants_1.OUTPUT_DELETE_CONDITION:
            return __assign(__assign({}, state), { outputConditions: state.outputConditions.filter(function (condition, index) { return index !== action.index; }) });
        case outputConstants_1.CHANGE_TAGS:
            return __assign(__assign({}, state), { outputTags: action.tags });
        case outputConstants_1.CHANGE_OUTPUT_CONDITION_VISIBILITY:
            return __assign(__assign({}, state), { outputConditions: state.outputConditions.map(function (oc) {
                    if (oc.conditionId === action.condition.value) {
                        return __assign(__assign({}, oc), { visible: true });
                    }
                    return __assign(__assign({}, oc), { visible: false });
                }) });
        case outputConstants_1.DELETE_OUTPUT_SLIDES_SUCCESS:
        case outputConstants_1.POST_OUTPUT_SLIDE_SUCCESS:
            return __assign(__assign({}, state), { outputSlides: action.outputSlides, loadings: constants_1.initialLoadings });
        case outputConstants_1.PUT_OUTPUT_SLIDES_LOADING: {
            return __assign(__assign({}, state), { outputSlides: reorderSlides(state.outputSlides, __assign(__assign({}, state.outputSlides.find(function (slide) { return slide.id === action.outputSlide.id; })), { outputSlideContents: action.outputSlide.outputSlideContents }), action.outputSlide.index), loadings: __assign(__assign({}, state.loadings), { post: true }) });
        }
        case outputConstants_1.PUT_OUTPUT_SLIDES_SUCCESS:
            return __assign(__assign({}, state), { outputSlides: state.outputSlides
                    .map(function (slide) {
                    return slide.id === action.outputSlide.id ? action.outputSlide : slide;
                })
                    .sort(function (a, b) { return a.index - b.index; }), loadings: constants_1.initialLoadings });
        case outputConstants_1.PUT_OUTPUT_SLIDE_CONTENT_SUCCESS:
            return __assign(__assign({}, state), { outputSlides: state.outputSlides.map(function (slide) {
                    if (slide.id === action.outputSlideContent.outputSlideId) {
                        slide.outputSlideContents = slide.outputSlideContents.map(function (slideContent) {
                            return slideContent.id === action.outputSlideContent.id
                                ? action.outputSlideContent
                                : slideContent;
                        });
                    }
                    return slide;
                }) });
        case outputConstants_1.CLEAR_OUTPUT:
            return __assign(__assign({}, state), { title: "", description: "", isFullStructure: false, groupOrLawsuit: "", type: output_1.OutputTypes.REPORT, outputText: "", outputFile: {}, outputConditions: [], outputTags: [], outputSlides: [] });
        case outputConstants_1.OUTPUT_DUPLICATE_SUCCESS:
            return __assign(__assign({}, state), { outputs: action.output ? __spreadArray(__spreadArray([], state.outputs, true), [action.output], false) : state.outputs, loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.postFolderFailed)("outputs"):
        case (0, folderConstants_1.putFolderTitleFailed)("outputs"):
        case (0, folderConstants_1.putFolderLocationFailed)("outputs"):
        case (0, folderConstants_1.putFolderElementLocationFailed)("outputs"):
        case (0, folderConstants_1.deleteFolderFailed)("outputs"):
        case (0, folderConstants_1.duplicateFolderFailed)("outputs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        case (0, folderConstants_1.deleteFolderLoading)("outputs"):
        case (0, folderConstants_1.putFolderLocationLoading)("outputs"):
        case (0, folderConstants_1.putFolderElementLocationLoading)("outputs"):
        case (0, folderConstants_1.putFolderTitleLoading)("outputs"):
        case (0, folderConstants_1.postFolderLoading)("outputs"):
        case (0, folderConstants_1.duplicateFolderLoading)("outputs"):
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_b = {}, _b[action.loadings] = true, _b)) });
        case (0, folderConstants_1.postFolderSuccess)("outputs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, outputs: __spreadArray([action.folder], state.outputs, true) });
        case (0, folderConstants_1.putFolderTitleSuccess)("outputs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, outputs: state.outputs.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderLocationSuccess)("outputs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, outputs: state.outputs.map(function (c) {
                    if (c.isFolder && c.id === action.folder.id) {
                        return action.folder;
                    }
                    return c;
                }) });
        case (0, folderConstants_1.putFolderElementLocationSuccess)("outputs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, outputs: state.outputs.map(function (c) {
                    if (!c.isFolder && c.id === action.id) {
                        return __assign(__assign({}, c), { folderId: action.folderId });
                    }
                    return c;
                }) });
        case (0, folderConstants_1.deleteFolderSuccess)("outputs"): {
            if (action.deleteChildren) {
                return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, outputs: state.outputs
                        .filter(function (c) { return c.id !== action.folder.id; })
                        .filter(function (c) { return c.folderId !== action.folder.id; }) });
            }
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, outputs: state.outputs
                    .filter(function (c) { return c.id !== action.folder.id; })
                    .map(function (c) {
                    return c.folderId === action.folder.id
                        ? __assign(__assign({}, c), { folderId: action.folder.folderId }) : c;
                }) });
        }
        case outputConstants_1.REMOVE_EXCLUDED_OUTPUT:
            return __assign(__assign({}, state), { excludedOutputs: state.excludedOutputs.filter(function (_o, index) { return index !== action.index; }) });
        case outputConstants_1.ADD_EXCLUDED_OUTPUT:
            return __assign(__assign({}, state), { excludedOutputs: __spreadArray(__spreadArray([], state.excludedOutputs, true), [action.excluded], false) });
        case outputConstants_1.CHANGE_EXCLUDED_OUTPUT:
            return __assign(__assign({}, state), { excludedOutputs: action.excluded });
        case (0, folderConstants_1.duplicateFolderSuccess)("outputs"):
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings, outputs: __spreadArray(__spreadArray([], action.folder, true), state.outputs, true) });
        case outputConstants_1.DOWNLOAD_OUTPUT_SUCCESS:
            return __assign(__assign({}, state), { loadings: constants_1.initialLoadings });
        default:
            return state;
    }
}
exports.default = reducer;
