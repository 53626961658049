const data = {
  "v": "5.7.11",
  "fr": 60,
  "ip": 0,
  "op": 75,
  "w": 190,
  "h": 190,
  "nm": "Walk",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 6,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            246.207,
            207.838,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            246.207,
            207.838,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.837,
                          0
                        ],
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.838,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.838,
                          0
                        ],
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.837,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.516,
                          0
                        ],
                        [
                          0,
                          1.516
                        ],
                        [
                          -1.517,
                          0
                        ],
                        [
                          0,
                          -1.516
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      247.078,
                      206.563
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 8",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  247.078,
                  206.563
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  247.078,
                  206.563
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 8",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.933,
                          0
                        ],
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ],
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.933,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.5,
                          0
                        ],
                        [
                          0,
                          4.46
                        ],
                        [
                          -3.5,
                          0
                        ],
                        [
                          0,
                          -4.46
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      246.207,
                      207.838
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 12",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  246.207,
                  207.838
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  246.207,
                  207.838
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 12",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 6,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            268.746,
            207.838,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            268.746,
            207.838,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.838,
                          0
                        ],
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.837,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0.837
                        ],
                        [
                          -0.837,
                          0
                        ],
                        [
                          0,
                          -0.837
                        ],
                        [
                          0.838,
                          0
                        ]
                      ],
                      "v": [
                        [
                          1.516,
                          0
                        ],
                        [
                          -0.001,
                          1.517
                        ],
                        [
                          -1.516,
                          0
                        ],
                        [
                          -0.001,
                          -1.517
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      269.726,
                      206.428
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 9",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  269.726,
                  206.428
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  269.726,
                  206.428
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 9",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.934,
                          0
                        ],
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ]
                      ],
                      "o": [
                        [
                          0,
                          2.463
                        ],
                        [
                          -1.933,
                          0
                        ],
                        [
                          0,
                          -2.463
                        ],
                        [
                          1.934,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.5,
                          0
                        ],
                        [
                          0,
                          4.46
                        ],
                        [
                          -3.5,
                          0
                        ],
                        [
                          0,
                          -4.46
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      268.746,
                      207.838
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 11",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  268.746,
                  207.838
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  268.746,
                  207.838
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 11",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "Walk Outlines - Group 10",
      "parent": 6,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            256.808,
            220.492,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            256.808,
            220.492,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      2.485,
                      0
                    ],
                    [
                      0,
                      2.321
                    ]
                  ],
                  "o": [
                    [
                      0,
                      2.321
                    ],
                    [
                      -2.485,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      4.5,
                      -2.102
                    ],
                    [
                      0,
                      2.102
                    ],
                    [
                      -4.5,
                      -2.102
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  256.808,
                  220.492
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 10",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 6,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 18,
              "s": [
                -3
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 37,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 56,
              "s": [
                -3
              ]
            },
            {
              "t": 73,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            217.636,
            192.489,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            217.636,
            192.489,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -3.775,
                          3.055
                        ],
                        [
                          1.565,
                          12.207
                        ],
                        [
                          -11.562,
                          -9.734
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          11.094,
                          8.568
                        ],
                        [
                          -5.48,
                          -11.922
                        ],
                        [
                          0.469,
                          11.922
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      207.582,
                      183.739
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 13",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  209.708,
                  183.739
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  209.708,
                  183.739
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 13",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          18.666,
                          -0.929
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          -11.073,
                          -7.853
                        ],
                        [
                          9.196,
                          4.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -3.796,
                          11.921
                        ],
                        [
                          -9.745,
                          -11.921
                        ],
                        [
                          12.949,
                          -6.38
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      211.847,
                      183.739
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 14",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  212.886,
                  183.739
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  212.886,
                  183.739
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 14",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "FACE",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 0,
              "s": [
                85.812,
                92.342,
                0
              ],
              "to": [
                0,
                -0.833,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 18,
              "s": [
                85.812,
                87.342,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 38,
              "s": [
                85.812,
                92.342,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 55,
              "s": [
                85.812,
                87.342,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                -0.833,
                0
              ]
            },
            {
              "t": 73,
              "s": [
                85.812,
                92.342,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            240.812,
            241.842,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          6.94,
                          5.67
                        ],
                        [
                          8.596,
                          5.158
                        ],
                        [
                          0.071,
                          -13.06
                        ],
                        [
                          -23.756,
                          -2.723
                        ]
                      ],
                      "o": [
                        [
                          -4.883,
                          -2.458
                        ],
                        [
                          -9.252,
                          -5.551
                        ],
                        [
                          -0.056,
                          10.063
                        ],
                        [
                          3.762,
                          -3.981
                        ]
                      ],
                      "v": [
                        [
                          13.03,
                          -3.758
                        ],
                        [
                          -0.656,
                          -12.105
                        ],
                        [
                          -19.914,
                          -3.956
                        ],
                        [
                          12.098,
                          17.656
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      279.868,
                      210.385
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 15",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  278.303,
                  211.508
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  278.303,
                  211.508
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 15",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                -1.87,
                                12.344
                              ],
                              [
                                16.292,
                                -7.518
                              ],
                              [
                                5.621,
                                -1.936
                              ],
                              [
                                4.519,
                                -1.733
                              ],
                              [
                                -5.771,
                                1.692
                              ],
                              [
                                5.282,
                                -0.978
                              ],
                              [
                                -6.053,
                                -3.622
                              ]
                            ],
                            "o": [
                              [
                                1.957,
                                -12.913
                              ],
                              [
                                -14.807,
                                6.834
                              ],
                              [
                                -3.065,
                                2.617
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                27.423,
                                -1.184
                              ]
                            ],
                            "v": [
                              [
                                36.326,
                                -0.968
                              ],
                              [
                                3.162,
                                -13.126
                              ],
                              [
                                -26.82,
                                1.218
                              ],
                              [
                                -38.282,
                                8.228
                              ],
                              [
                                -29.674,
                                8.912
                              ],
                              [
                                -38.282,
                                14.097
                              ],
                              [
                                -29.274,
                                20.644
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 18,
                        "s": [
                          {
                            "i": [
                              [
                                -1.87,
                                12.344
                              ],
                              [
                                16.292,
                                -7.518
                              ],
                              [
                                5.621,
                                -1.936
                              ],
                              [
                                4.061,
                                0.141
                              ],
                              [
                                -6.579,
                                0.441
                              ],
                              [
                                5.282,
                                -0.978
                              ],
                              [
                                -6.053,
                                -3.622
                              ]
                            ],
                            "o": [
                              [
                                1.957,
                                -12.913
                              ],
                              [
                                -14.807,
                                6.834
                              ],
                              [
                                -3.065,
                                2.617
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                27.423,
                                -1.184
                              ]
                            ],
                            "v": [
                              [
                                36.326,
                                -0.968
                              ],
                              [
                                3.162,
                                -13.126
                              ],
                              [
                                -26.82,
                                1.218
                              ],
                              [
                                -38.315,
                                3.962
                              ],
                              [
                                -29.299,
                                9.412
                              ],
                              [
                                -38.282,
                                12.331
                              ],
                              [
                                -29.274,
                                20.644
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 38,
                        "s": [
                          {
                            "i": [
                              [
                                -1.87,
                                12.344
                              ],
                              [
                                16.292,
                                -7.518
                              ],
                              [
                                5.621,
                                -1.936
                              ],
                              [
                                4.519,
                                -1.733
                              ],
                              [
                                -5.771,
                                1.692
                              ],
                              [
                                5.282,
                                -0.978
                              ],
                              [
                                -6.053,
                                -3.622
                              ]
                            ],
                            "o": [
                              [
                                1.957,
                                -12.913
                              ],
                              [
                                -14.807,
                                6.834
                              ],
                              [
                                -3.065,
                                2.617
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                27.423,
                                -1.184
                              ]
                            ],
                            "v": [
                              [
                                36.326,
                                -0.968
                              ],
                              [
                                3.162,
                                -13.126
                              ],
                              [
                                -26.82,
                                1.218
                              ],
                              [
                                -38.282,
                                8.228
                              ],
                              [
                                -29.674,
                                8.912
                              ],
                              [
                                -38.282,
                                14.097
                              ],
                              [
                                -29.274,
                                20.644
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 55,
                        "s": [
                          {
                            "i": [
                              [
                                -1.87,
                                12.344
                              ],
                              [
                                16.292,
                                -7.518
                              ],
                              [
                                5.621,
                                -1.936
                              ],
                              [
                                4.061,
                                0.141
                              ],
                              [
                                -6.579,
                                0.441
                              ],
                              [
                                5.282,
                                -0.978
                              ],
                              [
                                -6.053,
                                -3.622
                              ]
                            ],
                            "o": [
                              [
                                1.957,
                                -12.913
                              ],
                              [
                                -14.807,
                                6.834
                              ],
                              [
                                -3.065,
                                2.617
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                27.423,
                                -1.184
                              ]
                            ],
                            "v": [
                              [
                                36.326,
                                -0.968
                              ],
                              [
                                3.162,
                                -13.126
                              ],
                              [
                                -26.82,
                                1.218
                              ],
                              [
                                -38.315,
                                3.962
                              ],
                              [
                                -29.299,
                                9.412
                              ],
                              [
                                -38.282,
                                12.331
                              ],
                              [
                                -29.274,
                                20.644
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 73,
                        "s": [
                          {
                            "i": [
                              [
                                -1.87,
                                12.344
                              ],
                              [
                                16.292,
                                -7.518
                              ],
                              [
                                5.621,
                                -1.936
                              ],
                              [
                                4.519,
                                -1.733
                              ],
                              [
                                -5.771,
                                1.692
                              ],
                              [
                                5.282,
                                -0.978
                              ],
                              [
                                -6.053,
                                -3.622
                              ]
                            ],
                            "o": [
                              [
                                1.957,
                                -12.913
                              ],
                              [
                                -14.807,
                                6.834
                              ],
                              [
                                -3.065,
                                2.617
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                27.423,
                                -1.184
                              ]
                            ],
                            "v": [
                              [
                                36.326,
                                -0.968
                              ],
                              [
                                3.162,
                                -13.126
                              ],
                              [
                                -26.82,
                                1.218
                              ],
                              [
                                -38.282,
                                8.228
                              ],
                              [
                                -29.674,
                                8.912
                              ],
                              [
                                -38.282,
                                14.097
                              ],
                              [
                                -29.274,
                                20.644
                              ]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      223.254,
                      207.397
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 16",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  222.345,
                  209.588
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  222.345,
                  209.588
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 16",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                -25.455,
                                -3.981
                              ],
                              [
                                27.572,
                                0.003
                              ],
                              [
                                9.856,
                                5.898
                              ],
                              [
                                -1.87,
                                12.344
                              ]
                            ],
                            "o": [
                              [
                                1.869,
                                12.34
                              ],
                              [
                                -6.152,
                                5.802
                              ],
                              [
                                -27.03,
                                -0.003
                              ],
                              [
                                27.423,
                                -1.184
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                16.981,
                                -17.632
                              ],
                              [
                                48.994,
                                3.979
                              ],
                              [
                                5.976,
                                17.629
                              ],
                              [
                                -48.994,
                                3.98
                              ],
                              [
                                16.606,
                                -17.632
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 25,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                -25.455,
                                -3.981
                              ],
                              [
                                27.572,
                                0.003
                              ],
                              [
                                9.856,
                                5.898
                              ],
                              [
                                -1.87,
                                12.344
                              ]
                            ],
                            "o": [
                              [
                                1.869,
                                12.34
                              ],
                              [
                                -6.152,
                                5.802
                              ],
                              [
                                -27.03,
                                -0.003
                              ],
                              [
                                27.423,
                                -1.184
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                16.981,
                                -17.632
                              ],
                              [
                                48.994,
                                3.979
                              ],
                              [
                                5.976,
                                19.504
                              ],
                              [
                                -48.994,
                                3.98
                              ],
                              [
                                16.606,
                                -17.632
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 44,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                -25.455,
                                -3.981
                              ],
                              [
                                27.572,
                                0.003
                              ],
                              [
                                9.856,
                                5.898
                              ],
                              [
                                -1.87,
                                12.344
                              ]
                            ],
                            "o": [
                              [
                                1.869,
                                12.34
                              ],
                              [
                                -6.152,
                                5.802
                              ],
                              [
                                -27.03,
                                -0.003
                              ],
                              [
                                27.423,
                                -1.184
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                16.981,
                                -17.632
                              ],
                              [
                                48.994,
                                3.979
                              ],
                              [
                                5.976,
                                17.629
                              ],
                              [
                                -48.994,
                                3.98
                              ],
                              [
                                16.606,
                                -17.632
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 63,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                -25.455,
                                -3.981
                              ],
                              [
                                27.572,
                                0.003
                              ],
                              [
                                9.856,
                                5.898
                              ],
                              [
                                -1.87,
                                12.344
                              ]
                            ],
                            "o": [
                              [
                                1.869,
                                12.34
                              ],
                              [
                                -6.152,
                                5.802
                              ],
                              [
                                -27.03,
                                -0.003
                              ],
                              [
                                27.423,
                                -1.184
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                16.981,
                                -17.632
                              ],
                              [
                                48.994,
                                3.979
                              ],
                              [
                                5.976,
                                19.504
                              ],
                              [
                                -48.994,
                                3.98
                              ],
                              [
                                16.606,
                                -17.632
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 73,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                -25.455,
                                -3.981
                              ],
                              [
                                27.572,
                                0.003
                              ],
                              [
                                9.856,
                                5.898
                              ],
                              [
                                -1.87,
                                12.344
                              ]
                            ],
                            "o": [
                              [
                                1.869,
                                12.34
                              ],
                              [
                                -6.152,
                                5.802
                              ],
                              [
                                -27.03,
                                -0.003
                              ],
                              [
                                27.423,
                                -1.184
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                16.981,
                                -17.632
                              ],
                              [
                                48.994,
                                3.979
                              ],
                              [
                                5.976,
                                17.629
                              ],
                              [
                                -48.994,
                                3.98
                              ],
                              [
                                16.606,
                                -17.632
                              ]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.991999983788,
                      0.949000000954,
                      0.917999982834,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      242.973,
                      224.061
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 17",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  242.973,
                  224.059
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  242.973,
                  224.059
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 17",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          0,
                          0
                        ],
                        [
                          -9.31,
                          -5.456
                        ],
                        [
                          -3.959,
                          -1.587
                        ],
                        [
                          1.638,
                          2.37
                        ],
                        [
                          5.399,
                          4.281
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          8.022,
                          -10.957
                        ],
                        [
                          4.896,
                          -4.181
                        ],
                        [
                          -14.068,
                          8.247
                        ],
                        [
                          1.957,
                          -12.913
                        ]
                      ],
                      "o": [
                        [
                          0.573,
                          -12.975
                        ],
                        [
                          12.536,
                          7.348
                        ],
                        [
                          -2.715,
                          -3.194
                        ],
                        [
                          -4.599,
                          -6.652
                        ],
                        [
                          -8.403,
                          -6.663
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          -7.044,
                          0.978
                        ],
                        [
                          -22.892,
                          4.108
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          5.621,
                          -1.936
                        ],
                        [
                          9.31,
                          -5.457
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          15.289,
                          18.374
                        ],
                        [
                          34.548,
                          10.227
                        ],
                        [
                          48.232,
                          18.574
                        ],
                        [
                          41.458,
                          9.864
                        ],
                        [
                          25.609,
                          -9.408
                        ],
                        [
                          3.088,
                          -17.114
                        ],
                        [
                          5.262,
                          -20.561
                        ],
                        [
                          -5.89,
                          -15.669
                        ],
                        [
                          -40.521,
                          12.31
                        ],
                        [
                          -48.232,
                          20.561
                        ],
                        [
                          -18.25,
                          6.217
                        ],
                        [
                          14.914,
                          18.374
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      244.665,
                      188.054
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 18",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  244.665,
                  188.054
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  244.665,
                  188.054
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 18",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 6,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 18,
              "s": [
                3
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 37,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 56,
              "s": [
                3
              ]
            },
            {
              "t": 73,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            269.707,
            194.239,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            269.707,
            194.239,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          3.774,
                          3.055
                        ],
                        [
                          -1.565,
                          12.207
                        ],
                        [
                          11.562,
                          -9.734
                        ]
                      ],
                      "o": [
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          -11.094,
                          8.568
                        ],
                        [
                          5.479,
                          -11.922
                        ],
                        [
                          -0.469,
                          11.922
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 2,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      282.01,
                      185.739
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 19",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  279.885,
                  185.739
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  279.885,
                  185.739
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 19",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -18.666,
                          -0.929
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "o": [
                        [
                          11.073,
                          -7.853
                        ],
                        [
                          -9.196,
                          4.5
                        ],
                        [
                          0,
                          0
                        ]
                      ],
                      "v": [
                        [
                          3.797,
                          11.921
                        ],
                        [
                          9.745,
                          -11.921
                        ],
                        [
                          -12.949,
                          -6.38
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      277.745,
                      185.739
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 20",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  276.707,
                  185.739
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  276.707,
                  185.739
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 20",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 4,
      "nm": "Walk Outlines - Group 6",
      "parent": 11,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                -104
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 37,
              "s": [
                0
              ]
            },
            {
              "t": 73,
              "s": [
                -104
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            221.974,
            248.001,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            227.974,
            248.001,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            9.253,
                            -10.389
                          ],
                          [
                            10.041,
                            -2.281
                          ],
                          [
                            -8.862,
                            12.999
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -9.254,
                            10.388
                          ],
                          [
                            -11.188,
                            2.542
                          ],
                          [
                            9.641,
                            -14.142
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            25.174,
                            -1.036
                          ],
                          [
                            3.859,
                            4.839
                          ],
                          [
                            -9.333,
                            23.642
                          ],
                          [
                            -17.187,
                            -7.565
                          ],
                          [
                            10.945,
                            -26.228
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 18,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            12.712,
                            -9.963
                          ],
                          [
                            5.017,
                            3.251
                          ],
                          [
                            -11.706,
                            10.508
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -10.95,
                            8.582
                          ],
                          [
                            -10.229,
                            -6.629
                          ],
                          [
                            12.285,
                            -11.027
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            25.105,
                            -2.837
                          ],
                          [
                            4.096,
                            5.593
                          ],
                          [
                            -17.346,
                            15.554
                          ],
                          [
                            -8.905,
                            -12.324
                          ],
                          [
                            16.849,
                            -25.056
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 37,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            9.253,
                            -10.389
                          ],
                          [
                            10.041,
                            -2.281
                          ],
                          [
                            -8.862,
                            12.999
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -9.254,
                            10.388
                          ],
                          [
                            -11.188,
                            2.542
                          ],
                          [
                            9.641,
                            -14.142
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            25.174,
                            -1.036
                          ],
                          [
                            3.859,
                            4.839
                          ],
                          [
                            -9.333,
                            23.642
                          ],
                          [
                            -17.187,
                            -7.565
                          ],
                          [
                            10.945,
                            -26.228
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 53,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            10.537,
                            -6.043
                          ],
                          [
                            4.915,
                            9.048
                          ],
                          [
                            -14.568,
                            5.939
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -12.068,
                            6.922
                          ],
                          [
                            -6.686,
                            -12.308
                          ],
                          [
                            15.832,
                            -6.455
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            25.174,
                            -1.036
                          ],
                          [
                            5.363,
                            14.169
                          ],
                          [
                            -26.367,
                            15.461
                          ],
                          [
                            -7.988,
                            -7.473
                          ],
                          [
                            17.327,
                            -25.702
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "t": 73,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            9.253,
                            -10.389
                          ],
                          [
                            10.041,
                            -2.281
                          ],
                          [
                            -8.862,
                            12.999
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -9.254,
                            10.388
                          ],
                          [
                            -11.188,
                            2.542
                          ],
                          [
                            9.641,
                            -14.142
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            25.174,
                            -1.036
                          ],
                          [
                            3.859,
                            4.839
                          ],
                          [
                            -9.333,
                            23.642
                          ],
                          [
                            -17.187,
                            -7.565
                          ],
                          [
                            10.945,
                            -26.228
                          ]
                        ],
                        "c": false
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  208.042,
                  266.39
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 6",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 4,
      "nm": "Walk Outlines - Group 3",
      "parent": 10,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 18,
              "s": [
                33
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 37,
              "s": [
                15
              ]
            },
            {
              "t": 59,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 0,
              "s": [
                227.729,
                327.056,
                0
              ],
              "to": [
                -0.644,
                -0.776,
                0
              ],
              "ti": [
                0.108,
                -0.345,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 18,
              "s": [
                223.863,
                322.399,
                0
              ],
              "to": [
                -0.108,
                0.345,
                0
              ],
              "ti": [
                -0.644,
                -0.776,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 37,
              "s": [
                227.081,
                329.128,
                0
              ],
              "to": [
                0.644,
                0.776,
                0
              ],
              "ti": [
                -0.108,
                0.345,
                0
              ]
            },
            {
              "t": 59,
              "s": [
                227.729,
                327.056,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            227.729,
            327.056,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            -12.863,
                            -0.792
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            4.945,
                            -1.161
                          ],
                          [
                            5.486,
                            4.135
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            1.553,
                            2.543
                          ],
                          [
                            -8.279,
                            1.943
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            10.185,
                            -5.554
                          ],
                          [
                            12.415,
                            -3.632
                          ],
                          [
                            8.162,
                            3.31
                          ],
                          [
                            -13.677,
                            2.211
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 18,
                    "s": [
                      {
                        "i": [
                          [
                            -12.863,
                            -0.792
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            2.931,
                            -0.537
                          ],
                          [
                            5.486,
                            4.135
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            1.553,
                            2.543
                          ],
                          [
                            -6.731,
                            1.232
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            10.185,
                            -5.554
                          ],
                          [
                            12.124,
                            -2.378
                          ],
                          [
                            9.279,
                            4.03
                          ],
                          [
                            -13.677,
                            2.211
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 37,
                    "s": [
                      {
                        "i": [
                          [
                            -12.863,
                            -0.792
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            6.63,
                            -2.354
                          ],
                          [
                            5.486,
                            4.135
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            2.504,
                            1.929
                          ],
                          [
                            -6.449,
                            2.289
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            10.185,
                            -5.554
                          ],
                          [
                            13.458,
                            -3.653
                          ],
                          [
                            10.179,
                            2.877
                          ],
                          [
                            -13.677,
                            2.211
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "t": 73,
                    "s": [
                      {
                        "i": [
                          [
                            -12.863,
                            -0.792
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            4.945,
                            -1.161
                          ],
                          [
                            5.486,
                            4.135
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            1.553,
                            2.543
                          ],
                          [
                            -8.279,
                            1.943
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            10.185,
                            -5.554
                          ],
                          [
                            12.415,
                            -3.632
                          ],
                          [
                            8.162,
                            3.31
                          ],
                          [
                            -13.677,
                            2.211
                          ]
                        ],
                        "c": true
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.477999997606,
                  0.361000001197,
                  0.344999994016,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  228.191,
                  327.287
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 3",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 4,
      "nm": "Walk Outlines - Group 4",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                16
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 37,
              "s": [
                -33
              ]
            },
            {
              "t": 73,
              "s": [
                16
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            57.714,
            134.076,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            212.714,
            291.576,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.111,
                            -4.873
                          ],
                          [
                            0.81,
                            -1.164
                          ],
                          [
                            3.796,
                            4.639
                          ],
                          [
                            0.029,
                            9.888
                          ],
                          [
                            0,
                            5.157
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.134,
                            5.858
                          ],
                          [
                            -2.089,
                            3
                          ],
                          [
                            -0.866,
                            -1.058
                          ],
                          [
                            -0.017,
                            -6.065
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            13.214,
                            -6.439
                          ],
                          [
                            13.128,
                            2.715
                          ],
                          [
                            11.853,
                            16.451
                          ],
                          [
                            -12.126,
                            16.451
                          ],
                          [
                            -13.222,
                            -3.452
                          ],
                          [
                            -13.191,
                            -21.09
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 18,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.111,
                            -4.873
                          ],
                          [
                            0.961,
                            -0.529
                          ],
                          [
                            3.796,
                            4.639
                          ],
                          [
                            0.921,
                            9.845
                          ],
                          [
                            -2.025,
                            12.68
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.134,
                            5.858
                          ],
                          [
                            -3.202,
                            1.764
                          ],
                          [
                            -0.866,
                            -1.058
                          ],
                          [
                            -0.268,
                            -2.863
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            13.214,
                            -6.439
                          ],
                          [
                            14.881,
                            2.797
                          ],
                          [
                            5.571,
                            15.23
                          ],
                          [
                            -14.569,
                            3.507
                          ],
                          [
                            -11.159,
                            -6.003
                          ],
                          [
                            -14.164,
                            -19.457
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 28,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.111,
                            -4.873
                          ],
                          [
                            0.881,
                            -0.863
                          ],
                          [
                            3.796,
                            4.639
                          ],
                          [
                            0.451,
                            9.868
                          ],
                          [
                            -4.256,
                            10.418
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.134,
                            5.858
                          ],
                          [
                            -2.616,
                            2.414
                          ],
                          [
                            -0.866,
                            -1.058
                          ],
                          [
                            -0.136,
                            -4.548
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            13.214,
                            -6.439
                          ],
                          [
                            13.958,
                            2.754
                          ],
                          [
                            8.877,
                            15.872
                          ],
                          [
                            -13.283,
                            10.32
                          ],
                          [
                            -12.347,
                            -1.033
                          ],
                          [
                            -15.001,
                            -19.164
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 37,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.111,
                            -4.873
                          ],
                          [
                            0.81,
                            -1.164
                          ],
                          [
                            3.796,
                            4.639
                          ],
                          [
                            0.029,
                            9.888
                          ],
                          [
                            -7.982,
                            13.508
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.134,
                            5.858
                          ],
                          [
                            -2.089,
                            3
                          ],
                          [
                            -0.866,
                            -1.058
                          ],
                          [
                            -0.017,
                            -6.065
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            13.214,
                            -6.439
                          ],
                          [
                            13.128,
                            2.715
                          ],
                          [
                            11.853,
                            16.451
                          ],
                          [
                            -12.126,
                            16.451
                          ],
                          [
                            -13.417,
                            3.44
                          ],
                          [
                            -15.575,
                            -18.746
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 50,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.111,
                            -4.873
                          ],
                          [
                            0.81,
                            -1.164
                          ],
                          [
                            3.796,
                            4.639
                          ],
                          [
                            0.029,
                            9.888
                          ],
                          [
                            -2.762,
                            8.938
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.134,
                            5.858
                          ],
                          [
                            -2.089,
                            3
                          ],
                          [
                            -0.866,
                            -1.058
                          ],
                          [
                            -0.017,
                            -6.065
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            13.214,
                            -6.439
                          ],
                          [
                            13.128,
                            2.715
                          ],
                          [
                            11.853,
                            16.451
                          ],
                          [
                            -12.126,
                            16.451
                          ],
                          [
                            -13.417,
                            3.44
                          ],
                          [
                            -14.989,
                            -18.44
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "t": 73,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            0.111,
                            -4.873
                          ],
                          [
                            0.81,
                            -1.164
                          ],
                          [
                            3.796,
                            4.639
                          ],
                          [
                            0.029,
                            9.888
                          ],
                          [
                            0,
                            5.157
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.134,
                            5.858
                          ],
                          [
                            -2.089,
                            3
                          ],
                          [
                            -0.866,
                            -1.058
                          ],
                          [
                            -0.017,
                            -6.065
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            13.214,
                            -6.439
                          ],
                          [
                            13.128,
                            2.715
                          ],
                          [
                            11.853,
                            16.451
                          ],
                          [
                            -12.126,
                            16.451
                          ],
                          [
                            -13.222,
                            -3.452
                          ],
                          [
                            -13.191,
                            -21.09
                          ]
                        ],
                        "c": false
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  226.094,
                  312.322
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 4",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 11,
      "ty": 4,
      "nm": "BODY",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 0,
              "s": [
                93.959,
                116.131,
                0
              ],
              "to": [
                0,
                -0.5,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 18,
              "s": [
                93.959,
                113.131,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 37,
              "s": [
                93.959,
                116.131,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 56,
              "s": [
                93.959,
                113.131,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                -0.5,
                0
              ]
            },
            {
              "t": 73,
              "s": [
                93.959,
                116.131,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            248.959,
            271.131,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                -4.232,
                                -12.413
                              ],
                              [
                                -0.181,
                                11.651
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                8.842,
                                25.937
                              ],
                              [
                                0.441,
                                -28.306
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -18.835,
                                -25.889
                              ],
                              [
                                -18.009,
                                12.213
                              ],
                              [
                                25.954,
                                16.081
                              ],
                              [
                                20.486,
                                -31.125
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 18,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                -4.465,
                                -12.331
                              ],
                              [
                                -0.01,
                                11.652
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                6.848,
                                18.913
                              ],
                              [
                                0.02,
                                -22.692
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -18.835,
                                -25.889
                              ],
                              [
                                -18.009,
                                12.213
                              ],
                              [
                                22.454,
                                16.643
                              ],
                              [
                                20.486,
                                -31.125
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 37,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                -4.232,
                                -12.413
                              ],
                              [
                                -0.181,
                                11.651
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                8.842,
                                25.937
                              ],
                              [
                                0.441,
                                -28.306
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -18.835,
                                -25.889
                              ],
                              [
                                -18.009,
                                12.213
                              ],
                              [
                                25.954,
                                16.081
                              ],
                              [
                                20.486,
                                -31.125
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 56,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                -4.465,
                                -12.331
                              ],
                              [
                                -0.01,
                                11.652
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                6.848,
                                18.913
                              ],
                              [
                                0.02,
                                -22.692
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -18.835,
                                -25.889
                              ],
                              [
                                -18.009,
                                12.213
                              ],
                              [
                                22.454,
                                16.643
                              ],
                              [
                                20.486,
                                -31.125
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 73,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                -4.232,
                                -12.413
                              ],
                              [
                                -0.181,
                                11.651
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                8.842,
                                25.937
                              ],
                              [
                                0.441,
                                -28.306
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                -18.835,
                                -25.889
                              ],
                              [
                                -18.009,
                                12.213
                              ],
                              [
                                25.954,
                                16.081
                              ],
                              [
                                20.486,
                                -31.125
                              ]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.991999983788,
                      0.949000000954,
                      0.917999982834,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      262.542,
                      265.975
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 22",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      263.653,
                      263.362
                    ],
                    "to": [
                      0,
                      0.417
                    ],
                    "ti": [
                      0,
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 18,
                    "s": [
                      263.653,
                      265.862
                    ],
                    "to": [
                      0,
                      0
                    ],
                    "ti": [
                      0,
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 37,
                    "s": [
                      263.653,
                      263.362
                    ],
                    "to": [
                      0,
                      0
                    ],
                    "ti": [
                      0,
                      0
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 56,
                    "s": [
                      263.653,
                      265.862
                    ],
                    "to": [
                      0,
                      0
                    ],
                    "ti": [
                      0,
                      0.417
                    ]
                  },
                  {
                    "t": 73,
                    "s": [
                      263.653,
                      263.362
                    ]
                  }
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  263.653,
                  263.362
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 22",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                -32.87
                              ],
                              [
                                -9.057,
                                -3.598
                              ],
                              [
                                -4.203,
                                17.311
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                20.252,
                                8.045
                              ],
                              [
                                5.473,
                                -22.541
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                34.48,
                                -23.723
                              ],
                              [
                                33.361,
                                -43.605
                              ],
                              [
                                -34.216,
                                -40.736
                              ],
                              [
                                -36.346,
                                26.952
                              ],
                              [
                                -20.541,
                                35.56
                              ],
                              [
                                37.654,
                                19.637
                              ],
                              [
                                33.651,
                                -40.438
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.833,
                          "y": 0.833
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 18,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -3.983,
                                -22.22
                              ],
                              [
                                -9.057,
                                -3.598
                              ],
                              [
                                -0.587,
                                17.804
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                1.058,
                                5.905
                              ],
                              [
                                20.252,
                                8.045
                              ],
                              [
                                0.692,
                                -20.998
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                34.48,
                                -23.723
                              ],
                              [
                                33.361,
                                -43.605
                              ],
                              [
                                -34.216,
                                -40.736
                              ],
                              [
                                -36.346,
                                25.202
                              ],
                              [
                                -20.541,
                                35.56
                              ],
                              [
                                35.654,
                                20.887
                              ],
                              [
                                33.651,
                                -40.438
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.167,
                          "y": 0.167
                        },
                        "t": 27,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -3.983,
                                -22.22
                              ],
                              [
                                -9.057,
                                -3.598
                              ],
                              [
                                -2.252,
                                17.577
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                1.058,
                                5.905
                              ],
                              [
                                20.252,
                                8.045
                              ],
                              [
                                2.894,
                                -21.709
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                34.48,
                                -23.723
                              ],
                              [
                                33.361,
                                -43.605
                              ],
                              [
                                -34.216,
                                -40.736
                              ],
                              [
                                -37.086,
                                22.549
                              ],
                              [
                                -20.541,
                                35.56
                              ],
                              [
                                36.575,
                                20.311
                              ],
                              [
                                33.651,
                                -40.438
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 37,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -3.983,
                                -22.22
                              ],
                              [
                                -9.057,
                                -3.598
                              ],
                              [
                                -4.203,
                                17.311
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                1.058,
                                5.905
                              ],
                              [
                                20.252,
                                8.045
                              ],
                              [
                                5.473,
                                -22.541
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                34.48,
                                -23.723
                              ],
                              [
                                33.361,
                                -43.605
                              ],
                              [
                                -34.216,
                                -40.736
                              ],
                              [
                                -36.596,
                                23.577
                              ],
                              [
                                -20.541,
                                35.56
                              ],
                              [
                                37.654,
                                19.637
                              ],
                              [
                                33.651,
                                -40.438
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 56,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                -3.983,
                                -22.22
                              ],
                              [
                                -9.057,
                                -3.598
                              ],
                              [
                                -0.587,
                                17.804
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                1.058,
                                5.905
                              ],
                              [
                                20.252,
                                8.045
                              ],
                              [
                                0.692,
                                -20.998
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                34.48,
                                -23.723
                              ],
                              [
                                33.361,
                                -43.605
                              ],
                              [
                                -34.216,
                                -40.736
                              ],
                              [
                                -36.721,
                                25.452
                              ],
                              [
                                -20.541,
                                35.56
                              ],
                              [
                                35.654,
                                20.887
                              ],
                              [
                                33.651,
                                -40.438
                              ]
                            ],
                            "c": false
                          }
                        ]
                      },
                      {
                        "t": 73,
                        "s": [
                          {
                            "i": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                -32.87
                              ],
                              [
                                -9.057,
                                -3.598
                              ],
                              [
                                -4.203,
                                17.311
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "o": [
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                0,
                                0
                              ],
                              [
                                20.252,
                                8.045
                              ],
                              [
                                5.473,
                                -22.541
                              ],
                              [
                                0,
                                0
                              ]
                            ],
                            "v": [
                              [
                                34.48,
                                -23.723
                              ],
                              [
                                33.361,
                                -43.605
                              ],
                              [
                                -34.216,
                                -40.736
                              ],
                              [
                                -36.346,
                                26.952
                              ],
                              [
                                -20.541,
                                35.56
                              ],
                              [
                                37.654,
                                19.637
                              ],
                              [
                                33.651,
                                -40.438
                              ]
                            ],
                            "c": false
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      249.248,
                      273.28
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 23",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  248.959,
                  271.131
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  248.959,
                  271.131
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 23",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 12,
      "ty": 4,
      "nm": "Walk Outlines - Group 5",
      "parent": 13,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            276.306,
            322.633,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            276.306,
            322.633,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            -12.281,
                            3.907
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            2.54,
                            -1.56
                          ],
                          [
                            6.608,
                            1.875
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            2.367,
                            1.81
                          ],
                          [
                            -5.831,
                            3.58
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            7.062,
                            -8.866
                          ],
                          [
                            10.017,
                            -6.605
                          ],
                          [
                            9.678,
                            0.398
                          ],
                          [
                            -12.384,
                            6.992
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 37,
                    "s": [
                      {
                        "i": [
                          [
                            -12.281,
                            3.907
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            2.54,
                            -1.56
                          ],
                          [
                            6.608,
                            1.875
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            1.697,
                            3.201
                          ],
                          [
                            -5.831,
                            3.58
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            7.062,
                            -8.866
                          ],
                          [
                            9.349,
                            -4.945
                          ],
                          [
                            9.01,
                            2.058
                          ],
                          [
                            -12.384,
                            6.992
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "t": 74,
                    "s": [
                      {
                        "i": [
                          [
                            -12.281,
                            3.907
                          ],
                          [
                            0,
                            0
                          ],
                          [
                            2.54,
                            -1.56
                          ],
                          [
                            6.608,
                            1.875
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            2.367,
                            1.81
                          ],
                          [
                            -5.831,
                            3.58
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            7.062,
                            -8.866
                          ],
                          [
                            10.017,
                            -6.605
                          ],
                          [
                            9.678,
                            0.398
                          ],
                          [
                            -12.384,
                            6.992
                          ]
                        ],
                        "c": true
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.477999997606,
                  0.361000001197,
                  0.344999994016,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  283.777,
                  321.739
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 5",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 13,
      "ty": 4,
      "nm": "Walk Outlines - Group 24",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 37,
              "s": [
                56
              ]
            },
            {
              "t": 73,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 0,
              "s": [
                108.779,
                151.71,
                0
              ],
              "to": [
                0,
                -0.735,
                0
              ],
              "ti": [
                0,
                2.04,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 19,
              "s": [
                107.154,
                146.834,
                0
              ],
              "to": [
                0,
                -2.353,
                0
              ],
              "ti": [
                0,
                0,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 37,
              "s": [
                108.779,
                139.585,
                0
              ],
              "to": [
                0,
                0,
                0
              ],
              "ti": [
                0,
                -2.485,
                0
              ]
            },
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 56,
              "s": [
                108.779,
                140.85,
                0
              ],
              "to": [
                0,
                1.911,
                0
              ],
              "ti": [
                0,
                -0.688,
                0
              ]
            },
            {
              "t": 73,
              "s": [
                108.779,
                151.71,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            263.779,
            306.71,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.833,
                      "y": 0.833
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.619,
                            -1.583
                          ],
                          [
                            -1.552,
                            -0.879
                          ],
                          [
                            -0.864,
                            3.552
                          ],
                          [
                            3.305,
                            8.476
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0.778,
                            1.898
                          ],
                          [
                            2.622,
                            6.701
                          ],
                          [
                            5.216,
                            2.956
                          ],
                          [
                            0.304,
                            -1.25
                          ],
                          [
                            -3.369,
                            -8.644
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.145,
                            3.941
                          ],
                          [
                            -13.059,
                            9.164
                          ],
                          [
                            -7.52,
                            20.592
                          ],
                          [
                            14.84,
                            11.932
                          ],
                          [
                            8.643,
                            -6.503
                          ],
                          [
                            1.791,
                            -23.548
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.167,
                      "y": 0.167
                    },
                    "t": 18,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.619,
                            -1.583
                          ],
                          [
                            -1.552,
                            -0.879
                          ],
                          [
                            -0.864,
                            3.552
                          ],
                          [
                            3.31,
                            8.474
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0.778,
                            1.898
                          ],
                          [
                            2.622,
                            6.701
                          ],
                          [
                            5.216,
                            2.956
                          ],
                          [
                            0.304,
                            -1.25
                          ],
                          [
                            -3.621,
                            -9.27
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.145,
                            3.941
                          ],
                          [
                            -13.059,
                            9.164
                          ],
                          [
                            -7.52,
                            20.592
                          ],
                          [
                            14.84,
                            11.932
                          ],
                          [
                            8.643,
                            -6.503
                          ],
                          [
                            -1.921,
                            -20.984
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 37,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.619,
                            -1.583
                          ],
                          [
                            -1.552,
                            -0.879
                          ],
                          [
                            -0.864,
                            3.552
                          ],
                          [
                            3.316,
                            8.472
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0.778,
                            1.898
                          ],
                          [
                            2.622,
                            6.701
                          ],
                          [
                            5.216,
                            2.956
                          ],
                          [
                            0.304,
                            -1.25
                          ],
                          [
                            -3.894,
                            -9.948
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.145,
                            3.941
                          ],
                          [
                            -13.059,
                            9.164
                          ],
                          [
                            -7.52,
                            20.592
                          ],
                          [
                            14.84,
                            11.932
                          ],
                          [
                            8.643,
                            -6.503
                          ],
                          [
                            -5.719,
                            -23.607
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 56,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.619,
                            -1.583
                          ],
                          [
                            -1.552,
                            -0.879
                          ],
                          [
                            -0.864,
                            3.552
                          ],
                          [
                            3.316,
                            8.472
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0.778,
                            1.898
                          ],
                          [
                            2.622,
                            6.701
                          ],
                          [
                            5.216,
                            2.956
                          ],
                          [
                            0.304,
                            -1.25
                          ],
                          [
                            -3.894,
                            -9.948
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.145,
                            3.941
                          ],
                          [
                            -13.059,
                            9.164
                          ],
                          [
                            -7.52,
                            20.592
                          ],
                          [
                            14.84,
                            11.932
                          ],
                          [
                            8.643,
                            -6.503
                          ],
                          [
                            -2.47,
                            -21.717
                          ]
                        ],
                        "c": false
                      }
                    ]
                  },
                  {
                    "t": 75,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -0.619,
                            -1.583
                          ],
                          [
                            -1.552,
                            -0.879
                          ],
                          [
                            -0.864,
                            3.552
                          ],
                          [
                            3.305,
                            8.476
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0.778,
                            1.898
                          ],
                          [
                            2.622,
                            6.701
                          ],
                          [
                            5.216,
                            2.956
                          ],
                          [
                            0.304,
                            -1.25
                          ],
                          [
                            -3.369,
                            -8.644
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -15.145,
                            3.941
                          ],
                          [
                            -13.059,
                            9.164
                          ],
                          [
                            -7.52,
                            20.592
                          ],
                          [
                            14.84,
                            11.932
                          ],
                          [
                            8.643,
                            -6.503
                          ],
                          [
                            1.791,
                            -23.548
                          ]
                        ],
                        "c": false
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  277.921,
                  307.743
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 24",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 14,
      "ty": 4,
      "nm": "Walk Outlines - Group 25",
      "parent": 11,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                131
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 37,
              "s": [
                0
              ]
            },
            {
              "t": 73,
              "s": [
                131
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            264.343,
            251.83,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            269.343,
            246.83,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 1,
                "k": [
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 0,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -15.238,
                            -4.274
                          ],
                          [
                            -0.667,
                            -6.347
                          ],
                          [
                            15.319,
                            3.582
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            13.395,
                            3.757
                          ],
                          [
                            1.2,
                            11.41
                          ],
                          [
                            -17.166,
                            -4.013
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -29.837,
                            -18.773
                          ],
                          [
                            -1.176,
                            -14.192
                          ],
                          [
                            24.04,
                            4.308
                          ],
                          [
                            -0.384,
                            9.391
                          ],
                          [
                            -29.097,
                            7.425
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 18.006,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -12.957,
                            -2.281
                          ],
                          [
                            -1.317,
                            -7.555
                          ],
                          [
                            15.731,
                            -0.241
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            13.701,
                            2.412
                          ],
                          [
                            1.971,
                            11.302
                          ],
                          [
                            -19.246,
                            0.295
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -29.837,
                            -18.773
                          ],
                          [
                            -4.761,
                            -11.139
                          ],
                          [
                            20.58,
                            0.357
                          ],
                          [
                            -5.036,
                            14.214
                          ],
                          [
                            -29.097,
                            7.425
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 37,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -13.891,
                            0.76
                          ],
                          [
                            -9.978,
                            -5.662
                          ],
                          [
                            15.467,
                            -2.878
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            13.891,
                            -0.758
                          ],
                          [
                            9.978,
                            5.662
                          ],
                          [
                            -16.826,
                            3.131
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -29.837,
                            -18.773
                          ],
                          [
                            -3.228,
                            -5.121
                          ],
                          [
                            19.859,
                            -11.933
                          ],
                          [
                            5.381,
                            15.642
                          ],
                          [
                            -29.097,
                            7.425
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "i": {
                      "x": 0.667,
                      "y": 1
                    },
                    "o": {
                      "x": 0.333,
                      "y": 0
                    },
                    "t": 56,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -13.891,
                            0.76
                          ],
                          [
                            -5.941,
                            -10.203
                          ],
                          [
                            15.467,
                            -2.878
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            13.891,
                            -0.758
                          ],
                          [
                            3.843,
                            6.6
                          ],
                          [
                            -16.826,
                            3.131
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -29.837,
                            -18.773
                          ],
                          [
                            -4.301,
                            -17.732
                          ],
                          [
                            19.155,
                            -16.369
                          ],
                          [
                            -1.316,
                            2.565
                          ],
                          [
                            -29.097,
                            7.425
                          ]
                        ],
                        "c": true
                      }
                    ]
                  },
                  {
                    "t": 73,
                    "s": [
                      {
                        "i": [
                          [
                            0,
                            0
                          ],
                          [
                            -15.238,
                            -4.274
                          ],
                          [
                            -0.667,
                            -6.347
                          ],
                          [
                            15.319,
                            3.582
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "o": [
                          [
                            0,
                            0
                          ],
                          [
                            13.395,
                            3.757
                          ],
                          [
                            1.2,
                            11.41
                          ],
                          [
                            -17.166,
                            -4.013
                          ],
                          [
                            0,
                            0
                          ]
                        ],
                        "v": [
                          [
                            -29.837,
                            -18.773
                          ],
                          [
                            -1.176,
                            -14.192
                          ],
                          [
                            24.04,
                            4.308
                          ],
                          [
                            -0.384,
                            9.391
                          ],
                          [
                            -29.097,
                            7.425
                          ]
                        ],
                        "c": true
                      }
                    ]
                  }
                ],
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109999997008,
                  0.109999997008,
                  0.109999997008,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2,
                "ix": 5
              },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.779999976065,
                  0.722000002394,
                  0.705999995213,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  298.982,
                  248.623
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 25",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 15,
      "ty": 4,
      "nm": "Merged Shape Layer",
      "parent": 11,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 18,
              "s": [
                10
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 37,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 56,
              "s": [
                10
              ]
            },
            {
              "t": 73,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            230.72,
            280.596,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            230.72,
            280.596,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -3.569,
                          2.606
                        ],
                        [
                          -1.549,
                          1.431
                        ],
                        [
                          1.889,
                          6.445
                        ],
                        [
                          2.308,
                          -2.149
                        ],
                        [
                          2.344,
                          -3.179
                        ],
                        [
                          -4.258,
                          -4.795
                        ]
                      ],
                      "o": [
                        [
                          1.921,
                          -1.403
                        ],
                        [
                          -8.32,
                          -8.659
                        ],
                        [
                          -2.339,
                          1.477
                        ],
                        [
                          -4.062,
                          3.781
                        ],
                        [
                          3.313,
                          9.788
                        ],
                        [
                          4.589,
                          -1.602
                        ]
                      ],
                      "v": [
                        [
                          10.342,
                          12.737
                        ],
                        [
                          15.529,
                          8.475
                        ],
                        [
                          0.858,
                          -18.988
                        ],
                        [
                          -6.147,
                          -13.586
                        ],
                        [
                          -15.529,
                          -3.313
                        ],
                        [
                          -2.252,
                          18.988
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.477999985218,
                      0.361000001431,
                      0.344999998808,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      198.985,
                      305.22
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 26",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  198.985,
                  305.22
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  198.985,
                  305.22
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 26",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 1,
                    "k": [
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 0,
                        "s": [
                          {
                            "i": [
                              [
                                3.313,
                                9.788
                              ],
                              [
                                2.394,
                                -8.033
                              ],
                              [
                                -3.095,
                                0.551
                              ],
                              [
                                -7.007,
                                2.444
                              ]
                            ],
                            "o": [
                              [
                                -4.842,
                                6.567
                              ],
                              [
                                -0.897,
                                3.012
                              ],
                              [
                                4.878,
                                -0.869
                              ],
                              [
                                -4.257,
                                -4.795
                              ]
                            ],
                            "v": [
                              [
                                -0.971,
                                -13.842
                              ],
                              [
                                -11.409,
                                7.928
                              ],
                              [
                                -6.594,
                                13.291
                              ],
                              [
                                12.305,
                                8.459
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 18,
                        "s": [
                          {
                            "i": [
                              [
                                3.313,
                                9.788
                              ],
                              [
                                6.602,
                                -6.244
                              ],
                              [
                                -3.052,
                                -0.755
                              ],
                              [
                                -7.007,
                                2.444
                              ]
                            ],
                            "o": [
                              [
                                -4.842,
                                6.567
                              ],
                              [
                                -2.283,
                                2.16
                              ],
                              [
                                5.999,
                                1.485
                              ],
                              [
                                -4.257,
                                -4.795
                              ]
                            ],
                            "v": [
                              [
                                -0.971,
                                -13.842
                              ],
                              [
                                -14.131,
                                4.727
                              ],
                              [
                                -9.316,
                                10.09
                              ],
                              [
                                12.305,
                                8.459
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 37,
                        "s": [
                          {
                            "i": [
                              [
                                3.313,
                                9.788
                              ],
                              [
                                2.394,
                                -8.033
                              ],
                              [
                                -3.095,
                                0.551
                              ],
                              [
                                -7.007,
                                2.444
                              ]
                            ],
                            "o": [
                              [
                                -4.842,
                                6.567
                              ],
                              [
                                -0.897,
                                3.012
                              ],
                              [
                                4.878,
                                -0.869
                              ],
                              [
                                -4.257,
                                -4.795
                              ]
                            ],
                            "v": [
                              [
                                -0.971,
                                -13.842
                              ],
                              [
                                -11.409,
                                7.928
                              ],
                              [
                                -6.594,
                                13.291
                              ],
                              [
                                12.305,
                                8.459
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "i": {
                          "x": 0.667,
                          "y": 1
                        },
                        "o": {
                          "x": 0.333,
                          "y": 0
                        },
                        "t": 56,
                        "s": [
                          {
                            "i": [
                              [
                                3.313,
                                9.788
                              ],
                              [
                                6.602,
                                -6.244
                              ],
                              [
                                -3.052,
                                -0.755
                              ],
                              [
                                -7.007,
                                2.444
                              ]
                            ],
                            "o": [
                              [
                                -4.842,
                                6.567
                              ],
                              [
                                -2.283,
                                2.16
                              ],
                              [
                                5.999,
                                1.485
                              ],
                              [
                                -4.257,
                                -4.795
                              ]
                            ],
                            "v": [
                              [
                                -0.971,
                                -13.842
                              ],
                              [
                                -14.131,
                                4.727
                              ],
                              [
                                -9.316,
                                10.09
                              ],
                              [
                                12.305,
                                8.459
                              ]
                            ],
                            "c": true
                          }
                        ]
                      },
                      {
                        "t": 73,
                        "s": [
                          {
                            "i": [
                              [
                                3.313,
                                9.788
                              ],
                              [
                                2.394,
                                -8.033
                              ],
                              [
                                -3.095,
                                0.551
                              ],
                              [
                                -7.007,
                                2.444
                              ]
                            ],
                            "o": [
                              [
                                -4.842,
                                6.567
                              ],
                              [
                                -0.897,
                                3.012
                              ],
                              [
                                4.878,
                                -0.869
                              ],
                              [
                                -4.257,
                                -4.795
                              ]
                            ],
                            "v": [
                              [
                                -0.971,
                                -13.842
                              ],
                              [
                                -11.409,
                                7.928
                              ],
                              [
                                -6.594,
                                13.291
                              ],
                              [
                                12.305,
                                8.459
                              ]
                            ],
                            "c": true
                          }
                        ]
                      }
                    ],
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      184.427,
                      315.749
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 27",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  184.786,
                  315.508
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  184.786,
                  315.508
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 27",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [
                          -8.321,
                          -8.659
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          13.006,
                          -8.209
                        ]
                      ],
                      "o": [
                        [
                          12.216,
                          -11.275
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          0,
                          0
                        ],
                        [
                          1.888,
                          6.444
                        ]
                      ],
                      "v": [
                        [
                          -0.958,
                          17.836
                        ],
                        [
                          15.628,
                          -6.843
                        ],
                        [
                          10.701,
                          -15.078
                        ],
                        [
                          -15.629,
                          -9.627
                        ]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      0.109999999404,
                      0.109999999404,
                      0.109999999404,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 2,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 10,
                  "bm": 0,
                  "nm": "Stroke 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.77999997139,
                      0.722000002861,
                      0.705999970436,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      215.472,
                      295.858
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 28",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  215.472,
                  297.015
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  215.472,
                  297.015
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Walk Outlines - Group 28",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 16,
      "ty": 4,
      "nm": "Shape Layer 1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            90.5,
            95,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      61.625,
                      80
                    ],
                    [
                      49.625,
                      80
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109803929048,
                  0.109803929048,
                  0.109803929048,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2.5,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 3",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      29.75,
                      80
                    ],
                    [
                      -7.75,
                      80
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109803929048,
                  0.109803929048,
                  0.109803929048,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2.5,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 2",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      -24.25,
                      80
                    ],
                    [
                      -40.75,
                      80
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.109803929048,
                  0.109803929048,
                  0.109803929048,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 2.5,
                "ix": 5
              },
              "lc": 2,
              "lj": 2,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 0,
            "k": 94,
            "ix": 1
          },
          "e": {
            "a": 0,
            "k": 36,
            "ix": 2
          },
          "o": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.833
                  ],
                  "y": [
                    0.833
                  ]
                },
                "o": {
                  "x": [
                    0.167
                  ],
                  "y": [
                    0.167
                  ]
                },
                "t": 0,
                "s": [
                  0
                ]
              },
              {
                "t": 74,
                "s": [
                  360
                ]
              }
            ],
            "ix": 3
          },
          "m": 1,
          "ix": 4,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;