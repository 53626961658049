"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("@components/Flow/HelperLines/utils");
var conditionActions_1 = require("@pages/Conditions/reducers/conditionActions");
var react_1 = require("react");
var reactflow_1 = require("reactflow");
var useChangeElements = function (dispatch, nodeElements, edgeElements, saveNode, saveEdge) {
    var _a = (0, react_1.useState)(undefined), helperLineHorizontal = _a[0], setHelperLineHorizontal = _a[1];
    var _b = (0, react_1.useState)(undefined), helperLineVertical = _b[0], setHelperLineVertical = _b[1];
    var _c = (0, react_1.useState)(false), isMultiSelectionActive = _c[0], setMultiSelectionActive = _c[1];
    var customApplyNodeChanges = (0, react_1.useCallback)(function (changes, nodes) {
        // reset the helper lines (clear existing lines, if any)
        setHelperLineHorizontal(undefined);
        setHelperLineVertical(undefined);
        // this will be true if it's a single node being dragged
        // inside we calculate the helper lines and snap position for the position where the node is being moved to
        if (changes.length >= 1 &&
            !(changes.length === nodes.length) &&
            changes[0].type === "position" &&
            changes[0].dragging &&
            changes[0].position) {
            var _a = (0, utils_1.getHelperLines)(changes, nodes), helperLines = _a.helperLines, updatedChanges = _a.updatedChanges;
            var shouldSnapNode = helperLines.snapPosition.x !== undefined ||
                helperLines.snapPosition.y !== undefined;
            if (shouldSnapNode) {
                setHelperLineHorizontal(helperLines.horizontal);
                setHelperLineVertical(helperLines.vertical);
                // @ts-ignore
                return (0, reactflow_1.applyNodeChanges)(updatedChanges, nodes);
            }
        }
        // @ts-ignore
        return (0, reactflow_1.applyNodeChanges)(changes, nodes);
    }, []);
    var onSelectionEnd = (0, react_1.useCallback)(function (_e) {
        setMultiSelectionActive(false);
        var nodeSelectionChanges = [];
        var edgeSelectionChanges = [];
        Array.from(document.querySelectorAll(".react-flow__node.selected")).forEach(function (node) {
            var id = node.getAttribute("data-id");
            if (id) {
                nodeSelectionChanges.push({
                    id: id,
                    type: "select",
                    selected: true,
                });
            }
        });
        Array.from(document.querySelectorAll(".react-flow__edge.selected")).forEach(function (edge) {
            var pathWithEdgeId = edge.querySelector(".react-flow__edge-path");
            if (!pathWithEdgeId) {
                return;
            }
            var id = pathWithEdgeId.getAttribute("id");
            if (id) {
                edgeSelectionChanges.push({
                    id: id,
                    type: "select",
                    selected: true,
                });
            }
        });
        if (nodeSelectionChanges.length !== 0) {
            var appliesNodes = customApplyNodeChanges(nodeSelectionChanges, nodeElements);
            dispatch((0, conditionActions_1.changeNodes)(appliesNodes));
        }
        if (edgeSelectionChanges.length !== 0) {
            dispatch((0, conditionActions_1.changeEdges)((0, reactflow_1.applyEdgeChanges)(edgeSelectionChanges, edgeElements)));
        }
    }, [nodeElements, edgeElements, customApplyNodeChanges]);
    var onNodesChange = (0, react_1.useCallback)(function (changes) {
        if (isMultiSelectionActive) {
            return;
        }
        var appliesNodes = customApplyNodeChanges(changes, nodeElements);
        dispatch((0, conditionActions_1.changeNodes)(appliesNodes));
        if (changes.length === 1 && changes[0].type === "select" && !changes[0].selected) {
            saveNode(changes[0].id);
        }
    }, [nodeElements, isMultiSelectionActive]);
    var onEdgesChange = (0, react_1.useCallback)(function (changes) {
        if (isMultiSelectionActive) {
            return;
        }
        if (changes.length === 1 && changes[0].type === "select" && !changes[0].selected) {
            // @ts-ignore
            var edge = edgeElements.find(function (x) { return x.id === changes[0].id; });
            saveEdge(edge);
        }
        if ("id" in changes[0] && changes[0].id === "-1")
            return;
        dispatch((0, conditionActions_1.changeEdges)((0, reactflow_1.applyEdgeChanges)(changes, edgeElements)));
    }, [edgeElements, isMultiSelectionActive]);
    var onSelectionStart = (0, react_1.useCallback)(function () {
        setMultiSelectionActive(true);
    }, []);
    return {
        onNodesChange: onNodesChange,
        onEdgesChange: onEdgesChange,
        helperLineHorizontal: helperLineHorizontal,
        helperLineVertical: helperLineVertical,
        onSelectionEnd: onSelectionEnd,
        onSelectionStart: onSelectionStart,
    };
};
exports.default = useChangeElements;
