"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var propertyConstants_1 = require("./propertyConstants");
var lookupReducer_1 = require("@pages/Lookup/reducers/lookupReducer");
var initialMap = {
    center: [55.68136, 12.58171],
    map: undefined,
};
var initialState = {
    jordstykker: [],
    properties: [],
    newProperties: [],
    loadings: lookupReducer_1.initialLookupLoadings,
    map: initialMap,
};
function reducer(state, action) {
    var _a;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case propertyConstants_1.PROPERTY_INDEX_LOADING:
        case propertyConstants_1.PROPERTY_SAVE_LOADING:
        case propertyConstants_1.GET_JORDSTYKKER_AUTOCOMPLETE_LOADING:
        case propertyConstants_1.GET_PROPERTY_CADESTREAL_FROM_BOUNDS_LOADING:
        case propertyConstants_1.GET_PROPERTY_MAPCENTER_LOADING:
        case propertyConstants_1.PROPERTY_DELETE_LOADING:
            return __assign(__assign({}, state), { loadings: __assign(__assign({}, state.loadings), (_a = {}, _a[action.loadingType] = true, _a)) });
        case propertyConstants_1.PROPERTY_INDEX_FAILED:
        case propertyConstants_1.PROPERTY_SAVE_FAILED:
        case propertyConstants_1.GET_PROPERTY_CADESTREAL_FROM_BOUNDS_FAILED:
        case propertyConstants_1.GET_JORDSTYKKER_AUTOCOMPLETE_FAILED:
        case propertyConstants_1.GET_PROPERTY_MAPCENTER_FAILED:
        case propertyConstants_1.PROPERTY_DELETE_FAILED:
            return __assign(__assign({}, state), { loadings: lookupReducer_1.initialLookupLoadings });
        case propertyConstants_1.GET_JORDSTYKKER_AUTOCOMPLETE_SUCCESS:
            return __assign(__assign({}, state), { jordstykker: action.jordstykker, loadings: __assign(__assign({}, state.loadings), { panel: false }) });
        case propertyConstants_1.PROPERTY_INDEX_SUCCESS:
            return __assign(__assign({}, state), { properties: action.properties, loadings: __assign(__assign({}, state.loadings), { properties: false }) });
        case propertyConstants_1.PROPERTY_SAVE_SUCCESS:
            return __assign(__assign({}, state), { properties: action.properties, newProperties: [], loadings: __assign(__assign({}, state.loadings), { post: false }) });
        case propertyConstants_1.PROPERTY_DELETE_SUCCESS:
            return __assign(__assign({}, state), { properties: state.properties.filter(function (property) { return !action.ids.includes(property.id); }), loadings: __assign(__assign({}, state.loadings), { mouse: false }) });
        case propertyConstants_1.CHANGE_PROPERTY_TITLE:
            return __assign(__assign({}, state), { properties: state.properties.map(function (property) {
                    if (property.id === action.id) {
                        return __assign(__assign({}, property), { title: action.title, updated: true });
                    }
                    return property;
                }) });
        case propertyConstants_1.CHANGE_PROPERTY_JORDSTYKKE:
            return __assign(__assign({}, state), { properties: state.properties.map(function (property) {
                    if (property.id === action.id) {
                        return __assign(__assign({}, property), { jordstykkeText: action.jordstykkeText, ejerlavkode: action.ejerlavkode, matrikelnr: action.matrikelnr, updated: true });
                    }
                    return property;
                }) });
        case propertyConstants_1.ADD_NEW_PROPERTY:
            return __assign(__assign({}, state), { newProperties: __spreadArray(__spreadArray([], state.newProperties, true), [
                    {
                        title: action.title,
                        jordstykkeText: action.jordstykkeText,
                        ejerlavkode: action.ejerlavkode,
                        matrikelnr: action.matrikelnr,
                    },
                ], false) });
        case propertyConstants_1.CHANGE_NEW_PROPERTY_TITLE:
            return __assign(__assign({}, state), { newProperties: state.newProperties.map(function (property, index) {
                    if (index === action.index) {
                        return __assign(__assign({}, property), { title: action.title });
                    }
                    return property;
                }) });
        case propertyConstants_1.CHANGE_NEW_PROPERTY_JORDSTYKKE:
            return __assign(__assign({}, state), { newProperties: state.newProperties.map(function (property, index) {
                    if (index === action.index) {
                        return __assign(__assign({}, property), { jordstykkeText: action.jordstykkeText, ejerlavkode: action.ejerlavkode, matrikelnr: action.matrikelnr });
                    }
                    return property;
                }) });
        case propertyConstants_1.REMOVE_NEW_PROPERTY:
            return __assign(__assign({}, state), { newProperties: state.newProperties.filter(function (_, index) { return index !== action.index; }) });
        case propertyConstants_1.RESET_NEW_PROPERTIES:
            return __assign(__assign({}, state), { newProperties: [] });
        case propertyConstants_1.GET_PROPERTY_MAPCENTER_SUCCESS:
            return __assign(__assign({}, state), { map: __assign(__assign({}, state.map), { center: action.center }), loadings: __assign(__assign({}, state.loadings), { map: false }) });
        case propertyConstants_1.GET_PROPERTY_CADESTREAL_FROM_BOUNDS_SUCCESS:
            return __assign(__assign({}, state), { map: __assign(__assign({}, state.map), { map: action.map }), loadings: __assign(__assign({}, state.loadings), { map: false }) });
        default:
            return state;
    }
}
exports.default = reducer;
