"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var mui_1 = require("tss-react/mui");
var material_1 = require("@mui/material");
var useStyles = (0, mui_1.makeStyles)()(function () { return ({
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
    },
}); });
function VerticalCarousel(props) {
    var slides = props.slides, index = props.index, modBySlidesLength = props.modBySlidesLength, moveSlide = props.moveSlide, slideMap = props.slideMap, clampOffsetRadius = props.clampOffsetRadius, offsetRadiusConfig = props.offsetRadiusConfig;
    var classes = useStyles().classes;
    (0, react_1.useEffect)(function () {
        var handleKeyDown = function (event) {
            if (event.isComposing || event.keyCode === 229) {
                return;
            }
            if (event.keyCode === 38) {
                moveSlide(-1);
            }
            if (event.keyCode === 40) {
                moveSlide(1);
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return function () {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [index]);
    var getPresentableSlides = function () {
        var offsetRadius = clampOffsetRadius(offsetRadiusConfig);
        var presentableSlides = [];
        for (var i = -offsetRadius; i < 1 + offsetRadius; i++) {
            presentableSlides.push(slides[modBySlidesLength(index + i)]);
        }
        return presentableSlides;
    };
    return react_1.default.createElement(material_1.Box, { className: classes.root }, getPresentableSlides().map(slideMap));
}
exports.default = VerticalCarousel;
