"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function Emit(socket) {
    function emit(event, header, data, params) {
        if (data === void 0) { data = {}; }
        if (params === void 0) { params = {}; }
        return new Promise(function (resolve, reject) {
            if (!socket.connected) {
                reject(new Error("Socket is not connected"));
            }
            socket.emit(event, header, data, params, function (error, response) {
                if (error !== null) {
                    reject(error);
                }
                else {
                    resolve(response);
                }
            });
        });
    }
    function joinRoom(room) {
        return new Promise(function (resolve, reject) {
            socket.emit("JOIN_ROOM", room, function (error, response) {
                if (error !== null) {
                    reject(error);
                }
                else {
                    resolve(response !== null && response !== void 0 ? response : true);
                }
            });
        });
    }
    emit.joinRoom = joinRoom;
    emit.leaveRoom = function leaveRoom(room) {
        return new Promise(function (resolve, reject) {
            socket.emit("LEAVE_ROOM", room, function (error) {
                if (error !== null) {
                    reject(error);
                }
                else {
                    resolve(true);
                }
            });
        });
    };
    return emit;
}
exports.default = Emit;
